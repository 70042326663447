import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, FormGroup } from '@angular/forms';
import {
  AccountService,
  AuthenticationService,
  IInitRegisterViaMobileIdReq,
  IInitRegisterViaSmartIdReq,
  InfoService
} from 'src/app/services';
import { IRegisterAccountReq } from 'src/app/models';
import { MessageDispatcher } from 'src/app/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationOption } from 'src/app/components/_elements/authentication-options/authentication-options.component';
import { ActionHelper } from 'src/app/helpers/action';
import { VerificationSuccessDialogComponent } from 'src/app/components/dialogs/register-success/verification-success.dialog';
import { TermsOfUseDialogComponent } from 'src/app/components/dialogs/terms-of-use/terms-of-use.dialog';
import { VerificationMethod } from '../user/settings/verification/user-verification.view';
import { PendingVerificationDialogComponent } from 'src/app/components/dialogs/pending-verification/pending-verification.dialog';
import { AppState } from 'src/app/app.state';
import { VerificationReminderDialogComponent } from 'src/app/components/dialogs/verification-reminder/verification-reminder.dialog';
import { BANK_LINK_PROVIDER_OPTIONS, BankLinkProviderType } from 'src/app/components/_elements/providers/providers.component';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { BANK_OPTIONS } from 'src/app/components/_elements/bank-login-options/bank-login-options.component';


@Component({
  selector: 'register',
  templateUrl: './register.view.html',
  styleUrls: ['./register.view.scss']
})
export class RegisterViewComponent {
  public actionLoading = {} as any;
  public isAuthenticated = false;

  public AuthenticationOption = AuthenticationOption;

  public authenticationOptions = [
    AuthenticationOption.SmartID,
    AuthenticationOption.MobileID,
    AuthenticationOption.Password,
    AuthenticationOption.Bank
  ];
  public countryPrefixOptions = COUNTRY_PREFIX_OPTIONS;

  @ViewChild('captchaRef2') captchaRef2: ElementRef;
  private _reCaptchaId: number;

  public isNotRobot = false;

  public controls = new FormGroup({
    selectedView: new FormControl()
  });

  public zimplerCountryOptions = [
    {
      label: 'Common.Countries.Estonia',
      value: 'ee',
      providerOptions: [
        BankLinkProvider.ZimplerSwedbank,
        BankLinkProvider.ZimplerLHV,
        BankLinkProvider.ZimplerSEB,
        BankLinkProvider.ZimplerLuminor,
      ]
    },
    {
      label: 'Common.Countries.Finland',
      value: 'fi',
      providerOptions: [
        BankLinkProvider.ZimplerOPBank,
        BankLinkProvider.ZimplerNordea,
        BankLinkProvider.ZimplerDanskeBank,
        BankLinkProvider.ZimplerAktia,
        BankLinkProvider.ZimplerAlandsBanken,
        BankLinkProvider.ZimplerPOPPankki,
        BankLinkProvider.ZimplerSPankki,
        BankLinkProvider.ZimplerHandelsbanken,
        BankLinkProvider.ZimplerSäästöpankki,
        BankLinkProvider.ZimplerOMASäästöpankki
      ]
    }
  ];

  public formGroup = new FormGroup({
    prefix: new FormControl(this.countryPrefixOptions[0]),
    email: new FormControl(),
    username: new FormControl(),
    password: new FormControl(),
    idCode: new FormControl(),
    termsAndConditions: new FormControl(false),
    hasCoupon: new FormControl(false),
    coupon: new FormControl(),
    recaptcha: new FormControl(),
    phoneNumber: new FormControl(),
    zimplerCountry: new FormControl(this.zimplerCountryOptions[0])
  });

  protected showExperimentalVerifications: boolean = false;

  constructor(
    private _appState: AppState,
    private _accountService: AccountService,
    private _msg: MessageDispatcher,
    private _authService: AuthenticationService,
    private _router: Router,
    private _action: ActionHelper,
    private _info: InfoService,
    private _route: ActivatedRoute
  ) {
    // this.showExperimentalVerifications = window.__TOTO_DEV_SETTINGS.SHOW_EXPERIMENTAL_PAYMENTS;
    // if (!this.showExperimentalVerifications) {
    //   this.authenticationOptions.pop();
    // }

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";    

    eventer(messageEvent,function(e) {
      var key = e.message ? "message" : "data";
      var data = e[key];

      if(data.toString().startsWith('url:')) {
        window.location.href = data.substring(4);
      }

    },false);
  }

  ngAfterViewInit() {
    this.isNotRobot = true;
    // const grecaptcha = (window as any).grecaptcha;
    // if (grecaptcha) {
    //   this._reCaptchaId = grecaptcha.render(this.captchaRef2.nativeElement, {
    //     sitekey: '6LfO4GccAAAAANKTwgJ8WJUBMmVxbUWmGFttPXjs',
    //     callback: resonse => this.reCapchaSuccess(resonse),
    //     'expired-callback': () => this.reCapchaExpired()
    //   });
    // }
  }

  public get selectedView() {
    return this.controls.get('selectedView').value;
  }

  reCapchaSuccess(data: any) {
    if (data) {
      this.isNotRobot = true;
    }
  }

  reCapchaExpired() {
    this.isNotRobot = false;
  }

  public tempButton() {
    this._authService.openVerificationReminderDialog();
  }

  public tempButton2() {
    this._authService.openVerificationSuccessDialog();
  }

  public tempButton3() {
    this.openPendingVerificationDialog(
      { verificationCode: '1234' },
      VerificationMethod.SmartId,
      null
    );
  }

  public tempButton4() {
    this.openPendingVerificationDialog(
      { verificationCode: '1234' },
      VerificationMethod.MobileId,
      null
    );
  }

  public openTermsOfUseDialog() {
    this._info.getTermsAndConditions().result(null, x => {
      this._action.openDialog(
        TermsOfUseDialogComponent,
        {
          width: '90vw',
          height: '90dvh',
          data: { pages: x }
        },
        res => {}
      );
    });
  }

  public get canRegister() {
    if (
      location.hostname === 'localhost' ||
      location.hostname === '127.0.0.1'
    ) {
      return true;
    }
    return this.isNotRobot;
  }

  public openPendingVerificationDialog(
    data = {},
    method = VerificationMethod.SmartId,
    action = 'login'
  ) {
    this._action.openDialog(
      PendingVerificationDialogComponent,
      {
        minWidth: '320px',
        data: {
          data: data,
          method: method,
          action: action,
          formGroup: this.formGroup
        }
      },
      res => {
        if (res !== null && res !== undefined) {
          if (res.exception || res.validationResults || res.validationSummary) {
            return;
          }
          this.eraseAffiliateCookies();
          this._authService.updateToken(() => {
            this._accountService.getAccountInfo().result(null, info => {
              this._appState.setAccountInfo(info, 'register');
              this._router
                .navigate(['/' + this._authService.activeAppModuleRoot])
                .then(res => {
                  this._authService.openVerificationSuccessDialog();
                });
            });
          });
        }
      }
    );
  }

  public registerViaMobileId(): void {
    const form = this.formGroup.value;
    const {
      idCode,
      prefix,
      phoneNumber,
      termsAndConditions,
      hasCoupon,
      coupon,
      email,
      username
    } = form;

    if (this.actionLoading.register) {
      return;
    }

    let req: IInitRegisterViaMobileIdReq = {
      idCode: idCode,
      prefix: prefix.value,
      phone: phoneNumber,
      acceptTerms: termsAndConditions || false,
      hasCoupon: hasCoupon,
      coupon: coupon,
      email: email,
      userName: username,
      clickId: this.getClickId(),
      externalClickId: this.getExternalClickId()
    };

    const msg = this._action.createMessage();
    this._accountService.initRegisterViaMobileId(req).result(
      this.formGroup,
      res => {
        this.openPendingVerificationDialog(
          res,
          VerificationMethod.MobileId,
          'register'
        );
      },
      e => {
        console.error(e);
      },
      'register',
      this.actionLoading,
      msg
    );
  }

  public registerViaSmartId(): void {
    const form = this.formGroup.value;
    const {
      idCode,
      email,
      username,
      termsAndConditions,
      hasCoupon,
      coupon
    } = form;

    if (this.actionLoading.register) {
      return;
    }

    let req: IInitRegisterViaSmartIdReq = {
      idCode: idCode,
      email: email,
      userName: username,
      acceptTerms: termsAndConditions || false,
      hasCoupon: hasCoupon,
      coupon: coupon,
      clickId: this.getClickId(),
      externalClickId: this.getExternalClickId()
    };

    const msg = this._action.createMessage();
    this._accountService.initRegisterViaSmartId(req).result(
      this.formGroup,
      res => {
        this.openPendingVerificationDialog(
          res,
          VerificationMethod.SmartId,
          'register'
        );
      },
      e => {
        if (
          e.validationSummary &&
          e.validationSummary.startsWith('{"type":"about:blank"')
        ) {
          e.validationSummary = 'AppMessages.Register.Errors.SmartIdFailed';
        }
        console.error(e);
      },
      'register',
      this.actionLoading,
      msg
    );
  }

  public registerWithPassword() {
    const form = this.formGroup.value;
    const req: IRegisterAccountReq = {
      email: form.email,
      username: form.username,
      password: form.password,
      coupon: form.coupon,
      acceptTerms: form.termsAndConditions || false,
      clickId: this.getClickId(),
      externalClickId: this.getExternalClickId()
    };

    const msg = this._action.createMessage();
    this._accountService.registerAccount(req).result(
      this.formGroup,
      token => {
        this._msg.ok({
          message: 'AppMessages.Register.RegisterSuccess'
        });
        this.eraseAffiliateCookies();
        this._authService.setToken(token);
        this._router
          .navigate([this._authService.activeAppModuleRoot])
          .then(res => {
            this._authService.openVerificationReminderDialog();
          });
      },
      err => {
        this._msg.error({
          message: 'AppMessages.Register.RegisterFailed'
        });
      },
      'register',
      this.actionLoading,
      msg
    );
  }

  public registerViaZimpler() {
    
    const form = this.formGroup.value;
    const {
      termsAndConditions,
      hasCoupon,
      coupon,
      email,
      username,
      zimplerCountry
    } = form;

    if (this.actionLoading.register) {
      return;
    }
    const allowIFrame = !(this._appState.isViewTablet || this._appState.isViewMobile);
    const msg = this._action.createMessage();
    const req = {
      userName: username,
      email: email,
      clickId: this.getClickId(),
      externalClickId: this.getExternalClickId(),
      acceptTerms: termsAndConditions || false,
      country: zimplerCountry.value,
      iframe: allowIFrame
    };

    this._authService.initRegisterViaZimpler(req).result(
      this.formGroup,
      res => {
        const zimplerLink = res.url;
          if (zimplerLink) {
            // Redirect on smaller screens
            if (!allowIFrame) {
              window.open(zimplerLink, '_self');
            } else {
              var iframe = document.getElementById('trustlyFrame');
              iframe.addEventListener("load", function() {            
                  var trustlyOverlay = document.getElementById('trustlyOverlay');
                  trustlyOverlay.style.display = '';
              });
              iframe.setAttribute('src', zimplerLink);
            }     
          }
      },
      e => {
        console.error(e);
      },
      'register',
      this.actionLoading,
      msg
    );
  }

  private getClickId(): string {
    const cookie = this.getCookie('affiliateClickId');

    return cookie;
  }

  private getExternalClickId(): string {
    const cookie = this.getCookie('affiliateExternalId');

    return cookie;
  }

  private getCookie(cname): string {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  private eraseAffiliateCookies() {
    this.eraseCookie('affiliateClickId');
    this.eraseCookie('affiliateExternalId');
  }

  private eraseCookie(name) {
    document.cookie = name + '=; Path=/; Max-Age=-99999999;';
  }

  public register() {
    if (this.actionLoading.register) {
      return;
    }

    switch (this.selectedView) {
      case AuthenticationOption.Password: {
        this.registerWithPassword();
        break;
      }
      case AuthenticationOption.SmartID: {
        this.registerViaSmartId();
        break;
      }
      case AuthenticationOption.IDCard: {
        // Method not implemented
        break;
      }
      case AuthenticationOption.MobileID: {
        this.registerViaMobileId();
        break;
      }
      case AuthenticationOption.Bank: {
        this.registerViaZimpler();
      }
    }
  }

  public onNavigateBack() {
    this.controls.get('selectedView').reset();
  }

  public openLoginDialog() {
    this._authService.openLoginDialog(true);
  }
}

export const COUNTRY_PREFIX_OPTIONS = [
  {
    label: '+372 (EE)',
    value: '+372'
  }
  //,
  // {
  //   label: '+370 (LT)',
  //   value: '+370'
  // },
]