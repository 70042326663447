import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICoupon, IEstTotoCoupon } from '../models';


@Injectable({ providedIn: 'root' })
export class BettingService {

  constructor(private _http: HttpClient) {};

  public getPmuBettingCard(date: string, eventId: any, raceNr): Observable<any> {
    return this._http.get<any>(`api/Betting/pmu/BettingCard/${date}/${eventId}/${raceNr}`) as Observable<any>;
  }

  public getPmuRacingCard(date: string, eventId: any, raceNr, betType, betTypes: string[]): Observable<any> {
    const betTypesQs = betTypes.map(x => `betTypes=${x}`).join('&');
    return this._http.get<any>(`api/Betting/Pmu/RacingCard/${date}/${eventId}/${raceNr}/${betType}?${betTypesQs}`) as Observable<any>;
  }

  public getEstTotoBettingCard(eventId: any, trackId: any): Observable<any> {
    return this._http.get<any>(`api/Betting/EstToto/BettingCard/${eventId}/${trackId}`) as Observable<any>;
  }  

  public getEstTotoRacingCard(date: string, eventId: any, raceNr, betType, trackId): Observable<any> {
    return this._http.get<any>(`api/Betting/EstToto/RacingCard/${date}/${eventId}/${raceNr}/${betType}/${trackId}`) as Observable<any>;
  }

  public selectPmuStart(data): Observable<any> {
    return this._http.post<any>('api/Betting/Pmu/SelectStart', data) as Observable<any>;
  }

  public selectEstTotoStart(data): Observable<any> {
    return this._http.post<any>('api/Betting/EstToto/SelectStart', data) as Observable<any>;
  }

  public selectAllEstTotoStarts(data): Observable<any> {
    return this._http.post<any>('api/Betting/EstToto/SelectAllStarts', data) as Observable<any>;
  }

  public setStake(data: ISetStakeReq): Observable<any> {
    return this._http.post<any>('api/Betting/Pmu/SetStake', data) as Observable<any>;
  }

  public setEstTotoStake(data: ISetStakeEstTotoReq): Observable<any> {
    return this._http.post<any>('api/Betting/EstToto/SetStake', data) as Observable<any>;
  }

  public setBaseRunnersOrder(data: IRunnersOrderReq): Observable<any> {
    return this._http.post<any>('api/Betting/pmu/SetBaseRunnersOrder', data) as Observable<any>;
  }

  public setAssociatedRunnersOrder(data: IRunnersOrderReq): Observable<any> {
    return this._http.post<any>('api/Betting/pmu/SetAssociatedRunnersOrder', data) as Observable<any>;
  }

  public resetBet(data: IBetResetReq): Observable<any> {
    return this._http.post<any>('api/Betting/pmu/Reset', data) as Observable<any>;
  }

  public resetEstTotoBet(data: IBetResetEstTotoReq): Observable<any> {
    return this._http.post<any>('api/Betting/EstToto/Reset', data) as Observable<any>;
  }

  public saveBet(data: IBetSaveReq): Observable<any> {
    return this._http.post<any>('api/Betting/pmu/Save', data) as Observable<any>;
  }

  public saveEstTotoBet(data: IBetSaveEstTotoReq): Observable<any> {
    return this._http.post<any>('api/Betting/EstToto/Save', data) as Observable<any>;
  }

  public placeBet(data): Observable<any> {
    return this._http.post<any>('api/Betting/pmu/PlaceBet', data) as Observable<any>;
  }

  public placeEstTotoBet(data: IPlaceEstTotoBetReq): Observable<any> {
    return this._http.post<any>('api/Betting/EstToto/PlaceBet', data) as Observable<any>;
  }

  public getCoupon(id): Observable<any> {
    return this._http.get<any>(`api/Betting/Coupon/${id}`) as Observable<any>;
  }

  public setSpot(data: ISetSpotReq): Observable<any> {
    return this._http.post<any>('api/Betting/pmu/SetSpot', data) as Observable<any>;
  }

  public placeSportsBet(data: any) {
    return this._http.post<any>('api/sport/betting/sell', data) as Observable<any>;
  }

  public getTicketStatus(ticketId: any) {
    return this._http.get<any>(`api/sport/tickets/${ticketId}/status`) as Observable<any>;
  }

}

export interface IBetSaveReq {
  coupon: ICoupon;
  betTypes: string[];
}

export interface IBetResetReq {
  coupon: ICoupon;
  betTypes: string[];
}

export interface IBetSaveRes {
  showMessages: boolean;
}

export interface ISetStakeReq {
  coupon: ICoupon;
  betTypes: string[];
  stake: string | number;
}

export interface ISetStakeEstTotoReq {
  coupon: IEstTotoCoupon;
  stake: string | number;
}

export interface IRunnersOrderReq {
  coupon: ICoupon;
  orderedList: number[];
}

export interface ISetSpotReq {
  coupon: ICoupon;
  betTypes: string[];
  spot: boolean;
  spotSize: number;
  inAllOrders: boolean;
  stake: number;
}

export interface IBetSaveEstTotoReq {
  coupon: IEstTotoCoupon;
}

export interface ISelectEstTotoStartReq {
  coupon: IEstTotoCoupon;
  rowId: number;
  startNr: number;
  remove: boolean;
}

export interface IPlaceEstTotoBetReq {
  coupon: IEstTotoCoupon;
}

export interface IBetResetEstTotoReq {
  coupon: IEstTotoCoupon;
}