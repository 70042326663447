import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { KnownPageSection } from 'src/app/enum/KnownPageSection';
import { ViewBase } from 'src/app/views/view.base';
import { TitleService } from 'src/app/services';
import { PagesService } from 'src/app/services/pages.service';
import { capitalizeFirstLetter } from 'src/app/util/misc';
import { ResponsiveHelper } from 'src/app/helpers';

@Component({
  selector: 'support-layout',
  templateUrl: './support.layout.html',
  styleUrls: ['./support.layout.scss'],
  host: { class: 'layout' }
})
export class SupportLayout {
  public get titleFragments() {
    return [{ key: this.pageTitle }, { key: this.selectedSection?.title }];
  }

  public KnownPageSection = KnownPageSection;

  public pageSection: KnownPageSection;
  public selectedSection = null;

  private _routeParamsSubscription: Subscription;

  public data: any;

  public openPageId = null;

  constructor(
    private _titleService: TitleService,
    private _appState: AppState,
    private _route: ActivatedRoute,
    private _router: Router,
    private _pagesService: PagesService
  ) {
    const { sectionId } = this._route.snapshot.queryParams || {};
    const { pages, PageSection, page } = this._route.snapshot.data || {};

    const sectionData = pages[KnownPageSection[PageSection]];
    this.pageSection = PageSection;

    this.data = sectionData;

    const initSectionId = sectionId ?? (this.isTablet ? null : this.data[0]?.id);

    this._init(initSectionId, page);
    this._createAskly();
  }

  public get isTablet() {
    return this._appState.isViewTablet;
  }

  public toggleChat() {
    const win = window as any;
    win.asklyApi?.openChatTab();
  }

  public get pageTitle() {
    return this.pageSection === KnownPageSection.HowTo
      ? 'Navigator.Links.Instructions'
      : 'Navigator.Links.Support';
  }

  private _scrollPageIntoView(pageId: number) {
    setTimeout(() => {
      document.getElementById(`page${pageId}`)?.scrollIntoView();
    });
  }

  public onSectionClick(section, pageId = null) {
    this.selectedSection = section;

    if (section.isGroupOnly) {
      this.onPageClick(section);
    }

    this.mobileCategoriesOpen = false;

    this._router.navigate([], {
      queryParams: {
        sectionId: section.id,
        pageId: pageId
      },
      queryParamsHandling: 'merge'
    });


    this._titleService.setTitleFragments(this.titleFragments);
  }

  public onPageClick(page, scrollIntoView = false) {
    const pageId = page.id;

    if (page.isGroupOnly) {
      if (!page.content) {
        this._pagesService.getPageById(page.id).result(null, res => {
          page.content = res.content;
        });
      }
    } else {
      const toggle = this.openPageId == pageId ? false : true;
      if (!page.content && toggle) {
        this._pagesService.getPageById(page.id).result(null, res => {
          page.content = res.content;
          this.openPageId = toggle ? page.id : null;
          if (scrollIntoView && toggle) {
            this._scrollPageIntoView(page.id);
          }
        });
      } else {
        this.openPageId = toggle ? pageId : null;
        if (scrollIntoView && toggle) {
          this._scrollPageIntoView(page.id);
        }
      }

      this.mobileCategoriesOpen = false;

      this._router.navigate([], {
        queryParams: {
          pageId: page.id
        },
        queryParamsHandling: 'merge'
      });
    }

    this._titleService.setTitleFragments(this.titleFragments);
  }

  private _init(sectionId, pageData = null) {
    const selectedSection = this.data.find(x => x.id == sectionId);

    if (selectedSection) {
      this.onSectionClick(selectedSection);
    }

    if (pageData) {
      const pageId = pageData.id;
      let selectedPage = selectedSection?.children?.find(x => x.id == pageId);

      if (selectedPage) {
        selectedPage.content = pageData.content;
      }

      if (selectedPage) {
        this.onPageClick(selectedPage, true);
      }
    }

    this._titleService.setTitleFragments(this.titleFragments);
  }

  public get asklyScriptElement() {
    return document.getElementById('asklyScriptRef');
  }

  public get asklyChatElement() {
    return document.getElementById('tw-chat-root');
  }

  private _createAskly() {
    if (!this.asklyScriptElement) {
      var win = window as any;
      win.asklyConfig = {
        onConfigLoaded: function() {
          document.getElementById('tw-chat-root').style.display = 'none';
        }
      };
      let script = document.createElement('script');
      script.setAttribute('id', 'asklyScriptRef');
      script.setAttribute('src', 'https://chat.askly.me/cw/chat/latest.js');
      script.setAttribute('tw-client-key', 'yo96tusijbqmfqs2tjcizwss');
      script.setAttribute('async', '');
      document.body.appendChild(script);

      this._handleAsklyDisplay();
    }
  }

  public asklyDisplayInterval: NodeJS.Timeout;

  private _handleAsklyDisplay() {
    var win = window as any;
    this.asklyDisplayInterval = setInterval(() => {
      if (win.asklyApi) {
        clearTimeout(this.asklyDisplayInterval);
      } else {
        return;
      }
      var openBase = win.asklyApi.openChatTab;
      win.asklyApi.openChatTab = function() {
        document.getElementById('tw-chat-root').style.display = '';
        openBase();

        setTimeout(() => {
          var iframeRef = document
            .getElementsByTagName('iframe')[0]

          var btnMin = iframeRef.contentDocument.getElementsByClassName('btn-min-desktop')[0] ?? iframeRef.contentDocument.getElementsByClassName('btn-min-mob')[0];

          var newBtnMin = btnMin.cloneNode(true);
          btnMin.parentNode.replaceChild(newBtnMin, btnMin);

          newBtnMin.addEventListener('click', function() {
            win.asklyApi.minimize();
          });
        }, 100);
      };

      var minimizeBase = win.asklyApi.minimize;
      win.asklyApi.minimize = function() {
        minimizeBase();
        document.getElementById('tw-chat-root').style.display = 'none';
      };
    }, 200);
  }

  // private _removeAskly() {
  //   if (this.asklyScriptElement) {
  //     this.asklyScriptElement.remove();
  //   }
  //   if (this.asklyChatElement) {
  //     this.asklyChatElement.remove();
  //   }
  // }

  // MOBILE SPECIFIC

  public mobileCategoriesOpen = true;
  public toggleMobileCategories(toSet = this.mobileCategoriesOpen) {
    this.mobileCategoriesOpen = !toSet;
  }

  public onTitleSectionClick() {
    if (this.selectedSection) {
      this.selectedSection = null;
      this.mobileCategoriesOpen = true;

      this._router.navigate([], {
        queryParams: {
          sectionId: null,
          pageId: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  ngOnDestroy() {
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
    // this._removeAskly();
  }
}
