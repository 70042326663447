<div class="bank-login-wrapper">
    <div class="bank-login-content">

        <div class="header">
            <svg xmlns="http://www.w3.org/2000/svg" width="73" height="23" viewBox="0 0 73 23" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06867 0L0 5.49584H5.3295L2.08145 22.2074H7.57819L10.8262 5.49584H13.6575L20.2225 0H1.06867Z" fill="#111111"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1238 5.55159C25.3467 5.55159 24.5865 5.70004 23.8447 5.99605C23.102 6.29161 22.4322 6.69002 21.8357 7.18903C21.2392 7.68894 20.7268 8.27693 20.2993 8.95212C19.8723 9.62775 19.5843 10.345 19.4372 11.1038C19.2861 11.8809 19.2919 12.612 19.4551 13.2966C19.6179 13.9811 19.9014 14.5736 20.3033 15.0735C20.7053 15.5725 21.2209 15.9709 21.8478 16.2665C22.4756 16.5634 23.1776 16.7109 23.9543 16.7109C24.7135 16.7109 25.4594 16.5634 26.1922 16.2665C26.9251 15.9709 27.5904 15.5725 28.1874 15.0735C28.7839 14.5736 29.2967 13.9811 29.7255 13.2966C30.1548 12.612 30.4455 11.8809 30.5966 11.1038C30.7441 10.345 30.7352 9.62775 30.5702 8.95212C30.4057 8.27693 30.1222 7.68894 29.7197 7.18903C29.3169 6.69002 28.8067 6.29161 28.1887 5.99605C27.5708 5.70004 26.8822 5.55159 26.1238 5.55159ZM22.8867 22.2072C21.3503 22.2072 19.9682 21.9157 18.7404 21.3331C17.5112 20.75 16.4908 19.9545 15.6788 18.9453C14.8659 17.9375 14.2984 16.7624 13.9765 15.42C13.6541 14.0782 13.6425 12.6402 13.9412 11.1038C14.2358 9.58661 14.8064 8.15173 15.6524 6.80046C16.4979 5.44964 17.5219 4.27008 18.7269 3.26177C19.932 2.25301 21.2609 1.4571 22.7168 0.87447C24.1714 0.291396 25.6666 0.000305176 27.203 0.000305176C28.721 0.000305176 30.0987 0.291396 31.3359 0.87447C32.5732 1.4571 33.5985 2.25301 34.4114 3.26177C35.2234 4.27008 35.7899 5.44964 36.111 6.80046C36.4311 8.15173 36.4432 9.58661 36.1485 11.1038C35.8498 12.6402 35.2788 14.0782 34.4351 15.42C33.5913 16.7624 32.5669 17.9375 31.3628 18.9453C30.1577 19.9545 28.823 20.75 27.359 21.3331C25.8946 21.9157 24.4039 22.2072 22.8867 22.2072Z" fill="#111111"/>
                <mask id="mask0_6238_74538" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="36" y="0" width="21" height="23">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M36.6401 0.000305176H56.8626V22.2072H36.6401V0.000305176Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_6238_74538)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M50.2977 5.49616H47.4664L44.2183 22.2073H38.7216L41.9696 5.49616H36.6401L37.7088 -0.00012207H56.8626L50.2977 5.49616Z" fill="#111111"/>
                </g>
                <mask id="mask1_6238_74538" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="50" y="0" width="24" height="23">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M50.3662 0.000305176H73.0007V22.2072H50.3662V0.000305176Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_6238_74538)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M62.7641 5.55159C61.987 5.55159 61.2268 5.70004 60.485 5.99605C59.7423 6.29161 59.0734 6.69002 58.4769 7.18903C57.8786 7.68894 57.3666 8.27693 56.9396 8.95212C56.5122 9.62775 56.2255 10.345 56.0784 11.1038C55.9268 11.8809 55.9322 12.612 56.095 13.2966C56.2582 13.9811 56.5412 14.5736 56.9441 15.0735C57.3465 15.5725 57.8607 15.9709 58.4881 16.2665C59.1154 16.5634 59.8179 16.7109 60.5946 16.7109C61.3538 16.7109 62.0997 16.5634 62.8325 16.2665C63.5658 15.9709 64.2307 15.5725 64.8272 15.0735C65.4246 14.5736 65.9375 13.9811 66.3667 13.2966C66.7956 12.612 67.0862 11.8809 67.2373 11.1038C67.3849 10.345 67.3755 9.62775 67.2114 8.95212C67.0464 8.27693 66.762 7.68894 66.36 7.18903C65.9572 6.69002 65.447 6.29161 64.8295 5.99605C64.2106 5.70004 63.5229 5.55159 62.7641 5.55159ZM59.5263 22.2072C57.9909 22.2072 56.6083 21.9157 55.38 21.3331C54.1517 20.75 53.1313 19.9545 52.3188 18.9453C51.5064 17.9375 50.9385 16.7624 50.617 15.42C50.2951 14.0782 50.283 12.6402 50.5812 11.1038C50.8764 9.58661 51.4469 8.15173 52.2925 6.80046C53.1376 5.44964 54.1624 4.27008 55.3679 3.26177C56.5721 2.25301 57.9023 1.4571 59.3573 0.87447C60.8119 0.291396 62.3071 0.000305176 63.8435 0.000305176C65.3602 0.000305176 66.7379 0.291396 67.976 0.87447C69.2137 1.4571 70.2386 2.25301 71.0515 3.26177C71.8635 4.27008 72.4305 5.44964 72.7506 6.80046C73.0708 8.15173 73.0837 9.58661 72.7886 11.1038C72.4904 12.6402 71.9189 14.0782 71.0752 15.42C70.2314 16.7624 69.207 17.9375 68.0028 18.9453C66.7973 19.9545 65.4635 20.75 63.9996 21.3331C62.5347 21.9157 61.0439 22.2072 59.5263 22.2072Z" fill="#111111"/>
                </g>
              </svg>
        </div>

        <p class="login">{{'Views.User.Login.LogIn' | translate}}</p>

        <p class="bank-subheader">{{'Components.AuthOptions.Bank.ChooseBank' | translate}}</p>

        <div class="login-options">
            
        </div>

        <div class="footer">
            <div class="terms-of-user">
                Kasutajatingimused
            </div>
            <div class="zimpler">
                Zimpler
            </div>
            <div class="language">
                Keel
            </div>
        </div>
    </div>
</div>
