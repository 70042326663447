<mat-form-field [formGroup]="formGroup" [class.show-input]="showInput">
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate [formControlName]="from">
    <input matEndDate [formControlName]="to">
  </mat-date-range-input>    
  <mat-datepicker-toggle matSuffix suffix [for]="picker">
    <span class="datepicker-icon" matDatepickerToggleIcon></span>
  </mat-datepicker-toggle>
  <mat-date-range-picker #picker [touchUi]="touchUi">
  </mat-date-range-picker>
</mat-form-field> 
