export enum IceHockeyMarketTypeValue
{
    PuckLineWithOvertime = 141,
    HighestScoringPeriod = 156,
    FirstPeriodTotalGoalsOverUnder = 182,
    MoneyLine = 183,
    RaceTo3Goals = 187,
    MatchResult = 202,
    TotalMatchGoalsOverUnderWithOvertime = 282,
    TeamToScoreNextGoal = 298,
    Handicap = 299,
    FirstPeriodMoneyLine = 6472,
    FirstPeriodResult = 6576,
    DoubleChance = 6713,
    SecondPeriodResult = 6719,
    ThirdPeriodResult = 6720,
    CorrectScore = 6786,
    SecondPeriodMoneyLine = 6814,
    ThirdPeriodMoneyLine = 6815,
    SecondPeriodTotalGoalsOverUnder = 6826,
    ThirdPeriodTotalGoalsOverUnder = 6827,
    MatchToGoToShootout = 6840,
    AwayTeamTotalGoalsOddOrEven = 7003,
    HomeTeamTotalGoalsOddOrEven = 7004,
    OddEvenTotal = 7355,
    HomeTeamTotalGoals = 7504,
    AwayTeamTotalGoals = 7505,
    FirstPeriodPuckLine = 7642,
    FirstPeriodCorrectScore = 8264,
    WhenWillTheGameEnd = 8281,
    DrawNoBet = 8482,
    WillThereBeOvertime = 8773,
    HomeTeamTotalMatchGoalsOverUnderWithOverTime = 9034,
    AwayTeamTotalMatchGoalsOverUnderWithOvertime = 9035,
    RaceTo2Goals = 9642,
    RaceTo4Goals = 9643,
    TotalMatchGoalsOverUnder = 10099,
    PuckLine = 10101,
    TotalGoalsOddOrEvenPreMatch = 10467,
    BothTeamsToScore = 10600,
    AwayTeamToWinToNilYesNo = 11590,
    HomeTeamToWinToNilYesNo = 11591,
    BothTeamsToScoreTwice = 11628,
    AwayTeamTotalMatchGoalsOverUnder = 12017,
    HomeTeamTotalMatchGoalsOverUnder = 12018,
    AwayTeamTotalGoalsOddOrEven60MinuteBetting = 12470,
    OvertimeMoneyLine = 12968,
    TotalPenaltyMinutesOverUnder = 12970,
    HomeTeamTotalGoalsOddOrEven60MinuteBetting = 12990,
    MatchResultAndTotalGoalsOverUnder60MinuteBetting = 14246,
    TotalGoals = 14248,
    TotalGoals60MinuteBetting = 14249,
    HomeTeamTotalGoals60MinuteBetting = 14250,
    AwayTeamTotalGoals60MinuteBetting = 14251,
    MatchResultAndBothTeamsToScore = 14621,
    CurrentPeriodPuckLine = 14622,
    CurrentPeriodTotalGoalsOverUnder = 14623,
    CurrentPeriodBothTeamsToScore = 14624,
    CurrentPeriodWinner3Way = 14625,
    CurrentPeriodEitherTeamToScoreYesNo = 14629,
    MatchResultAndTotalGoalsOverUnder = 15178,
    CurrentPeriodHandicapResult = 15304,
    CurrentPeriodMoneyLine = 15308,
    CurrentPeriodCorrectScore = 15313,
    CurrentPeriodDoubleChance = 15314,
    CurrentPeriodExactNumberOfGoals = 15315,
    CurrentPeriodHomeTeamToWinToNilYesNo = 15318,
    CurrentPeriodAwayTeamToWinToNilYesNo = 15319,
    WinningMargin60MinuteBetting = 15320,
    HomeTeamExactNumberOfGoals60MinuteBetting = 15321,
    HomeTeamExactNumberOfGoals = 15322,
    AwayTeamExactNumberOfGoals = 15323,
    AwayTeamExactNumberOfGoals60MInuteBetting = 15324,
    HomeTeamToWinToNilYesNo60MinuteBetting = 15325,
    AwayTeamToWinToNilYesNo60MinuteBetting = 15326,
    ExactNumberOfGoals60MinuteBetting = 15327,
    ExactNumberOfGoals = 15328,
}

(IceHockeyMarketTypeValue as any).__typeName = 'IceHockeyMarketTypeValue';