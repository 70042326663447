import { Component, Output, EventEmitter, Input, Optional } from '@angular/core';
import { ControlContainer, FormGroup, UntypedFormGroup } from '@angular/forms';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';


@Component({
  selector: 'authentication-options',
  templateUrl: './authentication-options.component.html',
  styleUrls: ['./authentication-options.component.scss']
})
export class AuthenticationOptionsComponent {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Output() onOptionSelect = new EventEmitter();

  @Input() public options = [AuthenticationOption.Password, AuthenticationOption.SmartID, AuthenticationOption.MobileID, AuthenticationOption.Bank];

  public authenticationOptions: any = {
    [AuthenticationOption.Password]: {
      icon: 'lock',
      label: 'Components.AuthOptions.Password',
      iconSize: '14px'
    },
    [AuthenticationOption.SmartID]: {
      icon: 'smartid',
      label: undefined,
      iconSize: '90px'
    },
    [AuthenticationOption.MobileID]: {
      icon: 'mobiilid',
      label: 'Mobiil-ID',
      iconSize: '30px'
    },
    [AuthenticationOption.Bank]: {
      icon: 'bank',
      label: 'Bank',
      iconSize: '14px'
    }
  }

  public showExperimentalVerifications: boolean = false;
  public AuthenticationOption = AuthenticationOption;

  constructor(@Optional() private _controlContainer: ControlContainer) 
  {
    //this.showExperimentalVerifications = window.__TOTO_DEV_SETTINGS.SHOW_EXPERIMENTAL_PAYMENTS;
    // if(!this.showExperimentalVerifications) {
    //   this.options.pop();
    // }
  }

  ngOnInit() {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  public selectOption(option: AuthenticationOption) {
    this.formGroup.get(this.for).setValue(option);
    this.onOptionSelect.emit(option);
  }

  public get selectedOption(): AuthenticationOption {
    return this.formGroup.get(this.for).value;
  }

  public isOptionSelected(option: AuthenticationOption): boolean {
    return this.selectedOption === option;
  }

}


export enum AuthenticationOption {
  SmartID = 1,
  IDCard = 2,
  Password = 3,
  MobileID = 4,
  Bank = 5
}