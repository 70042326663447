import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInfoPage } from '../models';


@Injectable({ providedIn: 'root' })
export class InfoService {

  constructor(private _http: HttpClient) {};

  public getInfoPage(pageId: any, language: string): Observable<IInfoPage> {
    return this._http.get<any>(`api/Pages/${pageId}/${language}`) as Observable<IInfoPage>;
  }

  public getTermsAndConditions(): Observable<any> {
    return this._http.get<any>(`api/site/terms`) as Observable<any>;
  }
}
