import { Component, OnDestroy, HostBinding, Input, EventEmitter, Output, ChangeDetectorRef  } from "@angular/core";

@Component({
  selector: "collapsable-section",
  templateUrl: "./collapsable-section.component.html",
  styleUrls: ['./collapsable-section.component.scss']
})
export class CollapsableSectionComponent {

  @Input() public type = 'default';
  @Input() public manualToggle = false;
  @Input() public sticky = true;

  @Input() public speed = 250;

  @Output() public onToggle = new EventEmitter();
  @HostBinding('class.toggled') @Input() public isOpen = true;

  constructor(private _cd: ChangeDetectorRef) {}


  public toggleSection(toSet = !this.isOpen) {
    this.onToggle.emit(toSet);
    if (!this.manualToggle) {
      this.isOpen = toSet;
    }

    this._cd.markForCheck();
  }

}
