import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ControlContainer, FormGroup} from '@angular/forms';
import { PriceValuePipe, NumericValuePipe, PercentValuePipe } from 'src/app/pipes';

@Component({
  selector: "toto-input",
  templateUrl: './toto-input.component.html',
  styleUrls: ['./toto-input.component.scss'],
  providers: [PriceValuePipe, NumericValuePipe, PercentValuePipe]
})
export class InputComponent {

  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public type = 'text';
  @Input() public autocomplete: string = 'off';
  @Input() public multiple: string = '';

  @Input() public inputId: string;
  @Input() public label: string;
  @Input() public readonly = false;
  @Input() public disabled = false;
  @Input() public maxlength: string;
  @Input() public inputClass: string;
  @Input() public inputStyle: string;
  @Input() public placeholder: string;

  @Input() public validation = true; // For mask

  @Input() public pricePrecision: number = 2;
  @Input() public digits: number = 2;

  @Input() public mask: string | undefined;
  @Input() public maxLength: number;
  
  @Input() public color = 'default';

  @Input() public size: string = 'm';
  @HostBinding('class') public get sizeClass() {
    return `size--${this.size}`;
  }

  @Output() public onBlur = new EventEmitter();

  constructor(
    @Optional() private _controlContainer: ControlContainer,
    private _priceValue: PriceValuePipe, 
    private _numericValue: NumericValuePipe,
    private _percentValue: PercentValuePipe
    ) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }
  }

  public get id() {
    return this.inputId || this.for;
  }

  public get currencyPrefix() {
    return '€';
  }

  public get inputValue() {
    let value = this.formGroup.get(this.for)?.value;

    if (this.type === 'price') {
      return this._priceValue.transform(value, this.pricePrecision, false);
    }

    if (this.type === 'numeric') {
      return this._numericValue.transform(value, this.digits);
    }

    if (this.type === 'percent') {
      return this._percentValue.transform(value, this.digits)
    }

    if (!value && value !== 0) {
      return '-'
    }

    return value;
  }
}
