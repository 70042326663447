<div class="next-race">
    <span class="next-time" *ngIf="nextRace" [class.animate-start]="raceStarting">
        {{ timeLeft }}
    </span>
    <span class="next-name line-clamp-2" *ngIf="nextRace">
        {{ trackName }} {{raceNr}}
    </span>
    <span class="next-none" *ngIf="initialized && !nextRace">
        {{'_._.No race scheduled' | translate}}
    </span>
</div>