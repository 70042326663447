<card>
  <div class="login-container">
    <div class="login-content">
      <ng-container>
        <div class="login-header">
          <h3>
            {{'Users.Register.Create account' | translate}}
          </h3>
        </div>

        <form [formGroup]="formGroup" id="register-form">
          <!-- <ng-container [formGroup]="controls">
                <authentication-options for="selectedView"></authentication-options>
            </ng-container> -->

          <ng-container [ngSwitch]="selectedView">
            <form *ngSwitchCase="AuthenticationOption.Password" [formGroup]="passwordForm">
              <toto-input size="l" label="Users.Register.Email" class="login-input" autocomplete="off" type="email"
                autocomplete="email" id="email" for="email">
                <validation-errors for="email"></validation-errors>
              </toto-input>

              <toto-input size="l" label="Users.Register.Username" class="login-input" autocomplete="off"
                autocomplete="email" id="username" for="username">
                <validation-errors for="userName" aliasTo="username"></validation-errors>
              </toto-input>

              <toto-input size="l" label="Users.Register.Password" class="login-input" autocomplete="off"
                autocomplete="new-password" id="password" for="password">
                <validation-errors for="password"></validation-errors>
              </toto-input>

              <toto-input size="l" label="Users.Register.Coupon" class="login-input" autocomplete="off" for="coupon">
                <validation-errors for="coupon"></validation-errors>
              </toto-input>
            </form>

            <form *ngSwitchCase="AuthenticationOption.SmartID" [formGroup]="smartIdForm">
              <toto-input class="login-input" size="l" label="Isikukood" for="idCode" type="number" autocomplete="off"
                id="idCode">
                <validation-errors for="idCode"></validation-errors>
              </toto-input>

              <toto-input class="login-input" size="l" label="Users.Register.Email" for="email" type="email"
                autocomplete="email" id="email">
                <validation-errors for="email"></validation-errors>
              </toto-input>
              <!-- <div hint>
                        {{'Users.Register.Your email address' | translate}}
                    </div> -->

              <toto-input class="login-input" size="l" label="Users.Register.Username" for="username" type="text">
                <validation-errors for="userName" aliasTo="username"></validation-errors>
              </toto-input>
              <!-- <div hint>
                        {{'Users.Register.Username must be at least 4 characters long. If you leave username empty, email address is used instead' | translate}}
                    </div> -->

              <toto-input class="login-input" size="l" label="Users.Register.Coupon" for="coupon">
                <validation-errors for="coupon"></validation-errors>
              </toto-input>
              <!-- <div hint>
                        {{'Users.Register.Input coupon if you have one' | translate}}
                    </div> -->
            </form>
          </ng-container>

          <div class="register-footer">
            <div class="terms-and-conditions">
              <toto-checkbox for="termsAndConditions">
                <div label>
                  <span>
                    {{"Users.Register.By submitting this form, you agree to toto.ee's" | translate}}
                  </span>
                  <span>
                    {{"Users.Register.Terms of Use" | translate}}
                  </span>
                </div>
              </toto-checkbox>
              <validation-errors for="acceptTerms" aliasTo="termsAndConditions"></validation-errors>
            </div>

            <div class="recaptcha mt20">
              <div #captchaRef2></div>
              <validation-errors for="recaptcha"></validation-errors>
            </div>

            <toto-button class="full-width mt30" color="theme-1" label="Users.Register.Register" size="l"
              (click)="register()">
            </toto-button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</card>