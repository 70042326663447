import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActionHelper } from 'src/app/helpers/action';
import { AccountService, TitleService } from 'src/app/services';
import { ViewBase } from 'src/app/views/view.base';
import { MessageDispatcher } from 'src/app/helpers';

@Component({
  selector: 'limits-view',
  templateUrl: './limits.view.html',
  styleUrls: ['./limits.view.scss']
})
export class UserLimitsViewComponent extends ViewBase {
  public titleFragments = [
    { key: 'Navigator.Menu.ResponsibleGame.ResponsibleGame' },
    { key: 'Navigator.Menu.ResponsibleGame.Limits' }
  ];

  public depositLimitGroup: FormGroup;
  public bettingLimitGroup: FormGroup;
  public netLossLimitGroup: FormGroup;

  public actionLoading = {} as any;

  public data;

  constructor(
    _titleService: TitleService,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _action: ActionHelper,
    private _msg: MessageDispatcher
  ) {
    super(_titleService);
    const { data } = this._route.snapshot.data || {};
    this.data = data;

    const {
      emtaRestrictionActive,
      bettingLimit7DaysAmount,
      bettingLimit7DaysCreatedAt,
      bettingLimit24hAmount,
      bettingLimit24hCreatedAt,
      bettingLimit30DaysAmount,
      bettingLimit30DaysCreatedAt,
      depositLimit7DaysAmount,
      depositLimit7DaysCreatedAt,
      depositLimit24hAmount,
      depositLimit24hCreatedAt,
      depositLimit30DaysAmount,
      depositLimit30DaysCreatedAt,
      netLossLimit7DaysAmount,
      netLossLimit7DaysCreatedAt,
      netLossLimit24hAmount,
      netLossLimit24hCreatedAt,
      netLossLimit30DaysAmount,
      netLossLimit30DaysCreatedAt
    } = data || {};

    console.log(data);

    this.depositLimitGroup = new FormGroup({
      h24: new FormControl(depositLimit24hAmount),
      d7: new FormControl(depositLimit7DaysAmount),
      d30: new FormControl(depositLimit30DaysAmount)
    });

    this.bettingLimitGroup = new FormGroup({
      h24: new FormControl(bettingLimit24hAmount),
      d7: new FormControl(bettingLimit7DaysAmount),
      d30: new FormControl(bettingLimit30DaysAmount)
    });

    this.netLossLimitGroup = new FormGroup({
      h24: new FormControl(netLossLimit24hAmount),
      d7: new FormControl(netLossLimit7DaysAmount),
      d30: new FormControl(netLossLimit30DaysAmount)
    });
  }

  public edit = {
    depositLimit: false,
    bettingLimit: false,
    netLossLimit: false
  };

  public show = {
    additionalInfoDeposit: false,
    additionalInfoBetting: false,
    additionalInfoNetLoss: false
  };

  private _depositLimitSnapshot: any;
  private _bettingLimitSnapshot: any;
  private _netLossLimitSnapshot: any;

  public has24hValue(ref: FormGroup) {
    return ref.get('h24').value ? true : false;
  }

  public has7dValue(ref: FormGroup) {
    return ref.get('d7').value ? true : false;
  }

  public has30dValue(ref: FormGroup) {
    return ref.get('d30').value ? true : false;
  }

  public hasAnyLimit(ref: FormGroup) {
    return (
      this.has24hValue(ref) || this.has7dValue(ref) || this.has30dValue(ref)
    );
  }

  public hasAllLimits(ref: FormGroup) {
    return false;
    // return (
    //   this.has24hValue(ref) && this.has7dValue(ref) && this.has30dValue(ref)
    // );
  }

  public saveDepositLimit() {
    const f = this.depositLimitGroup.value;
    const req = {
      amount24Hours: f.h24,
      amount7Days: f.d7,
      amount30Days: f.d30
    };

    // const message = this._action.createMessage(
    //   'AppMessages.Limits.DepositLimitSaved'
    // );
    this._accountService.setDepositLimit(req).result(
      this.depositLimitGroup,
      res => {
        this.edit.depositLimit = false;
        this._depositLimitSnapshot = this.depositLimitGroup.value;
        this.data.depositLimit24hAmount = res.depositLimit24hAmount;
        this.depositLimitGroup.get('h24').setValue(res.depositLimit24hAmount);
        this.data.depositLimit24hCreatedAt = res.depositLimit24hCreatedAt;
        this.data.depositLimit7DaysAmount = res.depositLimit7DaysAmount;
        this.depositLimitGroup.get('d7').setValue(res.depositLimit7DaysAmount);
        this.data.depositLimit7DaysCreatedAt = res.depositLimit7DaysCreatedAt;
        this.data.depositLimit30DaysAmount = res.depositLimit30DaysAmount;
        this.depositLimitGroup.get('d30').setValue(res.depositLimit30DaysAmount);
        this.data.depositLimit30DaysCreatedAt = res.depositLimit30DaysCreatedAt;

      },
      err => {
        this._msg.error({
          message: err.validationSummary
        });
        console.error(err);
      },
      'saveDepositLimit',
      this.actionLoading
    );
  }

  public saveBettingLimit() {
    const f = this.bettingLimitGroup.value;
    const req = {
      amount24Hours: f.h24,
      amount7Days: f.d7,
      amount30Days: f.d30
    };

    const message = this._action.createMessage(
      'AppMessages.Limits.BettingLimitSaved'
    );
    this._accountService.setBettingLimit(req).result(
      this.bettingLimitGroup,
      res => {
        this.edit.bettingLimit = false;
        this._bettingLimitSnapshot = this.bettingLimitGroup.value;
        this.data.bettingLimit24hAmount = res.bettingLimit24hAmount;
        this.bettingLimitGroup.get('h24').setValue(res.bettingLimit24hAmount);
        this.data.bettingLimit24hCreatedAt = res.bettingLimit24hCreatedAt;
        this.data.bettingLimit7DaysAmount = res.bettingLimit7DaysAmount;
        this.bettingLimitGroup.get('d7').setValue(res.bettingLimit7DaysAmount);
        this.data.bettingLimit7DaysCreatedAt = res.bettingLimit7DaysCreatedAt;
        this.data.bettingLimit30DaysAmount = res.bettingLimit30DaysAmount;
        this.bettingLimitGroup.get('d30').setValue(res.bettingLimit30DaysAmount);
        this.data.bettingLimit30DaysCreatedAt = res.bettingLimit30DaysCreatedAt;
      },
      err => {
        this._msg.error({
          message: err.validationSummary
        });
        console.error(err);
      },
      'saveBettingLimit',
      this.actionLoading,
      message
    );
  }

  public saveNetLossLimit() {
    const f = this.netLossLimitGroup.value;
    const req = {
      amount24Hours: f.h24,
      amount7Days: f.d7,
      amount30Days: f.d30
    };

    const message = this._action.createMessage(
      'AppMessages.Limits.NetLossLimitSaved'
    );
    this._accountService.setNetLossLimit(req).result(
      this.netLossLimitGroup,
      res => {
        this.edit.netLossLimit = false;
        this._netLossLimitSnapshot = this.netLossLimitGroup.value;        
        this.data.netLossLimit24hAmount = res.netLossLimit24hAmount;
        this.netLossLimitGroup.get('h24').setValue(res.netLossLimit24hAmount);
        this.data.netLossLimit24hCreatedAt = res.netLossLimit24hCreatedAt;
        this.data.netLossLimit7DaysAmount = res.netLossLimit7DaysAmount;
        this.netLossLimitGroup.get('d7').setValue(res.netLossLimit7DaysAmount);
        this.data.netLossLimit7DaysCreatedAt = res.netLossLimit7DaysCreatedAt;
        this.data.netLossLimit30DaysAmount = res.netLossLimit30DaysAmount;
        this.netLossLimitGroup.get('d30').setValue(res.netLossLimit30DaysAmount);
        this.data.netLossLimit30DaysCreatedAt = res.netLossLimit30DaysCreatedAt;
      },
      err => {
        this._msg.error({
          message: err.validationSummary
        });
        console.error(err);
      },
      'saveNetLossLimit',
      this.actionLoading,
      message
    );

  }

  changeClickInfoDeposit() {
    this.show.additionalInfoDeposit = !this.show.additionalInfoDeposit;
  }

  changeClickInfoBetting() {
    this.show.additionalInfoBetting = !this.show.additionalInfoBetting;
  }

  changeClickInfoNetLoss() {
    this.show.additionalInfoNetLoss = !this.show.additionalInfoNetLoss;
  }

  toggleEditDeposit(val) {
    if (val) {
      this._depositLimitSnapshot = this.depositLimitGroup.value;
    } else {
      if (this._depositLimitSnapshot) {
        this.depositLimitGroup.setValue(this._depositLimitSnapshot);
      }
    }
    
    this.edit.depositLimit = val;
  }

  toggleEditBetting(val) {
    if (val) {
      this._bettingLimitSnapshot = this.bettingLimitGroup.value;
    } else {
      if (this._bettingLimitSnapshot) {
        this.bettingLimitGroup.setValue(this._bettingLimitSnapshot);
      }
    }
    this.edit.bettingLimit = val;
  }

  toggleEditNetLoss(val) {
    if (val) {
      this._netLossLimitSnapshot = this.netLossLimitGroup.value;
    } else {
      if (this._netLossLimitSnapshot) {
        this.netLossLimitGroup.setValue(this._netLossLimitSnapshot);
      }
    }
    this.edit.netLossLimit = val;
  }
}
