import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Guid, LocalStorage, MessageDispatcher } from 'src/app/helpers';
import {
  AccountService,
  AuthenticationService,
  ILimitReq,
  IRestrictBettingReq,
  LocaleService,
  TitleService
} from 'src/app/services';
import { environment } from 'src/environments/environment';
import {
  BetTypeGroup,
  asArray,
  ToteSystem,
  OcspStatus,
  SafeBettingPeriod
} from 'src/app/enum';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/enum';
import { LANGUAGE_OPTIONS } from 'src/app/app.settings';
import { ViewBase } from 'src/app/views/view.base';
import { AppState } from 'src/app/app.state';
import { ActionHelper } from 'src/app/helpers/action';
import { TotoAppModule } from 'src/app/app.state';

@Component({
  selector: 'user-preferences-view',
  templateUrl: './user-preferences.view.html',
  styleUrls: ['./user-preferences.view.scss', '../shared.scss']
})
export class UserPreferencesViewComponent extends ViewBase {

  public titleFragments = [
    { key: 'Navigator.Menu.Settings.Settings' },
    { key: 'Navigator.Menu.Settings.Preferences' }
  ];

  public preferencesGroup: FormGroup;
  public languageOptions = LANGUAGE_OPTIONS;
  public betTypeGroupOptions = asArray(BetTypeGroup);

  public actionLoading = {} as any;

  private _emptyManualPriceForBetType = {
    3.1: false,
    3.2: false,
    3.3: false,
    3.4: false
  };

  constructor(
    private _appState: AppState,
    _titleService: TitleService,
    private _action: ActionHelper,
    private _authService: AuthenticationService,
    private _localeService: LocaleService,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _msg: MessageDispatcher,
    private _translate: TranslateService,
    private _fb: FormBuilder
  ) {
    super(_titleService);
    this.initializeForm();    
  }

  private initializeForm() {
    const { data } = this._route.snapshot.data || {};
    const {
      language,
      // maxIdleTime,
      newsletterEnabled,
      newsletterLanguage,
      showMessages,
      manualBets
    } = data || {};

    console.log(this._appState.language);

    let betTypesWithManualCombinationPrice = this._emptyManualPriceForBetType;
    const manualPriceForBetType = manualBets;

    if (manualPriceForBetType) {
      for (let keyvalue of Object.entries(manualPriceForBetType)) {
        betTypesWithManualCombinationPrice[keyvalue[0]] = keyvalue[1];
      }
    }

    this.preferencesGroup = new FormGroup({
      language: new FormControl(this._appState.language?.value),
      // maxIdleTime: new FormControl(maxIdleTime),
      showMessages: new FormControl(showMessages),
      newsletterEnabled: new FormControl(newsletterEnabled),
      newsletterLanguage: new FormControl(newsletterLanguage),
      betTypesWithManualCombinationPrice: this._fb.group(
        betTypesWithManualCombinationPrice
      ), 
    });
  }

  public get isHorseRacing() {
    return this._appState.activeAppModule == TotoAppModule.HorseRacing;
  }

  public getSystemBetTypeKey(
    betType: BetTypeGroup,
    system: ToteSystem = ToteSystem.Pmu
  ): string {
    return `${system}.${betType}`;
  }

  public savePreferences() {
    const message = this._action.createMessage(
      'AppMessages.UserPreferences.SavePreferencesSuccess'
    );
    this._accountService
      .saveUserPreferences(this.preferencesGroup.value)
      .result(
        this.preferencesGroup,
        res => {
          console.log(res);
        },
        err => {
          console.error(err);
        },
        'save',
        this.actionLoading,
        message
      );
  }

  public onLanguageChange(lang) {
    // console.log(lang)
    this._localeService.setLanguageAndReload(lang);
  }

  ngOnInit() {
    super.ngOnInit();
    // this.preferencesGroup.get('language').valueChanges.subscribe(val => {
    //   console.log(val);
    //   this._localeService.setLanguageAndReload(val);
    // })
  }

  // public get selectedLanguage() {
  //   return this.preferencesGroup.get('language').value;
  // }

  // public setLanguage(language) {
  //   this.preferencesGroup.get('language').setValue(language);
  // }

  // public isLanguageActive(language): boolean {
  //   return language.value === this.selectedLanguage.value;
  // }


}
