import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "page-loader",
  templateUrl: "./page-loader.component.html",
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent {

  @Input() public isLoading = true;

}
