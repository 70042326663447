import { Injectable, EventEmitter, Inject, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { ILanguage } from 'src/app/models/';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppState, TotoAppModule } from 'src/app/app.state';
import { LocalStorage } from 'src/app/helpers/local-storage';
import moment from 'moment';
import { LOCAL_STORAGE_KEY } from '../config/local-storage-key';
import { DEFAULT_LANGUAGE, I18N_PATH } from '../app.settings';
import { AppModule } from '../app.module';
import { DateAdapter } from '@angular/material/core';

export let currentLanguage: ILanguage;

@Injectable({ providedIn: 'root' })
export class LocaleService {
  constructor(
    private _appState: AppState,
    private _http: HttpClient,
    private _matAdapter: DateAdapter<Date>,
    @Inject(DOCUMENT) private document: Document,
    public translate: TranslateService
  ) {
    // this.document.documentElement.classList.add('notranslate'); // disable google translate
  }

  public currentLanguageModule: AppModule = null;

  public getSiteLocale(
    language: ILanguage = this._appState.language || DEFAULT_LANGUAGE,
    appModule: TotoAppModule = this._appState.activeAppModule ?? TotoAppModule.HorseRacing
  ): Observable<any> {
    return this._http.get(I18N_PATH + `${appModule}`, { headers: { 'Accept-Language': language?.value ?? 'et'} }) as Observable<any>;
  }

  public setLanguage(
    language: ILanguage = this._appState.language || DEFAULT_LANGUAGE,
    appModule: TotoAppModule = this._appState.activeAppModule,
    callback = () => {}
  ) {
    return this.getSiteLocale(language, appModule).toPromise().then(res => {

      this.adaptLocale(language, res);
      this.currentLanguageModule = appModule;
      callback();
    }, e => {
      console.error(e);
    });
  }

  public async getLang(
    language: ILanguage = this._appState.language || DEFAULT_LANGUAGE,
    appModule: TotoAppModule = this._appState.activeAppModule,
  ) {
    return this.getSiteLocale(language, appModule).toPromise()
  }

  public setLanguageAndReload(language, force = false) {
    const currentLanguageValue = this._appState.language?.value;
    const languageValue = language?.value;
    if (currentLanguageValue === languageValue && !force) {
      return;
    }
    LocalStorage.set(LOCAL_STORAGE_KEY.APP_LANGUAGE, language);
    window.location.reload();
  }

  public reload(
    language: ILanguage = this._appState.language || DEFAULT_LANGUAGE
  ) {
    const { value } = language;
    this.translate.reloadLang(value);
  }

  public adaptLocale(language: ILanguage, siteLocale = null) {

    const { value } = language;
    const { i18n } = siteLocale;

    this.document.documentElement.lang = value;
    this._appState.siteLocaleSubject$.next(siteLocale);
    this._appState.language = language;
    currentLanguage = language;

    this.translate.setTranslation(value, i18n, false);
    this.translate.setDefaultLang(value);
    this.translate.use(value);
    moment.locale(value);
    this._matAdapter.setLocale(value);

    LocalStorage.set(LOCAL_STORAGE_KEY.APP_LANGUAGE, language);
  }

}
