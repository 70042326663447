import { Component, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormControl,
  FormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { AuthenticationOption } from 'src/app/components/_elements/authentication-options/authentication-options.component';
import { ActionHelper } from 'src/app/helpers/action';
import { AuthenticationService, AccountService } from 'src/app/services';
import { switchMap } from 'rxjs/operators';
import { IIdentity } from 'src/app/models/IIdentity';
import { PendingVerificationDialogComponent } from '../pending-verification/pending-verification.dialog';
import { VerificationMethod } from 'src/app/views/user/settings/verification/user-verification.view';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'login-dialog',
  host: { class: 'dialog' },
  templateUrl: './login.dialog.html',
  styleUrls: ['./login.dialog.scss']
})
export class LoginDialogComponent {
  public AuthenticationOption = AuthenticationOption;
  public countryPrefixOptions = COUNTRY_PREFIX_OPTIONS;

  public view = LoginView.Login;

  public LoginView = LoginView;

  public controls = new FormGroup({
    selectedLogin: new FormControl()
  });

  public passwordForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  public smartIdForm = new FormGroup({
    idCode: new FormControl()
  });

  public resetPasswordForm = new FormGroup({
    email: new FormControl()
  });

  public mobileIdForm = new FormGroup({
    prefix: new FormControl(this.countryPrefixOptions[0]),
    idCode: new FormControl(),
    phoneNumber: new FormControl()
  });  

  public zimplerCountryOptions = [
    {
      label: 'Common.Countries.Estonia',
      value: 'ee',
      providerOptions: [
        BankLinkProvider.ZimplerSwedbank,
        BankLinkProvider.ZimplerLHV,
        BankLinkProvider.ZimplerSEB,
        BankLinkProvider.ZimplerLuminor,
      ]
    },
    {
      label: 'Common.Countries.Finland',
      value: 'fi',
      providerOptions: [
        BankLinkProvider.ZimplerOPBank,
        BankLinkProvider.ZimplerNordea,
        BankLinkProvider.ZimplerDanskeBank,
        BankLinkProvider.ZimplerAktia,
        BankLinkProvider.ZimplerAlandsBanken,
        BankLinkProvider.ZimplerPOPPankki,
        BankLinkProvider.ZimplerSPankki,
        BankLinkProvider.ZimplerHandelsbanken,
        BankLinkProvider.ZimplerSäästöpankki,
        BankLinkProvider.ZimplerOMASäästöpankki
      ]
    }
  ];

  public zimplerForm = new FormGroup({
    country: new FormControl(this.zimplerCountryOptions[0])
  });

  public get selectedLogin(): AuthenticationOption {
    return this.controls.get('selectedLogin').value;
  }

  public actionLoading = {} as any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private _authService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _accountService: AccountService,
    private _appState: AppState,
    private _action: ActionHelper,
    private sanitizer: DomSanitizer
  ) {
    const { data } = this._data || {};

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";    

    eventer(messageEvent,function(e) {
      var key = e.message ? "message" : "data";
      var data = e[key];

      if(data.toString().startsWith('url:')) {
        window.location.href = data.substring(4);
      }

    },false);

  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }

  public get isLoginPage(): boolean {
    return this._route.snapshot.routeConfig.path === 'login';
  }

  public get isLoggedIn(): boolean {
    return this._appState.isLoggedIn;
  }

  public async logOut(): Promise<void> {
    await this._authService.logout();
  }

  public toResetPassword(): void {
    this.view = LoginView.ResetPassword;
  }

  public toLogin(): void {
    this.view = LoginView.Login;
  }

  public toIdLogin(): void {
    this.controls.get('selectedLogin').setValue(AuthenticationOption.IDCard);
  }

  public toSmartIdLogin(): void {
    this.controls.get('selectedLogin').setValue(AuthenticationOption.SmartID);
  }

  public toPasswordLogin(): void {
    this.controls.get('selectedLogin').setValue(AuthenticationOption.Password);
  }

  public toBankLogin(): void {
    this.controls.get('selectedLogin').setValue(AuthenticationOption.Bank);
  }

  public toRegister() {
    this._router.navigate(['register']).then(val => {
      this.closeDialog();
    });
  }

  public openPendingVerificationDialog(
    data = {},
    method = VerificationMethod.SmartId,
    action = 'register'
  ) {
    this._action.openDialog(
      PendingVerificationDialogComponent,
      {
        width: '498px',
        data: {
          data: data,
          method: method,
          action: action
        },
        allowMultipleDialogs: true
      },
      res => {
        if (res !== null && res !== undefined) {
          this._authService.updateToken(() => {
            this._accountService.getAccountInfo().result(null, info => {

              if(!window.posthogIdentified) {
                if(info.accountInfo) {            

                  var additional = {
                    first_name: info.accountInfo.first_name,
                    last_name: info.accountInfo.last_name,
                    email: ''
                  };
                  
                  if(info.contact && info.contact.email) {
                    additional.email = info.contact.email;
                  }

                  console.log(additional);

                  window.posthog.identify(info.accountInfo.id, additional);
                  window.posthogIdentified = true;
                }
              }

              this._appState.setAccountInfo(info, 'log_in');
              this._appState.token.next(res);
              this.afterLoginSucess();
            });
          });
        }
      }
    );
  }

  public routesThatShouldNavigateOnLogin = ['/register'];

  public afterLoginSucess() {
    const currentUrl = this._router.routerState.snapshot?.url;

    if (this.routesThatShouldNavigateOnLogin.includes(currentUrl)) { 
      this._router.navigate([this._authService.activeAppModuleRoot]);
    }

    this.closeDialog();
  }

  public iFrameUrl: SafeResourceUrl | null = null;

  public isBankLoginClicked: boolean = false;

  public logIn(): void {

    switch (this.selectedLogin) {
      case AuthenticationOption.Password:
        var f = this.passwordForm.value;
        this._authService.authPassword(f.username, f.password).result(
          this.passwordForm,
          res => {
            if(!window.posthogIdentified) {
              if(res) {            
                var additional = {
                  email: res.email,
                  first_name: res.given_name,
                  last_name: res.unique_name
                };
                window.posthog.identify(res.nameid, additional);
                window.posthogIdentified = true;
              }
            }
            this.afterLoginSucess();
          },
          e => {},
          'login',
          this.actionLoading,
          this._action.createMessage()
        );

        break;

      case AuthenticationOption.SmartID:
        var smartIdForm = this.smartIdForm.value;
        this._authService.initAuthenticateViaSmartId(smartIdForm.idCode).result(
          this.smartIdForm,
          res => {
            this.openPendingVerificationDialog(
              res,
              VerificationMethod.SmartId,
              'login'
            );
          },
          e => {
            if(e.validationSummary && e.validationSummary.startsWith('{"type":"about:blank"'))
            {
              e.validationSummary = "AppMessages.Register.Errors.SmartIdFailed";
            }
          },
          'login',
          this.actionLoading,
          this._action.createMessage()
        );
        break;

      case AuthenticationOption.IDCard:
        // Method not implemented

        break;

      case AuthenticationOption.MobileID:
        var mobileIdForm = this.mobileIdForm.value;
        this._authService
          .initAuthenticateViaMobileId(
            mobileIdForm.idCode,
            mobileIdForm.phoneNumber,
            mobileIdForm.prefix.value
          )
          .result(
            this.mobileIdForm,
            res => {

              this.openPendingVerificationDialog(
                res,
                VerificationMethod.MobileId,
                'login'
              );
            },
            e => {},
            'login',
            this.actionLoading,
            this._action.createMessage()
          );
        break;
      case AuthenticationOption.Bank: {
        var zimplerForm = this.zimplerForm.value;
        var openInIframe = !(this._appState.isViewTablet || this._appState.isViewMobile);
        this._authService.initAuthenticateViaZimpler(zimplerForm.country.value, openInIframe).result(
          null, 
          res => {            
            const link = res.url;
            if (link) {
              if (!openInIframe) {
                window.open(link, '_self');
              } else {
                this.iFrameUrl = link;
                this.isBankLoginClicked = true;
              }         
            }
            /*
            //window.location.href = res.url;
            this.iFrameUrl = res.url;
            // console.log('Response URL:', res.url);
            // this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
            // console.log('Sanitized URL:', this.iFrameUrl);
            this.isBankLoginClicked = true;*/
          },
          err => {},
          'login',
          this.actionLoading,
          this._action.createMessage()
        );
      }
    }
  }

  public resetPassword() {
    const data = {
      email: this.resetPasswordForm.value.email,
      language: this._appState.language?.value,
      callbackUrl:
        window.location.origin + this._router.createUrlTree(['/reset-password'])
    };

    const message = this._action.createMessage(
      'AppMessages.Login.ResetPasswordLinkSentToEmail'
    );

    this._accountService.sendResetPasswordLink(data).result(
      this.passwordForm,
      res => {},
      e => {},
      'sendingLink',
      this.actionLoading,
      message
    );
  }

  public onNavigateBack() {
    this.controls.get('selectedLogin').reset();
  }
}

enum LoginView {
  Login = 0,
  ResetPassword = 1,
  ResetSent = 2
}

export const COUNTRY_PREFIX_OPTIONS = [
  {
    label: '+372 (EE)',
    value: '+372'
  }
  // ,
  // {
  //   label: '+370 (LT)',
  //   value: '+370'
  // },
]