<div class="dialog-content">
  <h3 class="title mb20">
    {{'Views.User.Register.PendingVerificationDialog.Title' | translate}}
  </h3>

  <p class="description mb20" [ngSwitch]="method">
    <ng-container *ngSwitchCase="VerificationMethod.SmartId">
      {{'Views.User.Register.PendingVerificationDialog.TextSmartId' | translate}}
    </ng-container>
    <ng-container *ngSwitchCase="VerificationMethod.MobileId">
      {{'Views.User.Register.PendingVerificationDialog.TextMobileId' | translate}}
    </ng-container>
  </p>

  <ng-container [ngSwitch]="method">
    <svg-icon key="smartId" fontSize="72px" *ngSwitchCase="VerificationMethod.SmartId"></svg-icon>
    <svg-icon key="mobileId" fontSize="72px" *ngSwitchCase="VerificationMethod.MobileId"></svg-icon>
  </ng-container>
  
  <div class="code-container mb20 mt20">
    <span class="code-number" *ngFor="let number of displayCode">
      {{number}}
    </span>
  </div>
  <div class="loader-container mt20 mb20">
    <loader></loader>
  </div>
  <div class="flex">
    <toto-button color="transparent" label="Action.Cancel" (click)="cancelPendingVerification()"></toto-button>
  </div>
</div>
