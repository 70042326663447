<ng-container [ngSwitch]="isLoggedIn">
  <div class="login-wrapper" *ngSwitchDefault>
    <language-select class="mr10"></language-select>
    <toto-button label="Navigator.Action.LogIn" color="theme-2" (click)="openLoginDialog()" size="sm"></toto-button>
    <toto-button class="ml5" color="black-2" label="Navigator.Action.Register" routerLink="register" size="sm"></toto-button>
  </div>

  <div *ngSwitchCase="true" class="authenticated-user-wrapper">
    <div class="user-section user-info mr20">
      <toto-button color="theme-2" class="ml10" label="Navigator.Action.Deposit" routerLink="/user/payments/deposit" size="sm"></toto-button>
      <user-name class="ml20"></user-name>
      <user-balance class="ml10"></user-balance>
    </div>
    <!-- <div class="user-section user-language">
      <language-select class="ml5 mr5" color="black" [showName]="false"></language-select>
    </div> -->
    <!-- <div class="user-section user-notifications" (click)="toggleNotificationsMenu()" [class.open]="state.isNotificationsMenuOpen">
      <svg-icon fontSize="26px" key="menuNotifications"></svg-icon>
    </div> -->
    <div class="user-section user-account" (click)="toggleUserMenu()" [class.open]="state.isUserMenuOpen">
      <svg-icon fontSize="26px" key="menuAccount"></svg-icon>
    </div>
    <user-notifications [class.open]="state.isNotificationsMenuOpen"></user-notifications>
    <user-menu 
      [showAdmin]="isAdmin" 
      [showLogout]="true" 
      [class.open]="state.isUserMenuOpen"
      (onMenuItemClicked)="onMenuItemClicked($event)">
    </user-menu>
  </div>
</ng-container>
<div *ngIf="state.isUserMenuOpen" class="user-block-underlay" (click)="toggleUserMenu(false)"></div>
