export enum Gender {
    UNKNOWN = 0,
    MALE = 1,
    FEMALE = 2,
    GELDING = 3
}

(Gender as any).__typeName = 'Gender';

const _ = 'Enums.gender.';
(Gender as any).__altTranslationKeys = {
    [Gender.UNKNOWN]: _ + 'UNKNOWN',
    [Gender.MALE]: _ + 'MALE',
    [Gender.FEMALE]: _ + 'FEMALE',
    [Gender.GELDING]: _ + 'GELDING'
};
