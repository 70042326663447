<toto-dialog [dialogRef]="dialogRef">

  <section header-title>
    {{data.title | translate}}
  </section>
  <section body>

    <span>{{data.message | translate}}</span>

    <span *ngIf="data.template"
          [innerHTML]="data.template">
    </span>

  </section>

  <section footer>
    <toto-button color="green" [label]="data.yes" [mat-dialog-close]="true" ></toto-button>
    <toto-button color="dark-grey" class="ml10" [label]="data.no" (click)="onDecline()" *ngIf="data.showNo"></toto-button>
  </section>

</toto-dialog>
