import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Guid, MessageDispatcher } from 'src/app/helpers';
import { AccountService, AuthenticationService, TitleService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ViewBase } from 'src/app/views/view.base';
import { ISaveAccountContactInfoReq } from 'src/app/models';
import { toNumber } from 'src/app/util/number-util';
import { ActionHelper } from 'src/app/helpers/action';

@Component({
  selector: 'user-contact-details-card',
  templateUrl: './user-contact-details.view.html',
  styleUrls: ['./user-contact-details.view.scss', '../shared.scss']
})
export class UserContactDetailsViewComponent extends ViewBase {

  public titleFragments = [
    { key: 'Navigator.Menu.Settings.Settings' },
    { key: 'Navigator.Menu.Settings.ContactDetails' }
  ]

  public contactDetailsGroup: FormGroup;

  public countryOptions = [];

  public actionLoading = {} as any;

  constructor(
    _titleService: TitleService,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _action: ActionHelper
  ) {
    super(_titleService);
    const { formData, addressLine1, addressLine2, birthDate, countryId, email, firstName, idCode, lastName, phone, postalCode, userName } = this._route.snapshot.data.settingsData || {};
    const { countries } = formData || {};
    this.countryOptions = countries ?? [];

    this.contactDetailsGroup = new FormGroup({
      firstName: new FormControl(firstName),
      lastName: new FormControl(lastName),
      userName: new FormControl(userName),
      birthDate: new FormControl(birthDate),
      idCode: new FormControl(idCode),
      email: new FormControl(email),
      phone: new FormControl(phone),
      addressLine1: new FormControl(addressLine1), 
      addressLine2: new FormControl(addressLine2),
      postalCode: new FormControl(postalCode),
      countryId: new FormControl(countryId)
    });
  }

  public saveContactInfo(): void {
    const f = this.contactDetailsGroup.value;
    const req: ISaveAccountContactInfoReq = {
      email: f.email,
      phone: f.phone,
      addressLine1: f.addressLine1,
      addressLine2: f.addressLine2,
      postalCode: f.postalCode,
      countryId: f.countryId
    };

    const message = this._action.createMessage('Views.User.Settings.ContactDetails.SaveSuccess');
    this._accountService.saveContactInfo(req).result(this.contactDetailsGroup, res => {
      console.log(res);
    }, err => {
      console.error(err);
    }, "save", this.actionLoading, message);

  }
}
