export enum SquashMarketTypeValue
{
    MatchWinner = 13744,
    Game1Winner = 14011,
    Game2Winner = 14012,
    Game3Winner = 14013,
    Game4Winner = 14014,
    Game5Winner = 14015,
    TotalPointsOverUnder = 14016,
    Game1TotalPointsOverUnder = 14017,
    Game2TotalPointsOverUnder = 14018,
    Game3TotalPointsOverUnder = 14019,
    Game4TotalPointsOverUnder = 14020,
    Game5TotalPointsOverUnder = 14021,
    TotalPointsOddOrEven = 14024,
    Game1TotalPointsOddOrEven = 14025,
    Game2TotalPointsOddOrEven = 14026,
    Game3TotalPointsOddOrEven = 14027,
    Game4TotalPointsOddOrEven = 14028,
    Game5TotalPointsOddOrEven = 14029,
    CompetitorATotalPointsOddOrEven = 14030,
    CompetitorBTotalPointsOddOrEven = 14031,
    Game1NumberedPointWinner = 14032,
    Game2NumberedPointWinner = 14033,
    Game3NumberedPointWinner = 14035,
    Game4NumberedPointWinner = 14036,
    Game5NumberedPointWinner = 14037,
    Game1RaceTo3Points = 14038,
    Game2RaceTo3Points = 14039,
    Game3RaceTo3Points = 14040,
    Game4RaceTo3Points = 14041,
    Game5RaceTo3Points = 14042,
    Game1RaceTo5Points = 14043,
    Game2RaceTo5Points = 14044,
    Game3RaceTo5Points = 14045,
    Game4RaceTo5Points = 14046,
    Game5RaceTo5Points = 14047,
    Game1RaceTo7Points = 14048,
    Game2RaceTo7Points = 14049,
    Game3RaceTo7Points = 14050,
    Game4RaceTo7Points = 14051,
    Game5RaceTo7Points = 14052,
    MatchGameHandicap = 14053,
    MatchPointHandicap = 14054,
    Game1Handicap = 14055,
    Game2Handicap = 14056,
    Game3Handicap = 14057,
    Game4Handicap = 14058,
    Game5Handicap = 14059,
    Game1ExtraPoints = 14060,
    Game2ExtraPoints = 14061,
    Game3ExtraPoints = 14062,
    Game4ExtraPoints = 14063,
    Game5ExtraPoints = 14064,
    Game1LeadAfter2Points = 14070,
    Game1LeadAfter4Points = 14071,
    Game1LeadAfter6Points = 14072,
    Game1LeadAfter8Points = 14073,
    Game1LeadAfter10Points = 14074,
    Game2LeadAfter2Points = 14075,
    Game2LeadAfter4Points = 14076,
    Game2LeadAfter6Points = 14077,
    Game2LeadAfter8Points = 14078,
    Game2LeadAfter10Points = 14079,
    Game3LeadAfter2Points = 14080,
    Game3LeadAfter4Points = 14082,
    Game3LeadAfter6Points = 14083,
    Game3LeadAfter8Points = 14084,
    Game3LeadAfter10Points = 14085,
    Game4LeadAfter2Points = 14086,
    Game4LeadAfter4Points = 14087,
    Game4LeadAfter6Points = 14088,
    Game4LeadAfter8Points = 14089,
    Game4LeadAfter10Points = 14090,
    Game5LeadAfter2Points = 14091,
    Game5LeadAfter4Points = 14092,
    Game5LeadAfter6Points = 14093,
    Game5LeadAfter8Points = 14094,
    Game5LeadAfter10Points = 14095,
    Game1CorrectScore = 14096,
    Game2CorrectScore = 14097,
    Game3CorrectScore = 14098,
    Game4CorrectScore = 14099,
    Game5CorrectScore = 14100,
    CorrectMatchScore = 14101,
    TotalGamesOverUnder = 14128,
    CompetitorATotalPointsOverUnder = 14129,
    CompetitorBTotalPointsOverUnder = 14130,
    TotalGamesOddOrEven = 14131,
}

(SquashMarketTypeValue as any).__typeName = 'SquashMarketTypeValue';