export enum OcspStatus
{
  Good = 0,
  Revoked = 1,
  Unknown = 2
}

(OcspStatus as any).__typeName = 'OcspStatus';

const _ = 'Enums.OcspStatus.';
(OcspStatus as any).__altTranslationKeys = {
    [OcspStatus.Good]: _ + 'Good',
    [OcspStatus.Revoked]: _ + 'Revoked',
    [OcspStatus.Unknown]: _ + 'Unknown'
};
