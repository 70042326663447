import { Component, Input, Output, HostBinding, EventEmitter } from "@angular/core";
import { AppState } from "src/app/app.state";

@Component({
  selector: "toto-button",
  templateUrl: "./button.component.html",
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @HostBinding('class') @Input() public color: string = 'dark-grey';
  @HostBinding('class.disabled') @Input() public disabled = false;
  @HostBinding('class.readonly') @Input() public readonly = false;
  @HostBinding('class.loading') @Input() public loading = false;

  @Input() public icon: string;
  @Input() public label: string;
  @Input() public padding: string = '0 12px';
  @Input() public active = false;
  @Input() public type = 'button';
  @Input() public buttonStyle: string;
  @Input() public iconSize = '14px';
  @Input() public iconPosition = 'left';

  @Input() public size = 'md';
  @HostBinding('class') public get sizeClass() {
    return `size--${this.size}`;
  }

  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();
  public onButtonClick(event: MouseEvent) {
    if (this.disabled) return;
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.click.emit(event);
  }
}
