import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'terms-of-use-dialog',
  host: { class: 'dialog' },
  templateUrl: './terms-of-use.dialog.html',
  styleUrls: ['./terms-of-use.dialog.scss']
})
export class TermsOfUseDialogComponent {

  public pages: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    public dialogRef: MatDialogRef<TermsOfUseDialogComponent>,
  ) {

    const { pages } = this._data || {};
    this.pages = pages || [];
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }

}

