<ng-container [formGroup]="formGroup" [ngSwitch]="readonly">

  <span class="input-label" *ngIf="label" [class.required]="required">
    <label [for]="for">
      {{ label | translate }}
    </label>
  </span>

  <ng-container *ngSwitchDefault>

    <ng-select #select class="color--{{color}}" [class.autocomplete]="url" [class.has-value]="hasValue"
      [formControlName]="for" [searchFn]="search" [appendTo]="appendTo" [typeahead]="url ? acSubject : null"
      [items]="options" [bindValue]="value" [bindLabel]="labelField" [searchable]="searchable || url"
      [multiple]="multiple" [addTag]="addTag" [addTagText]="addTagText" [maxSelectedItems]="maxSelectedItems"
      [closeOnSelect]="!multiple" [clearSearchOnAdd]="true" [hideSelected]="hideSelected" [class.disabled]="disabled"
      [groupBy]="groupBy" [compareWith]="compareWith" [clearable]="clearable" [minTermLength]="minTermLength"
      [typeToSearchText]="typeToSearchTranslated" [notFoundText]="notFoundTranslated" (search)="find($event)"
      (open)="onOpen()" (close)="onClose()" (change)="onChange($event)">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div class="ng-value-wrapper default">
          <div class="ng-value-label flex f-1 space-between">
            <span class="item-value {{ labelValueClass }}">{{
              getItemValue(item)
              }}</span>
            <span class="additional-value {{ additionalValueClass }}">{{
              getAdditionalValue(item)
              }}</span>
          </div>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
      </ng-template>

      <ng-template *ngIf="!multiple" ng-option-tmp let-item="item">
        <div class="flex f-1 align-center space-between">
          <span class="item-value {{ labelValueClass }}">{{
            getItemValue(item)
            }}</span>
          <span class="additional-value {{ additionalValueClass }}">{{
            getAdditionalValue(item)
            }}</span>
        </div>
      </ng-template>
    </ng-select>

    <!-- <span *ngIf="isOpen" class="select-overlay" (click)="select.close()"></span> -->

    <!-- <select-fullscreen [formGroup]="formGroup" [isOpenSubject]="fullscreenSelectOpenSubject" [label]="fullscreenLabel ?? label"
      [for]="for" [value]="value" [options]="options" [labelField]="labelField" [compareWith]="compareWith"
      [translate]="translate" (change)="onChange($event)">
    </select-fullscreen> -->

  </ng-container>

  <ng-container *ngSwitchCase="true">
    <span class="input-readonly full-width">
      {{getItemValue(formGroup.get(for)?.value)}}
    </span>
  </ng-container>

</ng-container>