import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { MessageDispatcher } from 'src/app/helpers';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'login-view',
  templateUrl: './login.view.html',
  styleUrls: ['./login.view.scss']
})
export class LoginViewComponent {
  public isLoginLoading = false;

  public loginForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  });

  constructor(private _authService: AuthenticationService, private _router: Router, private _message: MessageDispatcher, private _appState: AppState) {
    // if (this.isLoggedIn) {
    //   this._router.navigate(['/']);
    // }
  }

  public get asModal(): boolean {
    return this._authService.showLoginModal;
  }

  @HostBinding('class') public get contentClass() {
    return this.asModal ? 'as-modal' : 'layout';
  }

  public get isLoggedIn(): boolean {
    return this._appState.isLoggedIn;
  }

  public closeModal() {
    this._authService.toggleLoginModal(false);
  }

  public async logOut() {
    await this._authService.logout();
  }
 
}
