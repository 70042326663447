import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState, TotoAppModule } from 'src/app/app.state';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-key';
import {
  EXTERNAL_LINKS,
  MAIN_MENU_LINKS,
  USER_MENU_LINKS
} from 'src/app/config/nav-links';
import { LocalStorage } from 'src/app/helpers';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  public TotoAppModule = TotoAppModule;

  @Output() public onMenuItemClicked = new EventEmitter();

  @Input() public showAdmin = false;
  @Input() public showLogout = true;

  public showChildren = {
    tickets: true,
    payments: true,
    settings: false,
    bonuses: true,
    responsible: false,
    devTools: false
  };

  public links = USER_MENU_LINKS;
  public ADMIN_LINK = EXTERNAL_LINKS.TOTO_ADMIN;

  public isLoginLoading = false;

  constructor(
    private _authService: AuthenticationService,
    public state: AppState
  ) {}

  public get activeAppModule(): TotoAppModule {
    return this.state.activeAppModule;
  }

  public get moduleRouterLinkRoot() {
    return MAIN_MENU_LINKS[this.activeAppModule].routerLink;
  }

  public toggleChildren(key: string) {
    const value = this.showChildren[key];
    this.showChildren[key] = !value;
  }

  public onMenuItemClick() {
    this.onMenuItemClicked.emit();
  }

  public async logOut() {
    await this._authService.logout();
    this.onMenuItemClick();
  }

  // DEV

  public get showDevTools() {
    return (window.__TOTO_DEV_SETTINGS?.SHOW_DEV_TOOLS && this.showAdmin ) ?? false;
  }

  public get showHiddenMarkets() {
    return window.__TOTO_DEV_SETTINGS?.SHOW_HIDDEN_MARKETS;
  }

  public get logRealtimeUpdates() {
    return window.__TOTO_DEV_SETTINGS?.LOG_REALTIME_CHANGES;
  }

  public toggleHiddenMarkets(
    toggle = !this.showHiddenMarkets
  ) {
    if (window.__TOTO_DEV_SETTINGS) {
      LocalStorage.set(LOCAL_STORAGE_KEY.SHOW_HIDDEN_MARKETS, toggle)
      window.__TOTO_DEV_SETTINGS.SHOW_HIDDEN_MARKETS = toggle;
      console.log(window.__TOTO_DEV_SETTINGS);
      window.location.reload();
    }
  }

  public toggleLogRealtimeUpdates(
    toggle = !this.logRealtimeUpdates
  ) {

    if (window.__TOTO_DEV_SETTINGS) {
      LocalStorage.set(LOCAL_STORAGE_KEY.LOG_REALTIME_CHANGES, toggle)
      window.__TOTO_DEV_SETTINGS.LOG_REALTIME_CHANGES = toggle;
      console.log(window.__TOTO_DEV_SETTINGS);
      window.location.reload();
    }
 
  }


}
