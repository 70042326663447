export enum IceHockeyMatchPhase {
    PreMatch,
    FirstPeriod,
    EndOfFirstPeriod,
    SecondPeriod,
    EndOfSecondPeriod,
    ThirdPeriod,
    EndOfThirdPeriod,
    OvertimePeriod,
    EndOfOvertimePeriod,
    PenaltyShootout,
    EndOfPenaltyShootout,
    Suspended,
    Abandoned,
    PostMatch,
}

(IceHockeyMatchPhase as any).__typeName = 'IceHockeyMatchPhase';