import { Component } from '@angular/core';
import { INextRaceDto } from 'src/app/models';
import { EventsService } from 'src/app/services/events.service';
import moment from 'moment';


@Component({
  selector: 'upcoming-block',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.scss']
})
export class UpcomingBlockComponent {

  public nextRace: INextRaceDto;

  public timeLeft: any;

  public raceStarting = false;

  private _countdownInterval: any;
  private _raceTimeout: any;

  public initialized = false;

  constructor(private _eventsService: EventsService) {
    this.getRace();
  }


  public getRace(): void {
    this._eventsService.getNextRace().result(null, res => {
      this.initialized = true;
      this.raceStarting = false;
      this.nextRace = res;
      this.countdown(res);
    }, e => {
      this.initialized = true;
      this.nextRace = null;
      this._raceTimeout = setTimeout(_ => {
        this.getRace();
      }, 10000)
    });
  }

  public get trackName(): string {
    return this.nextRace?.trackName;
  }

  public get raceNr(): number {
    return this.nextRace?.raceNr;
  }

  public countdown(nextRace: INextRaceDto = this.nextRace): void {
    const ref = this;

    const time = nextRace?.timeLeft;

    if (!time) {
      this.getRace();
      return;
    }

    const untilRace = moment(time, 'HH:mm:ss');
    this.timeLeft = untilRace.format('HH:mm:ss');

    clearInterval(this._countdownInterval);

    this._countdownInterval = setInterval(_ => {
      const timeLeft = untilRace.subtract(1, "second");
      const hour = timeLeft.hour();
      const minute = timeLeft.minute();
      const second = timeLeft.second();

      ref.timeLeft = timeLeft.format('HH:mm:ss');

      const invalid = hour + minute + second <= 0;

      if (invalid) {
        clearInterval(this._countdownInterval);
        this.raceStarting = true;
        this._raceTimeout = setTimeout(_ => {
          this.getRace();
        }, 10000)
      };

    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this._countdownInterval);
    clearTimeout(this._raceTimeout);
  }
}
