<div class="row">

  
    <!-- <div class="col-sm-12 col-md-4 col-lg-4" *ngFor="let option of options">
  
      <toto-button [label]="authenticationOptions[option].label" [icon]="authenticationOptions[option].icon"
        [iconSize]="authenticationOptions[option].iconSize" (click)="selectOption(option)" [color]="
          isOptionSelected(option)
            ? 'theme-1'
            : 'dark-grey'
        ">
      </toto-button>
    </div> -->
      <!-- <toto-button
        label="Components.AuthOptions.Password"
        icon="lock"
        (click)="selectOption(AuthenticationOption.Password)"
        [color]="
          isOptionSelected(AuthenticationOption.Password)
            ? 'theme-1'
            : 'dark-grey'
        "
      >
      </toto-button> -->
    
  <div class="column">
    <div class="btn-view" *ngFor="let option of options">

      <toto-button [label]="authenticationOptions[option].label" [icon]="authenticationOptions[option].icon"
        [iconSize]="authenticationOptions[option].iconSize" (click)="selectOption(option)" [color]="
        isOptionSelected(option)
          ? 'dark-grey'
          : 'dark-grey'
      ">
      </toto-button>
    </div>
  </div>

  <!-- <div class="col-sm-12 col-md-4 col-lg-4">
    <toto-button
      icon="smartId"
      iconSize="72px"
      (click)="selectOption(AuthenticationOption.SmartID)"
      [color]="
        isOptionSelected(AuthenticationOption.SmartID) ? 'theme-1' : 'dark-grey'
      "
    >
    </toto-button>
  </div>
  <div class="col-sm-12 col-md-4 col-lg-4">
    <toto-button
      label="Mobiil-ID"
      iconSize="72px"
      (click)="selectOption(AuthenticationOption.MobileID)"
      [color]="
        isOptionSelected(AuthenticationOption.MobileID)
          ? 'theme-1'
          : 'dark-grey'
      "
    >
    </toto-button>
  </div> -->
</div>

<!-- <toto-button
  icon="idCard"
  iconSize="72px"
  (click)="selectOption(AuthenticationOption.IDCard)"
  [color]="isOptionSelected(AuthenticationOption.IDCard) ? 'theme-1' : 'dark-grey'">
</toto-button> -->
