export enum TotoSportsBetSlipResult {
  None = 0,
  Winner = 1,
  Pushed = 2,
  Loser = 3,
  Placed = 4,
  Partial = 5,
  HalfWin = 6,
  HalfLose = 7
}

(TotoSportsBetSlipResult as any).__typeName = 'TotoSportsBetSlipResult';