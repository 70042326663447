export enum GaelicFootballMarketTypeValue
{
    MatchWinner = 9336,
    FirstHalfResult = 9338,
    FirstHalfHandicap = 9383,
    TotalPointsOverUnder = 9390,
    SecondHalfResult = 9393,
    FirstHalfTotalPointsOverUnder = 10051,
    DrawNoBet = 10161,
    MatchHandicap = 12464,
    DoubleResult = 12843,
    NextScoringPlay = 12844,
    FirstHalfHomeTeamTotalsOverUnder = 12845,
    FirstHalfAwayTeamTotalsOverUnder = 12846,
    BothTeamsToScoreAGoalOrMore = 12847,
    SecondHalfTotalPointsOverUnder = 12848,
    SecondHalfHandicap = 12849,
    SecondHalfHomeTeamTotalsOverUnder = 12850,
    SecondHalfAwayTeamTotalsOverUnder = 12851,
    RaceTo5Points = 12852,
    RaceTo10Points = 12853,
    RaceTo15Points = 12854,
    RaceTo20Points = 12855,
    RaceTo25Points = 12856,
    LeadAfter20Minutes = 12857,
    LeadAfter30Minutes = 12858,
    NextGoal = 12959,
}

(GaelicFootballMarketTypeValue as any).__typeName = 'GaelicFootballMarketTypeValue';