<ng-container *ngIf="detail">

  <div class="detail-row-item" *ngFor="let status of detail.items">

    <div class="field">
      <span class="field-label">
        {{ status.status | translateEnum: "TransactionStatus" }}
      </span>
      <span class="field-data">
        {{ status.createdAt | totoDate:'L HH:mm':false }}
      </span>
    </div>
    <div class="field" *ngIf="status.comment">
      <span class="field-label">
        {{ "Views.User.TransactionLog.Table.Comment" | translate }}
      </span>
      <span class="field-data">
        {{ status.comment || "-" }}
      </span>
    </div>

  </div>

</ng-container>
