import { Component, OnInit, Input, Optional, HostBinding } from '@angular/core';
import { UntypedFormGroup, ControlContainer, AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: "toto-slide-toggle",
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {
  
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public label: string;

  private _ref: AbstractControl;

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this.for) {
      if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
        this.formGroup = <FormGroup>this._controlContainer.control;
      }
      this._ref = this.formGroup?.controls[this.for];
    }
  }

  public get isChecked() {
    return this._ref?.value;
  }

  public toggleValue() {
    if (this._ref) {
      const refValue = this._ref.value;
      const newValue = refValue === true ? false : true;
      this._ref.setValue(newValue);
    }
  }
  
}
