<form [formGroup]="formGroup">

  <div class="form-title">
    <h2>{{'Views.User.TransactionLog.Title.TransactionLog' | translate}}</h2>
  </div>

  <div class="form-filters">

    <div class="form-filters-row">
      <swiper #swiperRef slidesPerView="auto" class="toto-swiper" direction="horizontal" [spaceBetween]="10"
        [navigation]="true" [watchOverflow]="true" [touchReleaseOnEdges]="true">
        <ng-template swiperSlide>
          <toto-button label="Common.Filters.Today" (click)="setDatePeriod(DatePeriod.Today)"
            [color]="datePeriod === DatePeriod.Today ? 'theme-2' : 'dark-grey'"></toto-button>
        </ng-template>
        <ng-template swiperSlide>
          <toto-button label="Common.Filters.ThisWeek" (click)="setDatePeriod(DatePeriod.ThisWeek)"
            [color]="datePeriod === DatePeriod.ThisWeek ? 'theme-2' : 'dark-grey'"></toto-button>
        </ng-template>
        <ng-template swiperSlide>
          <toto-button label="Common.Filters.ThisMonth" (click)="setDatePeriod(DatePeriod.ThisMonth)"
            [color]="datePeriod === DatePeriod.ThisMonth ? 'theme-2' : 'dark-grey'"></toto-button>
        </ng-template>
        <ng-template swiperSlide>
          <toto-button label="Common.Filters.DatePeriod" (click)="toggleDateControls()"
            [color]="datePeriod === DatePeriod.Custom ? 'theme-2' : 'dark-grey'"></toto-button>
        </ng-template>
      </swiper>
    </div>
    <expander [trigger]="showDateControls">
      <div class="form-filters-row" [formGroup]="formGroup">
        <toto-datepicker for="dateFrom" placeholder="Common.Label.DateFrom"></toto-datepicker>
        <toto-datepicker for="dateTo" placeholder="Common.Label.DateTo"></toto-datepicker>
      </div>
    </expander>
  </div>

  <div class="form-body mt20">

    <div class="data-table-container">

      <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef [ngSwitch]="showFilters">
            <div mat-sort-header data-table-filter-dropdown [options]="typeOptions"
              fullscreenLabel="Views.User.TransactionLog.Table.TransactionType" *ngSwitchCase="true">
              {{'Views.User.TransactionLog.Table.TransactionType' | translate}}
            </div>
            <div mat-sort-header *ngSwitchDefault>
              {{'Views.User.TransactionLog.Table.TransactionType' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.type | translateEnum: 'TransactionType'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'Views.User.TransactionLog.Table.TransactionDate' | translate}}
            </div>
          </mat-header-cell>

          <mat-cell *matCellDef="let row">
            {{row.date | totoDate:'L HH:mm' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef [ngSwitch]="showFilters" class="header-cell-end">
            <div mat-sort-header data-table-filter-text [skipFilterOptions]="[TextFilterValueType.Contains]"
              *ngSwitchCase="true">
              {{'Views.User.TransactionLog.Table.TransactionAmount' | translate}}
            </div>
            <div mat-sort-header *ngSwitchDefault class="cell-end">
              {{'Views.User.TransactionLog.Table.TransactionAmount' | translate}}
            </div>
          </mat-header-cell>

          <mat-cell *matCellDef="let row" class="cell-end">
            <span [class.amount-not-realized]="!isAmountRealized(row)"> {{row.amount | priceValue:2:true}} </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef [ngSwitch]="showFilters" class="header-cell-center">
            <div mat-sort-header data-table-filter-dropdown [options]="statusOptions"
              fullscreenLabel="Views.User.TransactionLog.Table.TransactionStatus" *ngSwitchCase="true">
              {{'Views.User.TransactionLog.Table.TransactionStatus' | translate}}
            </div>
            <div mat-sort-header *ngSwitchDefault>
              {{'Views.User.TransactionLog.Table.TransactionStatus' | translate}}
            </div>
          </mat-header-cell>

          <mat-cell *matCellDef="let row" class="cell-center">
            {{row.status | translateEnum: 'TransactionStatus'}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let row" class="full-width" [attr.colspan]="displayedColumns.length">
            <transaction-detail [detail]="row.expandedDetail" [@detailExpand]="row.expandedDetail ? 'expanded' : 'collapsed'"></transaction-detail>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="getTransactionDetail(row)"
        [class.detail-expanded]="row.expandedDetail" class="transaction-row"></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"
        [class.expanded-row]="row.expandedDetail"></mat-row>

      </mat-table>
      <no-content *ngIf="!hasData" text="{{'Views.User.TransactionLog.NoTransactions' | translate}}"
        icon="noTransactions"></no-content>
    </div>

    <data-table-footer (paginator)="hookPaginator($event)"> </data-table-footer>
  </div>
</form>