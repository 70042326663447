import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, LOCALE_ID, Injectable } from '@angular/core';

// --------------
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';

import {
  HttpClientModule,
} from '@angular/common/http';

import 'src/app/ext/observable-result';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppState, TotoAppModule } from './app.state';

// i18n
import {
  TranslateModule,
} from '@ngx-translate/core';

// MODULES
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './helpers/MomentUtcDateAdapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material/core';

// SERVICES
import {
  LocaleService,
  ResultsService,
  BettingService,
  TitleService,
  InfoService
} from './services';

// HELPERS
import {
  LocalStorage,
  ResponsiveHelper,
  MessageDispatcher,
  TotoDialog
} from './helpers';

// VIEWS
import {
  LoginViewComponent,
  ResetPasswordViewComponent,
  RegisterViewComponent,
  NotFoundViewComponent,
  InfoViewComponent,
  UserPaymentsOverviewViewComponent,
  UserTransactionLogViewComponent,
} from 'src/app/views';

// RESOLVERS
import {
  InfoResolve,
  UserInfoResolve,
  TransactionStatusResolve,
  UserSafeBettingResolve,
  TransactionLogResolve,
  UserDepositTransactionResolve,
  UserPreferencesResolve,
  UserBalanceResolve,
  UserContactInfoResolve,
  UserWithdrawalInfoResolve,
  UserBonusesResolve,
  UserDepositResolve
} from './resolvers';

import { ApiInterceptor, ErrorInterceptor } from './helpers';

// COMPONENTS
import {
  NavigatorComponent,
  NavigatorDesktopComponent,
  NavigatorMobileComponent,
  
  NewsBlockComponent,
  BannerComponent,
  ValidationErrorsComponent,
  ProvidersComponent,
  PageLoaderComponent,
  LoginBlockComponent,
  PopMessageComponent,
  SelectFullscreenComponent,
  HamburgerToggleComponent,
  PaymentAmountComponent,
  NoContentComponent
} from './components';

import { DataTableComponent } from 'src/app/components/_layout/data-table/data-table.component';

// CARD
import { ConfirmDialogComponent } from './components/_layout/confirm-dialog/confirm.dialog';
import { DialogComponent } from './components/_layout/dialog/dialog.component';

// DIRECTIVES
import { IsAuthenticatedDirective } from './directives';
import {
  DataTableDropdownFilterDirective,
  DataTableFilterDirective,
  DataTableTextFilterDirective
} from './directives/data-table-filter.directive';

import { ILanguage } from './models';
import { SharedModule } from './shared.module';
import { SvgIconsModule } from '@ngneat/svg-icon';

import icons from '../assets/svg/svg-icons';
import { BannersResolve } from './resolvers/banners.resolve';
import { BrowserModule, Title } from '@angular/platform-browser';
import { LivestreamComponent } from './components/_layout/navigator/components/livestream/livestream.component';
import { UpcomingBlockComponent } from './components/_layout/navigator/components/upcoming/upcoming.component';
import { UserBlockComponent } from './components/_layout/navigator/components/user/user.component';
import { UserMenuComponent } from './components/_elements/user/user-menu/user-menu.component';
import { UserSidebarComponent } from './components/_elements/user/user-sidebar/user-sidebar.component';
import { UserBalanceComponent } from './components/_elements/user/user-balance/user-balance.component';
import { PendingTicketsComponent } from './components/_elements/user/pending-tickets/pending-tickets.component';
import { UserNameComponent } from './components/_elements/user/user-name/user-name.component';
import { UserNotificationsComponent } from './components/_layout/navigator/components/notifications/notifications.component';
import { UserBlockDesktopComponent } from './components/_layout/navigator/components/user/desktop/user-desktop.component';
import { UserBlockMobileComponent } from './components/_layout/navigator/components/user/mobile/user-mobile.component';
import { NavigatorLanguagesBlockComponent } from './components/_layout/navigator/components/language-picker/nav-languages.component';
import { AuthenticationOptionsComponent } from './components/_elements/authentication-options/authentication-options.component';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { ActionHelper } from './helpers/action';
import { UserViewComponent } from './views/user/user.view';
import { UserBonusOverviewViewComponent } from './views/user/bonus/overview/bonus-overview.view';
import { LoginDialogComponent } from './components/dialogs/login/login.dialog';
import { RouterModule, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { RegisterDialogComponent } from './components/dialogs/register/register.dialog';
import { NavigationSpinnerComponent } from './components/_layout/loaders/navigation-spinner/navigation-spinner.component';
import { AblyService } from './services/ably.service';
import { UserVerificationViewComponent } from './views/user/settings/verification/user-verification.view';
import { UserPasswordViewComponent } from './views/user/settings/password/user-password.view';
import { UserPreferencesViewComponent } from './views/user/settings/preferences/user-preferences.view';
import { UserContactDetailsViewComponent } from './views/user/settings/contact-details/user-contact-details.view';
import { UserAddressViewComponent } from './views/user/settings/address/user-address.view';

import { UserCloseAccountViewComponent } from './views/user/responsible-betting/close-account/close-account.view';
import { UserPaymentsWithdrawalViewComponent } from './views/user/payments/withdrawal/withdrawal.view';
import { UserPaymentsDepositViewComponent } from './views/user/payments/deposit/deposit.view';
import { UserBonusCampaignsViewComponent } from './views/user/bonus/campaigns/bonus-campaigns.view';
import { UserLimitsViewComponent } from './views/user/responsible-betting/limits/limits.view';
import { UserSelfRestraintViewComponent } from './views/user/responsible-betting/self-restraint/self-restraint.view';
import { UserPaymentViewComponent } from './views/user/payments/payment/payment.view';
import { UserNotAuthenticatedMessageComponent } from './components/_elements/messages/user-not-authenticated/user-not-authenticated.component';
import { PendingVerificationDialogComponent } from './components/dialogs/pending-verification/pending-verification.dialog';
import { VerificationSuccessDialogComponent } from './components/dialogs/register-success/verification-success.dialog';
import { TermsOfUseDialogComponent } from './components/dialogs/terms-of-use/terms-of-use.dialog';
import { VerificationReminderDialogComponent } from './components/dialogs/verification-reminder/verification-reminder.dialog';
import { SelfRestraintSuccessDialogComponent } from './components/dialogs/self-restraint-success/self-restraint-success.dialog';
import { CloseAccountSuccessDialogComponent } from './components/dialogs/close-account-success/close-account-success.dialog';
import { CampaignComponent } from './views/user/bonus/campaign/campaign.component';
import { TransactionDetailComponent } from './views/user/transaction-log/detail/transaction-detail.component';
import { BankLoginOptionsComponent } from './components/_elements/bank-login-options/bank-login-options.component';
import { BankLoginDialogComponent } from './components/dialogs/login/bank-login.dialog';

// export class i18nLoader implements TranslateLoader {

//   constructor(private http: HttpClient, private _appState: AppState, private _locale: LocaleService) {}

//   public getTranslation(): any {
//     const lang = this._appState.language?.value;
//     const siteLocale = this._appState.siteLocale
//     const appModule = this._appState.activeAppModule;
    
//     // console.log("Module", appModule, TotoAppModule[appModule]);
//     // console.log(appModule, TotoAppModule, TotoAppModule[appModule]);

//     // return this.http.get(appSettings.i18nPath + `${TotoAppModule[appModule]}`, { headers: { 'Accept-Language': lang ?? 'et'} }).toPromise().then(((res: any) => {
//     //   console.log("SETTING", res);
//     //   this._locale.adaptLocale(res);
//     // }));

//     if (siteLocale) {
//       return of(siteLocale.i18n);
//     }


//     return this.http.get(appSettings.i18nPath + `${TotoAppModule[appModule]}`).pipe(
//       map((res: any) => {
//         this._appState.siteLocale = res;
//         return res.i18n;
//       })
//     );
//   }
// }

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  constructor(private readonly _titleService: TitleService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title) {
      this._titleService.setTitle(title);
    } 
    // else {
    //   this._titleService.setTitle("Toto");
    // }
  }
}

// function appLoadFactory(locale: LocaleService, appState: AppState) {
//   const language: ILanguage = appState.language || appSettings.languages[0];
//   return () => locale.getLang().then(res => {
//     locale.adaptLocale(language, res);
//   });
// }

@NgModule({
  declarations: [
    // COMPONENTS
    AppComponent,
    NavigatorComponent,
    NavigatorDesktopComponent,
    NavigatorMobileComponent,
    NavigatorLanguagesBlockComponent,
    TransactionDetailComponent,
    UserMenuComponent,
    UserNotificationsComponent,
    UserSidebarComponent,
    UserBalanceComponent,
    UserNameComponent,
    PendingTicketsComponent,
    NewsBlockComponent,
    BannerComponent,
    ProvidersComponent,
    ValidationErrorsComponent,
    PopMessageComponent,
    LivestreamComponent,
    PaymentAmountComponent,
    LoginDialogComponent,
    RegisterDialogComponent,
    PendingVerificationDialogComponent,
    VerificationSuccessDialogComponent,
    VerificationReminderDialogComponent,
    SelfRestraintSuccessDialogComponent,
    CloseAccountSuccessDialogComponent,
    TermsOfUseDialogComponent,

    // BLOCKS
    UpcomingBlockComponent,
    UserBlockComponent,
    UserBlockDesktopComponent,
    UserBlockMobileComponent,
    LoginBlockComponent,
    


    // VIEWS


     // User views
    UserViewComponent,
    UserPaymentsWithdrawalViewComponent,
    UserPaymentsDepositViewComponent,
    UserPaymentsOverviewViewComponent,
    UserPaymentViewComponent,
    
    CampaignComponent,
    UserBonusOverviewViewComponent,
    UserBonusCampaignsViewComponent,

    UserLimitsViewComponent,
    UserSelfRestraintViewComponent,
    UserCloseAccountViewComponent,

    UserAddressViewComponent,
    UserContactDetailsViewComponent,
    UserPasswordViewComponent,
    UserPreferencesViewComponent,
    UserVerificationViewComponent,
    UserTransactionLogViewComponent,

    UserVerificationViewComponent,
    UserPasswordViewComponent,
    UserPreferencesViewComponent,
    UserContactDetailsViewComponent,
    UserAddressViewComponent,

    LoginViewComponent,
    RegisterViewComponent,
    ResetPasswordViewComponent,
    //
  
    NotFoundViewComponent,
    InfoViewComponent,
    AuthenticationOptionsComponent,
    UserNotAuthenticatedMessageComponent,
    BankLoginOptionsComponent,
    BankLoginDialogComponent
    
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    // ANGULAR MODULES
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      // loader: {
      //   provide: TranslateLoader,
      //   useClass: i18nLoader,
      //   deps: [HttpClient, AppState]
      // }
    }),
    SvgIconsModule.forRoot({
      sizes: {
        xs: '10px',
        sm: '14px',
        md: '16px',
        lg: '20px'
      },
      icons
    })
  ],
  providers: [
    AppState,
    TotoDialog,
    LocaleService,
    ResultsService,
    BettingService,
    ResponsiveHelper,
    TitleService,
    AblyService,
    InfoResolve,
    UserInfoResolve,
    UserSafeBettingResolve,
    TransactionStatusResolve,
    TransactionLogResolve,
    LoginDialogComponent,
    BannersResolve,
    MessageDispatcher,
    UserDepositTransactionResolve,
    UserBonusesResolve,

    UserPreferencesResolve,
    UserBalanceResolve,
    UserContactInfoResolve,
    UserWithdrawalInfoResolve,
    UserDepositResolve,
    // {
    //   provide: APP_INITIALIZER,
    //   deps: [LocaleService, AppState],
    //   multi: true,
    //   useFactory: appLoadFactory
    // },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
      deps: [TitleService]
    },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentUtcDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
