<form [formGroup]="formGroup" (ngSubmit)="savePasswordChange()">
  <div class="form-title">
    <h2>{{'Navigator.Menu.Settings.Password' | translate}}</h2>
    <h5>
      {{'Navigator.Menu.Settings.Settings' | translate}}
    </h5>
  </div>

  <div class="form-body">
    <p>
      {{'Views.User.Settings.Password.Introduction' | translate}}
    </p>

    <br />

    <form-field
      label="Views.User.Settings.Password.Label.CurrentPassword"
      [labelTop]="true"
    >
      <toto-input for="currentPassword" type="password"></toto-input>
      <validation-errors for="currentPassword"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Password.Label.NewPassword"
      [labelTop]="true"
    >
      <toto-input for="password" type="password"></toto-input>
      <validation-errors for="password"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Password.Label.ConfirmPassword"
      [labelTop]="true"
    >
      <toto-input for="confirmation" type="password"></toto-input>
      <validation-errors for="confirmation"></validation-errors>
    </form-field>
  </div>

  <div class="form-footer">
    <toto-button
      color="theme-1"
      label="Action.Renew"
      type="submit"
      [loading]="actionLoading.save"
    ></toto-button>
  </div>
</form>
