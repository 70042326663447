import { Component, Inject } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IVideoBoxData } from "../../video-box/video-box.component";
import { BetType } from "src/app/enum";

@Component({
  selector: "video-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./video.dialog.html",
  styleUrls: ['./video.dialog.scss', '../../shared.scss']
})
export class VideoDialogComponent {

  public data: IVideoBoxData;

  public embedUrl: string;

  public BetType = BetType;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    public dialogRef: MatDialogRef<VideoDialogComponent>
  ) {
    this.data = this._data || {};
    this._createEmbedUrl();
  }

  public get id(): string {
    return this.data?.id;
  }

  public get name(): string {
    return this.data?.name;
  }

  public get text(): string {
    return this.data?.text;
  }

  public get betType() {
    return this.data?.betType;
  }

  public get betTypeEnum() {
    return this.data?.betTypeEnum;
  }

  private _createEmbedUrl(): void {
    this.embedUrl = `https://www.youtube.com/embed/${this.id}`;
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }


}

