import { Component, Output, EventEmitter, Optional, Input } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { AppState } from 'src/app/app.state';


@Component({
  selector: 'payment-amount',
  templateUrl: './payment-amount.component.html',
  styleUrls: ['./payment-amount.component.scss']
})
export class PaymentAmountComponent {


  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;

  @Input() public amountOptions = [20, 50, 100];

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
      this.formGroup = <UntypedFormGroup>this._controlContainer.control;
    }
  }
 
  public setAmount(amount: number) {
    this.formGroup.get(this.for).setValue(amount);
  }
}
