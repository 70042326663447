import { Component, OnInit, Input, Optional } from '@angular/core';
import { UntypedFormGroup, ControlContainer, AbstractControl } from '@angular/forms';

@Component({
  selector: "toto-radio",
  templateUrl: './toto-radio.component.html',
  styleUrls: ['./toto-radio.component.scss']
})
export class RadioComponent implements OnInit {

  public TotoRadioType = TotoRadioType;
  
  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public options: any[];
  @Input() public labelField = 'name';
  @Input() public label: string;
  @Input() public trailingLabel: string;
  @Input() public value: string;
  @Input() public clearable: boolean = false;
  @Input() public isLoginView: boolean = false;

  @Input() public type = TotoRadioType.Default;

  private _ref: AbstractControl;

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this.for) {
      if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
        this.formGroup = <UntypedFormGroup>this._controlContainer.control;
      }
      this._ref = this.formGroup.get(this.for);
    }
  }

  public isChecked(item: any): boolean {
    const refValue = this._ref.value;
    if (this.value) {
      return refValue === item[this.value];
    }
    return refValue === item;
  }

  public select(option: any): void {
    if (this._ref) {
      let value = this.value ? option[this.value] : option;
      this._setValue(value);
    }
  }
  
  private _setValue(value: any) {
    if(this.clearable && this._ref.value === value) {
      this._ref.setValue(undefined);
    }
    else {
      this._ref.setValue(value);
    }
  }
}


export enum TotoRadioType {
  Default = 1,
  Checkbox = 2
}