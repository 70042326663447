export enum TicketStatus {
  NotSet = 0,
  Pending = 1,
  Sent = 2,
  Rejected = 3,
  Cancelled = 4,
  Expired = 5
}

(TicketStatus as any).__typeName = 'TicketStatus';

