export enum WaterPoloMarketTypeValue
{
    TotalGoalsOverUnder = 7220,
    WinMatchIncludingOvertime = 8278,
    HandicapBetting = 9849,
    HomeTeamTotalPointsOverUnder = 12656,
    AwayTeamTotalPointsOverUnder = 12657,
    HomeTeamTotalPointsOddEven = 12658,
    AwayTeamTotalPointsOddEven = 12659,
    TeamToScoreNextGoal = 12660,
    FirstHalfResult = 12661,
    SecondHalfResult = 12662,
    FirstPeriodResult = 12663,
    SecondPeriodResult = 12664,
    ThirdPeriodResult = 12665,
    FourthPeriodResult = 12666,
    FirstPeriodHandicap = 12667,
    SecondPeriodHandicap = 12668,
    ThirdPeriodHandicap = 12669,
    FourthPeriodHandicap = 12670,
    FirstPeriodTotalsOverUnder = 12671,
    SecondPeriodTotalsOverUnder = 12672,
    ThirdPeriodTotalsOverUnder = 12673,
    FourthPeriodTotalsOverUnder = 12674,
    FirstPeriodHomeTeamTotalsOverUnder = 12675,
    SecondPeriodHomeTeamTotalsOverUnder = 12676,
    ThirdPeriodHomeTeamTotalsOverUnder = 12677,
    FourthPeriodHomeTeamTotalsOverUnder = 12678,
    FirstPeriodAwayTeamTotalsOverUnder = 12679,
    SecondPeriodAwayTeamTotalsOverUnder = 12680,
    ThirdPeriodAwayTeamTotalsOverUnder = 12681,
    FourthPeriodAwayTeamTotalsOverUnder = 12682,
    FirstPeriodTotalsOddEven = 12683,
    SecondPeriodTotalsOddEven = 12684,
    ThirdPeriodTotalsOddEven = 12685,
    FourthPeriodTotalsOddEven = 12686,
    FirstHalfHandicap = 12687,
    SecondHalfHandicap = 12688,
}

(WaterPoloMarketTypeValue as any).__typeName = 'WaterPoloMarketTypeValue';