import { Component, Input, Optional } from '@angular/core';
import { UntypedFormGroup, ControlContainer} from '@angular/forms';
import moment from 'moment';

@Component({
  selector: "title-date",
  templateUrl: "./title-date.component.html",
  styleUrls: ['./title-date.component.scss']
})
export class TitleDateComponent {

  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public date: any;

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
      this.formGroup = <UntypedFormGroup>this._controlContainer.control;
    }
  }

  public get currentDate() {
    return this.date ? moment(this.date) : this.formGroup.get(this.for).value;
  }

  public get currentMonth() {
    return this.currentDate.format('MMMM');
  };

  public get currentDayOfMonth() {
    return this.currentDate.date();
  }

  public get currentDay() {
    return this.currentDate.format('dddd');
  }

}
