import { Injectable, Inject } from '@angular/core';
import moment from 'moment';
import { DatePeriod } from '../enum';

@Injectable({ providedIn: 'root' })
export class DateHandler {

  public currentDate = moment();
  public currentMonth = this.currentDate.format('MMMM');
  public dayOfMonth = this.currentDate.date();
  public currentDay = this.currentDate.format('dddd');

  public normalize(date: moment.MomentInput) {
    return moment(date).format('yyyy-MM-DD');
  }

  public addDays(date: moment.MomentInput, days: number) {
    return moment(date).add(days, 'days');
  }

  public addMonths(date: moment.MomentInput, months: number) {
    return moment(date).add(months, 'months');
  }

  public addYears(date: moment.MomentInput, years: number) {
    return moment(date).add(years, 'years');
  }

  public subtractDays(date: moment.MomentInput, days) {
    return moment(date).add(days * -1, 'days');
  }

  public previousDay(date: moment.MomentInput) {
    return this.addDays(date, -1);
  }

  public nextDay(date: moment.MomentInput) {
    return this.addDays(date, 1);
  }

  public isSameOrAfter(date: moment.MomentInput, diff = moment()) {
    return moment(date).isSameOrAfter(diff);
  }

  public weekFromNow(date: moment.MomentInput) {
    return this.addDays(date, 7);
  }

  public monthFromNow(date: moment.MomentInput) {
    return this.addDays(date, 30);
  }

  public yearFromNow(date: moment.MomentInput) {
    return this.addDays(date, 365);
  }

  public getPeriod(period: DatePeriod) {
    const today = moment().startOf('day');
    switch (period) {
      case DatePeriod.Today:
        return {
          begin: today.clone().startOf('day'),
          end: today.clone().endOf('day')
        };
      case DatePeriod.Yesterday:
        const yesterday = this.subtractDays(today, 1);
        return {
          begin: yesterday.clone(),
          end: today.clone()
        };
      case DatePeriod.ThisWeek:
        return {
          begin: today.clone().startOf('week'),
          end: today.clone().endOf('week')
        };
      case DatePeriod.LastWeek:
        const lastWeek = today.subtract(1, 'weeks');
        return {
          begin: lastWeek.clone().startOf('week'),
          end: lastWeek.clone().endOf('week')
        };
      case DatePeriod.ThisMonth:
        return {
          begin: today.clone().startOf('month'),
          end: today.clone().endOf('month')
        };
      case DatePeriod.LastMonth:
        const lastMonth = today.subtract(1, 'months');
        return {
          begin: lastMonth.clone().startOf('month'),
          end: lastMonth.clone().endOf('month')
        };
    }
  }

  public getPeriodOdataFilter(period: DatePeriod, key = 'createdAt') {
    switch (period) {
      case DatePeriod.Today:
        const today = this.getPeriod(DatePeriod.Today);
        return `${key} ge ${today.begin.format('yyyy-MM-DD')} and ${key} lt ${today.end.add(1, 'days').format('yyyy-MM-DD')}`;
 
      case DatePeriod.Yesterday:
        const yesterday = this.getPeriod(DatePeriod.Yesterday);
        return `${key} ge ${yesterday.begin.format('yyyy-MM-DD')} and ${key} lt ${yesterday.end.add(1, 'days').format('yyyy-MM-DD')}`;

      case DatePeriod.ThisWeek:
        const thisWeek = this.getPeriod(DatePeriod.ThisWeek);
        return `${key} ge ${thisWeek.begin.format('yyyy-MM-DD')} and ${key} lt ${thisWeek.end.add(1, 'days').format('yyyy-MM-DD')}`;

      case DatePeriod.LastWeek:
        const lastWeek = this.getPeriod(DatePeriod.LastWeek);
        return `${key} ge ${lastWeek.begin.format('yyyy-MM-DD')} and ${key} lt ${lastWeek.end.add(1, 'days').format('yyyy-MM-DD')}`;
    
      case DatePeriod.ThisMonth:
        const thisMonth = this.getPeriod(DatePeriod.ThisMonth);
        return `${key} ge ${thisMonth.begin.format('yyyy-MM-DD')} and ${key} lt ${thisMonth.end.add(1, 'days').format('yyyy-MM-DD')}`;
   
      case DatePeriod.LastMonth:
        const lastMonth = this.getPeriod(DatePeriod.LastMonth);
        return `${key} ge ${lastMonth.begin.format('yyyy-MM-DD')} and ${key} lt ${lastMonth.end.add(1, 'days').format('yyyy-MM-DD')}`;
  
    }
  }


}
