import { FilterType } from "src/app/enum";
import { TextValueFilterType, DateValueFilterType, DropdownValueFilterType } from "src/app/enum/FilterType";
import { DataTableFilter } from "./filters/data-table-filter";
import { DateDataTableFilterComponent } from "./filters/date-filter/date-data-table-filter.component";
import { DropdownDataTableFilterComponent, FilterValueType } from "./filters/dropdown-filter/dropdown-data-table-filter.component";
import { TextDataTableFilterComponent } from "./filters/text-filter/text-data-table-filter.component";


export interface IDataTableFilterSerializer {
  serialize(filters: DataTableFilter[], map?: any): string;
}

export class DataTableFilterODataSerializer
  implements IDataTableFilterSerializer {
  public serialize(filters: DataTableFilter[], map?: any): string {
    return filters
      .filter(x =>
        (x.getFilterType() === FilterType.Text &&
        x.getValueFilterType() === TextValueFilterType.HasValue ||
        x.getValueFilterType() === TextValueFilterType.DoesNotHaveValue)
          ? true : (x.getValue() !== undefined &&
            x.getValue() !== null &&
            x.getValue() !== '')
      )
      .map(x => {
        switch (x.getFilterType()) {
          case FilterType.Text:
            return this._serializeTextFilter(
              x as TextDataTableFilterComponent,
              map
            );
          case FilterType.Dropdown:
            return this._serializeDropdownFilter(
              x as DropdownDataTableFilterComponent,
              map
            );
          case FilterType.Date:
            return this._serializeDateFilter(
              x as DateDataTableFilterComponent,
              map
            );
        }
      })
      .filter(x => x != null)
      .join(' and ');
  }

  private _key(filter: DataTableFilter, map?: any) {
    const key = filter.getFilterKey();
    if (map && map.hasOwnProperty(key)) {
      return map[key];
    }
    return key;
  }

  private _serializeDateFilter(
    filter: DateDataTableFilterComponent,
    map?: any
  ): string {
    const value = filter.getValue();
    const key = this._key(filter, map);

    switch (filter.getValueFilterType()) {
      case DateValueFilterType.GreaterThan:
        return key + ' gt ' + value;
      case DateValueFilterType.LessThan:
        return key + ' lt ' + value;
      case DateValueFilterType.Equals:
        return key + ' gt ' + value + ' and ' + key + ' lt ' + value;
      default:
        return '';
    }
  }

  private _serializeTextFilter(
    filter: TextDataTableFilterComponent,
    map?: any
  ): string {
    if (filter.fromArray) {
      return `${this._key(
        filter,
        map
      )}/any(x:contains(x,'${filter.getValue()}'))`;
    }

    if (filter.fromArrayKey) {
      return `${this._key(filter, map)}/any(x:contains(x/${
        filter.fromArrayKey
      },'${filter.getValue()}'))`;
    }

    switch (filter.getValueFilterType()) {
      case TextValueFilterType.Contains:
        return (
          'contains(' + this._key(filter, map) + ",'" + filter.getValue() + "')"
        );
      case TextValueFilterType.GreaterThan:
        return this._key(filter, map) + ' gt ' + filter.getValue();
      case TextValueFilterType.LessThan:
        return this._key(filter, map) + ' lt ' + filter.getValue();
      case TextValueFilterType.Equals:
        return this._key(filter, map) + ' eq ' + filter.getValue();
      case TextValueFilterType.HasValue:
        return this._key(filter, map) + ' ne null';
      case TextValueFilterType.DoesNotHaveValue:
        return this._key(filter, map) + ' eq null';
      default:
        return '';
    }
  }

  private _serializeDropdownFilter(
    filter: DropdownDataTableFilterComponent,
    map?: any
  ): string {
    if (filter.isFlag) {
      return `${this._key(
        filter,
        map
      )} has ${this._serializeDropdownFilterValue(filter)}`;
    }

    if (filter.fromArray) {
      return `${this._key(
        filter,
        map
      )}/any(x:contains(x,'${filter.getValue()}'))`;
    }

    if (filter.fromArrayKey) {
      return `${this._key(filter, map)}/any(x:contains(x/${
        filter.fromArrayKey
      },'${filter.getValue()}'))`;
    }

    switch (filter.getValueFilterType()) {
      case DropdownValueFilterType.DoesNotEqual:
        return (
          this._key(filter, map) +
          ' ne ' +
          this._serializeDropdownFilterValue(filter)
        );
      case DropdownValueFilterType.Equals:
        return (
          this._key(filter, map) +
          ' eq ' +
          this._serializeDropdownFilterValue(filter)
        );
      default:
        return '';
    }
  }

  private _serializeDropdownFilterValue(
    filter: DropdownDataTableFilterComponent
  ): string {
    const value = filter.getValue();
    switch (filter.valueType) {
      case FilterValueType.Literal:
        return value;
      case FilterValueType.String:
        return "'" + value + "'";
    }

    return null;
  }
}
