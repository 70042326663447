export enum PmuRaceCategory {
    UNKNOWN = 0,
    GALOP = 1,
    PLAT = 2,
    TROTATTELE = 3,
    TROTMONTE = 4,
    HAIE = 6,
    CROSS = 7,
    STEEPLE = 8
}

(PmuRaceCategory as any).__typeName = 'PmuRaceCategory';

const _ = 'Enums.PmuRaceCategory.';
(PmuRaceCategory as any).__altTranslationKeys = {
    [PmuRaceCategory.PLAT]: _ + 'PLAT',
    [PmuRaceCategory.TROTATTELE]: _ + 'TROTATTELE',
    [PmuRaceCategory.TROTMONTE]: _ + 'TROTMONTE',
    [PmuRaceCategory.STEEPLE]: _ + 'STEEPLE',
    [PmuRaceCategory.CROSS]: _ + 'CROSS',
    [PmuRaceCategory.HAIE]: _ + 'HAIE',
};
