<nav>
  <div class="nav-top-row">
    <div class="nav-content-wrapper">
      <div class="nav-left">
        <div class="logo-container" (click)="onLogoClick()">
          <img id="logo" src="/assets/images/brand/logo-m.svg" />
        </div>
        <a
          class="navlink-main"
          *ngFor="let link of MAIN_MENU_LINKS | keyvalue"
          [href]="link.value.routerLink"
          [class.active]="activeAppModule == link.key"
        >
          {{ link.value.label | translate }}
        </a>
      </div>
      <div class="nav-center">
        <nav-search
          *ngIf="activeAppModule === TotoAppModule.Sports"
        ></nav-search>
      </div>
      <div class="nav-right">
        <user-block-desktop></user-block-desktop>
      </div>
    </div>
  </div>

  <div class="nav-bottom-row">
    <div class="nav-content-wrapper">
      <ng-container *ngFor="let link of menuItems">
        <ng-container *ngIf="link.isAuthenticated ? isLoggedIn : true">
          <ng-container [ngSwitch]="link.component ? true : false">
            <a
              *ngSwitchDefault
              class="navlink"
              [routerLink]="link.routerLink"
              [class.disabled]="link.isDisabled"
              [routerLinkActiveOptions]="link.isActiveMatchOptions"
              routerLinkActive="active"
            >

              <badge *ngIf="link.badgeRef && this[link.badgeRef]" [count]="this[link.badgeRef]"></badge>
              
              <svg-icon
                *ngIf="link.icon"
                class="navlink--icon"
                fontSize="32px"
                [key]="link.icon"
              ></svg-icon>

              <span class="navlink--label">
                {{ link.label | translate }}
              </span>

            </a>

            <ng-container
              dynamic-content
              *ngSwitchCase="true"
              [component]="link.component"
            ></ng-container>

          </ng-container>
        </ng-container>
      </ng-container>


    </div>
  </div>
</nav>
