import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { AppState } from 'src/app/app.state';

@Component({
  selector: 'nav-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class NavSearchBarComponent {

  @ViewChild('termInput') public termInput: any;

  @HostBinding('class.open')
  @Input()
  public isOpen = false;

  @Output() public onOpenChange = new EventEmitter<boolean>();

  public isLoading = false;

  public formGroup: FormGroup;

  private _termSubscription: Subscription;

  @HostBinding('class.compact')
  @Input() public compact = false;

  @HostListener('click') public onClick() {
    this._focusTermInput();
  }

  constructor(private _router: Router, private _route: ActivatedRoute, private _state: AppState) {}

  @HostBinding('class.full')
  public get isViewTablet() {
    return this._state.isViewTablet;
  }

  public get hasTermValue() {
    return this.formGroup.get('term').value ? true : false;
  }

  public get minTermLengthExceeded() {
    return this.formGroup.get('term').value?.length > 2;
  }

  private _focusTermInput() {
    setTimeout(() => {
      this.termInput.nativeElement.focus();
    });
  }

  public search(term = this.formGroup.get('term').value) {
    if (this.minTermLengthExceeded) {
      this.isLoading = true;
      this._router.navigate(['/sports/search'], { queryParams: { term } }).then(res => {
        this.isLoading = false;
      });
    }
  }

  public toggleOpen(toSet = !this.isOpen) {
    this.isOpen = toSet;
    if (toSet) {
      this._focusTermInput();
    }
    this.onOpenChange.emit(toSet);
  }

  public clear($event) {
    $event.stopImmediatePropagation();
    this.formGroup.get('term').setValue('', { emitEvent: false });
    this._focusTermInput();
  }

  ngOnInit() {
    const { term } = this._route.snapshot.queryParams || {};

    this.formGroup = new FormGroup({
      term: new FormControl(term ?? '')
    });

    if (this.minTermLengthExceeded) {
      this.isOpen = true;
    }

    this._termSubscription = this.formGroup
      .get('term')
      .valueChanges.pipe(debounceTime(750), distinctUntilChanged())
      .subscribe((term: string) => {
        if (!this.isLoading) {
          this.search(term);
        }
      });

      this._focusTermInput();
  }

  ngOnDestroy() {
    if (this._termSubscription) {
      this._termSubscription.unsubscribe();
    }
  }
}
