<ng-container [ngSwitch]="readonly" [formGroup]="formGroup">
  <span class="input-label" *ngIf="label" [class.required]="required">
    <label [for]="for">
      {{ label | translate }}
    </label>
  </span>

  <mat-form-field
    *ngSwitchCase="false"
    disableRipple
    [style.width]="width"
    [class.show-input]="showInput"
  >
    <input
      matInput
      input
      [min]="min"
      [max]="max"
      [placeholder]="placeholder | translate"
      (dateChange)="onChange($event)"
      [formControlName]="for"
      [matDatepicker]="picker"
    />
    <mat-datepicker #picker [touchUi]="touchUi"> </mat-datepicker>
    <mat-datepicker-toggle matSuffix disableRipple *ngIf="showInput">
      <span class="datepicker-clear" matDatepickerToggleIcon disableRipple>
        <span *ngIf="hasValue" (click)="clearDate()">×</span>
      </span>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix suffix disableRipple [for]="picker">
      <!-- <svg-icon key="datePicker" fontSize="21px" matDatepickerToggleIcon disableRipple></svg-icon> -->
    </mat-datepicker-toggle>
  </mat-form-field>
  <span class="input-padding" *ngSwitchCase="true">
    {{ formGroup.get(for).value | totoDate }}
  </span>
</ng-container>
