<form [formGroup]="restraintGroup" (ngSubmit)="save()" autocomplete="off">
  <div class="form-title">
    <h2>
      {{'Navigator.Menu.ResponsibleGame.SelfRestraint' | translate}}
    </h2>
    <h5>{{'Navigator.Menu.ResponsibleGame.ResponsibleGame' | translate}}</h5>
  </div>

  <div class="form-body">
    <p [innerHTML]="'Views.User.ResponsibleGaming.Restraint.Introduction' | translate">
    </p>

    <br />

    <div class="restraint-block" [ngSwitch]="currentStep">
      <ng-container *ngSwitchCase="SelfRestraintViewStep.ActiveRestriction">
        <form-field labelWidth="custom-2" label="Views.User.ResponsibleGaming.Restraint.Label.ValidityPeriod">
          {{activeRestraintUntil | date}}
        </form-field>
      </ng-container>
      <ng-container *ngSwitchCase="SelfRestraintViewStep.ChoosePeriod">
        <form-field labelWidth="custom-2" label="Views.User.ResponsibleGaming.Restraint.Label.ValidityPeriod">
          <toto-select for="months" [options]="validityPeriodOptions" labelField="label" compareField="value"
            [translate]="true" [clearable]="false" fullscreenLabel="Views.User.ResponsibleGaming.Restraint.Label.ValidityPeriod">
          </toto-select>
        </form-field>

        <!-- <form-field labelWidth="custom-2" label="Vali tooted">
          <div class="flex flex-column">
            <toto-checkbox
              *ngFor="let product of productOptions"
              [label]="product.label"
              [checked]="isAppModuleSelected(product)"
              (change)="onProductSelect(product)"
              class="mb15"
            >
            </toto-checkbox>
          </div>
        </form-field> -->
      </ng-container>

      <ng-container *ngSwitchCase="SelfRestraintViewStep.Confirmation">
        <form-field labelWidth="custom-2" label="Views.User.ResponsibleGaming.Restraint.Label.ValidityPeriod">
          {{restraintGroup.get('months').value?.label | translate}}
        </form-field>

        <!-- <form-field labelWidth="custom-2" label="Tooted">
          <div class="flex flex-column">
            <div
              class="restraint-product mb10"
              *ngFor="let product of chosenProducts; let i = index">
              {{product.label | translate}}
            </div>
          </div>
        </form-field> -->

        <form-field labelWidth="custom-2" label="Common.Label.Password">
          <toto-input for="password" type="password" autocomplete="current-password"></toto-input>
        </form-field>
      </ng-container>
    </div>
  </div>

  <div class="form-footer" [ngSwitch]="currentStep">
    <ng-container *ngSwitchCase="SelfRestraintViewStep.ActiveRestriction">
      <toto-button label="Action.Back" color="theme-1" (click)="this._router.navigate([this._authService.activeAppModuleRoot])">
      </toto-button>
    </ng-container>

    <ng-container *ngSwitchCase="SelfRestraintViewStep.ChoosePeriod">
      <toto-button label="Action.Continue" color="theme-1" (click)="toView(SelfRestraintViewStep.Confirmation)">
      </toto-button>
    </ng-container>

    <ng-container *ngSwitchCase="SelfRestraintViewStep.Confirmation">
      <toto-button type="submit" label="Action.Confirm" color="green" class="mr15" [loading]="actionLoading.save">
      </toto-button>
      <toto-button label="Action.Cancel" color="dark-grey" (click)="toView(SelfRestraintViewStep.ChoosePeriod)">
      </toto-button>
    </ng-container>
  </div>
</form>