import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { AppState } from '../app.state';
import { MAIN_MENU_LINKS } from '../config/nav-links';

@Injectable({ providedIn: 'root' })
export class IsLoggedInGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _state: AppState
  ) { }

  public get activeAppModuleRoot() {
    return MAIN_MENU_LINKS[this._state.activeAppModule]?.routerLink
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._state.isLoggedIn) {
        this._router.navigate([this.activeAppModuleRoot ?? '/']);
        return false;
    }
    return true;
  }
}
