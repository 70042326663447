import { Component, Inject } from "@angular/core";
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: "pop-message",
  templateUrl: "./pop-message.component.html",
  styleUrls: ['./pop-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopMessageComponent {

  constructor(
    public snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<PopMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  public dismiss() {
    this.snackBar.dismiss();
  }

}
