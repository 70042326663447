export enum Region
{
    // Type: ??
    Burma = 3795039,
    IvoryCoast = 3795040,
    Tahiti = 3795041,
    USVirginIslands = 3795042,
    Micronesia = 3795043,
    Nauru = 3795044,
    CentralAfricanRep = 3795045,
    FrenchGuyana = 3795046,
    BritishVirginIslands = 3795047,
    Mauretania = 3795048,
    Surinam = 3795049,
    FormerEastTimor = 3795050,
    GBIreland = 3795051,
    MarshallIslands = 3795052,
    Tuvalu = 3795053,
    ChristmasIsland = 3795054,
    Curacao = 3795055,
    Zanzibar = 3795056,
    Yugoslavia = 3795057,
    WestGermany = 3795058,
    EastGermany = 3795059,
    SovietUnion = 3795060,
    Czechoslovakia = 3795061,
    DutchEastIndies = 3795062,
    Zaire = 3795063,
    Crimea = 3795064,
    FrenchGuiana = 3795065,
    CS = 3795066,
    DOTA = 3795067,
    LoL = 3795068,
    SC2 = 3795069,
    WoT = 3795070,
    HOTS = 3795071,
    OW = 3795072,
    HS = 3795073,
    FIFA = 3795074,
    SF = 3795075,
    SMITE = 3795076,
    COD = 3795077,
    RL = 3795078,
    VG = 3795079,
    HALO = 3795080,
    WoWArena = 3795081,
    SC = 3795082,
    CrossFire = 3795083,
    Paladins = 3795084,
    WC3 = 3795085,
    KoG = 3795086,
    AoV = 3795087,
    GoW = 3795088,
    Quake = 3795089,
    General = 3795090,
    WoWMDI = 3795091,
    NBA2K = 3795092,
    R6 = 3795093,
    Artifact = 3795094,
    PES = 3795095,
    NHL = 3795096,
    Tennis = 3795097,
    VALORANT = 3795098,
    WR = 3795099,
    AoE4 = 3795100,
    AoE2 = 3795101,
    MobileLegends = 3795102,
    BrawlStars = 3795103,
    CODM = 3795104,
    TemporaryFixturesRegion = 90000001,
    Test = 90000021,
    // Type: Country
    Burundi = 31,
    Comoros = 32,
    Djibouti = 33,
    Eritrea = 34,
    Ethiopia = 35,
    Kenya = 36,
    Madagascar = 37,
    Malawi = 38,
    Mauritius = 39,
    Mayotte = 40,
    Mozambique = 41,
    Rwanda = 43,
    Seychelles = 44,
    Somalia = 45,
    Uganda = 46,
    Tanzania = 47,
    Zambia = 48,
    Zimbabwe = 49,
    Angola = 50,
    Cameroon = 51,
    Chad = 53,
    CongoRep = 54,
    CongoDR = 55,
    EquatorialGuinea = 56,
    Gabon = 57,
    SaoTomeandPrincipe = 58,
    Algeria = 59,
    Egypt = 60,
    Libya = 61,
    Morocco = 62,
    Sudan = 63,
    Tunisia = 64,
    Botswana = 66,
    Lesotho = 67,
    Namibia = 68,
    SouthAfrica = 69,
    Swaziland = 70,
    Benin = 71,
    BurkinaFaso = 72,
    CapeVerde = 73,
    CotedIvoire = 74,
    Gambia = 75,
    Ghana = 76,
    Guinea = 77,
    GuineaBissau = 78,
    Liberia = 79,
    Mali = 80,
    Mauritania = 81,
    Niger = 82,
    Nigeria = 83,
    Senegal = 85,
    SierraLeone = 86,
    Togo = 87,
    Anguilla = 88,
    AntiguaandBarbuda = 89,
    Aruba = 90,
    Bahamas = 91,
    Barbados = 92,
    CaymanIslands = 94,
    Cuba = 95,
    Dominica = 96,
    DominicanRepublic = 97,
    Grenada = 98,
    Guadeloupe = 99,
    Haiti = 100,
    Jamaica = 101,
    Martinique = 102,
    Montserrat = 103,
    NetherlandsAntilles = 104,
    PuertoRico = 105,
    SaintKittsandNevis = 107,
    SaintLucia = 108,
    SaintVincentandtheGrenadines = 110,
    TrinidadandTobago = 111,
    TurksandCaicosIslands = 112,
    Belize = 114,
    CostaRica = 115,
    ElSalvador = 116,
    Guatemala = 117,
    Honduras = 118,
    Mexico = 119,
    Nicaragua = 120,
    Panama = 121,
    Argentina = 122,
    Bolivia = 123,
    Brazil = 124,
    Chile = 125,
    Colombia = 126,
    Ecuador = 127,
    Guyana = 130,
    Paraguay = 131,
    Peru = 132,
    Suriname = 133,
    Uruguay = 134,
    Venezuela = 135,
    Bermuda = 136,
    Canada = 137,
    Greenland = 138,
    UnitedStatesofAmerica = 140,
    Kazakhstan = 141,
    Kyrgyzstan = 142,
    Tajikistan = 143,
    Turkmenistan = 144,
    Uzbekistan = 145,
    China = 146,
    HongKong = 147,
    Macao = 148,
    KoreaDPR = 149,
    Japan = 150,
    Mongolia = 151,
    KoreaRep = 152,
    Afghanistan = 153,
    Bangladesh = 154,
    Bhutan = 155,
    India = 156,
    Iran = 157,
    Maldives = 158,
    Nepal = 159,
    Pakistan = 160,
    SriLanka = 161,
    BruneiDarussalam = 162,
    Cambodia = 163,
    Indonesia = 164,
    Laos = 165,
    Malaysia = 166,
    Myanmar = 167,
    Philippines = 168,
    Singapore = 169,
    Thailand = 170,
    TimorLeste = 171,
    Vietnam = 172,
    Armenia = 173,
    Azerbaijan = 174,
    Bahrain = 175,
    Cyprus = 176,
    Georgia = 177,
    Iraq = 178,
    Israel = 179,
    Jordan = 180,
    Kuwait = 181,
    Lebanon = 182,
    OccupiedPalestinianTerritory = 183,
    Oman = 184,
    Qatar = 185,
    SaudiArabia = 186,
    Syria = 187,
    Turkey = 188,
    UAE = 189,
    Yemen = 190,
    Belarus = 191,
    Bulgaria = 192,
    CzechRepublic = 193,
    Hungary = 194,
    Poland = 195,
    Moldova = 196,
    Romania = 197,
    Russia = 198,
    Slovakia = 199,
    Ukraine = 200,
    Denmark = 203,
    Estonia = 204,
    FaeroeIslands = 205,
    Finland = 206,
    Guernsey = 207,
    Iceland = 208,
    Ireland = 209,
    IsleofMan = 210,
    Jersey = 211,
    Latvia = 212,
    Lithuania = 213,
    Norway = 214,
    Sweden = 216,
    GreatBritainandNorthernIreland = 217,
    Albania = 218,
    Andorra = 219,
    BosniaandHerzegovina = 220,
    Croatia = 221,
    Gibraltar = 222,
    Greece = 223,
    Italy = 225,
    Malta = 226,
    Montenegro = 227,
    Portugal = 228,
    SanMarino = 229,
    Serbia = 230,
    Slovenia = 231,
    Spain = 232,
    NorthMacedonia = 233,
    Austria = 234,
    Belgium = 235,
    France = 236,
    Germany = 237,
    Liechtenstein = 238,
    Luxembourg = 239,
    Monaco = 240,
    Netherlands = 241,
    Switzerland = 242,
    Australia = 243,
    NewZealand = 244,
    NorfolkIsland = 245,
    Fiji = 246,
    NewCaledonia = 247,
    PapuaNewGuinea = 248,
    SolomonIslands = 249,
    Vanuatu = 250,
    Guam = 251,
    Kiribati = 252,
    NorthernMarianaIslands = 256,
    Palau = 257,
    AmericanSamoa = 258,
    CookIslands = 259,
    FrenchPolynesia = 260,
    Niue = 261,
    Samoa = 263,
    Tonga = 265,
    Taiwan = 275,
    Kosovo = 1295705,
    ZambiaGeneral = 1296921,
    SouthSudan = 3795037,
    UnitedStates = 90000019,
    // Type: Continent
    Africa = 3,
    Americas = 4,
    Asia = 5,
    Europe = 6,
    Oceania = 7,
    // Type: Constituent_Country
    England = 3795032,
    Wales = 3795033,
    NorthernIreland = 3795034,
    Scotland = 3795035,
    // CAN = 90000002,
    // CAN = 90000003,
    // CAN = 90000004,
    // CAN = 90000005,
    // CAN = 90000006,
    // CAN = 90000007,
    // CAN = 90000008,
    // CAN = 90000009,
    // CAN = 90000010,
    // CAN = 90000011,
    // CAN = 90000012,
    // CAN = 90000013,
    // CAN = 90000014,
    // CAN = 90000015,
    // Canada = 90000017,
    // Canada = 90000018,
    // Type: FirstOrder_Administrative_Region
    UnitedArabEmirates = 1292063,
    BrazilGeneral = 1292500,
    RioGrandedoNorte = 1292521,
    CanadaGeneral = 1292599,
    Andalusia = 1293176,
    CanaryIslands = 1293178,
    Catalonia = 1293181,
    BasqueCountry = 1293184,
    Donotuse = 1293267,
    FaroeIslands = 1293268,
    TheScottishBorders = 1293509,
    SouthKoreaGeneral = 1294298,
    Macedonia,TheFormerYugoslavRepublicof = 1294674,
    Macau = 1294853,
    Palestine = 1295603,
    GazaStrip = 1295604,
    WestBank = 1295605,
    SwedenGeneral = 1295916,
    TristandaCunha = 1295944,
    Svalbard = 1296139,
    Texas = 1296690,
    // Type: NonGeographic
    NonGeographic = 1,
    TestRegion = 3795038,
    // Type: World
    World = 2,
}

(Region as any).__typeName = 'Region';