import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InfoService, TitleService } from 'src/app/services';
import { AppState } from 'src/app/app.state';

@Component({
  selector: 'info-view',
  templateUrl: './info.view.html',
  host: { 'class': 'layout'}
})
export class InfoViewComponent {

  public content: string;
  public title: string;
  public id: number;

  private _routeSubscription: Subscription;

  constructor(
    private _titleService: TitleService, 
    private _route: ActivatedRoute, 
    private _infoService: InfoService, 
    private _appState: AppState) { 
    this.getResolveData();
  }

  public get parentTitle(): string {
    const { title } = this._route.snapshot.params;

    return title || undefined;
  }

  ngOnInit(): void {

    this._routeSubscription = this._route.params.subscribe(
        params => {
          this.getResolveData();
        }
    );

  }

  public get externalLinks() {
    return this._appState.siteLocale?.pages;
  }

  public getResolveData() {
    const initialData = this._route.snapshot.data?.initialData;

    this._setParams(initialData);
  }

  private _setParams(data) {
    const { content, title, id } = data;

    this.content = content;
    this.title = title;
    this.id = id;
    this._titleService.setTitle(title);
  }

  ngOnDestroy() {
    this._routeSubscription.unsubscribe();
  }

}
