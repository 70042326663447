import { Component, OnInit, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
  selector: "confirm-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./confirm.dialog.html"
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public onDecline() {
    this.dialogRef.close();
  }

  public onConfirm() {
  }
}
