export enum CyclingMarketTypeValue
{
    StageWinner = 6500,
    Top3 = 6501,
    Winner = 6510,
    StageTop3 = 7197,
    StageTeamWinner = 9900,
    StageTeamTop3 = 9901,
}

(CyclingMarketTypeValue as any).__typeName = 'CyclingMarketTypeValue';
