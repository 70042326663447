import { toNumber } from 'src/app/util/number-util';
import { SportType, SportTypeNames } from '../enum/SportType';

export function sportNameFromId(sportId: SportType) {
  return SportTypeNames[sportId] ?? null;
}

export function sportIdFromName(sportName: string): SportType {
  return toNumber(Object.entries(SportTypeNames).find(x => x[1] == sportName)?.[0]) ?? null;
}
