import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NavigatorComponent } from '../navigator.component';


@Component({
  selector: 'navigator-desktop',
  templateUrl: './navigator-desktop.component.html',
  styleUrls: ['./navigator-desktop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigatorDesktopComponent extends NavigatorComponent {


  public get menuItems() {
    return this.moduleMenuLinks.filter(x => x.isDesktop);
  }


}
