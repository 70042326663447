export enum Information {
    WNR_RAPP_SANS_PARTICULARITE = 0,
    WNR_RAPP_EN_4 = 1,
    WNR_RAPP_EN_5 = 2,
    WNR_RAPP_EN_6 = 3,
    WNR_RAPP_EN_7 = 4,
    WNR_RAPP_ORDRE = 5,
    WNR_RAPP_ORDRE_INEXACT = 6,
    WNR_RAPP_BONUS_4 = 7,
    WNR_RAPP_BONUS_4SUR5 = 8,
    WNR_RAPP_BONUS_3 = 9,
    WNR_RAPP_ORDRE_NPLUS = 10,
    WNR_RAPP_ORDRE_INEXACT_NPLUS = 11,
    WNR_RAPP_BONUS_4_NPLUS = 12,
    WNR_RAPP_BONUS_4SUR5_NPLUS = 13,
    WNR_RAPP_BONUS_3_NPLUS = 14,
    WNR_RAPP_3CHEVAUX = 15,
    WNR_RAPP_EN_3 = 16
}
