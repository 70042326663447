import { Component, Input } from "@angular/core";
import { BonusValueType, UserCampaignStatus } from "src/app/enum";
import { MessageDispatcher } from "src/app/helpers";
import { AccountService } from "src/app/services";

@Component({
    selector: 'toto-campaign',
    templateUrl: './campaign.component.html',
    styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent {

    public UserCampaignStatus = UserCampaignStatus;
    public BonusValueType = BonusValueType;
    public loading = {} as any;

    @Input() campaign: any;
    @Input() showBonuses = true;
    @Input() showDetailWhenNotActivated = false;

    public constructor(
        private _accountService: AccountService,
        private _msg: MessageDispatcher
    ) {

    }

    public maxVal(val: number, maxVal: number) {
        return val > maxVal ? maxVal : val;
    }

    public activate(campaign: any) {
        this._accountService.activateCampaign(campaign.id)
            .result(
                null,
                res => {
                    this._msg.ok({ message: 'AppMessages.Account.CampaignActivated' });
                    campaign.expiresAt = res.expiresAt;
                    campaign.status = res.status;
                    campaign.isActivated = true;
                },
                err => {
                },
                'activate',
                this.loading
            );
    }
}