import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'self-restraint-success-dialog',
  host: { class: 'dialog' },
  templateUrl: './self-restraint-success.dialog.html',
  styleUrls: ['./self-restraint-success.dialog.scss']
})
export class SelfRestraintSuccessDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _router: Router,
    public dialogRef: MatDialogRef<SelfRestraintSuccessDialogComponent>,
  ) {
  }

  public continue() {
    this.closeDialog();
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }

}

