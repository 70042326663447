<form>
  <div class="form-title">
    <h2>{{'Navigator.Menu.ResponsibleGame.Limits' | translate}}</h2>
    <h5>{{'Navigator.Menu.ResponsibleGame.ResponsibleGame' | translate}}</h5>
  </div>

  <div class="form-body">
    <p>
      {{'Views.User.ResponsibleGaming.Limits.Introduction' | translate}}
    </p>

    <br />

    <!-- DEPOSIT LIMIT -->
    <form [formGroup]="depositLimitGroup" class="limit-block">
      <h4>
        {{'Views.User.ResponsibleGaming.Limits.Title.DepositLimit' | translate}}
      </h4>
      <div class="select-limit">
        <p class="no-limit" *ngIf="!edit.depositLimit && !hasAnyLimit(depositLimitGroup)">
          {{'Views.User.ResponsibleGaming.Limits.Label.LimitNotSet' |
          translate}}
        </p>

        <!-- DEPOSIT LIMIT 24h -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.24hours"
          *ngIf="edit.depositLimit || has24hValue(depositLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="h24" color="dark" type="price"
              [zreadonly]="data.depositLimit24hCreatedAt ? true : false || !edit.depositLimit">
            </toto-input> -->
            <toto-input for="h24" color="dark" type="price"
              [readonly]="!edit.depositLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.depositLimit24hCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.depositLimit24hCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

        <!-- DEPOSIT LIMIT 7 days -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.7days"
          *ngIf="edit.depositLimit || has7dValue(depositLimitGroup)" type="custom">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="d7" color="dark" type="price"
              [readonly]="data.depositLimit7DaysCreatedAt ? true : false || !edit.depositLimit">
            </toto-input> -->
            <toto-input for="d7" color="dark" type="price"
              [readonly]="!edit.depositLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.depositLimit7DaysCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.depositLimit7DaysCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

        <!-- DEPOSIT LIMIT 30 days -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.30days"
          *ngIf="edit.depositLimit || has30dValue(depositLimitGroup)" type="custom">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="d30" color="dark" type="price"
              [readonly]="data.depositLimit30DaysCreatedAt ? true : false || !edit.depositLimit">
            </toto-input> -->
            <toto-input for="d30" color="dark" type="price"
              [readonly]="!edit.depositLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.depositLimit30DaysCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.depositLimit30DaysCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>
      </div>

      <!-- DEPOSIT LIMIT BUTTONS -->
      <div class="limit-buttons" [ngSwitch]="edit.depositLimit">
        <ng-container *ngSwitchCase="false">
          <toto-button *ngIf="!hasAllLimits(depositLimitGroup)"
            [label]="hasAnyLimit(depositLimitGroup) ? 'Action.Change' : 'Action.CreateLimit'"
            (click)="toggleEditDeposit(true)" color="theme-1"></toto-button>
          <toto-button icon="info"
            [label]="show.additionalInfoDeposit ? 'Action.HideAdditionalInformation' : 'Action.AdditionalInformation'"
            color="transparent" (click)="changeClickInfoDeposit()" type="button"></toto-button>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <toto-button [label]="'Action.Save'" (click)="saveDepositLimit()" type="submit" color="theme-1"></toto-button>
          <toto-button [label]="'Action.Back'" (click)="toggleEditDeposit(false)" type="button"></toto-button>
        </ng-container>
      </div>
      <p class="additional-info" *ngIf="show.additionalInfoDeposit">
        {{'Views.User.ResponsibleGaming.Limits.AdditionalInfo.Deposit' | translate}}
      </p>
    </form>

    <!-- BETTING LIMIT -->
    <form [formGroup]="bettingLimitGroup" class="limit-block">
      <h4>
        {{'Views.User.ResponsibleGaming.Limits.Title.BettingLimit' | translate}}
      </h4>
      <div class="select-limit">
        <p class="no-limit" *ngIf="!edit.bettingLimit && !hasAnyLimit(bettingLimitGroup)">
          {{'Views.User.ResponsibleGaming.Limits.Label.LimitNotSet' | translate}}
        </p>

        <!-- BETTING LIMIT 24h -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.24hours"
          *ngIf="edit.bettingLimit || has24hValue(bettingLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="h24" color="dark" type="price"
              [readonly]="data.bettingLimit24hCreatedAt ? true : false || !edit.bettingLimit">
            </toto-input> -->
            <toto-input for="h24" color="dark" type="price"
              [readonly]="!edit.bettingLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.bettingLimit24hCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.bettingLimit24hCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

        <!-- BETTING LIMIT 7 DAYS -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.7days"
          *ngIf="edit.bettingLimit || has7dValue(bettingLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="d7" color="dark" type="price"
              [readonly]="data.bettingLimit7DaysCreatedAt ? true : false || !edit.bettingLimit">
            </toto-input> -->
            <toto-input for="d7" color="dark" type="price"
              [readonly]="!edit.bettingLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.bettingLimit7DaysCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.bettingLimit7DaysCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

        <!-- BETTING LIMIT 30 DAYS -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.30days"
          *ngIf="edit.bettingLimit || has30dValue(bettingLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="d30" color="dark" type="price"
              [readonly]="data.bettingLimit30DaysCreatedAt ? true : false || !edit.bettingLimit">
            </toto-input> -->
            <toto-input for="d30" color="dark" type="price"
              [readonly]="!edit.bettingLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.bettingLimit30DaysCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.bettingLimit30DaysCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

      </div>

      <!-- BETTING LIMIT BUTTONS -->
      <div class="limit-buttons" [ngSwitch]="edit.bettingLimit">
        <ng-container *ngSwitchCase="false">
          <toto-button *ngIf="!hasAllLimits(bettingLimitGroup)"
            [label]="hasAnyLimit(bettingLimitGroup) ? 'Action.Change' : 'Action.CreateLimit'"
            (click)="toggleEditBetting(true)" color="theme-1"></toto-button>
          <toto-button icon="info"
            [label]="show.additionalInfoBetting ? 'Action.HideAdditionalInformation' : 'Action.AdditionalInformation'"
            (click)="changeClickInfoBetting()" type="button"></toto-button>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <toto-button [label]="'Action.Save'" (click)="saveBettingLimit()" type="submit" color="theme-1"></toto-button>
          <toto-button [label]="'Action.Back'" (click)="toggleEditBetting(false)" type="button"></toto-button>
        </ng-container>
      </div>
      <p class="additional-info" *ngIf="show.additionalInfoBetting">
        {{'Views.User.ResponsibleGaming.Limits.AdditionalInfo.BettingLimit' | translate}}
      </p>
    </form>


    <!-- NET LOSS LIMIT -->
    <form [formGroup]="netLossLimitGroup" class="limit-block">
      <h4>
        {{'Views.User.ResponsibleGaming.Limits.Title.NetLossLimit' | translate}}
      </h4>
      <div class="select-limit">
        <p class="no-limit" *ngIf="!edit.netLossLimit && !hasAnyLimit(netLossLimitGroup)">
          {{'Views.User.ResponsibleGaming.Limits.Label.LimitNotSet' | translate}}
        </p>

        <!-- NET LOSS LIMIT 24h -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.24hours"
          *ngIf="edit.netLossLimit || has24hValue(netLossLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <toto-input for="h24" color="dark" type="price"
              [readonly]="!edit.netLossLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.netLossLimit24hCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.netLossLimit24hCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

        <!-- NET LOSS LIMIT 7 DAYS -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.7days"
          *ngIf="edit.netLossLimit || has7dValue(netLossLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="d7" color="dark" type="price"
              [readonly]="data.netLossLimit7DaysCreatedAt ? true : false || !edit.netLossLimit">
            </toto-input> -->
            <toto-input for="d7" color="dark" type="price"
              [readonly]="!edit.netLossLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.netLossLimit7DaysCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.netLossLimit7DaysCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>

        <!-- NET LOSS LIMIT 30 DAYS -->
        <form-field labelWidth="custom" label="Views.User.ResponsibleGaming.Limits.Label.30days"
          *ngIf="edit.netLossLimit || has30dValue(netLossLimitGroup)">
          <div class="flex f-1 space-between align-center">
            <!-- <toto-input for="d30" color="dark" type="price"
              [readonly]="data.netLossLimit30DaysCreatedAt ? true : false || !edit.netLossLimit">
            </toto-input> -->
            <toto-input for="d30" color="dark" type="price"
              [readonly]="!edit.netLossLimit">
            </toto-input>
            <div class="flex ml15" *ngIf="data.netLossLimit30DaysCreatedAt">
              {{'Views.User.ResponsibleGaming.Limits.Label.Created' | translate}} {{data.netLossLimit30DaysCreatedAt | totoDate}}
            </div>
          </div>
        </form-field>
      </div>

      <!-- NET LOSS LIMIT BUTTONS -->
      <div class="limit-buttons" [ngSwitch]="edit.netLossLimit">
        <ng-container *ngSwitchCase="false">
          <toto-button *ngIf="!hasAllLimits(netLossLimitGroup)"
            [label]="hasAnyLimit(netLossLimitGroup) ? 'Action.Change' : 'Action.CreateLimit'"
            (click)="toggleEditNetLoss(true)" type="submit" color="theme-1"></toto-button>
          <toto-button icon="info"
            [label]="show.additionalInfoNetLoss ? 'Action.HideAdditionalInformation' : 'Action.AdditionalInformation'"
            (click)="changeClickInfoNetLoss()" type="button"></toto-button>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <toto-button [label]="'Action.Save'" (click)="saveNetLossLimit()" type="submit" color="theme-1"></toto-button>
          <toto-button [label]="'Action.Back'" (click)="toggleEditNetLoss(false)" type="button"></toto-button>
        </ng-container>
      </div>
      <p class="additional-info" *ngIf="show.additionalInfoNetLoss">
        {{'Views.User.ResponsibleGaming.Limits.AdditionalInfo.NetLoss' |
        translate}}
      </p>
    </form>
  </div>
</form>