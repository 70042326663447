<div class="thumbnail-section" (click)="openVideoDialog()">
  <img class="thumbnail" [src]="thumbnailSrc" />
  <div class="thumbnail-overlay">
    <svg-icon key="playVideo" fontSize="42px"></svg-icon>
  </div>
</div>
<div class="description-section">
  <div class="bet-type bet-type-{{betTypeEnum[betType]}}">
      <span class="bet-mask-icon mb6 mr6"></span>
      <span class="bet-text">
        {{ betType | translateEnum: betTypeEnum }}
      </span>
  </div>

  <div class="name mt5">
    {{ videoName | translate }}
  </div>
</div>
