export enum BetTypeGroup {
    Simple = 1,
    Couple = 2,
    Trio = 3,
    QuintePlus = 4
}

(BetTypeGroup as any).__typeName = 'BetTypeGroup';

const _ = 'Enums.PmuBetTypeGroup.';
(BetTypeGroup as any).__altTranslationKeys = {
    [BetTypeGroup.Simple]: _ + 'Simple',
    [BetTypeGroup.Couple]: _ + 'Couple',
    [BetTypeGroup.Trio]: _ + 'Trio',
    [BetTypeGroup.QuintePlus]: _ + 'QuintePlus'
};
