export enum AmericanFootballMarketTypeValue
{
    FirstHalfHandicapInPlay = 8,
    WinMatchIncludingOvertime = 103,
    HandicapBetting = 106,
    FirstTouchdownScorer = 158,
    TotalPointsOverUnder = 263,
    FirstQuarterTotalsOverUnder = 264,
    SecondQuarterTotalsOverUnder = 265,
    ThirdQuarterTotalsOverUnder = 266,
    FourthQuarterTotalsOverUnder = 267,
    FirstTeamToScore = 6539,
    HalftimeFulltime = 6551,
    FirstQuarterHandicap = 6552,
    FirstTeamToScoreWinsGame = 6559,
    WinMatchNormalTime = 6571,
    SecondQuarterHandicap = 6572,
    ThirdQuarterHandicap = 6573,
    FourthQuarterHandicap = 6574,
    SecondHalfMoneyLine = 6640,
    AnytimeTouchdownScorer = 6644,
    LastTouchdownScorer = 6646,
    FirstHalfTotalsOverUnder = 6767,
    OddOrEvenTotal = 6788,
    HandicapResult = 6789,
    HighestScoringQuarter = 6792,
    HighestScoringHalf = 6793,
    FirstHalfMoneyLine = 6801,
    FirstQuarterMoneyLine = 6805,
    SecondQuarterMoneyLine = 6806,
    ThirdQuarterMoneyLine = 6807,
    FourthQuarterMoneyLine = 6808,
    LastTeamToScore = 6809,
    TotalTouchdownsOverUnder = 6811,
    FirstHalfResult = 6834,
    SecondHalfTotalPointsOverUnderIncludingOvertime = 8251,
    HomeTeamTotalPointsOverUnder = 8252,
    AwayTeamTotalPointsOverUnder = 8253,
    SecondHalfPointSpreadIncludingOvertime = 8259,
    IndividualPlayerTotalPassingYardsOverUnder = 8385,
    IndividualPlayerTotalRushingYardsOverUnder = 8386,
    FirstHalfTotalsOddEven = 8616,
    FirstQuarterTotalsOddEven = 9246,
    SecondQuarterTotalsOddEven = 9247,
    ThirdQuarterTotalsOddEven = 9248,
    FourthQuarterTotalsOddEven = 9249,
    FirstQuarterResult = 9481,
    SecondQuarterResult = 9482,
    ThirdQuarterResult = 9483,
    FourthQuarterResult = 9484,
    SecondHalfResult = 10042,
    SecondHalfTotalsOddEven = 10050,
    DoubleResult = 10264,
    PlayerH2HMostPassingYardsMatchBet = 10321,
    PlayerH2HMostRushingYardsMatchBet = 10323,
    PlayerH2HMostRushReceivingYardsMatchBet = 10324,
    AwayTeamTotalPointsOddEven = 10621,
    HomeTeamTotalPointsOddEven = 10622,
    FirstHalfAwayTeamTotalsOverUnder = 11513,
    FirstHalfHomeTeamTotalsOverUnder = 11514,
    SecondHalfHomeTeamTotalsOverUnder = 11593,
    FirstQuarterHomeTeamTotalsOverUnder = 11639,
    FirstQuarterAwayTeamTotalsOverUnder = 11640,
    SecondHalfAwayTeamTotalsOverUnder = 11641,
    SecondQuarterAwayTeamTotalsOverUnder = 11642,
    SecondQuarterHomeTeamTotalsOverUnder = 11643,
    ThirdQuarterAwayTeamTotalsOverUnder = 11644,
    ThirdQuarterHomeTeamTotalsOverUnder = 11645,
    FourthQuarterAwayTeamTotalsOverUnder = 11646,
    FourthQuarterHomeTeamTotalsOverUnder = 11647,
    TeamToScoreNextTouchdown = 12860,
    IndividualPlayerTotalRushingTouchdownsOverUnder = 15201,
    IndividualPlayerTotalPassingTouchdownsOverUnder = 15202,
    IndividualPlayerTotalPassingCompletionsOverUnder = 15203,
    IndividualPlayerTotalReceivingYardsOverUnder = 15204,
    IndividualPlayerTotalReceivingTouchdownsOverUnder = 15205,
    IndividualPlayerTotalReceptionsOverUnder = 15206,
    IndividualPlayerTotalTouchdownsOverUnder = 15207,
    IndividualPlayerTotalFieldGoalsOverUnder = 15208,
    IndividualPlayerTotalRushingAndReceivingYardsOverUnder = 15209,
    IndividualPlayerTotalInterceptionsOverUnder = 15210,
    IndividualPlayerTotalIncompletePassesOverUnder = 15211,
    IndividualPlayerTotalPassAttemptsOverUnder = 15212,
    IndividualPlayerTotalDefensiveInterceptionsOverUnder = 15214,
    IndividualPlayerTotalDefensiveSacksOverUnder = 15215,
    IndividualPlayerTotalDefensiveTacklesAndAssistsOverUnder = 15216,
    PlayerH2HMostRushingTouchdownsMatchBet = 15217,
    PlayerH2HMostPassingTouchdownsMatchBet = 15218,
    PlayerH2HMostPassCompletionsMatchBet = 15219,
    PlayerH2HMostReceptionsMatchBet = 15220,
    PlayerH2HMostReceptionYardsMatchBet = 15221,
    PlayerH2HMostReceivingTouchdownsMatchBet = 15222,
    PlayerH2HMostTouchdownsMatchBet = 15223,
    PlayerH2HMostFieldGoalsMatchBet = 15224,
    PlayerH2HMostInterceptionsMatchBet = 15225,
    PlayerH2HMostIncompletePassesMatchBet = 15226,
    PlayerH2HMostPassAttemptsMatchBet = 15227,
    PlayerH2HMostDefensiveInterceptionsMatchBet = 15229,
    PlayerH2HMostDefensiveSacksMatchBet = 15230,
    PlayerH2HMostDefensiveTacklesAssists = 15232,
}

(AmericanFootballMarketTypeValue as any).__typeName = 'AmericanFootballMarketTypeValue';