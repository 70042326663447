export enum Basketball3x3MarketTypeValue
{
    MoneyLine = 14314,
    MatchHandicap = 14315,
    TotalPointsOverUnder = 14316,
    TotalPointsOddEven = 14317,
    RaceTo5Points = 14318,
    RaceTo10Points = 14319,
    RaceTo15Points = 14320,
    TeamToScoreTheNext2Pointer = 14321,
}

(Basketball3x3MarketTypeValue as any).__typeName = 'Basketball3x3MarketTypeValue';