import { Component, HostBinding, Input, ViewContainerRef } from '@angular/core';


@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent {

    @Input() public isError = true;
    @HostBinding('class.clearable') @Input() public clearable = false;
    @HostBinding('class.cleared') @Input() public cleared = false;

    constructor(private _viewContainer: ViewContainerRef,) {}

    public clear(toSet = !this.cleared) {
        if (this.clearable) {
            this.cleared = toSet;
        }
    }

}
