<toto-dialog [dialogRef]="dialogRef">
  <div header-title>
    <h3>{{'Views.TermsOfUse.Title' | translate}}</h3>
  </div>

  <div body class="terms-of-use-content">
    <section *ngFor="let page of pages">
      <h4 style="padding: 12px 15px">{{page.title}}</h4>
      <!-- <div [innerHTML]="page.content"></div> -->
      <quill-view class="full-width" [content]="page.content"></quill-view>
    </section>
  </div>
</toto-dialog>
