export enum BetType {
    Win = 11,
    Place = 13,
    Pair = 21,
    Trifecta = 31,
    V4 = 401
}


(BetType as any).__typeName = 'BetType';

const _ = 'Enums.BetType.';
(BetType as any).__altTranslationKeys = {
    [BetType.Win]: _ + 'Win',
    [BetType.Place]: _ + 'Place',
    [BetType.Pair]: _ + 'Pair',
    [BetType.Trifecta]: _ + 'Trifecta',
    [BetType.V4]: _ + 'V4',
};
