import { Component, OnInit, Input, Optional, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, ControlContainer, AbstractControl } from '@angular/forms';

@Component({
  selector: "toto-checkbox",
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  
  @Output() public onChange = new EventEmitter<any>();
  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public label: string;
  @Input() public checked = undefined;

  private _ref: AbstractControl;

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this.for) {
      if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
        this.formGroup = <UntypedFormGroup>this._controlContainer.control;
      }
      this._ref = this.formGroup?.controls[this.for];
    }
  }

  public get isChecked() {
    return this.checked !== undefined ? this.checked : this._ref?.value;
  }

  public select(option?: any) {
    if (this._ref) {
      const refValue = this._ref?.value;
      const newValue = refValue === true ? false : true;
      this._ref.setValue(newValue);
    }
    this.onChange.emit(this.isChecked);
  }
  
}
