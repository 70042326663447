
<div class="bonus-block">
    <div class="row-1">
      <p class="bonus-type">{{ 'Views.User.Bonus.Overview.Bonuses.SportsBetBonus' | translate }}</p>
      <p class="rules">
        <a [routerLink]="['/sports/instructions']" [queryParams]="{ sectionId: 163 }" target="_blank">{{ 'Views.User.Bonus.Overview.Bonuses.TermsAndConditions' | translate }} <svg-icon key="arrowRightSmall" fontSize="9px"></svg-icon></a></p>
    </div>

    <div class="row-1">
      <h4 class="bonus-title">{{ campaign.name }}</h4>
    </div>

    <div *ngIf="showBonuses">
      <div *ngFor="let bonus of campaign.bonuses" class="bonus-progress">
        <div class="progress-circle" [ngClass]="{ 
          'progress-circle--expired': campaign.status == UserCampaignStatus.Expired,
          'progress-circle--failed': campaign.status == UserCampaignStatus.Failed 
          }">
          <div class="bg"></div>
          <div class="fg fg-1" *ngIf="bonus.rotatedPercent >= 0" [ngStyle]="{ '--p': maxVal(bonus.rotatedPercent, 25) }"></div>
          <div class="fg fg-2" *ngIf="bonus.rotatedPercent >= 25" [ngStyle]="{ '--p': maxVal(bonus.rotatedPercent-25, 25) }"></div>
          <div class="fg fg-3" *ngIf="bonus.rotatedPercent >= 50" [ngStyle]="{ '--p': maxVal(bonus.rotatedPercent-50, 25) }"></div>
          <div class="fg fg-4" *ngIf="bonus.rotatedPercent >= 75" [ngStyle]="{ '--p': maxVal(bonus.rotatedPercent-75, 25) }"></div>
          <div class="complete" *ngIf="bonus.rotatedPercent >= 100">
            <svg-icon key="tickActive" fontSize="14px"></svg-icon>
          </div>
          <div class="failed" *ngIf="campaign.status == UserCampaignStatus.Failed">
            <svg-icon key="deleteTicket" fontSize="12px"></svg-icon>
          </div>

        </div>
        <div class="f-1" style="gap: 10px; display: flex; flex-direction: column;">
          <div class="progress-text">
            <span class="text">
              {{ 'Views.User.Bonus.Overview.Bonuses.Rotated' | translate }}: {{ bonus.rotatedPercent }} % 
            </span>
            <span class="small">{{ bonus.rotatedAmount }} € / {{ bonus.requiredRotatedAmount }} €</span>
          </div>
          <div class="progress-bar">
            <div class="bg"></div>
            <div class="fg" [ngStyle]="{ width: maxVal(bonus.rotatedPercent, 100) + '%'}" [ngClass]="{ 
              'fg--expired': campaign.status == UserCampaignStatus.Expired,
              'fg--failed': campaign.status == UserCampaignStatus.Failed 
              }"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-row">

      <div class="container">
        <div class="bonus-data-container f-1" *ngIf="campaign.isActivated || showDetailWhenNotActivated">

          <div class="flex flex-column ">
            <p class="bonus-data-title">{{ 'Views.User.Bonus.Overview.Bonuses.BonusValue' | translate }}</p>
            <p class="bonus-data">
              {{ campaign.bonus }}
              <span *ngIf="campaign.bonusValueType == BonusValueType.Percentage">%</span>
              <span *ngIf="campaign.bonusValueType == BonusValueType.Fix">€</span>
              ({{ campaign.minAmount }} - {{ campaign.maxAmount }} €)
            </p>
          </div>

          <div class="flex flex-column">
            <p class="bonus-data-title">{{ 'Views.User.Bonus.Overview.Bonuses.ExpiresAt' | translate }}</p>
            <p class="bonus-data">{{ campaign.expiresAt | totoDate:'LL'}}</p>
          </div>
        </div>

        <toto-button *ngIf="!campaign.isActivated"
          class="bonus-button f-1"
          label="Action.ActivateBonus"
          type="submit"
          color="theme-1"
          [loading]="loading.activate"
          (click)="activate(campaign)"
        ></toto-button>

          <div class="f-1 bonus-status bonus-status--active" *ngIf="campaign.status == UserCampaignStatus.Active"><svg-icon key="tickActive" fontSize="14px" class="mr5"></svg-icon> {{ campaign.status | translateEnum:UserCampaignStatus }}</div>
          <div class="f-1 bonus-status bonus-status--expired" *ngIf="campaign.status == UserCampaignStatus.Expired">{{ campaign.status | translateEnum:UserCampaignStatus }}</div>
          <div class="f-1 bonus-status bonus-status--complete" *ngIf="campaign.status == UserCampaignStatus.Complete">{{ campaign.status | translateEnum:UserCampaignStatus }}</div>
          <div class="f-1 bonus-status bonus-status--failed" *ngIf="campaign.status == UserCampaignStatus.Failed">{{ campaign.status | translateEnum:UserCampaignStatus }}</div>
      </div>

    </div>

  </div>