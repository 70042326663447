import { Component } from '@angular/core';


@Component({
  selector: 'news-block',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsBlockComponent {

  constructor() {}

 
}
