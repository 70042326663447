import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/app.state';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  public constructor(private _appState: AppState) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('api/')) {
      return next.handle(req);
    }

    const languageValue = this._appState.language?.value || 'et';

    req = req.clone({
      url: environment.apiUrl + req.url.substring(4),
      withCredentials: true,
      setHeaders: {
        'Accept-Language': languageValue,
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
        'client-url': this._appState.url
      },
    });

    return next.handle(req);
  }
}
