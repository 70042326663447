<app-content>
  <div class="row justify-center">
    <div class="col-lg-6 col-md-12 col-sm-12 register-image">
      <img src="/assets/images/banner/register/register.png" />
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12 col-form">
      <form [formGroup]="formGroup" id="register-form" (ngSubmit)="register()">
        <h3 *ngIf="!selectedView" style="margin-bottom: 0;">
          {{'Views.User.Register.Title' | translate}}
        </h3>

        <ng-container [formGroup]="controls" *ngIf="!selectedView">
          <authentication-options for="selectedView" class="mt20 mb20"
            [options]="authenticationOptions"></authentication-options>
        
          <div class="account-exists">
            {{'Views.User.AccountAlreadyExists' | translate}}
            <a class="link" (click)="openLoginDialog()">{{'Views.User.Login.LogIn' | translate}}</a>
          </div>
        </ng-container>

        <ng-container [ngSwitch]="selectedView" *ngIf="selectedView">

          <ng-container *ngSwitchCase="AuthenticationOption.Password">

            <h3>{{'Components.AuthOptions.Password' | translate}} <span>{{'Views.User.Register.Title' | translate}}</span></h3>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Email" for="email" type="email"
              autocomplete="email" id="email">
              <validation-errors for="email"></validation-errors>
            </toto-input>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Username" for="username"
              autocomplete="username" type="text">
              <validation-errors for="userName" aliasTo="username"></validation-errors>
            </toto-input>
            <toto-input class="full-width mb15" size="l" label="Common.Label.Password" for="password" type="password"
              autocomplete="new-password">
              <validation-errors for="password"></validation-errors>
            </toto-input>
          </ng-container>

          <ng-container *ngSwitchCase="AuthenticationOption.SmartID">

            <h3>Smart-ID <span>{{'Views.User.Register.Title' | translate}}</span></h3>

            <toto-input class="full-width mb15" size="l" label="Common.Label.IdCode" for="idCode" type="number"
              autocomplete="national-identification-number" id="idCode">
              <validation-errors for="idCode"></validation-errors>
            </toto-input>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Email" for="email" type="email"
              autocomplete="email" id="email">
              <validation-errors for="email"></validation-errors>
            </toto-input>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Username" for="username" type="text">
              <validation-errors for="userName" aliasTo="username"></validation-errors>
            </toto-input>
          </ng-container>

          <ng-container *ngSwitchCase="AuthenticationOption.MobileID">

            <h3>{{'Components.AuthOptions.MobileID' | translate}} <span>{{'Views.User.Register.Title' | translate}}</span></h3>

            <toto-input for="idCode" label="Common.Label.IdCode" size="l" class="mb15">
              <validation-errors for="idCode"></validation-errors>
            </toto-input>

            <form-field label="Common.Label.PhoneNumber" [labelTop]="true">
              <div class="flex flex-row align-center full-width">
                <toto-select
                  for="prefix"
                  [options]="countryPrefixOptions"
                  labelField="label"
                  compareField="value"
                  [clearable]="false"
                  [translate]="translate"
                  style="flex: 30%"
                ></toto-select>
                <span class="ml10 mr10">&nbsp;</span>
                <toto-input for="phoneNumber" size="1">
                </toto-input>                
              </div>
              <validation-errors for="phone" aliasTo="phoneNumber"></validation-errors>
            </form-field>            
            
            <toto-input class="full-width mb15" size="l" label="Common.Label.Email" for="email" type="email"
              autocomplete="email" id="email">
              <validation-errors for="email"></validation-errors>
            </toto-input>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Username" for="username" type="text">
              <validation-errors for="userName" aliasTo="username"></validation-errors>
            </toto-input>
          </ng-container>

          <ng-container *ngSwitchCase="AuthenticationOption.Bank">

            <h3>{{'Components.AuthOptions.Bank.WithBank' | translate}} <span>{{'Views.User.Register.Title' | translate}}</span></h3>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Email" for="email" type="email"
              autocomplete="email" id="email">
              <validation-errors for="email"></validation-errors>
            </toto-input>

            <toto-input class="full-width mb15" size="l" label="Common.Label.Username" for="username"
              autocomplete="username" type="text">
              <validation-errors for="userName" aliasTo="username"></validation-errors>
            </toto-input>

            <p class="choose-country">{{'Components.AuthOptions.Bank.ChooseCountry' | translate}}&nbsp;&#42;</p>

            <toto-radio
              [options]="zimplerCountryOptions"
              for="zimplerCountry"
              labelField="label"
              class="full-width mb15"
            >
            </toto-radio>

            <div class="bank-options">
              <bank-login-options
              [providerOptions]="formGroup.get('zimplerCountry').value?.providerOptions"
              >
              </bank-login-options>
            </div>

          </ng-container>
        </ng-container>

        <div class="register-footer" *ngIf="selectedView">
          <div class="terms-and-conditions">
            <div class="flex align-center">
              <toto-checkbox for="termsAndConditions"></toto-checkbox>
              <span>
                {{"Views.User.Register.AgreeTo" | translate}}
              </span>
              &nbsp;
              <a class="link" (click)="openTermsOfUseDialog()">
                {{"Views.User.Register.TermsOfUse" | translate}}
              </a>
            </div>
            <validation-errors for="acceptTerms" aliasTo="termsAndConditions"></validation-errors>
          </div>

          <div class="recaptcha">
            <div #captchaRef2></div>
            <validation-errors for="recaptcha"></validation-errors>
          </div>

          <ng-container [ngSwitch]="selectedView">
          
            <ng-container *ngSwitchDefault>
              <toto-button class="full-width" color="theme-1" label="Action.Register" size="l" [loading]="actionLoading.register"
                type="submit" [disabled]="!canRegister">
              </toto-button>
            </ng-container>
          
            <ng-container *ngSwitchCase="AuthenticationOption.Bank">
              <toto-button class="full-width" color="theme-1" label="Components.AuthOptions.Bank.BankLogin" size="l"
                [loading]="actionLoading.register" type="submit" [disabled]="!canRegister">
              </toto-button>
            </ng-container>
          
          </ng-container>

        
          <toto-button (click)="onNavigateBack()" class="full-width" color="dark-grey" label="Action.Back" size="l" style="margin-top: 10px;"></toto-button>
        </div>

      </form>
    </div>
  </div>
</app-content>

<div class="trustlyOverlay" id="trustlyOverlay" style="display:none">
  <iframe name="trustlyFrame" id="trustlyFrame" class="trustlyFrame"></iframe>
</div>