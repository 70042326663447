import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AccountService, TitleService } from 'src/app/services';
import { AppState } from 'src/app/app.state';
import { MessageDispatcher } from 'src/app/helpers';
import { TotoDialog } from 'src/app/helpers/dialogs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { ViewBase } from 'src/app/views/view.base';

@Component({
  selector: 'payments-withdrawal-card',
  templateUrl: './withdrawal.view.html',
  styleUrls: ['./withdrawal.view.scss', '../../shared.scss']
})
export class UserPaymentsWithdrawalViewComponent extends ViewBase {
  public titleFragments = [
    { key: 'Views.User.Payments.Withdrawal.Title.MyPayments' },
    { key: 'Views.User.Payments.Withdrawal.Title.Withdrawal' }
  ];

  public actionLoading = {} as any;

  public selectedProvider: BankLinkProvider;

  public formGroup = new FormGroup({
    amount: new FormControl()
  });

  public bankAccountNumber: string;

  public withdrawalInfo: IUserWithdrawalInfo;

  constructor(
    public state: AppState,
    _titleService: TitleService,
    private _accountService: AccountService,
    private _msg: MessageDispatcher,
    private _dialog: TotoDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService,
    private _appState: AppState
  ) {
    super(_titleService);
    const { withdrawalInfo } = this._route.snapshot.data || {};
    this.withdrawalInfo = withdrawalInfo;
    
    if(withdrawalInfo?.latestDepositAccount?.indexOf(':') >= 0) {
      var accountParts = withdrawalInfo.latestDepositAccount.split(':');
      var provider = accountParts[0];
      var account = accountParts[1];

      this.withdrawalInfo.latestDepositAccount = account;
      this.withdrawalInfo.withdrawalMethod = provider;
    }

    if (this.withdrawalInfo.latestDepositAccountDescription) {
      this.withdrawalInfo.withdrawalMethod = this.withdrawalInfo.latestDepositAccountDescription + ' (' + this.withdrawalInfo.withdrawalMethod + ')';
    } else if(this.withdrawalInfo.latestDepositAccount) {
      
      if(this.withdrawalInfo.latestDepositAccount.startsWith('EE')) {
        var bankCode = this.withdrawalInfo.latestDepositAccount.substring(4, 6);
        var bank = '';
        if(bankCode == '42') bank = 'Coop Pank';
        if(bankCode == '16') bank = 'Eesti pank';
        if(bankCode == '10') bank = 'SEB';
        if(bankCode == '22') bank = 'Swedbank';
        if(bankCode == '96') bank = 'Luminor';
        if(bankCode == '17') bank = 'Luminor';
        if(bankCode == '12') bank = 'Citadele';
        if(bankCode == '83') bank = 'Svenska Handelsbanken';
        if(bankCode == '00') bank = 'TBB';
        if(bankCode == '51') bank = 'OP Corporate Bank';
        if(bankCode == '77') bank = 'LHV';
        if(bankCode == '75') bank = 'Bigbank';
        //if(bankCode == '10') bank = 'SEB';

        if(bank)  {
          this.withdrawalInfo.withdrawalMethod = bank + ' (' + this.withdrawalInfo.withdrawalMethod + ')';
        }
      }
    }
  }

  public get amount() {
    return this.formGroup.value.amount;
  }

  public get userBalance() {
    return this._appState.userBalance;
  }

  public get resourcesAboveMinimum() {
    return this.userBalance?.balance >= 1;
  }

  public get showMinAmountDisclaimer() {
    return !isNaN(parseFloat(this.amount)) && this.amount < 1;
  }

  public get hasEnoughResources() {
    return this.userBalance?.balance >= this.amount;
  }

  public get canContinue() {
    return (
      this.resourcesAboveMinimum &&
      this.hasEnoughResources &&
      this.amount >= 1
    );
  }

  public onProviderSelect(provider: BankLinkProvider) {
    this.selectedProvider = provider;
  }

  public withdraw(provider: BankLinkProvider = this.selectedProvider) {
    const confirmText1 = this._translate.instant(
      'Views.User.Payments.Withdrawal.ConfirmDialog.ConfirmWithdrawalText1',
      { amount: `<span class="theme-color-1">${this.amount} €</span>` }
    );
    // const confirmText2 = this._translate.instant('Users.CashOut.Confirm cashout text2', { bankAccountNumber: `<span class="bold">${this.bankAccountNumber}</span>` });

    //   <p>
    //   ${confirmText2}
    // </p>

    this._dialog.confirm(
      {
        title: 'Views.User.Payments.Withdrawal.ConfirmDialog.ConfirmWithdrawalTitle',
        template: `
        <p>
          ${confirmText1}
        </p>
      `,
        yes: 'Action.Confirm',
        no: 'Action.Cancel'
      },
      () => {
        this._accountService.withdraw(this.amount).result(null, res => {
          const { transactionId } = res || {};

          this._router.navigate(['/user/payments/', transactionId, 'success']);
          console.log(res);
        }, err => {
          console.error(err);
        }, "withdraw", this.actionLoading);
      }
    );
  }
}


export interface IUserWithdrawalInfo {
  withdrawalBalance: number;
  latestDepositAccount: any;
  withdrawalMethod: any;
  latestDepositAccountDescription: any;
}