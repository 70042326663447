<toto-dialog [dialogRef]="dialogRef" *ngIf="!isBankLoginClicked">
  <div header-title class="flex f-1 justify-center" *ngIf="!selectedLogin">
    <h3>{{'Views.User.Login.Title' | translate}}</h3>
  </div>

  <div header-title class="flex f-1 justify-center" *ngIf="selectedLogin">
    <ng-container [ngSwitch]="selectedLogin">
      <ng-container *ngSwitchCase="AuthenticationOption.Password">
        <div header-title class="flex f-1 justify-center">
          <h3>{{'Views.User.Login.WithPassword' | translate}}</h3>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="AuthenticationOption.SmartID">
        <div header-title class="flex f-1 justify-center">
          <h3>{{'Views.User.Login.WithSmartID' | translate}}</h3>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="AuthenticationOption.MobileID">
        <div header-title class="flex f-1 justify-center">
          <h3>{{'Views.User.Login.WithMobileID' | translate}}</h3>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="AuthenticationOption.Bank">
        <div header-title class="flex f-1 justify-center">
          <h3>{{'Views.User.Login.WithBank' | translate}}</h3>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="login-container" body>
    <div class="login-content">
      <ng-container [ngSwitch]="view">
        <!-- LOG IN VIEW -->
        <ng-container
          *ngSwitchCase="LoginView.Login"
          [ngSwitch]="selectedLogin"
        >
          <div class="login-header" *ngIf="!selectedLogin">
            <!-- <h3>Sisene Toto kontosse</h3> -->

            <ng-container [formGroup]="controls">
              <authentication-options
                class="mt10 mb20"
                for="selectedLogin"
              ></authentication-options>
            </ng-container>
          </div>

          <!-- PASSWORD -->
          <ng-container *ngSwitchCase="AuthenticationOption.Password">
            <form
              class="login-tab-container"
              [formGroup]="passwordForm"
              (ngSubmit)="logIn()"
              novalidate
              *ngIf="selectedLogin"
            >
              <toto-input
                size="l"
                label="Common.Label.Username"
                class="login-input username"
                autocomplete="email"
                type="email"
                name="email"
                for="username"
              >
                <validation-errors for="username"></validation-errors>
              </toto-input>

              <toto-input
                size="l"
                label="Common.Label.Password"
                id="current-password"
                class="login-input password"
                autocomplete="current-password"
                type="password"
                for="password"
              >
                <validation-errors for="password"></validation-errors>
              </toto-input>

              <toto-button
                class="full-width mb10 mt10"
                size="l"
                type="submit"
                color="theme-1"
                label="Action.LogIn"
                [loading]="actionLoading.login"
              >
              </toto-button>

              <toto-button
                class="full-width"
                size="l"
                color="dark-grey"
                label="Action.Back"
                (click)="onNavigateBack()"
              >
              </toto-button>

              <div class="flex f-1 space-between mt20 mb20">
                <a class="link" (click)="toRegister()">
                  {{'Views.User.Login.CreateAccount' | translate}}
                </a>

                <a class="link" (click)="toResetPassword()">
                  {{'Views.User.Login.ForgotPassword' | translate}}
                </a>
              </div>
            </form>
          </ng-container>

          <!-- SMART ID -->
          <ng-container *ngSwitchCase="AuthenticationOption.SmartID">
            <form
              class="login-tab-container"
              [formGroup]="smartIdForm"
              (ngSubmit)="logIn()"
              novalidate
              *ngIf="selectedLogin"
            >
              <toto-input
                size="l"
                label="Common.Label.IdCode"
                class="login-input"
                autocomplete="off"
                type="number"
                for="idCode"
              >
                <validation-errors for="idCode"></validation-errors>
              </toto-input>

              <!-- Code: <span id="smartidCode"></span> -->

              <toto-button
                class="full-width mb10 mt10"
                size="l"
                type="submit"
                color="theme-1"
                label="Action.LogIn"
                [loading]="actionLoading.login"
              >
              </toto-button>

              <toto-button
                class="full-width mb20"
                size="l"
                color="dark-grey"
                label="Action.Back"
                (click)="onNavigateBack()"
              >
              </toto-button>
            </form>
          </ng-container>

          <!-- MOBILE ID -->
          <ng-container *ngSwitchCase="AuthenticationOption.MobileID">
            <form
              class="login-tab-container"
              [formGroup]="mobileIdForm"
              (ngSubmit)="logIn()"
              novalidate
              *ngIf="selectedLogin"
            >
              <toto-input
                size="l"
                label="Common.Label.IdCode"
                class="login-input"
                autocomplete="off"
                type="number"
                for="idCode"
              >
                <validation-errors for="idCode"></validation-errors>
              </toto-input>

              <form-field label="Common.Label.PhoneNumber" [labelTop]="true">
                <div class="flex flex-row align-center full-width">
                  <toto-select
                    for="prefix"
                    [options]="countryPrefixOptions"
                    labelField="label"
                    compareField="value"
                    [clearable]="false"
                    [translate]="translate"
                    style="flex: 30%"
                  ></toto-select>
                  <span class="ml10 mr10">&nbsp;</span>
                  <toto-input for="phoneNumber" size="1"> </toto-input>
                </div>
                <validation-errors
                  for="phone"
                  aliasTo="phoneNumber"
                ></validation-errors>
              </form-field>

              <!-- <toto-input size="l" label="Common.Label.PhoneNumber" class="login-input" autocomplete="off" type="number"
                for="phoneNumber">
                <validation-errors for="phoneNumber"></validation-errors>
              </toto-input> -->

              <toto-button
                class="full-width mb10 mt10"
                size="l"
                type="submit"
                color="theme-1"
                label="Action.LogIn"
                [loading]="actionLoading.login"
              >
              </toto-button>

              <toto-button
                class="full-width mb20"
                size="l"
                color="dark-grey"
                label="Action.Back"
                (click)="onNavigateBack()"
              >
              </toto-button>
            </form>
          </ng-container>

          <!-- BANK LOGIN -->
          <ng-container *ngSwitchCase="AuthenticationOption.Bank">
            <ng-container>
              <form class="login-tab-container" [formGroup]="zimplerForm" (ngSubmit)="logIn()" novalidate *ngIf="selectedLogin">
                <p class="choose-country">
                  {{'Components.AuthOptions.Bank.ChooseCountry' | translate}}
                </p>
          
                <toto-radio [options]="zimplerCountryOptions" for="country" labelField="label" class="full-width mb15"
                  [isLoginView]="true">
                </toto-radio>
          
                <toto-button class="full-width mb10 mt10" size="l" type="submit" color="theme-1" label="Action.LogIn"
                  [loading]="actionLoading.login">
                </toto-button>
          
                <toto-button class="full-width mb30" size="l" color="dark-grey" label="Action.Back" (click)="onNavigateBack()">
                </toto-button>
          
                <div class="bank-options">
                  <bank-login-options [providerOptions]="zimplerForm.get('country').value?.providerOptions" [isLoginView]="true">
                  </bank-login-options>
                </div>
              </form>
            </ng-container>
          </ng-container>
          <!-- ID CARD -->
          <!-- <ng-container *ngSwitchCase="AuthenticationOption.IDCard">
            <form class="login-tab-container" (ngSubmit)="logIn()" novalidate>
              <toto-button
                class="mt30 full-width"
                size="l"
                type="submit"
                color="theme-1"
                label="Action.LogIn"
                [loading]="actionLoading.login"
              >
              </toto-button>
            </form>
          </ng-container> -->
          <div class="register" *ngIf="!selectedLogin">
            {{'Views.User.Register.NoAccount' | translate}}
            <a class="link" (click)="toRegister()"
              >{{'Views.User.Register.Title' | translate}}</a
            >
          </div>

          <div class="register" *ngIf="selectedLogin">
            <ng-container [ngSwitch]="selectedLogin">
              <ng-container *ngSwitchCase="AuthenticationOption.SmartID">
                {{'Views.User.Register.NoAccount' | translate}}
                <a class="link" (click)="toRegister()"
                  >{{'Views.User.Register.Title' | translate}}</a
                >
              </ng-container>
              <ng-container *ngSwitchCase="AuthenticationOption.MobileID">
                {{'Views.User.Register.NoAccount' | translate}}
                <a class="link" (click)="toRegister()"
                  >{{'Views.User.Register.Title' | translate}}</a
                >
              </ng-container>
              <ng-container *ngSwitchCase="AuthenticationOption.Bank">
                  {{'Views.User.Register.NoAccount' | translate}}
                  <a class="link" (click)="toRegister()">{{'Views.User.Register.Title' | translate}}</a>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <!-- RESET PASSWORD VIEW -->
        <ng-container *ngSwitchCase="LoginView.ResetPassword">
          <form
            id="forgot-password-form"
            [formGroup]="resetPasswordForm"
            class="login-tab-container mt30"
            (ngSubmit)="resetPassword()"
            *ngIf="AuthenticationOption.Password"
          >
            <toto-input
              size="l"
              class="login-input"
              autocomplete="email"
              type="email"
              name="email"
              for="email"
              label="Common.Label.Email"
            >
              <validation-errors for="email"></validation-errors>
            </toto-input>
            <toto-button
              class="mt20 mb20 full-width"
              size="l"
              color="theme-1"
              type="submit"
              label="Action.UpdatePassword"
              [loading]="actionLoading.sendingLink"
            >
            </toto-button>
          </form>
          <a class="link mt20" (click)="toLogin()">
            {{'Action.Back' | translate}}
          </a>
        </ng-container>

        <!-- RESET PASSWORD SENT VIEW -->
        <ng-container *ngSwitchCase="LoginView.ResetSent">
          <div
            class="reset-password-info"
            *ngIf="AuthenticationOption.Password"
          >
            <span class="reset-password-title"
              >{{'Views.User.Login.ResetPassword.PasswordRequestSent.Title' |
              translate}}</span
            >
            <span class="reset-password-text mt12">
              {{'Views.User.Login.ResetPassword.PasswordRequestSent.Text' |
              translate}}
            </span>
          </div>

          <a class="link mt30" (click)="toLogin()">
            {{'Action.Back' | translate}}
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</toto-dialog>

<div class="iframe-wrapper" *ngIf="isBankLoginClicked">
  <div class="iframe-content">
    <div class="iframe-header">
      <!-- <svg-icon key="black_toto_icon"></svg-icon> -->
      <svg xmlns="http://www.w3.org/2000/svg" width="74" height="23" viewBox="0 0 74 23" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.56867 0L0.5 5.49584H5.8295L2.58145 22.2074H8.07819L11.3262 5.49584H14.1575L20.7225 0H1.56867Z"
          fill="#111111" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M26.6238 5.55153C25.8467 5.55153 25.0865 5.69998 24.3447 5.99599C23.602 6.29155 22.9322 6.68996 22.3357 7.18897C21.7392 7.68887 21.2268 8.27687 20.7993 8.95205C20.3723 9.62769 20.0843 10.3449 19.9372 11.1037C19.7861 11.8808 19.7919 12.6119 19.9551 13.2965C20.1179 13.9811 20.4014 14.5735 20.8033 15.0734C21.2053 15.5725 21.7209 15.9709 22.3478 16.2664C22.9756 16.5633 23.6776 16.7109 24.4543 16.7109C25.2135 16.7109 25.9594 16.5633 26.6922 16.2664C27.4251 15.9709 28.0904 15.5725 28.6874 15.0734C29.2839 14.5735 29.7967 13.9811 30.2255 13.2965C30.6548 12.6119 30.9455 11.8808 31.0966 11.1037C31.2441 10.3449 31.2352 9.62769 31.0702 8.95205C30.9057 8.27687 30.6222 7.68887 30.2197 7.18897C29.8169 6.68996 29.3067 6.29155 28.6887 5.99599C28.0708 5.69998 27.3822 5.55153 26.6238 5.55153ZM23.3867 22.2072C21.8503 22.2072 20.4682 21.9156 19.2404 21.333C18.0112 20.7499 16.9908 19.9545 16.1788 18.9453C15.3659 17.9374 14.7984 16.7623 14.4765 15.42C14.1541 14.0781 14.1425 12.6401 14.4412 11.1037C14.7358 9.58655 15.3064 8.15167 16.1524 6.8004C16.9979 5.44958 18.0219 4.27002 19.2269 3.26171C20.432 2.25295 21.7609 1.45704 23.2168 0.874409C24.6714 0.291335 26.1666 0.000244141 27.703 0.000244141C29.221 0.000244141 30.5987 0.291335 31.8359 0.874409C33.0732 1.45704 34.0985 2.25295 34.9114 3.26171C35.7234 4.27002 36.2899 5.44958 36.611 6.8004C36.9311 8.15167 36.9432 9.58655 36.6485 11.1037C36.3498 12.6401 35.7788 14.0781 34.9351 15.42C34.0913 16.7623 33.0669 17.9374 31.8628 18.9453C30.6577 19.9545 29.323 20.7499 27.859 21.333C26.3946 21.9156 24.9039 22.2072 23.3867 22.2072Z"
          fill="#111111" />
        <mask id="mask0_6524_1527" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="37" y="0" width="21"
          height="23">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M37.1401 0.000244141H57.3626V22.2072H37.1401V0.000244141Z"
            fill="white" />
        </mask>
        <g mask="url(#mask0_6524_1527)">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M50.7977 5.49604H47.9664L44.7183 22.2071H39.2216L42.4696 5.49604H37.1401L38.2088 -0.000244141H57.3626L50.7977 5.49604Z"
            fill="#111111" />
        </g>
        <mask id="mask1_6524_1527" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="50" y="0" width="24"
          height="23">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M50.8662 0.000244141H73.5007V22.2072H50.8662V0.000244141Z"
            fill="white" />
        </mask>
        <g mask="url(#mask1_6524_1527)">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M63.2641 5.55153C62.487 5.55153 61.7268 5.69998 60.985 5.99599C60.2423 6.29155 59.5734 6.68996 58.9769 7.18897C58.3786 7.68887 57.8666 8.27687 57.4396 8.95205C57.0122 9.62769 56.7255 10.3449 56.5784 11.1037C56.4268 11.8808 56.4322 12.6119 56.595 13.2965C56.7582 13.9811 57.0412 14.5735 57.4441 15.0734C57.8465 15.5725 58.3607 15.9709 58.9881 16.2664C59.6154 16.5633 60.3179 16.7109 61.0946 16.7109C61.8538 16.7109 62.5997 16.5633 63.3325 16.2664C64.0658 15.9709 64.7307 15.5725 65.3272 15.0734C65.9246 14.5735 66.4375 13.9811 66.8667 13.2965C67.2956 12.6119 67.5862 11.8808 67.7373 11.1037C67.8849 10.3449 67.8755 9.62769 67.7114 8.95205C67.5464 8.27687 67.262 7.68887 66.86 7.18897C66.4572 6.68996 65.947 6.29155 65.3295 5.99599C64.7106 5.69998 64.0229 5.55153 63.2641 5.55153ZM60.0263 22.2072C58.4909 22.2072 57.1083 21.9156 55.88 21.333C54.6517 20.7499 53.6313 19.9545 52.8188 18.9453C52.0064 17.9374 51.4385 16.7623 51.117 15.42C50.7951 14.0781 50.783 12.6401 51.0812 11.1037C51.3764 9.58655 51.9469 8.15167 52.7925 6.8004C53.6376 5.44958 54.6624 4.27002 55.8679 3.26171C57.0721 2.25295 58.4023 1.45704 59.8573 0.874409C61.3119 0.291335 62.8071 0.000244141 64.3435 0.000244141C65.8602 0.000244141 67.2379 0.291335 68.476 0.874409C69.7137 1.45704 70.7386 2.25295 71.5515 3.26171C72.3635 4.27002 72.9305 5.44958 73.2506 6.8004C73.5708 8.15167 73.5837 9.58655 73.2886 11.1037C72.9904 12.6401 72.4189 14.0781 71.5752 15.42C70.7314 16.7623 69.707 17.9374 68.5028 18.9453C67.2973 19.9545 65.9635 20.7499 64.4996 21.333C63.0347 21.9156 61.5439 22.2072 60.0263 22.2072Z"
            fill="#111111" />
        </g>
      </svg>
    </div>
    <iframe [src]="iFrameUrl | safe:'resourceUrl'" width="100%" height="100%" scrolling="no">

    </iframe>
  </div>
</div>