import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BannersService } from 'src/app/services/banners.service';
import { PagesService } from '../services/pages.service';
import { KnownPageSection } from '../enum/KnownPageSection';
import { of } from 'rxjs';

@Injectable()
export class SupportPagesResolve implements Resolve<any> {

  constructor(private _pagesService: PagesService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const { Area } = route.data || {};
    return this._pagesService.getPages(Area);
  }
}

@Injectable()
export class SupportPageResolve implements Resolve<any> {

  constructor(private _pagesService: PagesService) { }

  resolve(route: ActivatedRouteSnapshot) {

    const { pageId, sectionId } = route.queryParams || {};

    if (pageId) {
      return this._pagesService.getPageById(pageId);
    }
    return of({});
  }
}