export enum FootballMarketTypeValue
{
    MatchResult = 2,
    HalfTimeFullTime = 3,
    FirstGoalScorer = 12,
    LastGoalScorer = 13,
    AnytimeGoalScorer = 14,
    FirstGoalTime = 17,
    TotalBookingPoints = 19,
    TotalCorners = 20,
    HighestScoringHalf = 25,
    RestOfFirstHalfResult = 30,
    PlayerToScoreHatTrick = 42,
    ScoreInBothHalves = 59,
    WinBothHalves = 60,
    CleanSheet = 62,
    SecondHalfFirstTeamToScore = 66,
    AsianHandicap = 82,
    CorrectScore = 91,
    WinEitherHalf = 104,
    HandicapWithTie = 105,
    DrawNoBet = 122,
    FirstCardedTeamDrawNoBet = 168,
    ToWinToNil = 169,
    HalfTimeCorrectScore = 170,
    TotalGoals = 253,
    TotalGoalsOverUnder = 259,
    SecondHalfTotalGoals = 262,
    FirstTeamToScore = 295,
    MostCornersTeamWithDraw = 297,
    HalfTimeTotalGoals = 362,
    TotalCornersOddOrEven = 6491,
    HowWillTheTieBeDecided = 6497,
    TeamNotToScore = 6609,
    CornersHandicapWithTie = 6631,
    TotalCards = 6765, 
    HalfTimeResult = 6832,
    LastTeamToScore = 6911,
    HalfTimeHandicapWithTie = 7075,
    HalfTimeTotalOverUnder = 7076,
    BothTeamsToScore = 7079,
    AwayTeamToScore = 7081,
    HomeTeamToScore = 7084,
    HomeTeamTotalGoals = 7086,
    AwayTeamTotalGoals = 7087,
    NextGoalScorer = 7100,
    NextTeamToScore = 7105,
    NextCornerTeam = 7106,
    NextCardedTeam = 7108,
    PlayerToScoreTowOrMore = 7121,
    TeamsToScore = 7136,
    DoubleChance = 7202,
    WinningMargin = 7247,
    OddOrEvenTotal = 7354,
    SecondHalfAsianHandicap = 7466,
    TotalCornersOverUnder = 7478,
    CornerAsianHandicap = 7574,
    SecondHalfMatchResult = 7591,
    SecondHalfTotalGoalsOverUnder = 7706,
    NextAwayTeamGoalScorer = 7739,
    HalfTimeDoubleChance = 7769,
    TeamWithMostBookingPoints = 7783,
    TotalBookingPointsOverUnder = 7793,
    SecondHalfCorrectScore = 7809,
    HalfTimeAsianHandicap = 7871,
    MatchResultAndTotalGoalsOverUnder = 8239,
    BookingPointsHandicapWithTie = 8479,
    HomeTeamCleanSheet = 8480,
    AwayTeamCleanSheet = 8481,
    NextGoalTime = 8682,
    NextHomeGoalTime = 8683,
    NextAwayGoalTime = 8684,
    RestOfMatchResult = 8710,
    ExactNumberOfGoals = 8726,
    SecondHalfDoubleChance = 8770,
    PenaltyShootoutYesNo = 8804,
    HomeTeamToWinToNil = 8863,
    AwayTeamToWinToNil = 8864,
    AwayTeamTotalGoalsOverUnder = 9497,
    HomeTeamTotalGoalsOverUnder = 9498,
    HalfTimeOddOrEvenTotal = 9535,
    HalfTimeFirstTeamToScore_PreMatch = 9536,
    HomeTeamToScoreInBothHalves = 9559,
    AwayTeamToScoreInBothHalves = 9560,
    TotalCardsOddOrEven = 9562,
    HomeTeamToWinBothHalves = 9579,
    AwayTeamToWinBothHalves = 9580,
    FirstCardedTeam = 9966,
    FirstGoalTime_10 = 10031,
    SecondGAlfOddOrEvenTotalGoals = 10053,
    SecondHalfHomeTeamTotalGoalsOverUnder = 10054,
    SecondHalfAwayTeamTotalGoalsOverUnder = 10055,
    SecondHalfExactNumberOfGoals = 10145,
    HalfTimeExactNumberOfGoals = 10146,
    SecondHalfHandicap = 10162,
    TotalGoalsOverUnderAfter15Minutes = 10448,
    MatchResultAfter15Minutes = 10453,
    HalfTimeBothTeamsToScore = 10459,
    MatchResultAfter30Minutes = 10479,
    MatchResultAfter60Minutes = 10480,
    MatchResultAfter75Minutes = 10481,
    MatchResultAfterExtraTimeIncludingNormalTimeGoals = 10498,
    CorrectScoreAfterExtraTimeIncludingNormalTimeGoals = 10499,
    AsianHandicapAfterExtraTimeIncludingNormalTimeGoals = 10500,
    TotalGoalsAfterExtraTimeIncludingNormalTimeGoalsOverUnder = 10501,
    ResultAtExtraTimeHalfTimeIncludingNormalTimeGoals = 10502,
    ExtraTimeNextTeamToScoreIncludingNormalTimeGoals = 10503,
    ExtraTimeNextGoalscorerIncludingNormalTimeGoals = 10504,
    NextHomeTeamGoalScorer = 10523,
    CorrectScoreAtExtraTimeHalfTimeIncludingNormalTimeGoals = 10540,
    AsianHandicapAtExtraTimeHalfTimeIncludingNormalTimeGoals = 10541,
    TotalGoalsAtExtraTimeHalfTimeOverUnderIncludingNormalTimeGoals = 10542,
    MatchResultAfter10Minutes = 10554,
    SecondHalfBothTeamsToScore = 10576,
    SecondHalfAwayTeamToScoreYesNo = 10577,
    SecondHalfHomeTeamToScoreYesNo = 10578,
    HalfTimeAwayTeamToScoreYesNo = 10579,
    HalfTimeHomeTeamToScoreYesNo = 10580,
    AwayTeamNoBet = 10582,
    HomeTeamNoBet = 10583,
    HomeTeamHalfTimeExactNumberOfGoals = 10589,
    HomeTeamSecondHalfExactNumberOfGoals = 10590,
    AwayTeamSecondHalfExactNumberOfGoals = 10591,
    AwayTeamHalfTimeExactNumberOfGoals = 10592,
    SecondHalfDrawNoBet = 10615,
    HalfTimeDrawNoBet = 10616,
    HomeTeamTotalCornersOverUnder = 10747,
    AwayTeamTotalCornersOverUnder = 10748,
    HalfTimeCleanSheet = 10766,
    SecondHalfCleanSheet = 10779,
    AwayTeamCornersBands = 10781,
    HomeTeamCornersBands = 10782,
    HalfTimeAwayTeamTotalGoalsOverUnder = 10849,
    HalfTimeHomeTeamTotalGoalsOverUnder = 10850,
    HomeTeamExactNumberOfGoals = 10869,
    AwayTeamExactNumberOfGoals = 10870,
    AwayTeamOddOrEvenGoals = 10887,
    HomeTeamOddOrEvenGoals = 10888,
    ExtraTimeResultExcludingNormalTimeGoals = 10982,
    ExtraTimeCorrectScoreExcludingNormalTimeGoals = 10983,
    ExtraTimeAsianHandicapExcludingNormalTimeGoals = 10984,
    ExtraTimeTotalGoalsExcludingNormalTimeGoals = 10985,
    ExtraTimeHalfTimeResultExcludingNormalTimeGoals = 10986,
    ExtraTimeNextGoalscorerExcludingNormalTimeGoals = 10989,
    ExtraTimeNextTeamToScoreExcludingNormalTimeGoals = 10992,
    ExtraTimeHalfTimeCorrectScoreExcludingNormalTimeGoals = 10993,
    ExtraTimeHalfTimeAsianHandicapExcludingNormalTimeGoals = 10994,
    ExtraTimeHalfTimeTotalGoalsOverUnderExcludingNormalTimeGoals = 10995,
    HalfTimeFirstTeamToScore = 11044,
    ToWinTheTrophy = 11073,
    MatchResultAfter5Minutes = 11108,
    MatchResultAfter20Minutes = 11109,
    MatchResultAfter25Minutes = 11110,
    MatchResultAfter70Minutes = 11111,
    MatchResultAfter35Minutes = 11112,
    MatchResultAfter40Minutes = 11113,
    MatchResultAfter50Minutes = 11114,
    MatchResultAfter55Minutes = 11115,
    MatchResultAfter65Minutes = 11116,
    MatchResultAfter80Minutes = 11117,
    MatchResultAfter85Minutes = 11118,
    AsianHandicapAfter5Minutes = 11119,
    AsianHandicapAfter10Minutes = 11120,
    AsianHandicapAfter15Minutes = 11121,
    AsianHandicapAfter20Minutes = 11122,
    AsianHandicapAfter25Minutes = 11123,
    AsianHandicapAfter30Minutes = 11124,
    AsianHandicapAfter35Minutes = 11125,
    AsianHandicapAfter40Minutes = 11126,
    //AsianHandicapAfter45Minutes = 11127,
    AsianHandicapAfter50Minutes = 11128,
    AsianHandicapAfter55Minutes = 11129,
    AsianHandicapAfter60Minutes = 11130,
    AsianHandicapAfter65Minutes = 11131,
    AsianHandicapAfter70Minutes = 11132,
    AsianHandicapAfter75Minutes = 11133,
    AsianHandicapAfter80Minutes = 11134,
    AsianHandicapAfter85Minutes = 11135,
    TotalGoalsOverUnderAfter5Minutes = 11136,
    TotalGoalsOverUnderAfter25Minutes = 11137,
    TotalGoalsOverUnderAfter30Minutes = 11138,
    TotalGoalsOverUnderAfter10Minutes = 11139,
    TotalGoalsOverUnderAfter20Minutes = 11140,
    TotalGoalsOverUnderAfter35Minutes = 11141,
    TotalGoalsOverUnderAfter40Minutes = 11142,
    TotalGoalsOverUnderAfter50Minutes = 11143,
    TotalGoalsOverUnderAfter55Minutes = 11144,
    TotalGoalsOverUnderAfter60Minutes = 11145,
    TotalGoalsOverUnderAfter65Minutes = 11146,
    TotalGoalsOverUnderAfter70Minutes = 11147,
    TotalGoalsOverUnderAfter75Minutes = 11148,
    TotalGoalsOverUnderAfter80Minutes = 11149,
    TotalGoalsOverUnderAfter85Minutes = 11150,
    SecondHalfAwayTeamCleanSheetYesNo = 11392,
    SecondHalfHomeTeamCleanSheetYesNo = 11393,
    BothTeamsToScoreInBothHalvesYesNo = 11461,
    RestOfMatchTotalGoalsOverUnder = 11544,
    HomeTeamToWinEitherHalfYesNo = 11656,
    AwayTeamToWinEitherHalfYesNo = 11657,
    MatchToGoToExtraTimeAndGoalScoredInExtraTimeYesNo = 11678,
    SecondHalfHomeTeamTotalGoalsOddOrEven = 11679,
    SecondHalfAwayTeamTotalGoalsOddOrEven = 11680,
    HalfTimeAwayTeamTotalGoalsOddOrEven = 11681,
    HalfTimeHomeTeamTotalGoalsOddOrEven = 11682,
    AwayTeamFirstGoalScorer = 11767,
    HomeTeamFirstGoalScorer = 11768,
    BothTeamsToScore2OrMoreGoalsYesNo = 11921,
    MatchResultAndBothTeamsToScore = 12381,
    HalfTimeResultAndBothTeamsToScore = 12502,
    AamsHalfTimeCorrectScore = 12508,
    AamsCorrectScore = 12509,
    RestOfFirstHalfTotalGoalsOverUnder = 12931,
    BothTeamsToScoreAndTotalGoalsOverUnder = 13014,
    RestOfMatchAsianHandicap = 13496,
    HomeTeamTotalCardsOverUnder = 13649,
    AwayTeamTotalCardsOverUnder = 13650,
    CardsAsianHandicap = 13651,
    HalfTimeResultAndTotalGoalsOverUnder = 13681,
    HalfTimeHomeTeamToWinToNilYesNo = 13697,
    HalfTimeAwayTeamToWinToNilYesNo = 13698,
    SecondHalfAwayTeamToWinToNilYesNo = 13699,
    SecondHalfHomeTeamToWinToNilYesNo = 13700,
    DoubleChanceAndBothTeamsToScore = 13707,
    RestOfHalfTimeAsianHandicap = 13766,
    AamsDoubleChanceHomeTeam = 14305,
    AamsDoubleChanceAwayTeam = 14306,
    AamsDoubleChanceDraw = 14307,
    WhichTeamToScore = 14308,
    MatchResultAndFirstTeamToScore = 14309,
    DoubleChanceAndOverUnder = 14312,
    NoDrawBothTeamsToScore = 14313,
    HomeTeamFirstHalfCleanSheet = 14329,
    AwayTeamFirstHalfCleanSheet = 14330,
    NoDrawBothTeamsToScoreYesNo = 14415,
    GoalScoredInTheNextMinuteYesNo = 14773,
    GoalScoredInTheNext5MinutesYesNo = 14777,
    CornerAwardedInTheNextMinuteYesNo = 14795,
    CornerAwardedInTheNext5MinutesYesNo = 14799,
    MatchToGoToExtraTimeYesNo = 14837,
    HalfTimeDoubleChanceAndBothTeamsToScore = 14838,
    SecondHalfDoubleChanceAndBothTeamsToScore = 14981,
    SecondHalfResultAndBothTeamsToScore = 15053,
    TotalCardsOverUnder = 15064,
    NextHomePenaltyToBeScoredYesNo = 15065,
    ToWinTie = 15150,
    CornerAfterNext = 15154,
    HomeTeamTotalCornersOddOrEven = 15180,
    AwayTeamTotalCornersOddOrEven = 15181,
    HomeTeamTotalCardsOddOrEven = 15182,
    AwayTeamTotalCardsOddOrEven = 15183,
    HalfTimeAwayTeamTotalGoals = 15184,
    HalfTimeHomeTeamTotalGoals = 15185,
    SecondHalfHomeTeamTotalGoals = 15186,
    SecondHalfAwayTeamTotalGoals = 15187,
    HalfTimeWinningMargin = 15188,
    SecondHalfWinningMargin = 15189,
    SecondHalfResultAndTotalGoalsOverUnder = 15190,
    HalfTimeDoubleChanceAndTotalGoalsOverUnder = 15191,
    SecondHalfDoubleChanceAndTotalGoalsOverUnder = 15192,
    AamsSecondHalfCorrectScore = 15193,
    EitherTeamToWinYesNo = 15194,
    CardAwardedInTheNext1MinuteYesNo = 15199,
    CardAwardedInTheNext5MinutesYesNo = 15200,
    NumberedHomeShootOutPenaltyToBeScoredYesNo = 15302,
    NumberedAwayShootOutPenaltyToBeScoredYesNo = 15303,
    NextAwayPenaltyToBeScoredYesNo = 15066,
};

(FootballMarketTypeValue as any).__typeName = 'FootballMarketTypeValue';
