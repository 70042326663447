import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, of, Subscription, combineLatest } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { FormArray, FormGroup } from '@angular/forms';
import { HttpMethod } from 'src/app/enum';
import { DataTableService, ODataResponse } from 'src/app/services/data-table.service';
import { ITableParams } from './table-params';
import { IReactiveFormsDeepMappingOptions, formUtil } from 'src/app/util/form-util';

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  groupFor: string;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

export interface IGroupByColumn {
  key: string;
  label: string;
}

export class DataTableDataSource<TItem, TData> implements DataSource<TItem> {

  private _dataSubject = new BehaviorSubject<TItem[]>([]);
  private _countSubject = new BehaviorSubject<number>(0);
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  private _searchKeySubject = new BehaviorSubject<string>(null);
  private _pageSubject = new BehaviorSubject<number>(null);
  private _pageSizeSubject = new BehaviorSubject<number>(null);
  private _sortSubject = new BehaviorSubject<string>(null);
  private _summarySubject = new BehaviorSubject<any>(null);
  private _tableFilterSubject = new BehaviorSubject({criteria: null, searchTerm: null });

  private _loadSubscription: Subscription;

  public get loading$() {
    return this._loadingSubject.asObservable();
  }

  public get count$() {
    return this._countSubject.asObservable();
  }

  public get page$() {
    return this._pageSubject.asObservable();
  }

  public get pageSize$() {
    return this._pageSizeSubject.asObservable();
  }

  public get sort$() {
    return this._sortSubject.asObservable();
  }

  public get data$() {
    return this._dataSubject.asObservable();
  }

  public get data() {
    return this._dataSubject.value;
  }
  
  public get summary$() {
    return this._summarySubject.asObservable();
  }

  public get summary() {
    return this._summarySubject.value;
  }

  public get searchKey$() {
    return this._searchKeySubject.asObservable();
  }

  constructor(private _dataService: DataTableService<TItem, TData>, private _method: HttpMethod) {}

  public connect(collectionViewer: CollectionViewer): Observable<TItem[]> {
    // every time dataSubject or filterSubject emit new data
    return combineLatest([this._dataSubject, this._tableFilterSubject]).pipe(map(latestValues => {
        const [data, filterData] = latestValues;

        if (!filterData.criteria || !filterData.searchTerm) return data;

        let groupCount = 0;
        let groupExpanded = true;
        let groupParentExpanded = true;

        return data.filter((item: any) => {
          if (item.hasOwnProperty('expanded')) { 
            groupCount ++;
            groupExpanded = item.expanded;
            groupParentExpanded = item.parent.expanded;
            return groupParentExpanded;
          };

          if (groupCount > 0) {
            return groupParentExpanded && groupExpanded;
          }

          const filterValue = filterData.searchTerm.toString().toLocaleLowerCase();
          const itemValue = item[filterData.criteria]?.toString().toLocaleLowerCase();
          return itemValue?.includes(filterValue);
        });
    }));
    // return this._dataSubject.asObservable();
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this._dataSubject.complete();
    this._loadingSubject.complete();
  }

  public prepareItems(items, formMappingOptions: IReactiveFormsDeepMappingOptions, groupBy) {
    const data = formMappingOptions ? (items[0] instanceof FormGroup ? items : formUtil.createFormArray(items, null, formMappingOptions)?.controls) : items as any;
    return groupBy ? this.addGroups(data, groupBy) : data;
  }

  public pushItem(item: any, formMappingOptions: IReactiveFormsDeepMappingOptions, groupBy = null) {
    let dataItem = item;
    let firstArrayItem = this.data[0];

    // Convert item to FormGroup if data consists of FormGroups
    if (firstArrayItem instanceof FormGroup) {
      if (!(item instanceof FormGroup)) {
        dataItem = formUtil.createFormGroup(dataItem, null, formMappingOptions);
      };
    }

    this.data.push(dataItem);
    this.updateData(this.data, formMappingOptions, groupBy);
  }

  // GROUPS

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  getSublevel(data: any[], level: number, groupByColumns: any[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            const groupFor = groupByColumns[i].key;
            result[groupFor] = row[groupFor];
            result.groupFor = groupFor;
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn.key] === row[currentColumn.key]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  addGroups(data: any[], groupByColumns: IGroupByColumn[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  //

  public loadData(dataUrl: string, data: TData, tableParams: ITableParams, formMappingOptions: IReactiveFormsDeepMappingOptions, groupBy): void {
    this._loadingSubject.next(true);

    if (this._loadSubscription) {
      this._loadSubscription.unsubscribe();
    }

    this._dataService
      .get(dataUrl, data, tableParams, this._method)
      .pipe(
        catchError(() => of(new ODataResponse<TItem>())),
        finalize(() => this._loadingSubject.next(false))
      )
      .subscribe(response => {
        if (!response) {
          this._countSubject.next(0);
          this._searchKeySubject.next(null);
          this._dataSubject.next(new Array<TItem>());
          return;
        }

        this._countSubject.next(response.count ?? 0);
        this._searchKeySubject.next(response.searchKey);
        this._dataSubject.next(this.prepareItems(response.items, formMappingOptions, groupBy));
      });
  }

  public setData(data: ODataResponse<TItem>, formMappingOptions: IReactiveFormsDeepMappingOptions, groupBy) {
    this._searchKeySubject.next(data.searchKey);
    this._pageSubject.next(data.page);
    this._pageSizeSubject.next(data.pageSize);
    this._sortSubject.next(data.sort);
    this._dataSubject.next(this.prepareItems(data.items, formMappingOptions, groupBy));
    this._summarySubject.next(data.summary);
    this._countSubject.next(data.count || this._dataSubject.value.length);
    this._loadingSubject.next(false);
  }

  public updateData(data: TItem[], formMappingOptions: IReactiveFormsDeepMappingOptions, groupBy) {
    const items = this.prepareItems(data, formMappingOptions, groupBy);
    this._countSubject.next(data.length);
    this._dataSubject.next(items);
    this._loadingSubject.next(false);
  }

  public setFilter(filter) {
    this._tableFilterSubject.next(filter);
  }

}
