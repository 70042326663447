import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { LocalStorage } from 'src/app/helpers/local-storage';
import { ILanguage, ISiteLocale } from 'src/app/models';
import { LOCAL_STORAGE_KEY } from './config/local-storage-key';
import { AuthenticationService, TitleService } from './services';
import { DEFAULT_LANGUAGE } from './app.settings';
import { IIdentity } from './models/IIdentity';
import { RealtimeState } from './modules/sports/state/realtime.state';

@Injectable({ providedIn: 'root' })
export class AppState {
  public devSettings = {
    showHiddenMarkets: false,
    showNotOpenMarkets: false,
    showDevTools: true
  };

  // Default fallback page title
  public title = 'Toto';
  // Page loader
  public isPageLoaded = false;
  // Content loader
  public isViewLoaded = false;

  public isViewMobile = false;
  public isViewTablet = false;

  public isNavigatorOpen: boolean;
  public isUserMenuOpen = false;
  public isNotificationsMenuOpen = false;

  public url: string;

  public userIsActiveOnPage$ = new BehaviorSubject<boolean>(true);
  public updateApplicationSubject$ = new Subject<void>();

  private readonly _token = new BehaviorSubject<IIdentity>(null);

  public siteLocaleSubject$ = new BehaviorSubject<ISiteLocale>(null);
  public userBalanceSubject$ = new BehaviorSubject<IUserBalance>(null);
  public userBetStatusSubject$ = new BehaviorSubject<any>(null);
  public userAccountInfoSubject$ = new BehaviorSubject<IUserAccountInfoUser>(
    null
  );
  public userAvailableCampaignsSubject$ = new BehaviorSubject<any>(null);
  
  public userPreferencesSubject$ = new BehaviorSubject<any>(null);
  public activeSportsBetsCountSubject$ = new BehaviorSubject<number>(null);
  public favouriteSportEventsCountSubject$ = new BehaviorSubject<number>(null);

  public appModule$: BehaviorSubject<TotoAppModule> = new BehaviorSubject(
    this.storedAppModule
  );

  public language: ILanguage =
    LocalStorage.getValue(LOCAL_STORAGE_KEY.APP_LANGUAGE) || DEFAULT_LANGUAGE;

  public userPendingTickets: IUserPendingTickets;

  private _balanceChangeSubscription: Subscription;
  private _betStatusChangeSubscription: Subscription;
  private _activeSportsBetsCountChangeSubscription: Subscription;
  private _favouriteSportEventsCountChangeSubscription: Subscription;


  private _userTokenChangeSubscription: Subscription;

  public get siteLocale(): ISiteLocale {
    return this.siteLocaleSubject$.value;
  }

  public get activeAppModule(): TotoAppModule {
    return this.appModule$.value;
  }

  public get userBalance() {
    return this.userBalanceSubject$.value;
  }

  public get userAccountInfo() {
    return this.userAccountInfoSubject$.value;
  }

  public get isUserVerified(): boolean {
    return this.userAccountInfo?.isVerified;
  }

  public get activeSportsBetsCount(): number {
    return this.activeSportsBetsCountSubject$.value;
  }

  public get favouriteSportEventsCount(): number {
    return this.favouriteSportEventsCountSubject$.value;
  }

  public get availableCampaigns(): number[] {
    return this.userAvailableCampaignsSubject$.value;
  }

  public get currentLanguage(): string {
    return this.language?.value;
  }

  public get storedAppModule() {
    const stored = LocalStorage.getValue(LOCAL_STORAGE_KEY.LAST_VISITED_MODULE);
    if (stored && TotoAppModule[stored]) {
      return stored;
    }
    return TotoAppModule.HorseRacing;
  }

  constructor(private _realtime: RealtimeState) {
    this._userTokenChangeSubscription = this.token.subscribe(x =>
      this._onTokenChange()
    );
  }

  public get token(): BehaviorSubject<IIdentity> {
    return this._token;
  }

  public get user() {
    return this._token?.value;
  }

  public get userId() {
    return this.user?.nameid;
  }

  public get isLoggedIn(): boolean {
    return this.user ? true : false;
  }

  public setAppModule(module: TotoAppModule) {
    // console.log("SETTING APP MODULE", module);
    if (module) {
      // LocalStorage.set(LOCAL_STORAGE_KEY.LAST_VISITED_MODULE, module);
      this.appModule$.next(module);
    }
  }

  public pushDataLayer(data) {
    let dataLayerItem = {
      userId: this.userId,
      ...data
    };

    (window as any).dataLayer.push(dataLayerItem);

    console.log("PUSHED DATALAYER", dataLayerItem);
  }

  public setAccountInfo(
    accountInfoResponse: IUserAccountInfo,
    event = 'identify_user',
  ) {
    const { balance, accountInfo, preferences, contact, activeSportsBetsCount, favouriteSportEventsCount, availableCampaigns  } =
      accountInfoResponse || {};

    this.userBalanceSubject$.next(balance);
    this.userAccountInfoSubject$.next(accountInfo);
    this.userPreferencesSubject$.next(preferences);
    this.activeSportsBetsCountSubject$.next(activeSportsBetsCount);
    this.favouriteSportEventsCountSubject$.next(favouriteSportEventsCount);
    this.userAvailableCampaignsSubject$.next(availableCampaigns);


    const dataLayer = {
      event: event,
      firstName: accountInfo?.firstName,
      lastName: accountInfo?.lastName,
      email: contact?.email,
      phone: contact?.phone
    };

    this.pushDataLayer(dataLayer);
  }

  public clearAccountInfo() {
    this.userBalanceSubject$.next(null);
    this.userAccountInfoSubject$.next(null);
    this.userPreferencesSubject$.next(null);
    this.activeSportsBetsCountSubject$.next(null);
    this.favouriteSportEventsCountSubject$.next(null);
    this.userAvailableCampaignsSubject$.next(null);
  }

  private _onTokenChange() {
    if (this._balanceChangeSubscription) {
      this._balanceChangeSubscription.unsubscribe();
    }

    if (this._betStatusChangeSubscription) {
      this._betStatusChangeSubscription.unsubscribe();
    }

    if (this._activeSportsBetsCountChangeSubscription) {
      this._activeSportsBetsCountChangeSubscription.unsubscribe();
    }

    if (this._favouriteSportEventsCountChangeSubscription) {
      this._favouriteSportEventsCountChangeSubscription.unsubscribe();
    }

    if (this.userId) {
      if (!this._realtime.userChannel) {
        console.log('INIT USER CHANNEL', this.userId);
        this._realtime.initUserChannel(this.userId);
      }

      this._balanceChangeSubscription = this._realtime.userBalanceSubject$.subscribe(
        (res: IUserBalance) => {
          this.userBalanceSubject$.next(res);
        }
      );

      this._betStatusChangeSubscription = this._realtime.userBetStatusSubject$.subscribe(
        (res: any) => {
          this.userBetStatusSubject$.next(res);
        }
      );

      this._activeSportsBetsCountChangeSubscription = this._realtime.activeSportsBetsCountSubject$.subscribe((res: any) => {
        const { activeBetsCount } = res || {};
        this.activeSportsBetsCountSubject$.next(activeBetsCount);
      })

      this._favouriteSportEventsCountChangeSubscription = this._realtime.favouriteSportEventsCountSubject$.subscribe((res: any) => {
        const { favouriteEventsCount } = res || {};
        this.favouriteSportEventsCountSubject$.next(favouriteEventsCount);
      })


    }
  }
}

interface IUserPendingTickets {
  ticketsWaitingResultsCount: number;
  pendingTicketesCount: number;
}

export enum TotoAppModule {
  HorseRacing = 'HorseRacing',
  Sports = 'Sports'
}

export interface IUserAccountInfo {
  accountInfo: IUserAccountInfoUser;
  balance: IUserBalance;
  availableCampaigns: number[];
  contact?: any; // will be removed
  formData?: any; // will be removed
  manualBets?: any; // will be removed
  newsletter?: any; // will be removed
  preferences: any;
  activeSportsBetsCount: number;
  favouriteSportEventsCount: number;
}

export interface IUserBalance {
  balance: number;
  bonus: number;
  total: number;
  paidIn?: number;
}

export interface IUserAccountInfoUser {
  alias: string;
  birthDate: Date;
  firstName: string;
  idCode: string;
  isVerified: boolean;
  lastName: string;
  username: string;
}
