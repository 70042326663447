import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { KnownPageSection } from 'src/app/enum/KnownPageSection';
import { TotoDialog } from 'src/app/helpers';
import { TitleService } from 'src/app/services';
import { PagesService } from 'src/app/services/pages.service';
import { capitalizeFirstLetter } from 'src/app/util/misc';
import { BetType, BetTypeGroup } from 'src/app/enum';
import { VideoDialogComponent } from '../dialogs/video/video.dialog';

@Component({
  selector: 'video-box',
  templateUrl: './video-box.component.html',
  styleUrls: ['./video-box.component.scss', '../shared.scss']
  // host: { 'class': 'layout'}
})
export class VideoBoxComponent {

  public BetType = BetType;

  @Input() public data: IVideoBoxData;

  constructor(
    private _dialog: TotoDialog,
  ) {}

  public get videoId() {
    return this.data?.id;
  }

  public get videoName() {
    return this.data?.name;
  }

  public get betType() {
    return this.data?.betType;
  }

  public get betTypeEnum() {
    return this.data?.betTypeEnum;
  }

  public get thumbnailSrc() {
    return `https://i.ytimg.com/vi/${this.videoId}/hqdefault.jpg`
  }

  public openVideoDialog() {
    this._dialog.open(VideoDialogComponent, {
      data: this.data,
      width: '780px'
    }, res => {
    })
  }

}

export interface IVideoBoxData {
  id: string;
  name: string;
  text: string;
  betType: BetType | BetTypeGroup;
  betTypeEnum: any;
}