import { Component, Input, Optional, Host, OnInit } from '@angular/core';
import { UntypedFormGroup, ControlContainer } from '@angular/forms';
import { AppState } from 'src/app/app.state';
import { ResponsiveHelper } from 'src/app/helpers';

@Component({
  selector: "toto-rangepicker",
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.scss']
})
export class RangepickerComponent implements OnInit {

  @Input() public formGroup: UntypedFormGroup;
  @Input() public from: string;
  @Input() public to: string;
  @Input() public readonly = false;
  @Input() public width = '100%';
  @Input() public min: any;
  @Input() public showInput = true;
  @Input() public get touchUi() {
    return this._appState.isViewMobile;
  };

  constructor(private _appState: AppState, @Optional() @Host() private _controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
      this.formGroup = <UntypedFormGroup>this._controlContainer.control;
    }
  }

}
