import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { isNumber, toNumber } from "../util/number-util";
import { LocaleService } from "../services";
import { AppState } from "../app.state";

@Pipe({
  name: "priceValue",
  pure: false
})
export class PriceValuePipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe, private _appState: AppState) {};

  public get locale() {
    return this._appState.language?.locale;
  }

  transform(value: any, precision: number = 2, addPlus = false) {

    if (typeof (value) === "string") {
      value = value?.replace(',', '.');
    }

    if (!isNumber(value)) {
      value = toNumber(value);
    }

    if (isNaN(value)) {
      return ' ';
    }

    let currencyValue = this.currencyPipe.transform(value, 'EUR', 'symbol', `1.${precision}-${precision}`, this.locale) 

    if (addPlus) {
      let prefix = ' ';
      if (value > 0) {
        prefix = '+';
      }
      currencyValue = prefix + currencyValue;
    }

    // TODO - find better solution
    return currencyValue?.replace(',', '.');

  }
}
