export enum HurlingMarketTypeValue
{
    MatchWinner = 9408,
    FirstHalfResult = 9410,
    TotalPointsOverUnder = 9462,
    SecondHalfResult = 9465,
    DrawNoBet = 10475,
    MatchHandicap = 12689,
    DoubleResult = 12690,
    NextScoringPlay = 12691,
    FirstHalfTotalPointsOverUnder = 12692,
    FirstHalfHandicap = 12693,
    FirstHalfHomeTeamTotalsOverUnder = 12694,
    FirstHalfAwayTeamTotalsOverUnder = 12695,
    BothTeamsToScoreAGoalOrMore = 12696,
    SecondHalfTotalPointsOverUnder = 12697,
    SecondHalfHandicap = 12698,
    SecondHalfHomeTeamTotalsOverUnder = 12699,
    SecondHalfAwayTeamTotalsOverUnder = 12700,
    RaceTo5Points = 12701,
    RaceTo10Points = 12702,
    RaceTo15Points = 12704,
    RaceTo20Points = 12705,
    RaceTo25Points = 12706,
    LeadAfter20Minutes = 12707,
    LeadAfter30Minutes = 12708,
    NextGoal = 12960,
}

(HurlingMarketTypeValue as any).__typeName = 'HurlingMarketTypeValue';