<form [formGroup]="addressGroup" (ngSubmit)="saveAddress()">
  <div class="form-title">
    <h2>{{'Views.User.Settings.Address.Title.Address' | translate}}</h2>
    <h5>{{'Views.User.Settings.Address.Title.AccountSettings' | translate}}</h5>
  </div>

  <div class="form-body">
    <form-field
      label="Views.User.Settings.Address.Label.AddressFirstRow"
      [labelTop]="true"
    >
      <toto-input for="userAddress1"></toto-input>
      <validation-errors for="userAddress1"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Address.Label.AddressSecondRow"
      additionalLabel="Views.User.Settings.Address.Label.IfNecessary"
      [labelTop]="true"
    >
      <toto-input for="userAddress2"></toto-input>
      <validation-errors for="userAddress2"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Address.Label.PostalCode"
      [labelTop]="true"
    >
      <toto-input for="userPostalCode"></toto-input>
      <validation-errors for="userPostalCode"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Address.Label.City"
      [labelTop]="true"
    >
      <toto-select
        for="userCity"
        [options]="cityOptions"
        labelField="label"
        fullscreenLabel="Views.User.Settings.Address.Label.City"
      ></toto-select>
      <validation-errors for="userCity"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Address.Label.Country"
      [labelTop]="true"
    >
      <toto-select
        for="userCountry"
        [options]="countryOptions"
        labelField="label"
        fullscreenLabel="Views.User.Settings.Address.Label.Country"
      ></toto-select>
      <validation-errors for="userCountry"></validation-errors>
    </form-field>
  </div>

  <div class="form-footer">
    <toto-button label="Action.Save" type="submit" color="green"></toto-button>
  </div>
</form>
