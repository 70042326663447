export enum VolleyballMarketTypeValue
{
    MatchWinner = 6734,
    SetBetting = 6777,
    MatchSetHandicap = 6778,
    FirstSetWinner = 6779,
    TotalPointsOverUnder = 6942,
    FirstSetTotalPointsOverUnder = 7011,
    SecondSetWinner = 7012,
    ThirdSetWinner = 7013,
    TotalMatchPointsOddEven = 7391,
    Set1Handicap = 7820,
    PointHandicap = 7880,
    Set1TotalPointsOddEven = 8256,
    Set2TotalPointsOddEven = 8257,
    Set3TotalPointsOddEven = 8258,
    FourthSetWinner = 8576,
    Lose1stSetAndWinMatch = 8653,
    TotalSetsOverUnder = 10865,
    Set5Winner = 11478,
    Set2TotalPointsOverUnder = 11554,
    AwayTeamTotalPointsOverUnder = 11555,
    HomeTeamTotalPointsOverUnder = 11556,
    Set2Handicap = 11636,
    Set3Handicap = 11637,
    Set3TotalPointsOverUnder = 11638,
    Set1RaceTo5Points = 12274,
    Set1RaceTo10Points = 12275,
    Set1RaceTo15Points = 12276,
    Set1RaceTo20Points = 12277,
    Set4TotalPointsOverUnder = 12510,
    Set5TotalPointsOverUnder = 12511,
    Set4TotalPointsOddOrEven = 12539,
    Set5TotalPointsOddOrEven = 12540,
    Set4Handicap = 12541,
    Set5Handicap = 12542,
    Set2RaceTo5Points = 12543,
    Set2RaceTo10Points = 12544,
    Set2RaceTo15Points = 12545,
    Set2RaceTo20Points = 12546,
    Set3RaceTo5Points = 12547,
    Set3RaceTo10Points = 12548,
    Set3RaceTo15Points = 12549,
    Set3RaceTo20Points = 12550,
    Set4RaceTo5Points = 12551,
    Set4RaceTo10Points = 12552,
    Set4RaceTo15Points = 12553,
    Set4RaceTo20Points = 12555,
    Set5RaceTo5Points = 12556,
    Set5RaceTo10Points = 12557,
    Set1NumberedPointWinner = 12560,
    Set2NumberedPointWinner = 12561,
    Set3NumberedPointWinner = 12562,
    Set4NumberedPointWinner = 12563,
    Set5NumberedPointWinner = 12564,
    HomeTeamTotalPointsOddOrEven = 13883,
    AwayTeamTotalPointsOddOrEven = 13884,
    Set1ExtraPoints = 13885,
    Set2ExtraPoints = 13886,
    Set3ExtraPoints = 13887,
    Set4ExtraPoints = 13888,
    Set5ExtraPoints = 13889,
    Set1LeadAfter2Points = 13895,
    Set1LeadAfter4Points = 13896,
    Set1LeadAfter6Points = 13897,
    Set1LeadAfter8Points = 13898,
    Set1LeadAfter10Points = 13899,
    Set1LeadAfter20Points = 13900,
    Set2LeadAfter2Points = 13901,
    Set2LeadAfter4Points = 13902,
    Set2LeadAfter6Points = 13903,
    Set2LeadAfter8Points = 13904,
    Set2LeadAfter10Points = 13905,
    Set2LeadAfter20Points = 13906,
    Set3LeadAfter2Points = 13907,
    Set3LeadAfter4Points = 13908,
    Set3LeadAfter6Points = 13909,
    Set3LeadAfter8Points = 13910,
    Set3LeadAfter10Points = 13911,
    Set3LeadAfter20Points = 13912,
    Set4LeadAfter2Points = 13913,
    Set4LeadAfter4Points = 13914,
    Set4LeadAfter6Points = 13915,
    Set4LeadAfter8Points = 13916,
    Set4LeadAfter10Points = 13917,
    Set4LeadAfter20Points = 13918,
    Set5LeadAfter2Points = 13919,
    Set5LeadAfter4Points = 13920,
    Set5LeadAfter6Points = 13921,
    Set5LeadAfter8Points = 13922,
    Set5LeadAfter10Points = 13923,
    Set1CorrectScore = 13924,
    Set2CorrectScore = 13925,
    Set3CorrectScore = 13926,
    Set4CorrectScore = 13927,
    Set5CorrectScore = 13928,
}

(VolleyballMarketTypeValue as any).__typeName = 'VolleyballMarketTypeValue';