import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Guid, MessageDispatcher } from 'src/app/helpers';
import {
  AccountService,
  AuthenticationService,
  ILimitReq,
  IRestrictBettingReq,
  TitleService
} from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ViewBase } from 'src/app/views/view.base';
import { ActionHelper } from 'src/app/helpers/action';

@Component({
  selector: 'user-password-view',
  templateUrl: './user-password.view.html',
  styleUrls: ['./user-password.view.scss', '../shared.scss']
})
export class UserPasswordViewComponent extends ViewBase {
  
  public titleFragments = [
    { key: 'Navigator.Menu.Settings.Settings' },
    { key: 'Navigator.Menu.Settings.Password' }
  ];

  public accountInfo: any;

  public formData: any;
  public formGroup: FormGroup;

  public actionLoading = {} as any;

  constructor(
    _titleService: TitleService,
    private _action: ActionHelper,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _msg: MessageDispatcher
  ) {
    super(_titleService);
    const { accountInfo } = this._route.snapshot.data.settingsData || {};

    this.accountInfo = accountInfo;

    this.formGroup = new FormGroup({
      currentPassword: new FormControl(),
      password: new FormControl(),
      confirmation: new FormControl()
    });
  }

  public get isVerified(): boolean {
    return this.accountInfo?.isVerified;
  }

  public savePasswordChange(): void {
    const req = this.formGroup.value;

    const message = this._action.createMessage('AppMessages.UserPassword.PasswordChanged');
    this._accountService.saveAccountPasswordChange(req).result(
      this.formGroup,
      res => {
        this.formGroup.reset();
      },
      err => {
        console.error(err);
      },
      'save',
      this.actionLoading,
      message
    );
  }
}
