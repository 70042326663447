import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService, AuthenticationService } from '../services';
import { AppState } from '../app.state';
import { MAIN_MENU_LINKS } from '../config/nav-links';
import { MessageDispatcher } from '../helpers';


@Injectable({ providedIn: 'root' })
export class ZimplerGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _state: AppState,
    private _accountService: AccountService,
    private _authService: AuthenticationService,
    private _msg: MessageDispatcher,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
    var key = route.queryParams.key

    if(!key) {
        return true;
    }
    console.log('Zimpler guard in action');
    this._authService.authenticateViaZimpler(key).toPromise().then(
        res => {
            console.log('zimpler action: ' + res.zimplerAuthPurpose);
            if(res.zimplerAuthPurpose == 0 || res.zimplerAuthPurpose == 1) {
                this._authService.updateToken(() => {
                    this._accountService.getAccountInfo().result(null, info => {

                    if(!window.posthogIdentified) {
                        if(info.accountInfo) {            

                        var additional = {
                            first_name: info.accountInfo.first_name,
                            last_name: info.accountInfo.last_name,
                            email: ''
                        };
                        
                        if(info.contact && info.contact.email) {
                            additional.email = info.contact.email;
                        }

                        window.posthog.identify(info.accountInfo.id, additional);
                        window.posthogIdentified = true;
                        }
                    }

                    this._state.setAccountInfo(info, 'log_in');
                    this._state.token.next(res.subject);
                    this._router.navigate(['/' + this._authService.activeAppModuleRoot]);
                    });
                });
            } 
            else 
            {
                this._router.navigate(['/' + this._authService.activeAppModuleRoot]).then(res => {
                    this._authService.openVerificationSuccessDialog();
                });
            }
        }).catch(err => {
            console.log(err);
            this._msg.error({ message: err.validationSummary });
            return this._router.navigateByUrl('/');
        });

    return false;
  }
}