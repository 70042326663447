import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { MessageDispatcher } from 'src/app/helpers';
import { ViewBase } from 'src/app/views/view.base';
import { AccountService, TitleService } from 'src/app/services';
import { TransactionStatus } from 'src/app/enum';

@Component({
  selector: 'payment-view',
  templateUrl: './payment.view.html',
  styleUrls: ['./payment.view.scss', '../../shared.scss']
})
export class UserPaymentViewComponent extends ViewBase {
  public titleFragments = [
    { key: 'Views.User.Payments.Overview.Title.MyPayments' },
    { key: 'Views.User.Payments.Overview.Title.Overview' }
  ];

  public transaction: IUserTransaction;

  constructor(
    _titleService: TitleService,
    private _accountService: AccountService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _msg: MessageDispatcher
  ) {
    super(_titleService);
    const { data } = this._route.snapshot.data || {};
    const { transaction } = data || {};
    this.transaction = transaction;
    console.log(this._route);
  }
}

export interface IUserTransaction {
  amount: number;
  status: TransactionStatus;
}
