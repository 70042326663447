export enum FilterType {
  Text = 1,
  Dropdown = 2,
  Date = 3
};

export enum TextValueFilterType {
  Contains = 1,
  GreaterThan = 2,
  LessThan = 3,
  Equals = 4,
  HasValue = 5,
  DoesNotHaveValue = 6
}

export enum DateValueFilterType {
  Equals = 1,
  GreaterThan = 2,
  LessThan = 3
}

export enum DropdownValueFilterType {
  Equals = 1,
  DoesNotEqual = 2
}

export enum DropdownFlagValueFilterType {
  Includes = 1,
  // DoesNotInclude = 2
}

(FilterType as any).__typeName = "FilterType";

(TextValueFilterType as any).__typeName = "TextValueFilterType";
const _text = 'Enums.TextValueFilterType';
(TextValueFilterType as any).__altTranslationKeys = {
    [TextValueFilterType.Contains]: _text + '.Contains',
    [TextValueFilterType.GreaterThan]: _text + '.GreaterThan',
    [TextValueFilterType.LessThan]: _text + '.LessThan',
    [TextValueFilterType.Equals]: _text + '.Equals',
    [TextValueFilterType.HasValue]: _text + '.HasValue',
    [TextValueFilterType.DoesNotHaveValue]: _text + '.DoesNotHaveValue',
};

(DropdownValueFilterType as any).__typeName = "DropdownValueFilterType";
const _dropdown = 'Enums.DropdownValueFilterType';
(DropdownValueFilterType as any).__altTranslationKeys = {
    [DropdownValueFilterType.Equals]: _dropdown + '.Equals',
    [DropdownValueFilterType.DoesNotEqual]: _dropdown + '.DoesNotEqual'
};

(DropdownFlagValueFilterType as any).__typeName = "DropdownFlagValueFilterType";
const _flagDropdown = 'Enums.DropdownFlagValueFilterType';
(DropdownFlagValueFilterType as any).__altTranslationKeys = {
    [DropdownFlagValueFilterType.Includes]: _flagDropdown + '.Includes',
    // [DropdownFlagValueFilterType.DoesNotInclude]: _flagDropdown + '.DoesNotInclude'
};

(DateValueFilterType as any).__typeName = "DateValueFilterType";
const _date = 'Enums.DateValueFilterType';
(DateValueFilterType as any).__altTranslationKeys = {
    [DateValueFilterType.Equals]: _date + '.Equals',
    [DateValueFilterType.GreaterThan]: _date + '.GreaterThan',
    [DateValueFilterType.LessThan]: _date + '.LessThan'
};