export enum GolfMarketTypeValue
{
    Winner = 35,
    TwoBall = 37,
    ThreeBall = 235,
    FourBall = 236,
    PlaceTop4 = 271,
    PlaceTop6 = 272,
    PlaceTop5 = 273,
    PlaceTop10 = 274,
    MissTheCut = 355,
    MatchplayDrawNoBet = 6676,
    TwoBallDrawNoBet = 6800,
    PlaceTop3 = 6846,
    EndOfRoundLeader = 6857,
    WinningMargin = 7022,
    Foursome = 7319,
    PlaceTop2 = 8309,
    Matchplay = 9067,
}

(GolfMarketTypeValue as any).__typeName = 'GolfMarketTypeValue';