import { Component } from "@angular/core";

@Component({
    selector: 'bank-login-dialog',
    host: { class: 'dialog' },
    templateUrl: './bank-login.dialog.html',
    styleUrls: ['./bank-login.dialog.scss']
})

export class BankLoginDialogComponent {

}