<div class="filter-wrapper">
    <!-- <span class="filter-icon"></span> -->
    <select (change)="onValueFilterChanged($event.target.value)" *ngIf="showOptions">
        <option *ngFor="let type of valueFilterTypeOptions" [value]="type.id">
            {{type.name | translate}}
        </option>
    </select>
</div>

<toto-select select
    (change)="onValueChanged($event?.id)"
    (clear)="onValueChanged(null)"
    class="select full-width"
    labelField="name"
    [searchable]="true"
    [options]="options"
    [clearable]="true"
    [formGroup]="form"
    [translate]="translate"
    [labelField]="labelField"
    [fullscreenLabel]="fullscreenLabel"
    [value]="value"
    for="filter">
</toto-select>

<!-- placeholder="components.select.placeholder" -->
