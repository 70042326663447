import { Pipe, PipeTransform } from '@angular/core';
import { SportType } from '../modules/sports/enum/SportType';
import { MARKET_TYPE_NAME_MAP } from '../modules/sports/enum/MarketType';
import { TranslateEnumPipe } from 'src/app/pipes';
@Pipe({
  name: 'filterOdds'
})
export class FilterOddsPipe implements PipeTransform {
  transform(value: any[], key = 'odds'): any[]{
    const filteredValue = value.filter(x => x[key] > 1);
    return filteredValue;
  }
}
