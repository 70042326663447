<div [formGroup]="formGroup">
  <ng-select
    class="language-select {{color}}"
    [items]="languageOptions"
    formControlName="language"
    [clearable]="false"
    [searchable]="false"
  >
    <ng-template ng-label-tmp let-item="item">
      <div class="flex flex-row align-center justify-center">
        <span [class]="getLanguageIcon(item)" *ngIf="showFlag"></span>
        <span class="ml5" *ngIf="showName">{{item.name}}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <div class="flex flex-row align-center justify-center">
        <span [class]="getLanguageIcon(item)" *ngIf="showFlag"></span>
        <span class="ml5" *ngIf="showName">{{item.name}}</span>
      </div>
    </ng-template>
  </ng-select>
</div>
