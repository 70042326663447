export enum MatchResultOutcome {
  Home = 1,
  Draw = 2,
  Away = 3,

  Handicap_Home = 4,
  Handicap_Away = 5,
  Handicap_Tie = 6,

  HomeOrDraw = 7,
  HomeOrAway = 8,
  DrawOrAway = 9,

  HalfTimeFullTime_HomeHome = 10,
  HalfTimeFullTime_HomeDraw = 11,
  HalfTimeFullTime_HomeAway = 12,
  HalfTimeFullTime_DrawHome = 13,
  HalfTimeFullTime_DrawDraw = 14,
  HalfTimeFullTime_DrawAway = 15,
  HalfTimeFullTime_AwayHome = 16,
  HalfTimeFullTime_AwayDraw = 17,
  HalfTimeFullTime_AwayAway = 18,

  NoCards = 19,
  NoGoal = 21,

  NoGoalScorer = 24,

  TwoPlayer_Player1 = 25,

  TwoPlayer_Player2 = 26,

  HighestScoringHalf_Draw = 27,
  HighestScoringHalf_FirstHalf = 28,
  HighestScoringHalf_SecondHalf = 29,

  Over = 30,
  Under = 31,

  Even = 32,
  Odd = 33,
  NoCorner = 34,

  // "Which Team to Score"
  Both = 35,
  OnlyHome = 36,
  OnlyAway = 37,
  Neither = 38,

  Yes = 39,
  No = 40,

  Tie = 41,

  FirstQuarter = 44,
  SecondQuarter = 45,
  ThirdQuarter = 46,
  FourthQuarter = 47,

  FirstPeriod = 57,
  SecondPeriod = 58,
  ThirdPeriod = 59,

  HomeGoal = 60,
  HomeBehind = 61,
  AwayGoal = 62,
  AwayBehind = 63,

  AnyOther = 64,

  ThreePlayer_Player1 = 65,
  ThreePlayer_Player2 = 66,
  ThreePlayer_Player3 = 67,

  NoTryScorer = 73,
  PenaltyTry = 74,

  HomeIn90Minutes = 75,
  HomeInExtraTime = 76,
  HomeOnPenalties = 77,
  AwayIn90Minutes = 78,
  AwayInExtraTime = 79,
  AwayOnPenalties = 80,

  NoTeamToReachTarget = 83,

  CompetitorOneAnyOtherScore = 93,
  CompetitorTwoAnyOtherScore = 94,

  NoTry = 101,

  HomeAndOver = 102,
  HomeAndUnder = 103,
  DrawAndOver = 104,
  DrawAndUnder = 105,
  AwayAndOver = 106,
  AwayAndUnder = 107,

  HomeAndYes = 108,
  HomeAndNo = 109,
  DrawAndYes = 110,
  DrawAndNo = 111,
  AwayAndYes = 112,
  AwayAndNo = 113,

  HomePoint = 114,
  AwayPoint = 115,

  Player1ByKO = 116,
  Player1ByDecision = 117,
  Player2ByKO = 118,
  Player2ByDecision = 119,

  Yellow = 120,
  Green = 121,
  Brown = 122,
  Blue = 123,
  Pink = 124,
  Black = 125,

  _20Or10 = 126,
  _16Or8 = 127,
  Treble20 = 128,
  Single20 = 129,

  Player1OrPlayer2 = 130,
  Player1OrDraw = 131,
  Player2OrDraw = 132,

  Red = 133,

  Try = 134,
  Penalty = 135,
  DropGoal = 136,

  Caught = 137,

  HomeOrDrawAndYes = 139,
  HomeOrAwayAndYes = 140,
  DrawOrAwayAndYes = 141,
  HomeOrDrawAndNo = 142,
  HomeOrAwayAndNo = 143,
  DrawOrAwayAndNo = 144,

  HomeOrDrawAndOver = 145,
  HomeOrAwayAndOver = 146,
  DrawOrAwayAndOver = 147,
  HomeOrDrawAndUnder = 148,
  HomeOrAwayAndUnder = 149,
  DrawOrAwayAndUnder = 150,

  HomeWinAndHomeToScoreFirst = 151,
  DrawAndHomeToScoreFirst = 152,
  AwayWinAndHomeToScoreFirst = 153,
  HomeWinAndAwayToScoreFirst = 154,
  DrawAndAwayToScoreFirst = 155,
  AwayWinAndAwayToScoreFirst = 156,
  DrawAndNeitherTeamToScore = 157,

  RegularTime = 158,
  OverTime = 159,
  Shootout = 160,

  CompetitorOneAndOver = 161,
  CompetitorOneAndUnder = 162,
  CompetitorTwoAndOver = 163,
  CompetitorTwoAndUnder = 164,

  OffensiveTouchdown = 169,

  YesAndOver = 172,
  YesAndUnder = 173,
  NoAndOver = 174,
  NoAndUnder = 175,

  OffensiveFieldGoal = 176,
  Rush = 177,
  Pass = 178,

  // toto outcomes

  DrawValueRange = 10000001,
  HomeValueRange = 10000002,
  AwayValueRange = 10000003,
  Number = 10000004,
  ValueRange = 10000005,
  Competitor = 10000006
}

(MatchResultOutcome as any).__typeName = 'MatchResultOutcome';
