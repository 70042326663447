import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AccountService, AuthenticationService, IUserLogReq, ResultsService } from 'src/app/services';
import { Observable, of } from 'rxjs';
import { DateHandler } from '../helpers';
import { DatePeriod } from '../enum';
import { AppState, TotoAppModule } from '../app.state';
import { Location } from '@angular/common';

@Injectable()
export class UserInfoResolve implements Resolve<any> {

  constructor(private _accountService: AccountService, private _appState: AppState) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    if (this._appState.isLoggedIn) {
      return this._accountService.getAccountInfo();
    }
    return of({});
  }
}

@Injectable()
export class UserContactInfoResolve implements Resolve<any> {

  constructor(private _accountService: AccountService, private _authService: AuthenticationService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._accountService.getContactInfo();
  }
}

@Injectable()
export class UserSafeBettingResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    return this._accountService.getSafeBettingSettings();
  }
}


@Injectable()
export class UserBonusesResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    return this._accountService.getBonusSummary();
  }
}

@Injectable()
export class TransactionStatusResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._accountService.getTransactionStatus(route.paramMap.get('transactionId'));
  }
}

@Injectable()
export class SafeBettingResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(): Observable<any> {
    return this._accountService.getSafeBettingSettings();
  }
}

@Injectable()
export class HorsebettingTicketsResolve implements Resolve<any> {

  constructor(private _accountService: AccountService, private _appState: AppState) { }


  resolve(route: ActivatedRouteSnapshot) {
      const req = {
        from: "",
        to: "",
        searchKey: null
      }
      return this._accountService.getUserHorseRacingTickets(req);
  }
}

@Injectable()
export class SportsTicketsResolve implements Resolve<any> {

  constructor(private _accountService: AccountService, private _appState: AppState, private _dateHandler: DateHandler) { }

  resolve(route: ActivatedRouteSnapshot) {
    const req = {};

    const todayFilter = this._dateHandler.getPeriodOdataFilter(DatePeriod.Today, 'settledAtUtc');

    const params = {
      $filter: todayFilter
    }

    return this._accountService.getUserSportsTickets(req, params);
  }
}

@Injectable()
export class SportsActiveTicketsResolve implements Resolve<any> {

  constructor(private _accountService: AccountService, private _appState: AppState) { }

  resolve(route: ActivatedRouteSnapshot) {
    const params = {
      // $filter: `status eq ${SportTicketStatus.Active}`
    };

    return this._accountService.getUserActiveSportsTickets(params);
  }
}

@Injectable()
export class TransactionLogResolve implements Resolve<any> {

  constructor(private _accountService: AccountService, private _dateHandler: DateHandler) { }

  resolve(route: ActivatedRouteSnapshot) {

    const period = this._dateHandler.getPeriod(DatePeriod.Today);


    // const from = period.begin?.clone().format('yyyy-MM-DD').toString();
    // const to = period.end?.clone().add(1, 'days')?.format('yyyy-MM-DD').toString();

    const req: IUserLogReq = {
      from: period.begin,
      to: period.end,
      searchKey: null
    }

    return this._accountService.getUserTransactions(req);
  }
}

@Injectable()
export class UserDepositTransactionResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    const { paymentId, paymentStatus } = route.params || {};

    if (paymentStatus === 'fail') {
      return of({});
    }

    if (paymentId) {
      return this._accountService.getTransactionById(paymentId);
    }

  }
}

@Injectable()
export class UserBalanceResolve implements Resolve<any> {

  constructor(
    private _accountService: AccountService,
    private _location: Location
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
      var state = this._location.getState() as any;
      return this._accountService.getUserBalance(state.transactionId);
  }
}

@Injectable()
export class UserWithdrawalInfoResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
      return this._accountService.getUserWithdrawalDetails();
  }
}


@Injectable()
export class UserPreferencesResolve implements Resolve<any> {

  constructor(private _accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    return this._accountService.getUserPreferences();
  }
}


@Injectable()
export class UserDepositResolve implements Resolve<any> {
  constructor(private _accountService: AccountService) { }
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this._accountService.getAvailablePayInCampaigns();
  }
}