export enum HockeyMarketTypeValue
{
    MatchWinner = 6818,
    TotalGoalsOverUnder = 8996,
    HandicapBetting = 10495,
    HomeTeamTotalPointsOverUnder = 12709,
    AwayTeamTotalPointsOverUnder = 12710,
    HomeTeamTotalPointsOddEven = 12711,
    AwayTeamTotalPointsOddEven = 12712,
    TeamToScoreNextGoal = 12713,
    FirstHalfResult = 12714,
    SecondHalfResult = 12715,
    FirstQuarterResult = 12716,
    SecondQuarterResult = 12717,
    ThirdQuarterResult = 12718,
    FourthQuarterResult = 12719,
    FirstQuarterHandicap = 12720,
    SecondQuarterHandicap = 12721,
    ThirdQuarterHandicap = 12722,
    FourthQuarterHandicap = 12723,
    FirstQuarterTotalsOverUnder = 12724,
    SecondQuarterTotalsOverUnder = 12725,
    ThirdQuarterTotalsOverUnder = 12726,
    FourthQuarterTotalsOverUnder = 12727,
    FirstQuarterHomeTeamTotalsOverUnder = 12728,
    SecondQuarterHomeTeamTotalsOverUnder = 12729,
    ThirdQuarterHomeTeamTotalsOverUnder = 12730,
    FourthQuarterHomeTeamTotalsOverUnder = 12731,
    FirstQuarterAwayTeamTotalsOverUnder = 12734,
    SecondQuarterAwayTeamTotalsOverUnder = 12735,
    ThirdQuarterAwayTeamTotalsOverUnder = 12736,
    FourthQuarterAwayTeamTotalsOverUnder = 12737,
    FirstQuarterTotalsOddEven = 12738,
    SecondQuarterTotalsOddEven = 12739,
    ThirdQuarterTotalsOddEven = 12740,
    FourthQuarterTotalsOddEven = 12741,
    FirstHalfHandicap = 12742,
    SecondHalfHandicap = 12743,
}

(HockeyMarketTypeValue as any).__typeName = 'HockeyMarketTypeValue';