import { Component } from "@angular/core";
import { AppState } from "src/app/app.state";

@Component({
    selector: 'pending-tickets',
    templateUrl: './pending-tickets.component.html',
    styleUrls: ['./pending-tickets.component.scss']
  })
  export class PendingTicketsComponent {
  
    constructor(private _appState: AppState) {}
  
    public get hasPendingTickets() {
      return this._appState.userPendingTickets?.pendingTicketesCount > 0;
    }
  
    public get ticketsWaitingResults() {
      return this._appState.userPendingTickets?.ticketsWaitingResultsCount;
    }
  
  }
  