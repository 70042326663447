export enum SnookerMarketTypeValue
{
    MatchHandicap = 191,
    FirstFrameWinner = 203,
    First4Frames = 204,
    First4FramesScore = 205,
    TotalFramesOverUnder = 275,
    First8Frames = 276,
    First8FramesScore = 283,
    MatchWinner = 6672,
    MatchResultWithDraw = 6678,
    FrameBetting = 6679,
    FirstFrameHandicap = 6686,
    DoubleResult = 6702,
    HandicapWithDraw = 6979,
    NextFrameWinner = 7487,
    NextFrame100Break = 7654,
    NextFrame50Break = 7656,
    PotFirstBallNextFrame = 7667,
    NextFrameTotalPointsOverUnder = 7670,
    FirstColourPottedNextFrame = 9647,
    DrawNoBet = 9672,
    NextFrameTotalPointsOddEven = 11972,
    NextFrameRaceTo30 = 12803,
    CurrentFrameRaceTo30 = 12804,
    CurrentFrameTotalPointsOverUnder = 12805,
    CurrentFrame50Break = 12806,
    CurrentFrame100Break = 12807,
    NextFrameHighestBreak = 12808,
    NextFrameCompetitorA50Break = 12809,
    NextFrameCompetitorA100Break = 12810,
    NextFrameCompetitorB50Break = 12811,
    NextFrameCompetitorB100Break = 12812,
    CurrentFrameCompetitorA50Break = 12813,
    CurrentFrameCompetitorA100Break = 12814,
    CurrentFrameCompetitorB50Break = 12815,
    CurrentFrameCompetitorB100Break = 12816,
}

(SnookerMarketTypeValue as any).__typeName = 'SnookerMarketTypeValue';