import { Component, Input, Output } from "@angular/core";
import { DataTableFilter } from "../data-table-filter";
import { FilterType, asArray } from "src/app/enum";
import { FormGroup, FormControl } from "@angular/forms";
import { DateValueFilterType, TextValueFilterType } from "src/app/enum/FilterType";
import moment from "moment";

@Component({
  selector: "date-filter",
  templateUrl: "./date-data-table-filter.component.html",
  styleUrls: [ "./date-data-table-filter.component.scss", "../shared.scss" ]
})
export class DateDataTableFilterComponent extends DataTableFilter {
  @Output()
  public value: moment.Moment = null;

  @Output()
  public valueFilterType: DateValueFilterType;

  @Input()
  public showOptions = true;

  public form: FormGroup = new FormGroup({ filter: new FormControl() });

  public skipFilterOptions: DateValueFilterType[];

  public TextValueFilterType = DateValueFilterType;

  public valueFilterTypeOptions: any[];

  ngOnInit() {
    this.valueFilterTypeOptions = asArray(DateValueFilterType);
    this.valueFilterType = this.valueFilterTypeOptions[0]?.id;
  }

  public getFilterType(): FilterType {
    return FilterType.Date;
  }

  public getValueFilterType(): DateValueFilterType {
    return this.valueFilterType;
  }

  public getValue(): any {
    return this.value?.format('YYYY-MM-DD') ?? null;
  }

  public onValueChanged(val: moment.Moment): void {
    this.value = val;
    super.onValueChanged(val);
  }

  public onValueFilterChanged(val: any): void {
    this.valueFilterType = parseInt(val, 10);
    if (this.value === null || this.value === undefined) {
      return
    }
    this.onValueChanged(this.value);
  }
}
