import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { currentLanguage } from './locale.service';
import { KnownPageSection } from '../enum/KnownPageSection';
import { TranslationsSite } from '../enum/TranslationsSite';


@Injectable({ providedIn: 'root' })
export class PagesService {

  constructor(private _http: HttpClient) {};

  public getPages(area: TranslationsSite): Observable<any> {
    return this._http.get<any>(`api/pages`, { params: {area: area }}) as Observable<any>;
  }

  public getPageById(pageId): Observable<any> {
    return this._http.get<any>(`api/pages/${pageId}`) as Observable<any>;
  }

}
