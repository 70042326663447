export enum DatePeriod {
    All = 0,
    Today = 1,
    Yesterday = 2,
    ThisWeek = 3,
    LastWeek = 4,
    ThisMonth = 5,
    LastMonth = 6,
    Custom = 999
}

(DatePeriod as any).__typeName = 'PeriodPreset';

const _ = 'Enums.PeriodPreset.';
(DatePeriod as any).__altTranslationKeys = {
    [DatePeriod.All]: _ + 'All',
    [DatePeriod.Today]: _ + 'Today',
    [DatePeriod.Yesterday]: _ + 'Yesterday',
    [DatePeriod.ThisWeek]: _ + 'Current week',
    [DatePeriod.LastWeek]: _ + 'Last week',
    [DatePeriod.ThisMonth]: _ + 'Current Month',
    [DatePeriod.LastMonth]: _ + 'Last Month',
};
