import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService, AuthenticationService, LocaleService } from '../services';
import { AppState } from '../app.state';
import { DEFAULT_LANGUAGE } from '../app.settings';

@Injectable({ providedIn: 'root' })
export class UserInfoGuard implements CanActivate {

  constructor(private _state: AppState, private _accountService: AccountService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const balance = this._state.userBalance;

    if (!this._state.isLoggedIn || balance !== null) {
      return true;
    }

    return new Promise<boolean>((resolve, reject) => { 
      this._accountService.getAccountInfo().toPromise().then(res => {
        this._state.setAccountInfo(res);
        resolve(true);
      }).catch(err => { 
        console.error(err);
        resolve(true);
      });
    });
  }
}
