<card>
    <ng-container title>
        <ng-container *ngIf="parentTitle">
            <span>{{parentTitle}}</span>
            <span>
                »
            </span>
        </ng-container>
        <span>
            {{title}}
        </span>
    </ng-container>
    <div class="page-content"
        [innerHTML]="content">
    </div>
</card>
