import { Component, Input, Output } from "@angular/core";
import { DataTableFilter } from "../data-table-filter";
import { FilterType, TextValueFilterType } from "src/app/enum/FilterType";
import { asArray } from "src/app/enum";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "text-filter",
  templateUrl: "./text-data-table-filter.component.html",
  styleUrls: [ "./text-data-table-filter.component.scss", "../shared.scss" ]
})
export class TextDataTableFilterComponent extends DataTableFilter {
  
  @Output()
  public value: string = null;

  @Output()
  public valueFilterType: TextValueFilterType;

  @Input()
  public showOptions = true;

  @Input() public isNumber = false;

  public form: FormGroup = new FormGroup({ filter: new FormControl() });

  public skipFilterOptions: TextValueFilterType[];

  public TextValueFilterType = TextValueFilterType;

  public valueFilterTypeOptions: any[];

  public fromArray: boolean = false;
  public fromArrayKey: string;

  ngOnInit() {
    let options = asArray(TextValueFilterType);
    let filteredOptions = options;

    if (this.skipFilterOptions) {
      filteredOptions = options.filter(x => !this.skipFilterOptions.includes(x.id));
      this.valueFilterTypeOptions = filteredOptions;
      this.valueFilterType = filteredOptions[0]?.id;
      return;
    }

    if (!this.isNumber) {
      filteredOptions = options.filter(x => x.id === TextValueFilterType.Contains || x.id === TextValueFilterType.HasValue || x.id === TextValueFilterType.DoesNotHaveValue);
    } else {
      filteredOptions = options.filter(x => x.id !== TextValueFilterType.Contains);
    }


    this.valueFilterTypeOptions = filteredOptions;
    this.valueFilterType = filteredOptions[0]?.id;
  }

  public getFilterType(): FilterType {
    return FilterType.Text;
  }

  public getValue(): string {
    return this.value;
  }

  public getValueFilterType(): TextValueFilterType {
    return this.valueFilterType;
  }

  public onValueChanged(val: string): void {
    this.value = val;
    super.onValueChanged(val);
  }

  public onValueFilterChanged(val: any): void {
    this.valueFilterType = parseInt(val, 10);
    console.log(val, this.value);
    // if (this.value === null || this.value === undefined || this.value === '') {
    //   return
    // }
    this.onValueChanged(this.value);
  }

}
