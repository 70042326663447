declare global {
  interface Window {
    __totoAppSettings: {
      production: boolean,
      apiUrl: string,
      ablyKey: string,
      GTMKey: string,
      depositBannerId: number
    };
    __TOTO_DEV_SETTINGS?: {
      SHOW_DEV_TOOLS: boolean,
      SHOW_EXPERIMENTAL_PAYMENTS: boolean,
      SHOW_HIDDEN_MARKETS: boolean, 
      LOG_REALTIME_CHANGES: boolean
    };
    posthog: any;
    posthogIdentified: any;
  }
}

export const environment = window.__totoAppSettings;