<form [formGroup]="formGroup" (ngSubmit)="deposit()">
  <div class="form-title">
    <h2>{{'Views.User.Payments.Deposit.Title.Deposit' | translate}}</h2>
    <h5>{{'Views.User.Payments.Deposit.Title.MyPayments' | translate}}</h5>
  </div>

  <div class="form-body" [ngSwitch]="state.isUserVerified">
    <ng-container *ngSwitchCase="false">
      <user-not-authenticated-message
        title="Views.User.Payments.Deposit.AccountNotVerified.Title"
        text="Views.User.Payments.Deposit.AccountNotVerified.Text"
      ></user-not-authenticated-message>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <section>
        <h4>
          {{'Views.User.Payments.Deposit.Title.ChooseDepositMethod' |
          translate}}
        </h4>

        <!-- Switch added for the option of deposit country -->
        <div class="deposit-country" *ngIf="showExperimentalPayments">
          <div class="deposit estonia">
            <label class="custom" for="estonia">
              <input
              type="radio"
              id="estonia"
              name="deposit-country"
              value="estonia"
              (click)="onSelectEstonianDeposit()"
              [checked]="!isChecked"
            />
            {{'Action.Estonia' | translate}}</label>
          </div>

          <div class="deposit international">
            <label class="custom" for="international"
              >
              <input
              type="radio"
              id="international"
              name="deposit-country"
              value="international"
              (click)="onSelectInternationalDeposit()"
              [checked]="isChecked"
            />
              {{'Action.International' | translate}}</label
            >
          </div>
        </div>

        <div [ngSwitch]="viewTab" *ngIf="showExperimentalPayments">
          <div *ngSwitchCase="DepositProvidersViewTab.Estonian">
            <providers
              (onProviderSelect)="onProviderSelect($event)"
              [(selectedProvider)]="selectedProvider"
              [providerOptions]="estonianProvidersList"
            ></providers>
          </div>

          <div *ngSwitchCase="DepositProvidersViewTab.International">
            <providers
              (onProviderSelect)="onProviderSelect($event)"
              [(selectedProvider)]="selectedProvider"
              [providerOptions]="internationalProvidersList"
            ></providers>
          </div>
        </div>

        <providers
          (onProviderSelect)="onProviderSelect($event)"
          [(selectedProvider)]="selectedProvider"
          [providerOptions]="providerOptions"
          *ngIf="!showExperimentalPayments"
        ></providers>
      </section>

      <section *ngIf="availableCampaigns.length">
        <h4>{{ 'Views.User.Payments.Deposit.Title.UseBonus' | translate }}</h4>
        <div class="disclaimer">
          {{ 'Views.User.Payments.Deposit.Title.UseBonusBeforePayIn' | translate
          }}
        </div>
        <div *ngFor="let campaign of availableCampaigns">
          <toto-campaign
            [campaign]="campaign"
            [showDetailWhenNotActivated]="true"
            [showBonuses]="false"
          ></toto-campaign>
        </div>
      </section>

      <section [ngSwitch]="selectedProvider" class="mt20">
        <ng-container *ngSwitchDefault>
          <h3>
            {{'Views.User.Payments.Deposit.Title.ChooseAmount' | translate}}
          </h3>
          <payment-amount for="amount"></payment-amount>
          <div class="mt40 mb40">
            <toto-button
              label="Action.Continue"
              color="theme-1"
              type="submit"
              [disabled]="!canContinue"
              [loading]="actionLoading.deposit"
            ></toto-button>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="BankLinkProvider.BankTransfer">
          <h3>
            {{'Views.User.Payments.Deposit.Title.BankTransfer' | translate}}
          </h3>
          <div class="txt">{{'Components.PaymentAmount.BankTransferSuccess' | translate}}</div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <form-field
                label="{{'Views.User.Payments.Deposit.Title.ReceiverName' | translate}}"
                [labelTop]="true"
              >
                AS Totalisaator
              </form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <form-field
                label="{{'Views.User.Payments.Deposit.Title.IBAN' | translate}}"
                [labelTop]="true"
              >
                EE632200221006160260
              </form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <form-field
                label="{{'Views.User.Payments.Deposit.Title.BankName' | translate}}"
                [labelTop]="true"
              >
                Swedbank AS
              </form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <form-field
                label="{{'Views.User.Payments.Deposit.Title.BankAddress' | translate}}"
                [labelTop]="true"
              >
                Liivalaia 8, 15040 Tallinn
              </form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <form-field
                label="{{'Views.User.Payments.Deposit.Title.Swift' | translate}}"
                [labelTop]="true"
              >
                HABAEE2X
              </form-field>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <form-field
                label="{{'Views.User.Payments.Deposit.Title.Reference' | translate}}"
                [labelTop]="true"
              >
                {{'Views.User.Payments.Deposit.Title.AccountUsername' |
                translate}}
              </form-field>
            </div>
          </div>
        </ng-container>
      </section>

      <form [method]="banklink?.method" [action]="banklink?.url" #banklinkForm>
        <input
          *ngFor="let field of banklink?.data | keyvalue"
          type="hidden"
          [name]="field.key"
          [value]="field.value"
        />
      </form>
    </ng-container>
  </div>
</form>

<div class="trustlyOverlay" id="trustlyOverlay" style="display:none">
  <iframe name="trustlyFrame" id="trustlyFrame" class="trustlyFrame"></iframe>
</div>
