import { Injectable } from '@angular/core';
import * as Ably from 'ably';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AblyService } from 'src/app/services/ably.service';
import { toNumber } from 'src/app/util/number-util';

@Injectable({ providedIn: 'root' })
export class RealtimeState {

  public CHANNEL = {
    markets: 'markets',
    liveEvents: 'liveEvents',
    user: 'user'
  };

  public marketsChangeSubject$ = new Subject();
  public marketsChannel: Ably.Types.RealtimeChannelCallbacks | undefined;
  public marketsUserChannel: Ably.Types.RealtimeChannelCallbacks | undefined;
  public marketSubjects: { [key: string]: Subject<any> } = {};


  public liveEventsChangeSubject$ = new Subject();
  public liveEventsChannel: Ably.Types.RealtimeChannelCallbacks | undefined;
  public liveEventsUserChannel: Ably.Types.RealtimeChannelCallbacks | undefined;
  public liveEventsSubjects: { [key: string]: Subject<any> } = {};

  constructor(private _ablyService: AblyService) {}

  // DEV
  public get logChanges() {
    return window.__TOTO_DEV_SETTINGS?.LOG_REALTIME_CHANGES
  }

  public message = {
    initializing: (channel: string) => `Initializing channel ${channel}`,
    channelEntered: (channel: string) => `Entering channel ${channel}`,
    clientEnteredChannel: (channel: string, clientId: string) => `Client ${clientId} entered channel ${channel}`,
    updating: (channel: string) => `Updating channel ${channel}`,
    leaving: (channel: string) => `Leaving channel ${channel}`,
    presenceUpdated: (channel: string) => `Channel ${channel} presence updated: `,
    presenceUpdateFailed: (channel: string) => `Channel ${channel} presence update failed: `,
    notInitialized: (channel: string) => `Channel ${channel} has not been initialized`
  }

  public logMessage = {
    initializing: (channel: string) => this.logChanges ? console.log(this.message.initializing(channel)) : undefined,
    channelEntered: (channel: string) => this.logChanges ? console.log(this.message.channelEntered(channel)) : undefined,
    clientEnteredChannel: (channel: string, clientId: string) => this.logChanges ? console.log(this.message.clientEnteredChannel(channel, clientId)) : undefined,
    updating: (channel: string) => this.logChanges ? console.log(this.message.updating(channel)) : undefined,
    leaving: (channel: string) => this.logChanges ? console.log(this.message.leaving(channel)) : undefined,
    presenceUpdated: (channel: string, ids: number[]) => this.logChanges ? console.log(this.message.presenceUpdated(channel), ids) : undefined,
    presenceUpdateFailed: (channel: string, ids: number[]) => this.logChanges ? console.log(this.message.presenceUpdateFailed(channel), ids) : undefined,
    notInitialized: (channel: string) => this.logChanges ? console.log(this.message.notInitialized(channel)) : undefined
  }

  // USER SECTION

  public userBalanceSubject$ = new Subject();
  public userBetStatusSubject$ = new Subject();

  public activeSportsBetsCountSubject$ = new Subject();
  public favouriteSportEventsCountSubject$ = new Subject();

  public userChannel: Ably.Types.RealtimeChannelCallbacks | undefined;

  public initUserChannel(userId: any): void {
    if (!userId) {
      throw('User ID is required to initialize user channel');
    }
    const channel = `${this.CHANNEL.user}:${userId}`;
    this.logMessage.initializing(channel);
    this.userChannel = this._ablyService.getChannel(channel);
    this.userChannel.presence.subscribe('enter', member => { 
      this.logMessage.clientEnteredChannel(channel, member.clientId);
    });
    this._subscribeUserChanges();
  }

  private _subscribeUserChanges() {
    this.userChannel.subscribe(message => {
      console.log(message);

      if (message.name == "balance") {
        this.userBalanceSubject$.next(message.data);
      }
      else if (message.name == "betStatus") {
        this.userBetStatusSubject$.next(message.data);
      } else if (message.name == "activeSportsBetsCount") {
        this.activeSportsBetsCountSubject$.next(message.data);
      }
    });
  }

  // MARKETS SECTION

  public getMarketSubject(selectionId): Subject<any> {
    return this.marketSubjects[selectionId] ?? null;
  }

  public addMarketSubject(selectionId): Subject<any> {
    if (!this.marketSubjects[selectionId]) {
      const changeSubject$ = new Subject();
      this.marketSubjects[selectionId] = changeSubject$;
    }
    return this.getMarketSubject(selectionId);
  }

  public clearMarketSubjects(): void {
    this.marketSubjects = {};
  }

  public removeMarketSubject(selectionId, force = false): void {
    const ref: Subject<any> = this.marketSubjects[selectionId];
    if (ref) {
      if (force || ref.observers.length < 2) {
        delete this.marketSubjects[selectionId];
      }
    }
  }

  public get currentMarketIds(): number[] {
    return Object.keys(this.marketSubjects).map(x => Number(x));
  }

  private _subscribeMarketChanges(): void {
    this.marketsUserChannel.subscribe(message => {

      const relevantOdds = message.data.filter(x => this.marketSubjects[x[0]] ? true : false);
      // if (this.dev) {
      //   console.log(message, relevantOdds, Object.keys(this.marketSubjects)?.length);
      // }

      relevantOdds.forEach(market => {
        const id = market[0];
        if (this.marketSubjects[id]) {
          this.marketSubjects[id].next(market);
        }
      });
    })
  }

  public initMarketsUserChannel(): void { 
    this.marketsUserChannel = this._ablyService.getUserChannel(this.CHANNEL.markets);
    this._subscribeMarketChanges();
  }

  public initMarketsChannel(): void {
    this.logMessage.initializing(this.CHANNEL.markets);
    this.marketsChannel = this._ablyService.getChannel(this.CHANNEL.markets);
    this.marketsChannel.presence.subscribe('enter', member => { 
      this.logMessage.clientEnteredChannel(this.CHANNEL.markets, member.clientId);
    });
    this.enterMarketsChannel();
  }

  public enterMarketsChannel() {
    this.marketsChannel.presence.enter(this.currentMarketIds, res => {
      this.logMessage.channelEntered(this.CHANNEL.markets);
    });
  }

  public initMarketsChannels(): void {
    if (!this.marketsUserChannel) {
      this.initMarketsUserChannel();
    }
    if (!this.marketsChannel) {
      this.initMarketsChannel();
    }
  }

  public updateMarketsChannel(): void {

    this.initMarketsChannels();

    this.logMessage.updating(this.CHANNEL.markets);
    if (this.marketsChannel === undefined || this.marketsUserChannel === undefined) {
      throw(this.message.notInitialized(this.CHANNEL.markets))
    }

    this.marketsChannel.presence.update(this.currentMarketIds, res => {
      if (!res) {
        this.logMessage.presenceUpdated(this.CHANNEL.markets, this.currentMarketIds);

      } else {
        this.logMessage.presenceUpdateFailed(this.CHANNEL.markets, this.currentMarketIds);
      }
    });
  }

  public leaveMarketsUserChannel(): void {
    this.logMessage.leaving(this.CHANNEL.markets);
    this.marketsUserChannel.presence.leave();
  }

  public detachMarketsChannel(): void {
    this.marketsChannel.detach();
  }

  // END MARKETS SECTION

  // LIVE EVENTS SECTION

  public getLiveEventSubject(selectionId): Subject<any> {
    return this.liveEventsSubjects[selectionId] ?? null;
  }

  public addLiveEventSubject(selectionId): Subject<any> {
    if (!this.liveEventsSubjects[selectionId]) {
      const changeSubject$ = new Subject();
      Object.assign(this.liveEventsSubjects, {[selectionId]: changeSubject$});
    }
    return this.getLiveEventSubject(selectionId);
  }

  public clearLiveEventSubjects(): void {
    this.liveEventsSubjects = {};
  }

  public removeLiveEventSubject(selectionId, force = false): void {
    const ref: Subject<any> = this.liveEventsSubjects[selectionId];
    if (ref) {
      if (force || ref.observers.length < 2) {
        delete this.liveEventsSubjects[selectionId];
      }
    }
  }

  public get currentLiveEventIds(): number[] {
    return Object.keys(this.liveEventsSubjects).map(x => Number(x));
  }

  private _subscribeLiveEventsChanges(): void {
    this.liveEventsUserChannel.subscribe(message => {
      const id = message.data[0];
      if (this.liveEventsSubjects[id]) {
        this.liveEventsSubjects[id].next(message.data);
      }

      if (this.logChanges) {
        console.log(message, Object.keys(this.liveEventsSubjects)?.length);
      }
    })
  }

  public initLiveEventsChannel(): void {
    this.logMessage.initializing(this.CHANNEL.liveEvents);
    this.liveEventsChannel = this._ablyService.getChannel(this.CHANNEL.liveEvents);
    this.liveEventsChannel.presence.subscribe('enter', member => this.logMessage.clientEnteredChannel(this.CHANNEL.liveEvents, member.clientId));
    this.enterLiveEventsChannel();
  }

  public initLiveEventsUserChannel(): void { 
    this.liveEventsUserChannel = this._ablyService.getUserChannel(this.CHANNEL.liveEvents);
    this._subscribeLiveEventsChanges();
  }

  public enterLiveEventsChannel() {
    this.liveEventsChannel.presence.enter(this.currentLiveEventIds, res => {
      this.logMessage.channelEntered(this.CHANNEL.liveEvents);
    });
  }

  public initLiveEventsChannels(): void {
    if (!this.liveEventsUserChannel) {
      this.initLiveEventsUserChannel();
    }
    if (!this.liveEventsChannel) {
      this.initLiveEventsChannel();
    }
  }

  public updateLiveEventsChannel(): void {

    this.initLiveEventsChannels();

    this.logMessage.updating(this.CHANNEL.liveEvents);
    if (this.liveEventsChannel === undefined || this.liveEventsUserChannel === undefined) {
      throw(this.message.notInitialized(this.CHANNEL.liveEvents));
    }
    
    this.liveEventsChannel.presence.update(this.currentLiveEventIds, res => {
      if (!res) {
        this.logMessage.presenceUpdated(this.CHANNEL.liveEvents, this.currentLiveEventIds);
      } else {
        this.logMessage.presenceUpdateFailed(this.CHANNEL.liveEvents, this.currentLiveEventIds);
      }
    });
  }

  public leaveLiveEventsUserChannel(): void {
    this.logMessage.leaving(this.CHANNEL.liveEvents);
    this.liveEventsChannel.presence.leave();
  }

  public detachLiveEventsChannel(): void {
    this.liveEventsChannel.detach();
  }

  // END LIVEBET SECTION

}

// export type MarketSubject = Array<[number, Subject<any>]>;
