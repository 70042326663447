export enum SportType {
    AustralianRules = 2,
    Baseball = 3,
    Basketball = 4,
    Boxing = 5,
    Cricket = 6,
    Cycling = 7,
    Darts = 8,
    Football = 10,
    Golf = 12,
    IceHockey = 15,
    MotorSport = 16,
    AmericanFootball = 17,
    Snooker = 22,
    Tennis = 24,
    Bowls = 8554,
    RugbyLeague = 73743,
    RugbyUnion = 73744,
    Volleyball = 91189,
    Handball = 99614,
    Hockey = 208627,
    TableTennis = 269467,
    MartialArts = 300115,
    Futsal = 491393,
    GaelicFootball = 6463040,
    Hurling = 6463041,
    BeachVolleyball = 7950337,
    eSports = 10915624,
    Basketball3x3 = 11205863,
    Badminton = 271554,
    Pickleball = 14103274,
    Squash = 437727,
    WaterPolo = 276032
}
  
(SportType as any).__typeName = 'SportType';

export const SportTypeNames = {
    [SportType.AustralianRules]: 'australian-rules',
    [SportType.Baseball]: 'baseball',
    [SportType.Basketball]: 'basketball',
    [SportType.Boxing]: 'boxing',
    [SportType.Cricket]: 'cricket',
    [SportType.Cycling]: 'cycling',
    [SportType.Darts]: 'darts',
    [SportType.Football]: 'football',
    [SportType.Golf]: 'golf',
    [SportType.IceHockey]: 'ice-hockey',
    [SportType.MotorSport]: 'motor-sport',
    [SportType.AmericanFootball]: 'american-football',
    [SportType.Snooker]: 'snooker',
    [SportType.Tennis]: 'tennis',
    [SportType.Bowls]: 'bowls',
    [SportType.RugbyLeague]: 'rugby-league',
    [SportType.RugbyUnion]: 'rugby-union',
    [SportType.Volleyball]: 'volleyball',
    [SportType.Handball]: 'handball',
    [SportType.Hockey]: 'hockey',
    [SportType.TableTennis]: 'table-tennis',
    [SportType.MartialArts]: 'martial-arts',
    [SportType.Futsal]: 'futsal',
    [SportType.GaelicFootball]: 'gaelic-football',
    [SportType.Hurling]: 'hurling',
    [SportType.BeachVolleyball]: 'beach-volleyball',
    [SportType.eSports]: 'e-sports',
    [SportType.Basketball3x3]: '3x3-basketball',
    [SportType.Badminton]: 'badminton',
    [SportType.Pickleball]: 'pickleball',
    [SportType.Squash]: 'squash',
    [SportType.WaterPolo]: 'waterpolo',
}
