import { HttpClient } from "@angular/common/http";
import { TemplateRef } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { PopMessageComponent } from "../components";
import { HttpBadRequestResponse, HttpUnauthorizedResponse, IErrorValue, MessageDispatcher, TotoDialog } from "../helpers";


declare module "rxjs/internal/Observable" {
  interface Observable<T> {
    result(
      form: UntypedFormGroup,
      handler: (value: any) => void,
      errHandler?: (
        error: HttpBadRequestResponse | HttpUnauthorizedResponse
      ) => void,
      actionName?: string,
      actionLoading?: any,
      message?: IResultMessage,
      confirm?: IResultConfirm | any
    ): Subscription;
  }
}

interface IResultMessage {
  MessageDispatcher: MessageDispatcher;
  successMessage: string;
  errorMessage: string;
}

interface IResultConfirm {
  TotoDialog: TotoDialog;
  image?: string;
  template?: string;
  title?: string;
  message?: string;
  templateRef?: TemplateRef<any>;
}

Observable.prototype.result = function(
  form: UntypedFormGroup,
  handler: (value: any) => void,
  errHandler?: (
    error: HttpBadRequestResponse | HttpUnauthorizedResponse
  ) => void,
  actionName?: string,
  actionLoading?: any,
  message?: IResultMessage,
  confirm?: IResultConfirm | any
): Subscription {
  const handleResult = () => {
    if (message) {
      var {  MessageDispatcher, successMessage, errorMessage } = message;
    }

    if (actionLoading) {
      actionLoading[actionName] = true;
    }

    const o = this as Observable<any>;
    return o.subscribe(
      value => {
        handler(value);
        if (MessageDispatcher && successMessage) {
          MessageDispatcher.ok({
            message: successMessage
          });
        }

        if (actionLoading) {
          actionLoading[actionName] = false;
        }
      },
      error => {
        if (error instanceof HttpBadRequestResponse) {
          if (form) {
            const validationErrors: { [key: string]: IErrorValue[] } = {};
            for (const i in error.validationResults) {
              if (error.validationResults.hasOwnProperty(i)) {
                const c = form.get(i);
                if (c) {
                  c.setErrors(error.validationResults[i]);
                }
                validationErrors[i] = error.validationResults[i];
              }
            }
            form.setErrors(validationErrors);
          }
        } else if (error instanceof HttpUnauthorizedResponse) {
          error.validationSummary = `AppMessages.Common.Errors.Unauthorized`;
        } else {
          const newError = new HttpBadRequestResponse();
          newError.exception = error;
          newError.validationSummary = "AppMessages.Common.Errors.Unauthorized";
          error = newError;
        }

        if (errHandler) {
          errHandler(error);
        }

        if (MessageDispatcher) {

          let message = (errorMessage ? errorMessage : error?.validationSummary) ?? "AppMessages.Common.Errors.GenericError";

          let messageArgs: any;

          if (!message) {
            const firstValidationError = Object.values(form.errors)?.[0]?.[0];
            if (firstValidationError) {
              const { value, args } = firstValidationError || {};
              if (value) {
                message = value;
                if (args) {
                  messageArgs = args;
                }
              }
            }
          }

          MessageDispatcher.error({
            message: message ?? "AppMessages.Common.Errors.GenericError",
            args: messageArgs
          });
        }

        if (actionLoading) {
          actionLoading[actionName] = false;
        }
      }
    );
  };

  if (confirm) {
    var { TotoDialog } = confirm;
    TotoDialog.confirm(
      confirm,
      res => {
        if (res) {
          return handleResult();
        }
      }
    );
  } else {
    return handleResult();
  }
};
