<toto-dialog [dialogRef]="dialogRef">
  <h3 header-title class="flex justify-center f-1">
    {{'Views.User.Register.VerificationReminderDialog.Title' | translate}}
  </h3>
  <div body class="flex flex-column align-center" style="margin-top: -20px">
    <svg-icon class="flex" key="documentPhoto" fontSize="122px"></svg-icon>
    <p style="text-align: center;"
      [innerHTML]="'Views.User.Register.VerificationReminderDialog.Text' | translate"
    ></p>
  </div>
  <div footer class="flex justify-center">
    <div class="flex flex-column align-center">
      <toto-button
        color="green"
        label="Views.User.Register.VerificationReminderDialog.Action.ToConfirmAccount"
        (click)="continue()"
      ></toto-button>

      <toto-button
        class="mt10"
        color="transparent"
        label="Views.User.Register.VerificationReminderDialog.Action.ConfirmLater"
        (click)="closeDialog()"
      ></toto-button>
    </div>
  </div>
</toto-dialog>
