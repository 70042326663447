<div class="login-options-wrapper" *ngIf="!isLoginView; else loginView">

  <ng-container *ngFor="let provider of shownProviders">
    <div class="provider bank-link" [class.selected]="provider.provider === selectedProvider"
      (click)="selectProvider(provider.provider)">
      <div class="provider-image">
        <img class="{{ provider.title }}" [src]="provider.image" [alt]="provider.title" />
      </div>
      <!-- <input checked="checked" id="SelectedProvider" name="SelectedProvider" type="radio"
          [value]="provider.provider" /> -->
    </div>
  </ng-container>

</div>

<ng-template #loginView>

  <div class="login-options-wrapper login-view">
    <ng-container *ngFor="let provider of shownProviders">
      <div class="provider bank-link" [class.selected]="provider.provider === selectedProvider"
        (click)="selectProvider(provider.provider)">
        <div class="provider-image">
          <img class="{{ provider.title }}" [src]="provider.image" [alt]="provider.title" />
        </div>
        <!-- <input checked="checked" id="SelectedProvider" name="SelectedProvider" type="radio"
          [value]="provider.provider" /> -->
      </div>
    </ng-container>
  </div>

</ng-template>