<ng-container [ngSwitch]="state.currentSportView">
  <!-- MAIN TOPICS VIEW -->
  <ng-container *ngSwitchCase="SportsSidebarView.SportsList">
    <ng-template *ngTemplateOutlet="sportsViewTemplate"></ng-template>
  </ng-container>

  <!-- OPEN TOPIC VIEW -->
  <ng-container *ngSwitchCase="SportsSidebarView.CurrentSport">
    <ng-template *ngTemplateOutlet="currentSportViewTemplate"></ng-template>
  </ng-container>
</ng-container>

<ng-template #sportsViewTemplate>
  <div class="sports-header">
    {{'Common.Sports' | translate}}
  </div>
  <div class="sport" *ngFor="let sport of menuItems | sortByOrder" [class.active]="isSportActive(sport.id)"
    (click)="onSportClick(sport)">
    <svg-icon key="sport{{sport.id}}" fontSize="20px"></svg-icon>
    <span class="sport-name">
      {{sport.id | translateEnum:'SportType'}}
    </span>
  </div>
</ng-template>

<ng-template #currentSportViewTemplate>
  <div class="sport" (click)="toSportsView()">
    <svg-icon key="arrowLeftSmall" fontSize="9px" class="mr5" style="margin-left: -5px;"></svg-icon>
    <svg-icon class="mr10" [key]="'sport' + state.currentSportId" fontSize="20px"></svg-icon>
    <span class="current-sport-name">
      {{state.currentSportId | translateEnum:'SportType'}}
    </span>
  </div>

  <div class="sports-container">

    <ng-container *ngFor="let competition of sportTopCompetitions">
      <ng-container
        *ngTemplateOutlet="competitionTemplate; context: { regionId: competition.regionId, competition: competition, isTop: true }">
      </ng-container>
    </ng-container>

    <div class="regions-container" *ngFor="let region of sportRegions">

      <ng-container *ngTemplateOutlet="regionTemplate; context: { region: region }"></ng-container>

      <div class="competitions-container" *ngIf="state.isSportRegionExpanded(region.id)">

        <ng-container *ngFor="let competition of region.competitions">
          <ng-container
            *ngTemplateOutlet="competitionTemplate; context: { regionId: region.id, competition: competition, isTop: false }">
          </ng-container>
        </ng-container>

      </div>

    </div>
  </div>
</ng-template>

<ng-template #regionTemplate let-region="region">
  <div class="region space-between" (click)="onRegionClick(region.id, state.currentSportId)"
    [class.active]="!region.competitions?.length && isRegionActive(region.id)">
    <span class="region-name">
      {{ region.name }}
    </span>
    <span class="expanded-indicator" [class.expanded]="state.isSportRegionExpanded(region.id)">
      <svg-icon key="arrowRightSmall" fontSize="9px"></svg-icon>
    </span>
  </div>
</ng-template>

<ng-template #competitionTemplate let-competition="competition" let-regionId="regionId" let-isTop="isTop">
  <div class="competition" [class.active]="isCompetitionActive(competition.id)" [class.top]="isTop"
    (click)="onCompetitionClick(regionId, competition)">
    <span class="competition-name">
      {{ competition.name}}
    </span>
  </div>
</ng-template>