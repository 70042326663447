import { Pipe, PipeTransform } from '@angular/core';
import { SportType } from '../modules/sports/enum/SportType';
@Pipe({
  name: 'sportScoreNameKey'
})
export class SportScorePipe implements PipeTransform {

  transform(value: SportType): string {
    const mapValue = SPORT_SCORE_TRANSLATION_KEY_MAP[value];
    if (mapValue) {
      return mapValue
    }
    return SPORT_SCORE_TRANSLATION_KEY_MAP.fallback;
  }
}


export const SPORT_SCORE_TRANSLATION_KEY_MAP = {
  [SportType.Football]: 'Common.SportScoreName.Goals',
  [SportType.Basketball]: 'Common.SportScoreName.Points',
  fallback: 'Common.SportScoreName.Points'
}