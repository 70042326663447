import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  Mousewheel
} from 'swiper';
import { EventsParams, SwiperComponent } from 'swiper/angular';

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  Mousewheel
]);

@Component({
  selector: 'providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProvidersComponent {
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;

  public swiperBreakpoints = {
    650: {
      slidesPerView: 3
    },
    // when window width is >= 640px
    450: {
      slidesPerView: 2
    },
    0: { slidesPerView: 1, spaceBetween: 10 }
  };

  public Providers = BankLinkProvider;

  @Input() public providerOptions = [
    BankLinkProvider.Trustly,
    BankLinkProvider.Zimpler
  ]; //BankLinkProvider.Swedbank, BankLinkProvider.Lhv,

  @Input() public selectedProvider: BankLinkProvider;
  @Output() selectedProviderChange = new EventEmitter();

  @Output() onProviderSelect = new EventEmitter();

  public onSlideChange([e]) {
    const provider = this.shownProviders[e.activeIndex]?.provider;
    this.selectProvider(provider);
  }

  public get selectedProviderIndex() {
    return this.shownProviders.findIndex(
      x => x.provider === this.selectedProvider
    );
  }

  public selectProvider(provider: BankLinkProvider) {
    this.selectedProvider = provider;
    this.slideToIndex();
    this.selectedProviderChange.emit(this.selectedProvider);
    this.onProviderSelect.emit(this.selectedProvider);
  }

  public slideToIndex(index: number = this.selectedProviderIndex) {
    this.swiperRef?.swiperRef.slideTo(index);
  }

  public showProvider(provider: BankLinkProvider) {
    return this.providerOptions.includes(provider);
  }

  public get shownProviders() {
    return BANK_LINK_PROVIDER_OPTIONS.filter(x =>
      this.showProvider(x.provider)
    );
  }

  ngAfterViewInit() {
    this.slideToIndex();
  }
}

export const BANK_BIC_MAP = {
  [BankLinkProvider.Swedbank]: 'HABAEE2X',
  [BankLinkProvider.ZimplerSwedbank]: 'HABAEE2X',
  [BankLinkProvider.LHV]: 'LHVBEE22',
  [BankLinkProvider.ZimplerLHV]: 'LHVBEE22',
  [BankLinkProvider.Luminor]: 'RIKOEE22',
  [BankLinkProvider.ZimplerLuminor]: 'RIKOEE22',
  [BankLinkProvider.SEB]: 'EEUHEE2X',
  [BankLinkProvider.ZimplerSEB]: 'EEUHEE2X',
  [BankLinkProvider.NeopayRevolut]: 'REVOEE21',
  [BankLinkProvider.NeopayN26]: 'NTSBEEB1',
  [BankLinkProvider.NeopayWise]: 'TRWIEE2L',
  [BankLinkProvider.NeopayPaysera]: 'PAYEEE21',
  // FINNISH BANKS ADDED
  [BankLinkProvider.ZimplerOPBank]: 'OKOYFIHH',
  [BankLinkProvider.ZimplerNordea]: 'NDEAFIHH',
  [BankLinkProvider.ZimplerDanskeBank]: 'DABAFIHH',
  [BankLinkProvider.ZimplerAktia]: 'HELSFIHH',
  [BankLinkProvider.ZimplerAlandsBanken]: 'AABAFI22',
  [BankLinkProvider.ZimplerPOPPankki]: 'POPFFI22',
  [BankLinkProvider.ZimplerSPankki]: 'SBANFIHH',
  [BankLinkProvider.ZimplerHandelsbanken]: 'HANDFIHH',
  [BankLinkProvider.ZimplerSäästöpankki]: 'ITELFIHH',
  [BankLinkProvider.ZimplerOMASäästöpankki]: 'OMSAFI2S',
};


export const BANK_LINK_PROVIDER_AMOUNTS = {
  [BankLinkProvider.Neopay]: {
    min: 0.1,
    max: 1
  },
  [BankLinkProvider.NeopayRevolut]: {
    min: 10,
    max: 5000
  },
  [BankLinkProvider.NeopayN26]: {
    min: 10,
    max: 5000
  },
  [BankLinkProvider.NeopayWise]: {
    min: 10,
    max: 5000
  },
  [BankLinkProvider.NeopayPaysera]: {
    min: 10,
    max: 5000
  },
  [BankLinkProvider.Zimpler]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerSwedbank]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerLHV]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerLuminor]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerSEB]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.Swedbank]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.LHV]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.Luminor]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.Trustly]: {
    min: 10,
    max: 5000
  },
  [BankLinkProvider.BankTransfer]: {
    min: 10,
    max: 5000
  },
  // FINNISH BANKS ADDED
  [BankLinkProvider.ZimplerOPBank]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerNordea]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerDanskeBank]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerAktia]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerAlandsBanken]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerPOPPankki]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerSPankki]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerHandelsbanken]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerSäästöpankki]: {
    min: 2,
    max: 5000
  },
  [BankLinkProvider.ZimplerOMASäästöpankki]: {
    min: 2,
    max: 5000
  }
}

export enum BankLinkProviderType {
  Estonia = 1,
  International = 2,
}

export const BANK_LINK_PROVIDER_OPTIONS = [
  {
    provider: BankLinkProvider.Zimpler,
    image: '/assets/images/banklinks/zimpler.svg',
    title: 'Zimpler',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Zimpler].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Zimpler].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerSwedbank,
    image: '/assets/images/banklinks/swedbank.svg',
    title: 'Swedbank',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSwedbank].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSwedbank].max,
    bic: BANK_BIC_MAP[BankLinkProvider.Swedbank],
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.ZimplerLHV,
    image: '/assets/images/banklinks/lhv.svg',
    title: 'LHV',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.LHV],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerLHV].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerLHV].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.ZimplerSEB,
    image: '/assets/images/banklinks/seb.svg',
    title: 'SEB',
    description: '',
    amount: '€2 - €5000',
    bic: BANK_BIC_MAP[BankLinkProvider.SEB],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSEB].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSEB].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.ZimplerLuminor,
    image: '/assets/images/banklinks/luminor.svg',
    title: 'Luminor',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.Luminor],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerLuminor].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerLuminor].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.Swedbank,
    image: '/assets/images/banklinks/swedbank.svg',
    title: 'Swedbank',
    description: '',
    amount: '€2 - €5000',
    bic: BANK_BIC_MAP[BankLinkProvider.Swedbank],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Swedbank].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Swedbank].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.LHV,
    image: '/assets/images/banklinks/lhv.svg',
    title: 'LHV',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.LHV],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.LHV].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.LHV].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.NeopayRevolut,
    image: '/assets/images/banklinks/Revolut_Black.jpg',
    title: 'Revolut',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayRevolut].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayRevolut].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.NeopayN26,
    image: '/assets/images/banklinks/N26_Logo_Black.svg',
    title: 'N26',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayN26].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayN26].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.NeopayWise,
    image: '/assets/images/banklinks/wise.svg',
    title: 'Wise',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayWise].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayWise].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.NeopayPaysera,
    image: '/assets/images/banklinks/paysera.svg',
    title: 'Paysera',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayPaysera].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.NeopayPaysera].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.Neopay,
    image: '/assets/images/banklinks/neopay.svg',
    title: 'Neopay (test)',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Neopay].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Neopay].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.Trustly,
    image: '/assets/images/banklinks/trustly.svg',
    title: 'Trustly',
    description: '',
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Trustly].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.Trustly].max,
    providerType: BankLinkProviderType.Estonia
  },
  {
    provider: BankLinkProvider.BankTransfer,
    image: '/assets/images/banklinks/bank-transfer.svg',
    title: 'Enums.BankLinkProvider.BankTransfer',
    description: null,
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.BankTransfer].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.BankTransfer].max,
    
  },
  // FINNISH BANKS ADDED
  {
    provider: BankLinkProvider.ZimplerOPBank,
    image: '/assets/images/banklinks/OP_Bank2.svg',
    title: 'OP Bank',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerOPBank],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerOPBank].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerOPBank].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerNordea,
    image: '/assets/images/banklinks/nordea.svg',
    title: 'Nordea',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerNordea],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerNordea].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerNordea].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerDanskeBank,
    image: '/assets/images/banklinks/danskebank-original.svg',
    title: 'Danske Bank',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerDanskeBank],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerDanskeBank].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerDanskeBank].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerAktia,
    image: '/assets/images/banklinks/aktia-logo.svg',
    title: 'Aktia',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerAktia],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerAktia].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerAktia].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerAlandsBanken,
    image: '/assets/images/banklinks/Alandsbanken3.svg',
    title: 'Alandsbanken',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerAlandsBanken],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerAlandsBanken].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerAlandsBanken].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerPOPPankki,
    image: '/assets/images/banklinks/poppankki-purple-logo.svg',
    title: 'POP Pankki',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerPOPPankki],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerPOPPankki].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerPOPPankki].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerSPankki,
    image: '/assets/images/banklinks/s-pankki-logo.svg',
    title: 'S-Pankki',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerSPankki],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSPankki].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSPankki].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerHandelsbanken,
    image: '/assets/images/banklinks/Handelsbanken2.svg',
    title: 'Handelsbanken',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerHandelsbanken],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerHandelsbanken].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerHandelsbanken].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerSäästöpankki,
    image: '/assets/images/banklinks/saastopankki-logo.svg',
    title: 'Säästöpankki',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerSäästöpankki],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSäästöpankki].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerSäästöpankki].max,
    providerType: BankLinkProviderType.International
  },
  {
    provider: BankLinkProvider.ZimplerOMASäästöpankki,
    image: '/assets/images/banklinks/oma-saastopankki.svg',
    title: 'OMA säästöpankki',
    description: '',
    bic: BANK_BIC_MAP[BankLinkProvider.ZimplerOMASäästöpankki],
    minAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerOMASäästöpankki].min,
    maxAmount: BANK_LINK_PROVIDER_AMOUNTS[BankLinkProvider.ZimplerOMASäästöpankki].max,
    providerType: BankLinkProviderType.International
  },
];


