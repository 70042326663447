import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AuthenticationOption } from 'src/app/components/_elements/authentication-options/authentication-options.component';
import { LoginDialogComponent } from 'src/app/components/dialogs/login/login.dialog';
import { MessageDispatcher } from 'src/app/helpers';
import { ActionHelper } from 'src/app/helpers/action';
import { AccountService, AuthenticationService } from 'src/app/services';


@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.view.html',
  styleUrls: ['./reset-password.view.scss'],
  host: { 'class': 'app-content'}
})
export class ResetPasswordViewComponent {

  public resettingInProgress = false;
  public isReset = false;

  public userId: string;
  public token: string;

  public formGroup = new FormGroup({
    password: new FormControl(),
    passwordConfirm: new FormControl()
  })

  constructor(
    private _accountService: AccountService,
    route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _msg: MessageDispatcher,
    private _router: Router,
    private _action: ActionHelper,
    public state: AppState,
    private router: Router
  ) { 

    this.userId = route.snapshot.paramMap.get('userId');
    this.token = route.snapshot.queryParamMap.get('token');
  }


  public resetPassword() {

    this.resettingInProgress = true;

    const data = {
      userId: this.userId,
      token: this.token,
      password: this.formGroup.value.password,
      passwordConfirm: this.formGroup.value.passwordConfirm
    };

    this._accountService.resetPassword(data)
      .pipe(finalize(() => {
        this.resettingInProgress = false;
      }))
      .result(this.formGroup, () => {
        this._msg.ok({
          message: 'account.forgot_password.password_has_been_reset'
        });
        // this._router.navigate(['/login']);
        this.isReset = true;
      });
  }

  // isOpenLoginDialog = false;

  // public openLoginDialog() {
  //   this.isOpenLoginDialog = true;
  // }

  public openLoginDialog() {
    this._authService.openLoginDialog(true);
  }

  public get activeAppModuleRootLink() {
    return `/${this._authService.activeAppModuleRoot}`;
  }

  public get isLoggedIn(): boolean {
    return this.state.isLoggedIn;
  }
}
 