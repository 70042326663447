export enum BowlsMarketTypeValue
{
    FirstSetWinner = 8408,
    MatchWinner = 9777,
    FirstSetTotalPointsOverUnder = 10214,
    MatchHandicap = 10229,
    FirstSetTotalPointsOddEven = 10234,
    SecondSetWinner = 12650,
    ThirdSetWinner = 12651,
    FirstSetHandicap = 12653,
    SecondSetHandicap = 12654,
    ThirdSetHandicap = 12655,
    SecondSetTotalPointsOverUnder = 12936,
    ThirdSetTotalPointsOverUnder = 12937,
    SecondSetTotalPointsOddEven = 12938,
    ThirdSetTotalPointsOddEven = 12939,
    MatchTotalPointsOverUnder = 12940,
    MatchTotalPointsOddEven = 12941,
    CompetitorAMatchTotalsOverUnder = 12942,
    CompetitorBMatchTotalsOverUnder = 12943,
    CompetitorASet1TotalPointsOverUnder = 12944,
    CompetitorASet2TotalPointsOverUnder = 12945,
    CompetitorASet3TotalPointsOverUnder = 12946,
    CompetitorBSet1TotalPointsOverUnder = 12947,
    CompetitorBSet2TotalPointsOverUnder = 12948,
    CompetitorBSet3TotalPointsOverUnder = 12949,
    TotalSetsOverUnder = 12950,
    FirstSetEndWinner = 12951,
    SecondSetEndWinner = 12952,
    ThirdSetEndWinner = 12953,
}

(BowlsMarketTypeValue as any).__typeName = 'BowlsMarketTypeValue';