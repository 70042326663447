import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'user-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
  })
  export class UserNotificationsComponent {
  
    @Output() public onMenuItemClicked = new EventEmitter();
  
    public onMenuItemClick() {
      this.onMenuItemClicked.emit();
    }
  
  }
  