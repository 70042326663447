import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { toNumber } from 'src/app/util/number-util';
import { SportsSidebarView } from '../components/sports-list/sports-list.component';
import { LocalStorage, ResponsiveHelper } from 'src/app/helpers';
import { SportType } from '../enum/SportType';
import { sportIdFromName } from '../utilities/translate.utility';
import { TranslateEnumPipe } from 'src/app/pipes';
import { BehaviorSubject, Subject } from 'rxjs';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-key';
import { AppState } from 'src/app/app.state';

@Injectable({ providedIn: 'root' })
export class SportsLayoutState {
  public updateCdSubject$ = new Subject<void>();

  // public menu = [];
  public events = [];
  public sportsWithLiveEvents = [];

  public isSportsSidebarCollapsed = LocalStorage.getValue(
    LOCAL_STORAGE_KEY.SPORTS_SIDEBAR_COLLAPSED
  );
  public isTicketSidebarCollapsed = true;

  public livebetWidgetsEvent$ = new BehaviorSubject(null);
  public livebetWidgetsCloseable = true;

  // Handled by components, used to determine 
  public isSportsSidebarInView = false;
  public isTicketsSidebarInView = false;
  public isLivebetWidgetsInView = false;

  public currentSportView: SportsSidebarView = SportsSidebarView.SportsList;

  public sidebarExpandedSportRegionIds = {};

  public viewDataSubject$ = new BehaviorSubject({
    currentSportId: null,
    currentRegionId: null,
    currentCompetitionId: null,
    currentEventId: null,
    sport: null,
    region: null,
    competition: null,
    event: null
  });

  public get currentViewData() {
    return this.viewDataSubject$.value;
  }

  public get livebetWidgetsEvent() {
    return this.livebetWidgetsEvent$?.value ?? null;
  }

  constructor(
    private _state: AppState,
    private _route: ActivatedRoute,
    private _breadcrumbService: BreadcrumbService,
    private _translateEnumPipe: TranslateEnumPipe,
  ) {}

  public get menu() {
    return this._state.siteLocale?.menu ?? [];
  }

  public get livebetWidgetsEventId() {
    return this.livebetWidgetsEvent?.id ?? null;
  }

  public get livebetWidgetsEventSportType() {
    return this.livebetWidgetsEvent?.sportType ?? null;
  }

  public get currentSport() {
    return this.currentViewData?.sport ?? null;
  }

  public get currentRegion() {
    return this.currentViewData?.region ?? null;
  }

  public get currentCompetition() {
    return this.currentViewData?.competition ?? null;
  }

  public get currentEvent() {
    return this.currentViewData?.event ?? null;
  }

  public get currentSportId(): number {
    return this.currentViewData?.currentSportId ?? null;
  }

  public get currentRegionId(): number {
    return this.currentViewData?.currentRegionId ?? null;
  }

  public get currentCompetitionId(): number {
    return this.currentViewData?.currentCompetitionId ?? null;
  }

  public get currentEventId(): number {
    return this.currentViewData?.currentEventId ?? null;
  }

  public openLivebetWidgets(event) {
    this.livebetWidgetsEvent$.next(event);
  }

  public toggleLivebetWidgets(event) {
    if (this.livebetWidgetsEvent && event.id === this.livebetWidgetsEventId) {
      this.closeLivebetWidgets();
    } else {
      this.openLivebetWidgets(event);
    }
  }

  public closeLivebetWidgets() {
    this.livebetWidgetsEvent$.next(null);
  }

  public isSportRegionExpanded(
    regionId: number,
    sportType: SportType = this.currentSportId
  ) {
    return (
      this.sidebarExpandedSportRegionIds[sportType]?.includes(regionId) || false
    );
  }

  public toggleSportRegionExpanded(
    regionId: number,
    sportType: SportType = this.currentSportId
  ) {

    if (!this.sidebarExpandedSportRegionIds[sportType]) {
      this.sidebarExpandedSportRegionIds[sportType] = [];
    }

    if (!this.sidebarExpandedSportRegionIds[sportType].includes(regionId)) {
      this.sidebarExpandedSportRegionIds[sportType].push(regionId);
    } else {
      const index = this.sidebarExpandedSportRegionIds[sportType].findIndex(
        x => x === regionId
      );
      this.sidebarExpandedSportRegionIds[sportType].splice(index, 1);
    }
  }

  public updateViewData(
    event = this.currentEvent,
    params = this._route.snapshot.params
  ) {
    const { sportName, regionId, competitionId, eventId } = params || {};
    const currentSportId = toNumber(sportIdFromName(sportName)) ?? null;
    const currentRegionId = toNumber(regionId) ?? null;
    const currentCompetitionId = toNumber(competitionId) ?? null;
    const currentEventId = event?.id ?? eventId ?? null;

    const sport = this.menu.find(x => x.id === currentSportId) ?? null;
    const region = sport?.regions.find(x => x.id === currentRegionId) ?? null;
    const competition =
      region?.competitions.find(x => x.id === currentCompetitionId) ?? null;

    const viewData = {
      currentSportId: currentSportId,
      currentRegionId: currentRegionId,
      currentCompetitionId: currentCompetitionId,
      currentEventId: currentEventId,
      sport: sport,
      region: region,
      competition: competition,
      event: event
    };

    if (currentSportId) {
      this.currentSportView = SportsSidebarView.CurrentSport;
    } else {
      this.currentSportView = SportsSidebarView.SportsList;
    }

    if (!this.isSportRegionExpanded(currentRegionId, currentSportId)) {
      this.toggleSportRegionExpanded(currentRegionId, currentSportId);
    }

    this.updateBreadCrumbs(viewData);
    this.viewDataSubject$.next(viewData);
  }

  public clearExpandedRegionIds() {
    this.sidebarExpandedSportRegionIds = [];
  }

  public updateBreadCrumbs(viewData = this.currentViewData) {
    // console.log("UPDATE BC");
    const { sport, region, competition, event, currentSportId, currentRegionId  } = viewData;

    this._breadcrumbService.set('@sports', 'Spordiennustus');
    this._breadcrumbService.set('@sportName', {
      label: this._translateEnumPipe.transform(currentSportId, 'SportType'),
      skip: false
    });
    this._breadcrumbService.set('@sportRegion', {
      label: this._translateEnumPipe.transform(currentRegionId, 'Region'),
      skip: false
    });
    this._breadcrumbService.set('@leagueWinner', {
      label: 'Winner',
      skip: false
    });
    this._breadcrumbService.set('@sportCompetition', {
      label: competition?.name,
      skip: false
    });
    this._breadcrumbService.set('@sportEvent', {
      label: event?.name,
      skip: false
    });

    // console.log(this.currentViewData, this._breadcrumbService.breadcrumbs$);
  }
}
