import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, } from '@angular/forms';
import { TotoAppModule } from 'src/app/app.state';
import { AccountService, AuthenticationService, TitleService } from 'src/app/services';
import { ViewBase } from 'src/app/views/view.base';
import { MONTH_DURATION_OPTIONS } from '../constants';
import { ActionHelper } from 'src/app/helpers/action';
import { SelfRestraintSuccessDialogComponent } from 'src/app/components/dialogs/self-restraint-success/self-restraint-success.dialog';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'self-restraint-view',
    templateUrl: './self-restraint.view.html',
    styleUrls: ['./self-restraint.view.scss']
  })
  export class UserSelfRestraintViewComponent extends ViewBase {

    public SelfRestraintViewStep = SelfRestraintViewStep;

    public currentStep = SelfRestraintViewStep.ChoosePeriod;

    public titleFragments = [
      { key: 'Navigator.Menu.ResponsibleGame.ResponsibleGame' },
      { key: 'Navigator.Menu.ResponsibleGame.SelfRestraint' }
    ];

    public actionLoading = {} as any;

    public validityPeriodOptions = MONTH_DURATION_OPTIONS;
    public activeRestraintUntil;

    constructor(_titleService: TitleService, private _accountService: AccountService, private _authService: AuthenticationService, private _action: ActionHelper, private _router: Router) {
      super(_titleService);

      _accountService.getSelfRestraint().result(
          null,
          res => {
            if(res.selfRestraintValidUntilUtc) {
              this.activeRestraintUntil = res.selfRestraintValidUntilUtc;
              this.toView(SelfRestraintViewStep.ActiveRestriction);
            }            
          }
      );
    }

    public restraintGroup = new FormGroup({
      months: new FormControl(this.validityPeriodOptions[0]),
      password: new FormControl()
    });

    public toView(step: SelfRestraintViewStep) {
      this.currentStep = step;
    }

    public openRestraintSuccessDialog() {
      this._action.openDialog(
        SelfRestraintSuccessDialogComponent,
        {
          width: '542px',
          height: 'auto'
        },
        res => {}
      );
    }

    public save() {
      const f = this.restraintGroup.value;
      const req = {
        months: f.months.value,
        password: f.password
      };

      this._accountService.restrictBetting(req).result(this.restraintGroup, res => {
        this._authService.logout().then(res => {
          this.openRestraintSuccessDialog();
        });
      }, err => {
        console.error(err);
      }, "save", this.actionLoading)
    }

  }

  enum SelfRestraintViewStep {
    ActiveRestriction,
    ChoosePeriod,
    Confirmation
  }