import { TotoAppModule } from "../app.state";

export const THEME_COLORS = {
    [TotoAppModule.HorseRacing]: {
        background: 'var(--black-1)',
        color1: 'var(--red)',
        color2: 'var(--dark-red)'
    },
    [TotoAppModule.Sports]: {
        background: 'var(--black-borders)',
        color1: 'var(--green)',
        color2: 'var(--dark-green)'
    }
}

export const PAGE_TITLE_SEPARATOR = ' - ';
export const PAGE_TITLE_PREFIX = `Toto`;