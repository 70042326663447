import { Pipe, PipeTransform, ChangeDetectorRef, Injector } from '@angular/core';
import moment from 'moment';

export const DEFAULT_DATE_PIPE_FORMAT = 'LL HH:mm';

@Pipe({
  name: 'totoDate'
})
export class TotoDatePipe implements PipeTransform {

  private _timeZone: string;

  constructor(
    injector: Injector
  ) {

    // const cdRef = injector.get(ChangeDetectorRef);
    // this._authService.tenantTimeZone.subscribe(value => {
    //   this._timeZone = value;
    //   cdRef.markForCheck();
    // });
  }

  public transform(value: any, ...args: any[]): string {
    const useUtc = args[1] || true;

    const date = this.getActualValue(value);

    if (!date) {
      return '-';
    }

    let format = args[0] || DEFAULT_DATE_PIPE_FORMAT; //'DD.MM.YYYY HH:mm'
    // let offset = parseInt(args[1]);

    // const convertedMoment = moment(date).tz(this._timeZone);
    // if (isNaN(offset) && convertedMoment) {
    //   offset = convertedMoment.utcOffset();
    // }

    // if (offset !== date.utcOffset()) {
    //   format += ' Z';
    // }

    return date.local().format(format);
  }

  private getActualValue(value: any): moment.Moment {
    if (!value) {
      return null;
    }

    if (moment.isMoment(value)) {
      // MAT_MOMENT_DATE_ADAPTER_OPTIONS use { useUtc: true }
      // but when we display date, we consider current locale offset
      // therefore we must convert utc date to local date
      return moment.parseZone(value.toDate());
    }

    if (value instanceof Date || typeof value === 'string') {
      return moment.parseZone(value);
    }

    return moment.parseZone((<any>value).displayDate);
  }
}
