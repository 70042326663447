import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services';


@Component({
  selector: 'livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.scss']
})
export class LivestreamComponent {

  public LivestreamDisplayType = LivestreamDisplayType;

  @Input() public displayType = LivestreamDisplayType.Banner;

  private _windowRef: any;

  constructor(private _accountService: AccountService) {}

  @HostListener('click')
  public openLivestream() {
    this._windowRef = window.open("about:blank","_blank", "width=800,height=450");
    this._accountService.getLivestreamUrl().toPromise().then(res => {
      this._windowRef.location = res;
    }, e => {
      console.error(e);
    })
  }

}

enum LivestreamDisplayType {
  Banner = 'banner',
  Icon = 'icon'
}