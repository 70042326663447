import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Guid, MessageDispatcher } from 'src/app/helpers';
import {
  AccountService,
  AuthenticationService,
  ILimitReq,
  IRestrictBettingReq,
  TitleService
} from 'src/app/services';
import { environment } from 'src/environments/environment';
import {
  BetTypeGroup,
  asArray,
  ToteSystem,
  OcspStatus,
  SafeBettingPeriod,
  label
} from 'src/app/enum';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { translate } from 'src/app/enum';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { LayoutModule } from '@angular/cdk/layout';
import { LANGUAGE_OPTIONS } from 'src/app/app.settings';
import { ViewBase } from 'src/app/views/view.base';
import { ActionHelper } from 'src/app/helpers/action';
import { PendingVerificationDialogComponent } from '../../../../components/dialogs/pending-verification/pending-verification.dialog';
import { AppState } from 'src/app/app.state';
import { ViewChild, ElementRef } from '@angular/core';
import { TotoDialog } from 'src/app/helpers';

@Component({
  selector: 'user-verification-view',
  templateUrl: './user-verification.view.html',
  styleUrls: ['./user-verification.view.scss', '../shared.scss']
})
export class UserVerificationViewComponent extends ViewBase {
  public titleFragments = [
    { key: 'Navigator.Menu.Settings.Settings' },
    { key: 'Navigator.Menu.Settings.Verification' }
  ];

  public countryPrefixOptions = COUNTRY_PREFIX_OPTIONS;

  public verificationGroup: FormGroup = new FormGroup({
    verificationMethod: new FormControl(VerificationMethod.SmartId),
    smartIdUserIdCode: new FormControl(),
    mobileIdUserIdCode: new FormControl(),
    mobileIdPhoneNumber: new FormControl(),
    prefix: new FormControl(this.countryPrefixOptions[0].value),
    userIdCode: new FormControl(),
    country: new FormControl('ee')
  });

  public verificationMethodOptions = [
    {
      label: 'Views.User.Settings.Verification.Method.SmartID',
      value: VerificationMethod.SmartId
    },
    {
      label: 'Views.User.Settings.Verification.Method.MobileID',
      value: VerificationMethod.MobileId
    },
    {
      label: 'Views.User.Settings.Verification.Method.DocumentPhoto',
      value: VerificationMethod.DocumentPhoto
    },
    {
      label: 'Views.User.Settings.Verification.Method.Bank',
      value: VerificationMethod.Bank
    }
    // {
    //   label: 'Views.User.Settings.Verification.Method.IDCard',
    //   value: VerificationMethod.IdCard
    // },
    // {
    //   label: 'Views.User.Settings.Verification.Method.Deposit',
    //   value: VerificationMethod.Deposit
    // }
  ];

  public zimplerCountryOptions = [
    {
      label: 'Common.Countries.Estonia',
      value: 'ee'
    },
    {
      label: 'Finland',
      value: 'fi'
    }
  ];

  public VerificationMethod = VerificationMethod;
  public languageOptions = LANGUAGE_OPTIONS;
  public betTypeGroupOptions = asArray(BetTypeGroup);
  public providerOptions = [BankLinkProvider.ZimplerSwedbank, BankLinkProvider.ZimplerLHV, BankLinkProvider.SEB, BankLinkProvider.Luminor];

  public monthsTranslation = this._translate.instant(
    'SafeBetting.Index.Months'
  );

  public actionLoading = {} as any;
  public files = [] as any;

  accountInfo: any;
  formData: any;
  idCardUrl: string;

  public showExperimentalVerifications : boolean = false;

  constructor(
    private _appState: AppState,
    _titleService: TitleService,
    private _authService: AuthenticationService,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _action: ActionHelper,
    private _msg: MessageDispatcher,
    private _translate: TranslateService,
    private _message: MessageDispatcher
  ) {
    super(_titleService);

    this.showExperimentalVerifications = window.__TOTO_DEV_SETTINGS.SHOW_EXPERIMENTAL_PAYMENTS;
    if(!this.showExperimentalVerifications) {
      this.verificationMethodOptions = [
        {
          label: 'Views.User.Settings.Verification.Method.SmartID',
          value: VerificationMethod.SmartId
        },
        {
          label: 'Views.User.Settings.Verification.Method.MobileID',
          value: VerificationMethod.MobileId
        },
        // {
        //   label: 'Views.User.Settings.Verification.Method.IDCard',
        //   value: VerificationMethod.IdCard
        // },
        // {
        //   label: 'Views.User.Settings.Verification.Method.Deposit',
        //   value: VerificationMethod.Deposit
        // }
      ];
    }

    if (this._router.url.startsWith('/user/settings/finalizeIdCardAuth')) {
      var verifySessionId = this._route.snapshot.params['sessionId'];
      _authService
        .verifyIdCardSession(verifySessionId)
        .pipe(
          finalize(() => {
            _router.navigate(['/user/settings'], {
              replaceUrl: true
            });
          })
        )
        .result(
          null,
          res => {
            this._msg.error({
              message: 'account.register.account_verified_successfully'
            });
          },
          err => {
            var errorMessages = [err.validationSummary];
            if (err.validationResults && err.validationResults.certStatus) {
              let e = err.validationResults.certStatus[0];

              e.args[0] = translate(OcspStatus, e.args[0], _translate);
              errorMessages.push(this._translate.instant(e.value, e.args));
            }

            this._msg.error({
              messages: errorMessages
            });
          }
        );
    }

    const { accountInfo, contact, formData, manualBets, newsletter } =
      this._route.snapshot.data.settingsData || {};

    this.accountInfo = accountInfo;
    this.formData = formData;

    const sessionId = Guid.newGuid().toString();
    const localCallbackUri = location.href + `/finalizeIdCardAuth/${sessionId}`;
    const apiCallbackUri = `${
      environment.apiUrl
    }auth/verifyId/idCard?redirect_uri=${encodeURIComponent(
      localCallbackUri
    )}&sessionId=${sessionId}`;
    this.idCardUrl = `https://idcard.brightspark.ee/idcard/authorize?redirect_uri=${encodeURIComponent(
      apiCallbackUri
    )}`;

    this._accountService.listUserFiles().result(
      null, 
      res => {
        this.files = res;
      },
      err => {
        console.log(err);
      }
    );
  }

  public get verificationMethod() {
    return this.verificationGroup.value.verificationMethod;
  }

  public get isVerified(): boolean {
    return this.accountInfo?.isVerified;
  }

  private _reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigate([currentUrl], { skipLocationChange: true });
  }

  // Verification methods

  public openPendingVerificationDialog(
    data = {},
    method = VerificationMethod.SmartId,
    action = 'verify'
  ) {
    this._action.openDialog(
      PendingVerificationDialogComponent,
      {
        width: '498px',
        data: {
          data: data,
          method: method,
          action: action,
          formGroup: this.verificationGroup
        }
      },
      res => {                
        if(res.validationSummary && res.validationSummary.startsWith('{"type":"about:blank"')){
          res.validationSummary = "AppMessages.Register.Errors.SmartIdFailed";
        }
        if(res.validationSummary || res.exception || res.validationResults)
        {
          return;
        }
        if (res !== null) {
          this._authService.updateToken(() => {
            this._accountService.getAccountInfo().result(null, info => {
              this._appState.setAccountInfo(info, 'log_in');
              this._router.navigate(['/' + this._authService.activeAppModuleRoot]).then(res => {
                this._authService.openVerificationSuccessDialog();
              });
            })
          });
        }
      },
    );
  }

  public verifyViaSmartId(): void {
    const form = this.verificationGroup.value;
    const { smartIdUserIdCode } = form;

    console.log(form, smartIdUserIdCode);
    if (this.actionLoading.verifySmartId) {
      return;
    }

    this._authService.initVerifyViaSmartId(smartIdUserIdCode).result(
      this.verificationGroup,
      res => {
        this.openPendingVerificationDialog(res, VerificationMethod.SmartId);
      },
      e => {
        if(e && e.validationSummary && e.validationSummary.startsWith('{"type":"about:blank"'))
        {
          e.validationSummary = "AppMessages.Register.Errors.SmartIdFailed";
        }
        this._msg.error(e);
        console.error(e);
      },
      'verifySmartId',
      this.actionLoading
    );
  }

  public verifyViaIdCard(): void {}

  public verifyViaMobileId(): void {
    const form = this.verificationGroup.value;
    const { mobileIdUserIdCode, mobileIdPhoneNumber, prefix } = form;

    if (this.actionLoading.verifyMobileId) {
      return;
    }

    this._authService
      .initVerifyViaMobileId(mobileIdUserIdCode, mobileIdPhoneNumber, prefix).result(
        this.verificationGroup,
        res => {
          this.openPendingVerificationDialog(res, VerificationMethod.MobileId);
        },
        err => {
          this._msg.error(err);
          console.error(err);
        },
        'verifyMobileId',
        this.actionLoading
      );
  }

  public verifyViaZimpler(): void {
    var country = this.verificationGroup.value.country;
    this._authService
      .initVerifyViaZimpler(country).result(
        this.verificationGroup,
        res => {
          window.location.href = res.url;
        },
        err => {
          this._msg.error(err);
          console.error(err);
        },
        'verifyMobileId',
        this.actionLoading
      );
  }

  private documentFront: any;
  private documentBack: any;

  public filesSelected(evt) {
    if(evt.target.files.length != 2) {
      this._message.error({
        message: 'Views.User.Settings.Verification.Label.TwoPhotosNeeded'
      });
      this.emptyDocumentImageInput();
      return;
    }

    this.documentFront =  evt.target.files[0];
    this.documentBack =  evt.target.files[1];
  }

  public uploadDocumentPhotos() : void {
    const formData = new FormData();
    formData.append("documentFront",this.documentFront);
    formData.append("documentBack",this.documentBack);

    this._accountService.uploadDocumentPhoto(formData).result(
      this.verificationGroup,
      res => {
        this.emptyDocumentImageInput();
        console.log(res);
        this._message.ok({
          message: 'Views.User.Settings.Verification.Text.WaitForPhotoVerification'
        });
        this._router.navigate(['/']);
      },
      err => {
        this._msg.error(err);
        console.error(err);
      }
    );
  }

  public hasPhotos() {
    return this.documentFront && this.documentBack;
  }

  private emptyDocumentImageInput() {
    (<HTMLInputElement>(document.getElementsByClassName('documentFile').item(0))).value = null;
  }

  public downloadImage(id: any) {
    this._accountService.getVerificationImage(id);
  }
}

export enum VerificationMethod {
  SmartId = 1,
  DocumentPhoto = 2,
  MobileId = 3,
  IdCard = 4,
  Deposit = 5,
  Bank = 6
}

export const COUNTRY_PREFIX_OPTIONS = [
  {
    label: '+372 (EE)',
    value: '+372'
  }
  // ,
  // {
  //   label: '+370 (LT)',
  //   value: '+370'
  // },
]
