<div class="field-wrapper" [class.readonly]="readonly || formGroupDisabled" [class.label-top]="labelTop">

  <span class="field-label labelWidth--{{labelWidth}}" *ngIf="!noLabel" [class.required]="required">

    <span class="prelabel">
      <ng-content select="[prelabel]"></ng-content>
    </span>
    <!-- [for]="for" -->
    <label *ngIf="label" (click)="(readonly || formGroupDisabled) ? $event.preventDefault() : null">
      {{ labelKey | translate }}
    </label>
    <span class="additional-label" *ngIf="additionalLabel">{{ additionalLabel | translate }}</span>
    <ng-content select="[label]"></ng-content>

  </span>

  <div class="field-content">

    <div class="field-data">

      <ng-container *ngIf="isInput" [formGroup]="formGroup">
        <toto-input class="{{inputClass}}" [label]="inputLabel" [type]="inputType" [for]="for"
          [maxlength]="maxlength" [readonly]="readonly || formGroupDisabled">
        </toto-input>
      </ng-container>

      <ng-content></ng-content>

      <ng-content select="[text]"></ng-content>

      <ng-content select="[select]"></ng-content>

      <ng-content select="[any]"></ng-content>

      <ng-content select="[after]"></ng-content>

    </div>

    <ng-content select="[hint]"></ng-content>

    <ng-content select="validation-errors"></ng-content>

    <ng-content select="[below]"></ng-content>

  </div>

</div>