import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InfoService } from '../services';

@Injectable()
export class InfoResolve implements Resolve<any> {

  constructor(private _infoService: InfoService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const pageId = route.paramMap.get('id');
    const language = route.paramMap.get('language');
    return this._infoService.getInfoPage(pageId, language);
  }
}
