import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService, AuthenticationService } from 'src/app/services';
import { VerificationMethod } from '../../../views/user/settings/verification/user-verification.view';
import { FormGroup } from '@angular/forms';
import { ActionHelper } from 'src/app/helpers/action';

@Component({
  selector: 'pending-verification-dialog',
  host: { class: 'dialog' },
  templateUrl: './pending-verification.dialog.html',
  styleUrls: ['./pending-verification.dialog.scss']
})
export class PendingVerificationDialogComponent {
  public actionLoading = {} as any;
  public isAuthenticated = false;

  public VerificationMethod = VerificationMethod;

  constructor(
    private _action: ActionHelper,
    @Inject(MAT_DIALOG_DATA) private _data,
    private _authService: AuthenticationService,
    private _accountService: AccountService,
    public dialogRef: MatDialogRef<PendingVerificationDialogComponent>
  ) {}

  public get method(): VerificationMethod {
    return this._data?.method;
  }

  public get action(): string {
    return this._data?.action;
  }

  public get data() {
    return this._data?.data;
  }

  public get formGroup(): FormGroup {
    return this.data?.formGroup;
  }

  public get sessionId() {
    return this.data?.sessionId;
  }

  public get displayCode(): any[] {
    const code = this.data?.verificationCode;
    if (!code) {
      return [];
    }
    return code.split('');
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }

  public registerSmartId() {

    const msg = this._action.createMessage();
    this._accountService.registerViaSmartId(this.sessionId).result(
      this.formGroup,
      statusResult => {
        this.closeDialog(statusResult);
      },
      statusError => {
        console.error(statusError);
        this.closeDialog(null);
      },
      "registerViaSmartId",
      this.actionLoading,
      msg
    );
  }

  public verifySmartId() {

    const msg = this._action.createMessage();
    this._authService.verifySmartId(this.sessionId).result(
      this.formGroup,
      statusResult => {
        this.closeDialog(statusResult);
      },
      statusError => {                
        this.closeDialog(null);
      },
      "verifySmartId",
      this.actionLoading,
      msg
    );
  }

  public initRegisterMobileId() {
    this.waitForRegisterMobileId(this.sessionId);
    // .result(
    //   this.formGroup,
    //   statusResult => {
    //     this.closeDialog(statusResult);
    //   },
    //   err => {
    //     console.error(err);
    //     this.closeDialog(null);
    //   }
    // );
  }

  public waitForRegisterMobileId(sessionId: string): void {

    const msg = this._action.createMessage();
    this._accountService.registerViaMobileId(sessionId).result(
      this.formGroup,
      res2 => {
        // if (res2 == 'RUNNING') {
        //   console.log('Mobile-ID is running');
        //   setTimeout(() => {
        //     this.waitForRegisterMobileId(sessionId);
        //   }, 2000);
        //   return;
        // }

        if (this.isAuthenticated) {
          console.log('Already authenticated');
          return;
        }

        this._accountService.getAccountInfo().result(null, info => {
          console.log('Mobile-ID authenticating');
          this.isAuthenticated = true;
          console.log('Mobile-ID authenticated');
          this.closeDialog(info);
        });
      },
      e => {
        this.closeDialog(e);
        console.error(e);
      },
      'register',
      this.actionLoading,
      msg
    );

    if (this.isAuthenticated) {
      console.log('Authenticated, returning');
      return;
    }
  }

  public initVerifyMobileId() {
    this.waitForVerifyMobileId(this.sessionId);
    // this._authService.verifyMobileId(this.sessionId).result(
    //   this.formGroup,
    //   statusResult => {
    //     this.closeDialog(statusResult);
    //   },
    //   err => {
    //     console.error(err);
    //     this.closeDialog(null);
    //   }
    // );
  }

  public waitForVerifyMobileId(sessionId: string): void {

    const msg = this._action.createMessage();
    this._authService.verifyMobileId(sessionId).result(
      this.formGroup,
      res2 => {
        if (res2 == 'RUNNING') {
          console.log('Mobile-ID is running');
          // setTimeout(() => {
          //   this.waitForVerifyMobileId(sessionId);
          // }, 2000);
          return;
        }

        if (this.isAuthenticated) {
          console.log('Already authenticated');
          return;
        }

        this._accountService.getAccountInfo().result(null, info => {
          console.log('Mobile-ID authenticating');
          this.isAuthenticated = true;
          console.log('Mobile-ID authenticated');
          this.closeDialog(info);
        });
      },
      e => {
        this.closeDialog(e);
        console.error(e);
      },
      'verify',
      this.actionLoading,
      msg
    );

    if (this.isAuthenticated) {
      console.log('Authenticated, returning');
      return;
    }
  }

  public verifyIdCard() {
    const msg = this._action.createMessage();
    this._authService.verifyIdCardSession(this.sessionId).result(
      this.formGroup,
      statusResult => {
        this.closeDialog(statusResult);
      },
      err => {
        console.error(err);
        this.closeDialog(null);
      },
      'verifyIdCard',
      this.actionLoading,
      msg
    );
  }

  public cancelPendingVerification() {
    this.closeDialog(null);
  }

  private _authenticateSmartId() {

    const msg = this._action.createMessage();
    var timer = setInterval(() => {
      this._authService.authenticateSmartId(this.sessionId).result(
        null,
        res => {
          if (res == 'RUNNING') {
            return;
          }
          clearInterval(timer);
          this.closeDialog(res);
        },
        e => {
          clearInterval(timer);
          this.closeDialog(e);
        },
        'login',
        this.actionLoading,
        msg
        // message3
      );
    }, 2000);
  }

  private _authenticateMobileId() {

    const msg = this._action.createMessage();
    // var timer = setInterval(() => {
      this._authService.authenticateMobileId(this.sessionId).result(
        null,
        res => {
          if (res == 'RUNNING') {
            return;
          }
          //clearInterval(timer);
          this.closeDialog(res);
        },
        e => {
          //clearInterval(timer);
          this.closeDialog(e);
        },
        'login',
        this.actionLoading,
        msg
        // message2
      );
    //}, 2000);
  }

  ngOnInit() {
    switch (this.action) {
      case 'login':
        switch (this.method) {
          case VerificationMethod.SmartId:
            this._authenticateSmartId();
            break;
          case VerificationMethod.MobileId:
            this._authenticateMobileId();
            break;
          // case VerificationMethod.Deposit:
          //   this.verifyDeposit();
          //   break;
        }
        break;

      case 'register':
        switch (this.method) {
          case VerificationMethod.SmartId:
            this.registerSmartId();
            break;
          case VerificationMethod.MobileId:
            this.initRegisterMobileId();
            break;
          case VerificationMethod.IdCard:
            this.verifyIdCard();
            break;
          // case VerificationMethod.Deposit:
          //   this.verifyDeposit();
          //   break;
        }
        break;

      case 'verify':
        switch (this.method) {
          case VerificationMethod.SmartId:
            this.verifySmartId();
            break;
          case VerificationMethod.MobileId:
            this.initVerifyMobileId();
            break;
          case VerificationMethod.IdCard:
            this.verifyIdCard();
            break;
          // case VerificationMethod.Deposit:
          //   this.verifyDeposit();
          //   break;
        }
        break;
    }
  }
}
