<ng-container [ngSwitch]="isLoggedIn">
  <div class="login-wrapper" *ngSwitchCase="false">
    <!-- <language-select class="ml10 mr10"></language-select> -->
    <div class="nav-box login" (click)="openLoginDialog()">
      {{'Navigator.Action.LogIn' | translate}}
    </div>
    <div class="nav-box register ml5" routerLink="register">
      {{'Navigator.Action.Register' | translate}}
    </div>
    <!-- <toto-button label="Users.Login.Login" color="theme-1" (click)="openLoginDialog()"></toto-button>
    <toto-button class="ml5" color="theme-2" label="Users.Register.Register" routerLink="register"></toto-button> -->
  </div>

  <div *ngSwitchCase="true" class="authenticated-user-wrapper" [class.drawer-open]="state.isNavigatorOpen">
    <div class="user-section user-account" (click)="toggleUserMenu()" [class.open]="state.isUserMenuOpen">
      <svg-icon fontSize="20px" key="menuAccount"></svg-icon>
      <user-balance class="mt5"></user-balance>
    </div>
    <user-notifications [class.open]="state.isNotificationsMenuOpen"></user-notifications>
    <user-menu 
      [showAdmin]="isAdmin" 
      [showLogout]="true" 
      [class.open]="state.isUserMenuOpen"
      (onMenuItemClicked)="onMenuItemClicked($event)">
    </user-menu>
  </div>
</ng-container>
<div *ngIf="state.isUserMenuOpen" class="nav-drawer-underlay" (click)="toggleUserMenu(false)"></div>