export enum BasketballMatchPhase {
    PreGame,
    FirstQuarter,
    BeforeSecondQuarter,
    SecondQuarter,
    FirstHalf,
    HalfTime,
    ThirdQuarter,
    BeforeFourthQuarter,
    FourthQuarter,
    SecondHalf,
    BeforeOvertime,
    Overtime,
    PostGame,
    GameAbandoned,
}

(BasketballMatchPhase as any).__typeName = 'BasketballMatchPhase';