export enum BadmintonMarketTypeValue
{
    MatchWinner = 6768,
    MatchGameHandicap = 8219,
    TotalPointsOverUnder = 9531,
    TotalPointsOddOrEven = 9785,
    Game1TotalPointsOverUnder = 12614,
    Game2TotalPointsOverUnder = 12615,
    Game3TotalPointsOverUnder = 12616,
    Game1NumberedPointWinner = 12617,
    Game2NumberedPointWinner = 12618,
    Game3NumberedPointWinner = 12619,
    Game1RaceTo5Points = 12620,
    Game1RaceTo10Points = 12621,
    Game1RaceTo15Points = 12622,
    Game2RaceTo5Points = 12623,
    Game2RaceTo10Points = 12624,
    Game2RaceTo15Points = 12625,
    Game3RaceTo5Points = 12626,
    Game3RaceTo10Points = 12627,
    Game3RaceTo15Points = 12628,
    Game1ExtraPoints = 12629,
    Game2ExtraPoints = 12630,
    Game3ExtraPoints = 12631,
    Game1Handicap = 12632,
    Game2Handicap = 12633,
    Game3Handicap = 12634,
    CorrectMatchScore = 12641,
    CompetitorATotalPointsOverUnder = 12642,
    CompetitorBTotalPointsOverUnder = 12643,
    Game1Winner = 12644,
    Game2Winner = 12645,
    Game3Winner = 12646,
    Game1TotalPointsOddOrEven = 12647,
    Game2TotalPointsOddOrEven = 12648,
    Game3TotalPointsOddOrEven = 12649,
    MatchPointHandicap = 13361,
    Game4TotalPointsOverUnder = 13752,
    Game5TotalPointsOverUnder = 13753,
    Game4NumberedPointWinner = 13754,
    Game5NumberedPointWinner = 13755,
    Game4Handicap = 13756,
    Game5Handicap = 13757,
    Game4Winner = 13762,
    Game5Winner = 13763,
    Game4TotalPointsOddOrEven = 13764,
    Game5TotalPointsOddOrEven = 13765,
    Game1CorrectScore = 13780,
    Game2CorrectScore = 13781,
    Game3CorrectScore = 13782,
    Game4CorrectScore = 13783,
    Game5CorrectScore = 13784,
    TotalGamesOverUnder = 13788,
    Game4RaceTo5Points = 13843,
    CompetitorATotalPointsOddOrEven = 13847,
    CompetitorBTotalPointsOddOrEven = 13848,
    Game1LeadAfter4Points = 13849,
    Game1LeadAfter6Points = 13850,
    Game1LeadAfter8Points = 13851,
    Game1LeadAfter10PointsDoNotSupportForNbl = 13852,
    Game1LeadAfter20PointsDoNotSupportForNbl = 13853,
    Game2LeadAfter2Points = 13854,
    Game2LeadAfter4Points = 13855,
    Game2LeadAfter6Points = 13856,
    Game2LeadAfter8Points = 13857,
    Game1LeadAfter2Points = 13858,
    Game2LeadAfter10PointsDoNotSupportForNbl = 13859,
    Game2LeadAfter20PointsDoNotSupportForNbl = 13860,
    Game3LeadAfter2Points = 13861,
    Game3LeadAfter4Points = 13862,
    Game3LeadAfter6Points = 13863,
    Game3LeadAfter8Points = 13864,
    Game3LeadAfter10PointsDoNotSupportForNbl = 13865,
    Game3LeadAfter20PointsDoNotSupportForNbl = 13866,
    Game4LeadAfter2Points = 13867,
    Game4LeadAfter4Points = 13868,
    Game4LeadAfter6Points = 13869,
    Game4LeadAfter8Points = 13870,
    Game5LeadAfter2Points = 13872,
    Game5LeadAfter4Points = 13873,
    Game5LeadAfter6Points = 13874,
    Game5LeadAfter8Points = 13875,
}

(BadmintonMarketTypeValue as any).__typeName = 'BadmintonMarketTypeValue';