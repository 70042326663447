import { Component, Input, OnInit, Output, Optional, Inject, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, BehaviorSubject } from 'rxjs';

@Component({
  selector: "toto-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {

  private _dataLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  public title: string;

  @Input() public hasImage: boolean = false;

  @Input()
  public dialogRef: MatDialogRef<any>;

  @Output()
  public dataLoaded$: Observable<boolean> = this._dataLoadedSubject.asObservable();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    if (!this.data || !this.data.__resolve) {
      this._dataLoadedSubject.next(true);
      return;
    }

    let resolve = this.data.__resolve as () => Observable<any>;
    if (!resolve) {
      this._dataLoadedSubject.next(true);
      return;
    }

    let observable = resolve();
    if (!observable) {
      this._dataLoadedSubject.next(true);
      return;
    }

    observable
      .subscribe(res => {
        if (res instanceof Object) {
          Object.keys(res).forEach(x => {
            this.data[x] = res[x];
          });
        }

        this._dataLoadedSubject.next(true);
      });

  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
