<form class="dialog-container">

  <!-- <section class="dialog-image">
    <ng-content select="[header-image]"></ng-content>
  </section> -->

  <section class="dialog-header" [class.with-image]="hasImage">
    <div class="dialog-title">
      <ng-content select="[header-title]"></ng-content>
      <ng-content select="[header-image]"></ng-content>
    </div>
    <div class="dialog-actions">
      <ng-content select="[header-actions]"></ng-content>
      <toto-button color="transparent toto-button--no-border" icon="menuClose" iconSize="22px" (click)="closeDialog()"></toto-button>
    </div>
  </section>

  <section class="dialog-content">
    <ng-content select="[body]"></ng-content>
    <ng-content></ng-content>
  </section>

  <section class="dialog-footer">
    <ng-content select="[footer]"></ng-content>
  </section>

  <!-- <dialog-spinner *ngIf="!(dataLoaded$ | async)"></dialog-spinner> -->

</form>
