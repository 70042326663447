import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'close-account-success-dialog',
  host: { class: 'dialog' },
  templateUrl: './close-account-success.dialog.html',
  styleUrls: ['./close-account-success.dialog.scss']
})
export class CloseAccountSuccessDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _router: Router,
    public dialogRef: MatDialogRef<CloseAccountSuccessDialogComponent>
  ) {}

  public continue() {
    this.closeDialog();
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }
}
