<div class="user-notifications-header">
  <h4>{{'Navigator.Notifications.Notifications' | translate}}</h4>
</div>
<div class="user-notifications-list">
  <ul>

  
  
  </ul>
</div>
<div class="user-notifications-footer">
  <toto-button label="Navigator.Action.ViewAllNotifications" color="dark-grey" class="full-width"></toto-button>
</div>
