import { Component, Input, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppState } from 'src/app/app.state';
import { LoginDialogComponent } from 'src/app/components/dialogs/login/login.dialog';
import { RegisterDialogComponent } from 'src/app/components/dialogs/register/register.dialog';
import { ActionHelper } from 'src/app/helpers/action';
import { IIdentity } from 'src/app/models/IIdentity';
import { AuthenticationService} from 'src/app/services';

@Component({
  template: ``,
  providers: [ActionHelper]
})
export class UserBlockComponent {

  public ADMIN_LINK = "https://admin.toto.ee/";

  public isLoginLoading = false;

  public loginForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  constructor(
    public state: AppState,
    private _authService: AuthenticationService, 
    private _action: ActionHelper) { }

  public get user(): IIdentity {
    return this.state.user;
  }
  
  public get isAdmin(): boolean {
    return this.user?.role > 2;
  }

  public get isLoggedIn(): boolean {
    return this.state.isLoggedIn;
  }

  public async logOut() {
    await this._authService.logout();
  }

  public toggleUserMenu(toSet = !this.state.isUserMenuOpen) {
    this.state.isNotificationsMenuOpen = false;
    this.state.isUserMenuOpen = toSet;
  }

  public toggleNotificationsMenu(toSet = !this.state.isNotificationsMenuOpen) {
    this.state.isUserMenuOpen = false;
    this.state.isNotificationsMenuOpen = toSet;
  }

  public openLoginDialog() {
    this._action.openDialog(LoginDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {}
    }, () => {
      
    })
  }

  public openRegisterDialog() {
    this._action.openDialog(RegisterDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {}
    }, () => {
      
    })
  }

  public onMenuItemClicked(e) {
    this.toggleUserMenu(false);
  }

  public setShowLoginModal() {
    this._authService.showLoginModal = true;
  }

}
