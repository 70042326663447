import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocaleService } from 'src/app/services/locale.service';
import { AppState } from 'src/app/app.state';
import { LANGUAGE_OPTIONS } from 'src/app/app.settings';


@Component({
  selector: 'nav-languages',
  templateUrl: './nav-languages.component.html',
  styleUrls: ['./nav-languages.component.scss']
})
export class NavigatorLanguagesBlockComponent {

  @Input() public color: string;
  @Input() public showFlag = true;
  @Input() public showName = true;

  constructor(private _localeService: LocaleService, private _appState: AppState) {}

  public languageOptions = LANGUAGE_OPTIONS;

  public formGroup = new FormGroup({
    language: new FormControl(this._appState.language)
  })

  ngOnInit() {
    this.formGroup.get('language').valueChanges.subscribe(val => {
      this._localeService.setLanguageAndReload(val);
    })
  }

  public get selectedLanguage() {
    return this.formGroup.get('language').value;
  }

  public setLanguage(language) {
    this.formGroup.get('language').setValue(language);
  }

  public isLanguageActive(language): boolean {
    return language.value === this.selectedLanguage.value;
  }

}
