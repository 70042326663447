import { Component, Input, Output } from "@angular/core";
import { DataTableFilter } from "../data-table-filter";
import { FormGroup, FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FilterType, asArray } from "src/app/enum";
import { DropdownFlagValueFilterType, DropdownValueFilterType } from "src/app/enum/FilterType";

@Component({
  selector: "dropdown-filter",
  templateUrl: "./dropdown-data-table-filter.component.html",
  styleUrls: ["./dropdown-data-table-filter.component.scss", "../shared.scss"]
})
export class DropdownDataTableFilterComponent extends DataTableFilter {

  private _value: string;
  public options: any[] = [];
  public translate: boolean;
  public labelField: string;
  public value: string;
  
  public form: FormGroup = new FormGroup({ filter: new FormControl() });

  @Input() public fullscreenLabel: string;
  @Input() public showOptions = true;
  @Input() public valueType: FilterValueType = FilterValueType.String;
  @Output() public valueFilterType: DropdownValueFilterType | DropdownFlagValueFilterType;

  public valueFilterTypeOptions: any[];


  public isFlag: boolean = false;
  public fromArray: boolean = false;
  public fromArrayKey: string;

  ngOnInit() {
    if (this.isFlag) {
      this.valueFilterTypeOptions = asArray(DropdownFlagValueFilterType);
      this.valueFilterType = this.valueFilterTypeOptions[0]?.id;
      return;
    } else {
      this.valueFilterTypeOptions = asArray(DropdownValueFilterType);
      this.valueFilterType = this.valueFilterTypeOptions[0]?.id;
    }

    console.log(this.fullscreenLabel);
  }

  public getFilterType(): FilterType {
    return FilterType.Dropdown;
  }

  public getValue(): string {
    return this._value;
  }

  public getValueFilterType(): DropdownValueFilterType | DropdownFlagValueFilterType {
    return this.valueFilterType;
  }

  public onValueChanged(val: string): void {
    this._value = val;
    super.onValueChanged(val);
  }

  public onValueFilterChanged(val: any): void {
    this.valueFilterType = parseInt(val, 10);
    if (this._value === null || this._value === undefined) {
      return
    }
    this.onValueChanged(this._value);
  }

}

export enum FilterValueType {
  String = 1,
  Literal = 2
}