export enum MarketGroup {
  Result = 1, 
  HomeAway = 2, 
  OddEven = 3, 
  YesNo = 4, 
  ValueRange = 5, 
  CorrectScore = 6, 
  ExactNumber = 7,  
  OverUnder = 8, 
  AsianHandicap = 9, 
  Competitor = 10, 
  DoubleChance = 11, 
  HalfTimeFullTime = 12, 
  HandicapWithTie = 13, 
  HighestScoringHalf = 14, 
  ResultAndOverUnder = 15, 
  ResultAndTeamToScore = 16, 
  ResultAndYesNo = 17, 
  TeamToCard = 18, 
  TeamToScore = 19, 
  TeamToCorner = 20, 
  ResultTime = 21, 
  DoubleChanceYesNo = 22, 
  AamsDoubleChanceHomeTeam = 23, 
  AamsDoubleChanceAwayTeam = 24, 
  AamsDoubleChanceDraw = 25, 
  TeamToScoreInGame = 26, 
  DoubleChanceAndOverUnder = 27, 
  YesNoAndOverUnder = 28, 
  TeamToReachTarget = 29, 
  DoubleResult = 30, 
  CompetitorVsCompetitor = 31, 
  CompetitorVsCompetitorWithTie = 32, 
  ThreeCompetitorMarket = 33, 

  // New
  CompetitorVSCompetitorHandicap = 34, 
  GameEnd = 35, 
  HomeAwayAndOverUnder = 37, 
  CompetitorVsCompetitorOverUnder = 38, 
  HighestScoringQuarter = 39, 
  Touchdown = 40, 
  BoxingResult = 41, 
  SnookerColour = 42,
  DoubleResultNoDraw = 43,
  HalfTimeFullTimeNoDraw = 44,
  ValueRangeWithOutcome = 45,
  HomeDraw = 46,
  AwayDraw = 47,
  TennisCorrectGameScore = 48,



  NoMarket = 999999999
}

(MarketGroup as any).__typeName = 'MarketGroup';