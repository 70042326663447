import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService, AuthenticationService } from '../services';
import { AppState } from '../app.state';
import { MAIN_MENU_LINKS } from '../config/nav-links';


@Injectable({ providedIn: 'root' })
export class AffiliateGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _state: AppState,
    private _accountService: AccountService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      var clickId = this.getCookie('affiliateClickId');
      if(!clickId)
      {            
        var ref = route.queryParams.ref;
        if(!ref) {
          return;
        }
        var externalClickId = route.queryParams.externalclickid;

        this._accountService.getClickId(ref).result(
          null,
          res => {
              if(res.clickId) {
                  this.setCookie('affiliateClickId', res.clickId, 30);
                  this.setCookie('affiliateExternalId', externalClickId, 30);
              }
          },
          err => {
              console.log(err);
          }
        );
      }      

    return true;
  }

  private getCookie(cname) : string {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
  }

  private setCookie(name,value,days) : void {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
}