import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState, IUserBalance } from 'src/app/app.state';
import { IIdentity } from 'src/app/models/IIdentity';
import { RealtimeState } from 'src/app/modules/sports/state/realtime.state';

@Component({
  selector: 'user-balance',
  templateUrl: './user-balance.component.html',
  styleUrls: ['./user-balance.component.scss']
})
export class UserBalanceComponent {

  constructor(
    private _appState: AppState,
    private _realtime: RealtimeState
  ) {}

  public get totalBalance() {
    return this._appState.userBalance?.total ?? 0;
  }

  public get user(): IIdentity {
    return this._appState.user;
  }

  public get firstName(): string {
    return this.user?.given_name;
  }

  public get lastName(): string {
    return this.user?.name;
  }

  public duration = 0.001;

  private _balanceChangeSubscription: Subscription;

  // ngOnInit() {
  //   const userId = this.user?.nameid;

  //   if (!this._realtime.userChannel) {
  //     this._realtime.initUserChannel(userId);
  //   }

  //   this._balanceChangeSubscription = this._realtime.userChangeSubject$.subscribe(
  //     (res: IUserBalance) => {
  //       console.log(res);
  //       this._appState.userBalanceSubject$.next(res);
  //     }
  //   );
  // }

  // ngAfterViewInit() {
  //   setTimeout(_ => {
  //     this.duration = 2;
  //   },1000)
  // }

  // ngOnDestroy() {
  //   if (this._balanceChangeSubscription) {
  //     this._balanceChangeSubscription.unsubscribe();
  //   }
  // }
}
