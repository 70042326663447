export enum FutsalMarketTypeValue
{
    MatchWinner = 7469,
    TotalGoalsOverUnder = 7470,
    HandicapBetting = 7471,
    DoubleChance = 7772,
    FirstHalfHandicap = 8427,
    FirstHalfTotalGoalsOverUnder = 8428,
    DrawNoBet = 8731,
    TotalGoalsOddEven = 8733,
    HalfTimeFullTime = 9033,
    FirstTeamToScore = 9790,
    FirstHalfWinner = 10258,
    HighestScoringHalf = 11589,
    TeamBTotalGoalsOverUnder = 11595,
    TeamATotalGoalsOverUnder = 11596,
    SecondHalfHandicap = 11816,
    SecondHalfWinner = 11817,
    SecondHalfTotalGoalsOverUnder = 11818,
    TeamToScoreNextGoal = 12817,
    TeamToScoreNextGoal3Way = 12818,
    RaceTo2Goals = 12819,
    RaceTo3Goals = 12820,
    RaceTo4Goals = 12821,
    RaceTo5Goals = 12822,
    RaceTo6Goals = 12823,
    RaceTo7Goals = 12824,
    RaceTo8Goals = 12825,
    FirstHalfRaceTo2Goals = 12826,
    FirstHalfRaceTo3Goals = 12827,
    FirstHalfRaceTo4Goals = 12828,
    FirstHalfRaceTo5Goals = 12829,
    FirstHalfTotalGoalsOddEven = 12831,
    SecondHalfTotalGoalsOddEven = 12832,
    TeamA1stHalfTotalGoalsOverUnder = 12833,
    TeamA2ndHalfTotalGoalsOverUnder = 12834,
    TeamB1stHalfTotalGoalsOverUnder = 12835,
    TeamB2ndHalfTotalGoalsOverUnder = 12836,
    TeamATotalGoalsOddEven = 12837,
    TeamBTotalGoalsOddEven = 12838,
    TeamA1stHalfTotalGoalsOddEven = 12839,
    TeamA2ndHalfTotalGoalsOddEven = 12840,
    TeamB1stHalfTotalGoalsOddEven = 12841,
    TeamB2ndHalfTotalGoalsOddEven = 12842,
}

(FutsalMarketTypeValue as any).__typeName = 'FutsalMarketTypeValue';