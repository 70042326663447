import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services';
import { VerificationMethod } from '../../../views/user/settings/verification/user-verification.view';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'verification-success-dialog',
  host: { class: 'dialog' },
  templateUrl: './verification-success.dialog.html',
  styleUrls: ['./verification-success.dialog.scss']
})
export class VerificationSuccessDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _authService: AuthenticationService,
    private _router: Router,
    public dialogRef: MatDialogRef<VerificationSuccessDialogComponent>
  ) {}

  public continue() {
    // this._router.navigate(['/user/settings/verification']);
    this.closeDialog();
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }
}
