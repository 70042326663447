export enum TennisMarketTypeValue
{
    FirstSetWinner = 77,
    Lose1stSetAndWinMatch = 78,
    SetBetting = 120,
    HandicapGames = 311,
    MatchResult = 322,
    TotalGamesOverUnder = 6481,
    MostGames = 6505,
    SecondSetWinner = 6599,
    SecondSetTotalGames = 6650,
    FirstSetTotalGames = 6651,
    TotalGames = 6730,
    MostGames1stSet = 6783,
    DoublesMatch = 6930,
    DoublesGameHandicap = 7169,
    TotalSets = 7171,
    FirstSetCorrectScore = 7172,
    DoublesWin1stSet = 7173,
    DoublesSetBetting = 7175,
    SecondSetCorrectScore = 7530,
    ThirdSetCorrectScore = 7531,
    FirstSetTotalGamesOverUnder = 7643,
    TotalGamesOddOrEven = 7822,
    Win1stSetAndWinMatch = 7825,
    SecondSetTotalGamesOverUnder = 8438,
    SetHandicap = 8660,
    GameHandicapWithDraw = 8793,
    ThirdSetTotalGamesOverUnder = 8870,
    TotalSetsOverUnder = 9566,
    CurrentSetCorrectScore = 10581,
    NextServiceGameWinner = 10979,
    NextSetWinner = 10996,
    CurrentSetWinner = 10997,
    NextSetCorrectScore = 10999,
    CurrentServiceGameWinner = 11047,
    TieBreakWinner = 11056,
    CompetitorOneFirstServiceGameWinner = 11092,
    CompetitorTwoFirstServiceGameWinner = 11093,
    CurrentServiceGameWinnerSetNumberGameNumber = 11094,
    NextServiceGameWinnerSetNumberGameNumber = 11095,
    NumberedServiceGameWinner = 11096,
    NumberedServiceGameWinnerSetNumberGameNumber = 11097,
    NumberedSetWinner = 11098,
    NumberedSetCorrectScore = 11099,
    CurrentGameCorrectScore = 11101,
    NextGameCorrectScore = 11102,
    NumberedGameCorrectScore = 11103,
    CurrentGameCorrectScoreSetNumberGameNumber = 11104,
    NextGameCorrectScoreSetNumberGameNumber = 11105,
    NumberedGameCorrectScoreSetNumberGameNumber = 11106,
    TieBreakCorrectScore = 11107,
    WillCurrentServiceGameGoToDeuce = 11151,
    WillNextServiceGameGoToDeuce = 11152,
    WillNumberedServiceGameGoToDeuce = 11153,
    WillCurrentServiceGameGoToDeuceSetNumberGameNumber = 11154,
    WillNextServiceGameGoToDeuceSetNumberGameNumber = 11155,
    WillNumberedServiceGameGoToDeuceSetNumberGameNumber = 11156,
    WillCurrentSetGoToTieBreakSetNumber = 11157,
    WillNextSetGoToTieBreakSetNumber = 11158,
    WillNumberedSetGoToTieBreakSetNumber = 11159,
    CurrentServiceGamePointWinnerGameNumberPointNumber = 11328,
    NextServiceGamePointWinnerGameNumberPointNumber = 11329,
    NumberedServiceGamePointWinnerGameNumberPointNumber = 11335,
    CurrentServiceGamePointWinnerSetNumberGameNumberPointNumber = 11336,
    NextServiceGamePointWinnerSetNumberGameNumberPointNumber = 11337,
    NumberedServiceGamePointWinnerSetNumberGameNumberPointNumber = 11340,
    TieBreakPointsSetNumberPointNumber = 11344,
    CurrentSetTotalGamesOverUnder = 11629,
    NextSetTotalGamesOverUnder = 11630,
    NumberedSetTotalGamesOverUnder = 11631,
    CurrentSetGameHandicap = 11632,
    NextSetGameHandicap = 11633,
    NumberedSetGameHandicap = 11634,
    TiebreakTotalPointsOverUnder = 12987,
    Player2ToWinASet = 13731,
    Player1ToWinASet = 13732,
    NextServiceGameTotalPointsOverUnderSetNumberGameNumber = 14641,
    NextServiceGameTotalPointsOverUnderGameNumber = 14642,
    NumberedSetWinnerAndTotalSetGames = 14643,
    CurrentSetWinnerAndTotalSetGames = 14644,
    NextSetWinnerAndTotalSetGames = 14645,
    NumberedSetTotalGamesOddOrEven = 14839,
    CompetitorOneNextSetTotalGamesOverUnder = 15237,
    CompetitorTwoNextSetTotalGamesOverUnder = 15238,
    NumberedGameTotalPointsOddOrEvenSetNumberGameNumber = 15239,
    TiebreakTotalPointsOddOrEven = 15240,
    TiebreakPointHandicap = 15245,
    CompetitorOneCurrentSetTotalGamesOverUnder = 15247,
    CompetitorTwoCurrentSetTotalGamesOverUnder = 15248,
    CompetitorOneNumberedSetTotalGamesOverUnder = 15249,
    CompetitorTwoNumberedSetTotalGamesOverUnder = 15250,
    NextGameTotalPointsOddOrEvenGameNumber = 15251,
    CurrentSetTotalGamesOddOrEven = 15252,
    NextSetTotalGamesOddOrEven = 15254,
}

(TennisMarketTypeValue as any).__typeName = 'TennisMarketTypeValue';