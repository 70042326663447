export enum SafeBettingPeriod {
    Day = 1,
    Week = 2,
    Month = 3
}

(SafeBettingPeriod as any).__typeName = 'SafeBettingPeriod';

const _ = 'Enums.SafeBettingPeriod.';
(SafeBettingPeriod as any).__altTranslationKeys = {
    [SafeBettingPeriod.Day]: _ + 'Day',
    [SafeBettingPeriod.Week]: _ + 'Week',
    [SafeBettingPeriod.Month]: _ + 'Month',
};