import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.view.html',
  styleUrls: ['./user.view.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserViewComponent {

  public contentMaxWidth = 'user';

  constructor(private _route: ActivatedRoute) {
    const { contentMaxWidth } = _route.snapshot.data;
    if (contentMaxWidth) {
      this.contentMaxWidth = contentMaxWidth;
    }
  }

}
