export enum ToteSystem {
    Atg = 1,
    Pmu = 3,
    Colossus = 4,
    EstToto = 5,
}

(ToteSystem as any).__typeName = 'ToteSystem';

const _ = 'Enums.ToteSystem.';
(ToteSystem as any).__altTranslationKeys = {
    [ToteSystem.Atg]: _ + 'Atg',
    [ToteSystem.Pmu]: _ + 'Pmu',
    [ToteSystem.Colossus]: _ + 'Colossus',
    [ToteSystem.EstToto]: _ + 'EstToto'
};
