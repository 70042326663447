import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PAGE_TITLE_PREFIX, PAGE_TITLE_SEPARATOR } from '../config/constants';
import { TranslateEnumPipe } from '../pipes';

@Injectable({ providedIn: 'root' })
export class TitleService {

  public constructor(
    private _translateService: TranslateService,
    private _titleService: Title,
    private _translateEnumPipe: TranslateEnumPipe
  ) {}

  public subscription: Subscription;

  private _translateTitle(newTitle: string, params?: any) {
    this._titleService.setTitle((PAGE_TITLE_PREFIX ? PAGE_TITLE_PREFIX + PAGE_TITLE_SEPARATOR : '') + this._translateService.instant(newTitle, params));
  }

  public setTitle(newTitle: string, params?: any) {
    this._translateTitle(newTitle);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this._translateService.onLangChange.subscribe(_ => {
      this._translateTitle(newTitle, params);
    });
  }


  private _mapTitleFragment(fragment) {
    const translateEnum = fragment.enum;
    if (translateEnum) {
      return this._translateEnumPipe.transform(translateEnum.value, translateEnum.name);
    }
    if (fragment.key) {
      return this._translateService.instant(fragment.key, fragment.params);
    }
    return;
  }

  private _translateTitleFragments(fragments: ITitleFragment[] = []) {
    let translatedFragments = fragments.map(fragment => this._mapTitleFragment(fragment));
    let titles = [PAGE_TITLE_PREFIX, ...translatedFragments];
    const newTitle = titles.join(PAGE_TITLE_SEPARATOR);
    this._titleService.setTitle(newTitle);
  }

  public setTitleFragments(fragments: ITitleFragment[]) {
    this._translateTitleFragments(fragments);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this._translateService.onLangChange.subscribe(_ => {
      this._translateTitleFragments(fragments);
    });
  }

}

export interface ITitleFragment {
  key?: string;
  params?: any;
  enum?: ITitleFragmentEnumValue;
}

interface ITitleFragmentEnumValue {
  value: any;
  name: string;
}