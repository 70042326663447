import {
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  Component,
  ChangeDetectionStrategy,
  AfterViewChecked,
  HostListener
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader, SortDirection } from '@angular/material/sort';
import { merge, Subscription, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


import { ITableParams } from './table-params';
import { MatTable } from '@angular/material/table';
import { DataTableService } from 'src/app/services/data-table.service';
import {
  DateValueFilterType,
  DropdownValueFilterType,
  FilterType,
  TextValueFilterType
} from 'src/app/enum/FilterType';
import { LocalStorage } from 'src/app/helpers';
import { DataTableFilterDirective } from 'src/app/directives/data-table-filter.directive';
import { Bool, Status, asBoolArray, asArray, HttpMethod } from 'src/app/enum';
import { IReactiveFormsDeepMappingOptions } from 'src/app/util/form-util';
import { DataTableDataSource, IGroupByColumn } from './data-table.datasource';
import { DataTableFilter } from './filters/data-table-filter';
import { DataTableFilterODataSerializer } from './data-table-filter-serializer';
import { FilterValueType } from './filters/dropdown-filter/dropdown-data-table-filter.component';

@Component({
  template: ''
}) // Necessary for proper injection
export class DataTableComponent<TItem, TData>
  implements OnInit, AfterViewInit, AfterViewChecked {
  public actionLoading = {} as any;

  // for use in template
  public FilterType = FilterType;
  public FilterValueType = FilterValueType;
  public Bool = Bool;
  public Status = Status;
  public trueFalse: any[] = asBoolArray(Bool);
  public statuses: any[] = asArray(Status);

  public isLoading = false;
  protected loadAfterViewInit = false;

  public TextValueFilterType = TextValueFilterType;
  public DropdownValueFilterType = DropdownValueFilterType;
  public DateValueFilterType = DateValueFilterType;

  private _dataSource: DataTableDataSource<TItem, TData>;

  private _serializer = new DataTableFilterODataSerializer();

  private _isInitialized: boolean;

  private _filters: DataTableFilter[];
  private _sort: MatSort;

  private _onUpdate = new EventEmitter();
  private _onRowClick = new EventEmitter<TItem>();

  protected colPropertyMap: any;

  public selection: any[] = [];
  public currentPageSelected: string[] = [];

  private _activeRowsCount = 0;

  @Input() public paginator: MatPaginator;

  @ViewChild('table') tableRef: MatTable<any>;

  @ViewChildren(MatSort)
  protected sorts: QueryList<MatSort>;

  @ViewChildren(DataTableFilterDirective)
  protected filterDirectives: QueryList<DataTableFilterDirective>;

  @Input() public displayedColumns: string[];

  @Input() public dataUrl: string;

  @Input()
  public method = HttpMethod.Get;

  @Input()
  public initialData: any;

  @Input()
  private _customFilter: any;

  protected get customFilter(): any {
    return this._customFilter;
  }
  protected set customFilter(value: any) {
    this._customFilter = value;
  }

  @Input() public params: any = {};

  @Input() public formMappingOptions: IReactiveFormsDeepMappingOptions;

  @Input() public groupBy: IGroupByColumn[];

  @Input() public filterToggleLocalStorageKey: string;
  @Input() public showFilters = true;

  @Output()
  public onUpdate$ = this._onUpdate.asObservable();

  @Output()
  public onRowClick$ = this._onRowClick.asObservable();

  public loadOnInit = true;

  public get dataSource(): DataTableDataSource<TItem, TData> {
    return this._dataSource;
  }

  public get hasData() {
    return this.dataSource?.data?.length > 0 ? true : false;
  }

  public boolFilterOptions = [
    {
      name: 'True',
      id: true
    },
    {
      name: 'False',
      id: false
    }
  ];

  private _filterSubscription: Subscription;
  private _sortSubscription: Subscription;
  private _sortStateSubscription: Subscription;
  private _pageSizeSubscription: Subscription;
  private _pageIndexSubscription: Subscription;
  private _pageLoadSubscription: Subscription;
  private _countSubscription: Subscription;

  constructor(
    private _dataService: DataTableService<TItem, TData>,
    public _cd: ChangeDetectorRef
  ) {}

  public detectChanges() {
    this._cd.detectChanges();
  }

  public hookPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
  }

  public ngOnInit(): void {

    if (this.filterToggleLocalStorageKey) {
      this.showFilters = LocalStorage.getValue(this.filterToggleLocalStorageKey) ?? true;
    }

    this._dataSource = new DataTableDataSource<TItem, TData>(
      this._dataService,
      this.method
    );

    if (this.loadOnInit) {
      this.load();
    }
  }

  public ngAfterViewInit(): void {
    this._bindFilters();
    this._bindSorts();

    if (this.paginator) {
      this._pageLoadSubscription = this.paginator.page.subscribe(_ => {
        this.load();
      });
      this._countSubscription = this._dataSource.count$.subscribe(
        count => (this.paginator.length = count)
      );
      this._pageIndexSubscription = this._dataSource.page$.subscribe(page => {
        this.paginator.pageIndex = page;
      });
      this._pageSizeSubscription = this._dataSource.pageSize$.subscribe(
        pageSize => {
          if (pageSize) {
            this.paginator.pageSize = pageSize;
          }
        }
      );
      this._sortStateSubscription = this._dataSource.sort$.subscribe(x => {
        if (x) {
          var segments = x.split(' ');
          this._sort.active = segments[0];
          this._sort.direction = (segments[1] || '') as SortDirection;

          // HACK to update UI (https://github.com/angular/components/issues/10242)
          const toState = 'active';
          const colId = this.getColId(segments[0]);
          const col = this._sort.sortables.get(colId) as MatSortHeader;
          col._setAnimationTransitionState({ toState });
        }
      });
    }

    this._isInitialized = true;
    if (this.loadAfterViewInit) {
      this.load();
    }

    this.detectChanges();
  }

  protected getColId(sortable: string) {
    return sortable;
  }

  public ngAfterViewChecked(): void {
    if (!this._isInitialized) {
      return;
    }

    this._bindSorts();
    this._bindFilters();
  }

  public load(initialData: any = this.initialData): void {
    if (!this._dataSource) {
      return;
    }

    if (initialData) {
      this._dataSource.setData(
        initialData,
        this.formMappingOptions,
        this.groupBy
      );
      this.initialData = null;
    } else {
      this._dataSource.loadData(
        this.dataUrl,
        this.getData(),
        this.getTableParams(),
        this.formMappingOptions,
        this.groupBy
      );
    }

    this._onUpdate.emit();
  }

  public updateDataSourceData(data = this.dataSource.data) {
    this._dataSource.updateData(
      data,
      this.formMappingOptions,
      this.groupBy
    );
  }

  public pushItem(item) {
    this._dataSource.pushItem(item, this.formMappingOptions, this.groupBy);
  }

  protected getData(): any {
    return this.params;
  }

  // For data table filters
  public onFiltersChange(params) {
    this.params = params;
    this.load();
  }

  public toggleFilters(toSet = !this.showFilters) {
    this.clearFilters();
    this.showFilters = toSet;
    LocalStorage.set(this.filterToggleLocalStorageKey, toSet);
  }

  // Grouping

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    const criteria = row.groupFor;
    const searchTerm = row[criteria];
    if (criteria) {
      this.dataSource.setFilter({ criteria: criteria, searchTerm: searchTerm });
    }

    if (this.tableRef) {
      this.tableRef.updateStickyColumnStyles();
      this.tableRef.updateStickyHeaderRowStyles();
      this.tableRef.renderRows();
    }
  }

  isGroup(index, item): boolean {
    return item.level ? true : false;
  }

  //

  protected getTableParams(): ITableParams {
    return {
      filter: this._getFilterParam(),
      sort: this.getSortParam(this._sort),
      pageIndex: this._getPageIndexParam(),
      pageSize: this._getPageSizeParam(),
      count: this._getCountParam()
    };
  }

  protected onRowClick(row): void {
    this._onRowClick.emit(row);
  }

  protected sortParamHandler(sort: MatSort) {
    return sort.active;
  }

  protected getSortParam(sort: MatSort): string {
    if (!sort || !sort.active || sort.direction === '') {
      return '';
    }

    let prop = this.sortParamHandler(sort);
    if (
      this.colPropertyMap &&
      this.colPropertyMap.hasOwnProperty(sort.active)
    ) {
      prop = this.colPropertyMap[sort.active];
    }

    return prop + ' ' + sort.direction;
  }

  private _getFilterParam(): string {
    if (this.customFilter) {
      return this.customFilter;
    }

    if (!this._filters) {
      return '';
    }

    return this._serializer.serialize(this._filters, this.colPropertyMap);
  }

  private _getPageIndexParam(): number {
    return this.paginator ? this.paginator.pageIndex : 0;
  }

  private _getPageSizeParam(): number {
    return this.paginator ? this.paginator.pageSize : null;
  }

  private _getCountParam(): boolean {
    return this.paginator ? true : false;
  }

  private _bindReload(...events: Observable<any>[]): Subscription {
    return merge(...events)
      .pipe(
        tap(() => {
          this.resetPaginator();
          this.load();
        })
      )
      .subscribe();
  }

  private _bindSorts(): void {
    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }

    if (!this.sorts || !this.sorts.first) {
      return;
    }

    this._sort = this.sorts.first;
    this._sortSubscription = this._bindReload(this._sort.sortChange);
  }

  private _bindFilters(): void {
    if (this._filterSubscription) {
      this._filterSubscription.unsubscribe();
    }

    if (!this.filterDirectives) {
      return;
    }

    this._filters = this.filterDirectives.map(x => x.compRef.instance);
    const filterEvents = this._filters.map(x => x.onChange);
    this._filterSubscription = this._bindReload(...filterEvents);
  }

  protected clearSorts() {
    this._sort = undefined;
  }

  protected clearFilters() {
    this._filters = undefined;
  }

  public resetPaginator(): void {
    if (!this.paginator) {
      return;
    }

    this.paginator.pageIndex = 0;
  }

  public isItemSelected(row): boolean {
    return this.selection.find(x => x.id === row.id) ? true : false;
  }

  public toggleSelection(row): void {
    const index = this._getSelectionItemIndex(row);
    if (index < 0) {
      this.selection.push(row);
      this.currentPageSelected.push(row.id);
    } else {
      this.selection.splice(index, 1);
      const i = this._getCurrentPageItemIndex(row);
      if (i > -1) {
        this.currentPageSelected.splice(i, 1);
      }
    }
  }

  public emptySelectedItems() {
    this.selection = [];
    this.currentPageSelected = [];
  }

  protected _getSelectionItemIndex(row): number {
    return this.selection.findIndex(x => x.id === row.id);
  }

  protected _getCurrentPageItemIndex(row): number {
    return this.currentPageSelected.findIndex(x => x === row.id);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    return this.currentPageSelected.length === this._activeRowsCount;
  }

  public masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach(row => {
        const index = this._getSelectionItemIndex(row);
        if (index > -1) {
          this.selection.splice(index, 1);
        }
      })
    } else {
      this.dataSource.data.forEach(row => {
        if (!this.isItemSelected(row)) {
          this.selection.push(row);
        }
      });
    }
    this._updateCurrentPageSelection();
  } 

  protected _updateCurrentPageSelection(data = this.dataSource.data) {
    this.currentPageSelected = [];
    data?.forEach((row: any) => {
      this.isItemSelected(row) ? this.currentPageSelected.push(row.id) : null;
    });
    this._activeRowsCount = data ? data.length : 0;
  }


  ngOnDestroy() {
    if (this._filterSubscription) {
      this._filterSubscription.unsubscribe();
    }

    if (this._sortSubscription) {
      this._sortSubscription.unsubscribe();
    }

    if (this._sortStateSubscription) {
      this._sortStateSubscription.unsubscribe();
    }

    if (this._pageSizeSubscription) {
      this._pageSizeSubscription.unsubscribe();
    }

    if (this._pageIndexSubscription) {
      this._pageIndexSubscription.unsubscribe();
    }

    if (this._pageLoadSubscription) {
      this._pageLoadSubscription.unsubscribe();
    }

    if (this._countSubscription) {
      this._countSubscription.unsubscribe();
    }
  }
}
