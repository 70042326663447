<form [formGroup]="bonusGroup">
  <div class="form-title">
    <h2>{{'Views.User.Bonus.Overview.Title.Overview' | translate}}</h2>
    <h5>{{'Views.User.Bonus.Overview.Title.MyBonuses' | translate}}</h5>
  </div>

  <div class="form-body">
    <div class="block-container">
      <div class="row">
        <!-- <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="inner-block">
            <h5>
              {{'Views.User.Bonus.Overview.Title.HorseBettingBonus' | translate}}
            </h5>
            <div>
              <user-balance></user-balance>
            </div>
          </div>
        </div> -->
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div class="inner-block">
            <h5>
              {{'Views.User.Bonus.Overview.Title.SportsBettingBonus' | translate}}
            </h5>
            <div class="flex">
              <div class="user-balance">{{ bonusTotal }}</div> <div class="currency">€</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 class="bonuses-title">{{ 'Views.User.Bonus.Overview.Bonuses.Title' | translate }}</h4>

    <!-- 
    <div class="filters">
      <toto-button
        class="mr15 filter"
        color="green"
        id="showAll"
        (click)="showAllBonuses()"
        >{{ 'Views.User.Bonus.Overview.Bonuses.All' | translate }}</toto-button
      >
      <toto-button
        class="mr15 filter"
        id="showAvailable"
        (click)="showAvailableBonuses()"
        >{{ 'Views.User.Bonus.Overview.Bonuses.Available' | translate }}</toto-button
      >
      <toto-button
        class="mr15 filter"
        id="showActive"
        (click)="showActiveBonuses()"
        >{{ 'Views.User.Bonus.Overview.Bonuses.Active' | translate }}</toto-button
      >
      <toto-select
        for="bonusType"
        placeholder="boonuse tüüp"
        [options]="bonusOptions"
        type="select"
        labelField="text"
      ></toto-select> 
    </div>
    -->

    <div class="bonus-container" *ngFor="let campaign of campaigns">
        <toto-campaign [campaign]="campaign"></toto-campaign>
    </div>
  </div>
</form>
