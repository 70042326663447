<div class="card-message-wrapper color--{{color}}">
  <span class="card-message-indicator"></span>
  <div class="flex flex-column f-1">
    <div class="card-message-text">
      <ng-content></ng-content>
      <span class="flex flex-row" *ngFor="let message of messages; let i = index">
        {{message | translate }}
      </span>
    </div>
  </div>
</div>
