export enum ExternalRaceState {
    UNKNOWN = 0,
    START = 1,
    STOP = 2,
    RED = 3,
    RIDE = 4,
    ON_TRACK = 5,
    THREE_MN_START = 6,
    UNDER_ORDER = 7,
    FALSE_START = 8,
    STARTED = 9,
    TEMPORARY_ARRIVED = 10,
    TEMPORARY_PICTURE = 11,
    TEMPORARY_VALIDATED = 12,
    RED_ARRIVED = 13,
    DEFINITIVE_ARRIVED = 14,
    END = 15,
    CANCELLED = 16
}