import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountService, TitleService } from 'src/app/services';
import { AppState } from 'src/app/app.state';
import { ViewBase } from 'src/app/views/view.base';

@Component({
  selector: 'bonus-campaigns',
  templateUrl: './bonus-campaigns.view.html',
  styleUrls: ['./bonus-campaigns.view.scss']
})
export class UserBonusCampaignsViewComponent extends ViewBase {

  public titleFragments = [
    { key: 'Views.User.Bonus.Campaigns.Title.MyBonuses' },
    { key: 'Views.User.Bonus.Campaigns.Title.Campaigns' }
  ]

  public cashOutInProgress = false;

  public formGroup = new FormGroup({
    bonus: new FormControl()
  })

  constructor(
    _titleService: TitleService,
    private _accountService: AccountService, 
    private _appState: AppState
  ) {
    super(_titleService);
  }

  public get canContinue() {
    return this.formGroup.get('bonus').value;
  }

  public tryBonus() {
    return;
  }

}
