<form [formGroup]="formGroup" (ngSubmit)="save()">
  <div class="form-title">
    <h2>
      {{'Navigator.Menu.ResponsibleGame.AccountClosure' | translate}}
    </h2>
    <h5>{{'Navigator.Menu.ResponsibleGame.ResponsibleGame' | translate}}</h5>
  </div>

  <div class="form-body">
    <p
      [innerHTML]="'Views.User.ResponsibleGaming.CloseAccount.Introduction' | translate"
    ></p>
    <br />

    <div class="restraint-block" [ngSwitch]="currentStep">
      <ng-container *ngSwitchCase="CloseAccountViewStep.ChoosePeriod">
        <form-field
          labelWidth="custom-2"
          label="Views.User.ResponsibleGaming.CloseAccount.Label.ValidityPeriod"
        >
          <toto-select
            for="months"
            [options]="validityPeriodOptions"
            fullscreenLabel="Views.User.ResponsibleGaming.CloseAccount.Label.ValidityPeriod"
            labelField="label"
            compareField="value"
            [clearable]="false"
            [translate]="true"
          >
          </toto-select>
        </form-field>
        <form-field
          labelWidth="custom-2"
          label="Views.User.ResponsibleGaming.CloseAccount.Label.Reason"
        >
          <toto-input for="reason"></toto-input>
        </form-field>
      </ng-container>

      <ng-container *ngSwitchCase="CloseAccountViewStep.Confirmation">
        <form-field
          labelWidth="custom-2"
          label="Views.User.ResponsibleGaming.CloseAccount.Label.ValidityPeriod"
        >
          {{formGroup.get('months').value?.label | translate}}
        </form-field>
        <form-field
          labelWidth="custom-2"
          label="Views.User.ResponsibleGaming.CloseAccount.Label.Reason"
        >
          {{formGroup.get('reason').value || '-'}}
        </form-field>
        <form-field labelWidth="custom-2" label="Common.Label.Password">
          <toto-input
            for="password"
            type="password"
            autocomplete="off"
          ></toto-input>
        </form-field>
      </ng-container>
    </div>
  </div>

  <div class="form-footer" [ngSwitch]="currentStep">
    <ng-container *ngSwitchCase="CloseAccountViewStep.ChoosePeriod">
      <toto-button
        label="Action.Continue"
        color="theme-1"
        (click)="toView(CloseAccountViewStep.Confirmation)"
      >
      </toto-button>
    </ng-container>

    <ng-container *ngSwitchCase="CloseAccountViewStep.Confirmation">
      <toto-button
        type="submit"
        label="Action.Confirm"
        color="theme-1"
        class="mr15"
        (click)="save()"
      >
      </toto-button>
      <toto-button
        label="Action.Cancel"
        color="dark-grey"
        (click)="toView(CloseAccountViewStep.ChoosePeriod)"
      >
      </toto-button>
    </ng-container>
  </div>
</form>
