import { Directive, Input, OnInit, AfterViewChecked, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../services';
import { AppState } from '../app.state';

@Directive({
  selector: '[isAuthenticated]'
})
export class IsAuthenticatedDirective {

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _state: AppState) {
  }

  private subscription: Subscription;

  public handleView() {
    this._viewContainer.clear();
    if (this._state.isLoggedIn) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      return;
    }
}

  ngOnInit() {
    this.handleView();

    this.subscription = this._state.token.subscribe(token => {
        this.handleView();
    });
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

}
