import { Component, Input, Optional, Host, OnInit, HostBinding } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, ControlContainer, UntypedFormControl } from '@angular/forms';
import { label } from 'src/app/enum';

@Component({
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements OnInit {

  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public type: string;
  @Input() public label: string;
  @Input() public inputLabel: string;
  @Input() public required = false;
  @Input() public readonly = false;
  @Input() public noLabel = false;
  @Input() public inputClass: string;
  @Input() public maxlength: string;
  @Input() public minRows: string;
  @Input() public additionalLabel: string;
  @Input() public labelWidth: any = "default";


  @Input() public labelTop = false;

  public formGroupDisabled = false;

  public get isInput() {
    return ['input-text', 'input-password', 'input-number', 'input-tel', 'input-email'].indexOf(this.type) >= 0;
  }

  public get isTextarea() {
    return this.type === 'textarea';
  }

  public get control() {

    if (!this.formGroup || !this.for) {
      return undefined;
    }

    return this.formGroup.get(this.for) as UntypedFormControl;
  }

  public get errors() {
    if (!this.control) {
      return [];
    }
    return this.control.errors;
  }

  public get inputType() {
    return this.type.replace("input-", "");
  }

  public translationsPath: string;

  public get labelKey() {

    if (this.translationsPath) {
      return this.translationsPath + '.label.' + this.label;
    }

    return this.label;
  }

  constructor(
    @Optional()  private _controlContainer: ControlContainer) {

  }

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
      this.formGroup = <UntypedFormGroup>this._controlContainer.control;
    }
  }
}
