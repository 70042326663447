<app-content contentMaxWidth="user" [ngSwitch]="isReset">

  <!-- PASSWORD IS NOT RESET -->
  <ng-container *ngSwitchCase="false">
    <form [formGroup]="formGroup" (ngSubmit)="resetPassword()" novalidate>
      <div class="form-title">
        <h2>{{'Views.User.ForgotPassword.Title' | translate}}</h2>
      </div>
      <div class="form-body" style="flex: 0;">
        <form-field [labelTop]="true" label="Views.User.ForgotPassword.Label.NewPassword">
          <toto-input type="password" name="password" for="password">
            <validation-errors for="password"></validation-errors>
          </toto-input>
        </form-field>

        <form-field [labelTop]="true" label="Views.User.ForgotPassword.Label.ConfirmNewPassword">
          <toto-input type="password" for="passwordConfirm">
            <validation-errors for="passwordConfirm"></validation-errors>
          </toto-input>
        </form-field>
      </div>
      <div class="form-footer">
        <toto-button color="green" type="submit" label="Views.User.ForgotPassword.Action.ChangePassword"
          [loading]="resettingInProgress" (click)="resetPassword()" class="full-width"></toto-button>
      </div>
    </form>
  </ng-container>

  <!-- PASSWORD RESET SUCCESS PAGE -->
  <ng-container *ngSwitchCase="true">

      <form class="reset-success">
        <div class="success-logo">
          <svg-icon key="onnestunud" size='lg' style="width: 154px; height: 92px; margin-bottom: 30px;"></svg-icon>
        </div>
        <div class="title">
          <h3>{{"Views.User.Login.ResetPassword.ResetSuccess" | translate}}</h3>
        </div>
        <div class="login-button">
          <toto-button color="green" type="submit" label="Views.User.Login.Title"
            (click)="openLoginDialog()"></toto-button>
          <!-- <toto-button color="green" type="submit" label="Views.User.LoggedIn" [routerLink]="activeAppModuleRootLink" *ngSwitchCase="true"></toto-button> -->
          <!-- <toto-button label="Navigator.Action.LogIn" color="theme-2" routerLink="/sports/top" size="sm" (click)="openLoginDialog()></toto-button> -->
        </div>
      </form>
    
  </ng-container>

</app-content>