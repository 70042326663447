const PAGE_SIZE_OPTIONS_DEFAULT = [20, 40, 60, 80, 100];

export const PAGE_SIZE_OPTIONS = {
    DEFAULT: PAGE_SIZE_OPTIONS_DEFAULT
}

export const PAGE_SIZE = {
    DEFAULT: PAGE_SIZE_OPTIONS_DEFAULT[0],
}

export const DEFAULT_DATA_TABLE_PARAMS = {
    $count: 'true',  
    $top: `${PAGE_SIZE.DEFAULT}`
}