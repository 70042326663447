import { Component, Input, HostBinding } from "@angular/core";
import {Location} from '@angular/common';

@Component({
  selector: "no-content",
  templateUrl: "./no-content.component.html",
  styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent {

  @Input() public image: string = 'no-content';
  @Input() public text: string;
  @Input() public description: string;
  @Input() public icon: string = 'noTransactions';
  @Input() public iconSize: string = '154px';
  @Input() public showBackButton: boolean = false;

  @Input() public size: string = 'md';

  public hasReferrer = false;

  constructor(private _location: Location) {}

  public get getHasReferrer(): boolean {
    const referrer = document.referrer;
    const href = window.location.href;
    return referrer && referrer !== href;
  }

  public onBackClick() {
    if (this.hasReferrer) {
      this._location.back();
     }
  }

  public ngOnInit() {
    this.hasReferrer = this.getHasReferrer;
  }

}

