import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  Resolve,
  ActivatedRouteSnapshot,
  PreloadAllModules,
  Router
} from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import {
  LoginViewComponent,
  RegisterViewComponent,
  NotFoundViewComponent,
  InfoViewComponent,
  ResetPasswordViewComponent,
  UserTransactionLogViewComponent,
  UserPaymentsOverviewViewComponent
} from 'src/app/views';

import { InfoResolve } from './resolvers/info.resolve';
import {
  UserInfoResolve,
  TransactionStatusResolve,
  TransactionLogResolve,
  UserDepositTransactionResolve,
  HorsebettingTicketsResolve,
  SportsActiveTicketsResolve,
  SportsTicketsResolve,
  UserPreferencesResolve,
  UserBalanceResolve,
  UserSafeBettingResolve,
  UserContactInfoResolve,
  UserWithdrawalInfoResolve,
  UserBonusesResolve,
  UserDepositResolve
} from './resolvers';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { ToteSystem } from './enum';
import { LocalStorage } from './helpers';
import { LOCAL_STORAGE_KEY } from './config/local-storage-key';
import { MAIN_MENU_LINKS } from './config/nav-links';
import { AppState, TotoAppModule } from './app.state';
import { UserLimitsViewComponent } from './views/user/responsible-betting/limits/limits.view';
import { UserSelfRestraintViewComponent } from './views/user/responsible-betting/self-restraint/self-restraint.view';
import { UserViewComponent } from './views/user/user.view';
import { UserBonusOverviewViewComponent } from './views/user/bonus/overview/bonus-overview.view';
import { AppComponent } from './app.component';
import { AppModuleGuard } from './guards/app-module.guard';

import { UserContactDetailsViewComponent } from './views/user/settings/contact-details/user-contact-details.view';
import { UserAddressViewComponent } from './views/user/settings/address/user-address.view';
import { UserPreferencesViewComponent } from './views/user/settings/preferences/user-preferences.view';
import { UserPasswordViewComponent } from './views/user/settings/password/user-password.view';
import { UserVerificationViewComponent } from './views/user/settings/verification/user-verification.view';
import { UserCloseAccountViewComponent } from './views/user/responsible-betting/close-account/close-account.view';
import { UserPaymentsDepositViewComponent } from './views/user/payments/deposit/deposit.view';
import { UserPaymentsWithdrawalViewComponent } from './views/user/payments/withdrawal/withdrawal.view';
import { UserPaymentViewComponent } from './views/user/payments/payment/payment.view';
import { UserInfoGuard } from './guards/user-info.guard';
import { AffiliateGuard } from './guards/affiliate-guard';
import { ZimplerGuard } from './guards/zimpler-guard';

export const GlobalRoutes: Routes = [
  {
    path: '',
    data: {
      AppModule: null
    },
    // canActivate: [AppModuleGuard],
    children: [
      {
        path: 'Pages/:id/:title',
        component: InfoViewComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          initialData: InfoResolve
        }
      },
      {
        path: 'login',
        component: LoginViewComponent,
        canActivate: [IsLoggedInGuard]
      },
      {
        path: 'reset-password/:userId',
        component: ResetPasswordViewComponent
      },
      {
        path: 'user',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        component: UserViewComponent,
        data: {
          contentMaxWidth: 'user' // Content max-width for app-content component
        },
        children: [
          {
            path: '',
            redirectTo: 'settings',
            pathMatch: 'full'
          },

          // PAYMENTS
          {
            path: 'payments',
            // resolve: {
            //   initialData: UserSettingsResolve
            // },
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: UserPaymentsOverviewViewComponent,
                resolve: {
                  balanceData: UserBalanceResolve
                }
              },
              {
                path: 'deposit',
                component: UserPaymentsDepositViewComponent,
                resolve: {
                  data: UserDepositResolve
                }
              },
              {
                path: 'withdrawal',
                component: UserPaymentsWithdrawalViewComponent,
                resolve: {
                  withdrawalInfo: UserWithdrawalInfoResolve
                }
              },
              {
                path: ':paymentId',
                children: [
                  {
                    path: ':paymentStatus',
                    component: UserPaymentsOverviewViewComponent,
                    resolve: {
                      transactionData: UserDepositTransactionResolve
                    }, 
                  }
                ]
              }
            ]
          },

          // BONUS
          {
            path: 'bonus',
            component: UserBonusOverviewViewComponent,
            resolve: {
              data: UserBonusesResolve
            }
          },
          {
            path: 'limits',
            component: UserLimitsViewComponent,
            resolve: {
              data: UserSafeBettingResolve
            }
          },
          {
            path: 'restrictions',
            component: UserSelfRestraintViewComponent
          },
          {
            path: 'close-account',
            component: UserCloseAccountViewComponent
          },
          {
            path: 'settings',
            children: [
              {
                path: 'contact-details',
                component: UserContactDetailsViewComponent,
                resolve: {
                  settingsData: UserContactInfoResolve
                }
              },
              // {
              //   path: 'address',
              //   component: UserAddressViewComponent,
              //   resolve: {
              //     settingsData: UserInfoResolve
              //   }
              // },
              {
                path: 'preferences',
                component: UserPreferencesViewComponent,
                resolve: {
                  data: UserPreferencesResolve
                }
              },
              {
                path: 'password',
                component: UserPasswordViewComponent
              },
              {
                path: 'verification',
                component: UserVerificationViewComponent
              },
              {
                path: 'finalizeIdCardAuth/:sessionId',
                component: UserVerificationViewComponent,
                resolve: {
                  initialData: UserInfoResolve
                }
              }
            ]
          }
        ]
      },
      // Second user route due to different content max width
      {
        path: 'user',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        component: UserViewComponent,
        data: {
          contentMaxWidth: 'transaction-log' // Content max-width for app-content component
        },
        children: [
          {
            path: 'transactions',
            children: [
              {
                path: '',
                component: UserTransactionLogViewComponent,
                resolve: {
                  initialData: TransactionLogResolve
                }
              },
              {
                path: ':transactionId',
                component: UserPaymentsOverviewViewComponent,
                resolve: {
                  initialData: TransactionStatusResolve
                }
              }
            ]
          }
        ]
      },

      {
        path: 'register',
        component: RegisterViewComponent,
        canActivate: [IsLoggedInGuard]
      },
      {
        path: '**',
        component: NotFoundViewComponent
      }
    ]
  },
  {
    path: '**',
    component: NotFoundViewComponent
  }
];

const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AppModuleGuard, UserInfoGuard,AffiliateGuard,ZimplerGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo:
          MAIN_MENU_LINKS[
            LocalStorage.getValue(LOCAL_STORAGE_KEY.LAST_VISITED_MODULE)
          ]?.routerLink ||
          MAIN_MENU_LINKS[TotoAppModule.Sports]?.routerLink,
        pathMatch: 'full'
      },
      {
        path: MAIN_MENU_LINKS[TotoAppModule.HorseRacing]?.routerLink,
        loadChildren: () =>
          import('./modules/horse/horse.module').then(m => m.HorseModule),
        data: {
          AppModule: TotoAppModule.HorseRacing
        }
        // canActivate: [AppModuleGuard]
      },
      {
        path: MAIN_MENU_LINKS[TotoAppModule.Sports]?.routerLink,
        loadChildren: () =>
          import('./modules/sports/sports.module').then(m => m.SportsModule),
        data: {
          AppModule: TotoAppModule.Sports
        }
        // canActivate: [AppModuleGuard]
      },
      ...GlobalRoutes
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
