import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IMessageOptions, MessageDispatcher } from './messages';
import { IConfirmOptions, IDialogOptions, TotoDialog } from './dialogs';
import { ConfirmationDialog } from '../components/dialogs/confirm/confirm.dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ActionHelper {

  constructor(
    private _dialog: TotoDialog,
    private _message: MessageDispatcher,
    private _translateService: TranslateService
  ) { }

  public translate = (key: string) => this._translateService.instant(key);
  public ok = (options: IMessageOptions) => this._message.ok(options);
  public error = (options: IMessageOptions) => this._message.error(options);
  public confirm = (options: IConfirmOptions, onConfirm: (cd?: MatDialogRef<ConfirmationDialog, any>) => any ) => this._dialog.confirm(options, onConfirm);
  public openDialog = (ref, options: IDialogOptions, afterClosed = _ => {}) => this._dialog.open(ref, options, afterClosed);
  // public toPreviousRoute = (options: IPreviousRouteOptions) => this._routeNavigator.toPreviousRoute(options);

  public createMessage(successMessage?: string, errorMessage?: string) {
    return {
      MessageDispatcher: this._message,
      successMessage: successMessage,
      errorMessage: errorMessage
    }
  }

  public createConfirm(image: string, title: string, body: string, templateRef?: TemplateRef<any>) {

    if (image) {
      return {
        TotoDialog: this._dialog,
        image: image,
        template: image ? `
        <p class="dialog-title mt5 mb0">${this.translate(title)}</p
        <p>
          ${this.translate(body)}
        </p>
        ` : null,
        templateRef: templateRef
      }
    }

    return {
      TotoDialog: this._dialog,
      title: title,
      message: body,
      templateRef: templateRef
    }

  }
  
}


