import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AccountService,
  AuthenticationService,
  TitleService
} from 'src/app/services';
import { ViewBase } from 'src/app/views/view.base';
import { MONTH_DURATION_OPTIONS } from '../constants';
import { ActionHelper } from 'src/app/helpers/action';
import { CloseAccountSuccessDialogComponent } from 'src/app/components/dialogs/close-account-success/close-account-success.dialog';

@Component({
  selector: 'close-account-view',
  templateUrl: './close-account.view.html',
  styleUrls: ['./close-account.view.scss']
})
export class UserCloseAccountViewComponent extends ViewBase {
  public actionLoading = {} as any;

  public CloseAccountViewStep = CloseAccountViewStep;

  public currentStep = CloseAccountViewStep.ChoosePeriod;

  public titleFragments = [
    { key: 'Navigator.Menu.ResponsibleGame.ResponsibleGame' },
    { key: 'Navigator.Menu.ResponsibleGame.AccountClosure' }
  ];

  public validityPeriodOptions = MONTH_DURATION_OPTIONS;

  public formGroup = new FormGroup({
    months: new FormControl(this.validityPeriodOptions[0]),
    reason: new FormControl(),
    password: new FormControl()
  });

  constructor(
    _titleService: TitleService,
    private _action: ActionHelper,
    private _accountService: AccountService,
    private _authService: AuthenticationService
  ) {
    super(_titleService);
  }

  public openCloseAccountSuccessDialog() {
    this._action.openDialog(
      CloseAccountSuccessDialogComponent,
      {
        width: '542px',
        height: 'auto'
      },
      res => {}
    );
  }

  public toView(step: CloseAccountViewStep) {
    this.currentStep = step;
  }

  public save() {
    const f = this.formGroup.value;
    const req = {
      months: f.months.value,
      reason: f.reason,
      password: f.password
    };

    this._accountService.closeAccount(req).result(
      this.formGroup,
      res => {
        this._authService.logout().then(res => {
          this.openCloseAccountSuccessDialog();
        });
      },
      err => {
        console.error(err);
      },
      'save',
      this.actionLoading
    );
  }
}

enum CloseAccountViewStep {
  ChoosePeriod,
  Confirmation
}
