import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { INextRaceDto } from '../models';
import { Observable } from 'rxjs';
import { IEvent } from '../models/IEvents';


@Injectable({ providedIn: 'root' })
export class EventsService {

  constructor(private _http: HttpClient) {}

  public getEvents(): Observable<IEvent[]> {
    return this._http.get<IEvent[]>('api/events');
  }

  public getNextRace(raceNr?: any, trackId?: any): Observable<INextRaceDto> {
    let url = 'api/events/nextrace';
    if (raceNr && trackId) {
      url = `${url}/${raceNr}/${trackId}`;
    }
    return this._http.get<INextRaceDto>(url);
  }

}
