export enum HandballMarketTypeValue
{
    MatchHandicap = 194,
    MatchWinner = 6662,
    TotalMatchGoalsOverUnder = 6663,
    DrawNoBet = 6690,
    FirstHalfHandicap = 6799,
    Total1stHalfGoalsOverUnder = 6855,
    FirstHalfTotalGoalsOddEven = 7023,
    TotalMatchGoalsOddEven = 7024,
    FirstTeamToScore = 7025,
    DoubleResult = 7290,
    LastTeamToScore = 7306,
    HalfTimeFullTime = 7307,
    HalfWithMostGoals = 7308,
    HalfTimeResult = 7381,
    FirstTeamToScore3Goals = 8350,
    FirstTeamToScore5Goals = 8351,
    SecondHalfResult = 8377,
    Total2ndHalfGoalsOverUnder = 8379,
    SecondHalfHandicap = 8388,
    AwayTeam1stHalfTotalGoalsOverUnder = 8486,
    HomeTeam1stHalfTotalGoalsOverUnder = 8487,
    AwayTeam2ndHalfTotalGoalsOverUnder = 8488,
    HomeTeam2ndHalfTotalGoalsOverUnder = 8489,
    TotalHomeTeamGoalsOverUnder = 8490,
    TotalAwayTeamGoalsOverUnder = 8491,
    WhoWillScoreThe10thGoal = 8492,
    WhoWillScoreThe20thGoal = 8493,
    WhoWillScoreThe30thGoal = 8494,
    WhoWillScoreThe40thGoal = 8495,
    WhoWillScoreThe50thGoal = 8496,
    LastTeamToScoreIn1stHalf = 8497,
    FirstHalfRaceTo15Goals = 8566,
    FirstHalfRaceTo20Goals = 8567,
    FirstHalfDrawNoBet = 8584,
    HomeTeamGoalsOddEven = 9652,
    AwayTeamGoalsOddEven = 9653,
    HandicapWithDraw = 9784,
    HalfTimeHandicapWithDraw = 9786,
    FirstHalfRaceTo10Goals = 11712,
    FirstHalfRaceTo5Goals = 11713,
    SecondHalfTotalGoalsOddEven = 11807,
    SecondHalfRaceTo5Goals = 11872,
    SecondHalfRaceTo10Goals = 11873,
    SecondHalfDrawNoBet = 12314,
    SecondHalfRaceTo15Goals = 12934,
    SecondHalfRaceTo20Goals = 12935,
    Most7MeterThrows = 12971,
    WhoWillScoreThe5thGoal = 13415,
    WhoWillScoreThe15thGoal = 13416,
    WhoWillScoreThe25thGoal = 13417,
    WhoWillScoreThe35thGoal = 13418,
    WhoWillScoreThe45thGoal = 13419,
    FirstTeamToScoreInThe2ndHalf = 14527,
    NextPenaltyScored = 14528,
}

(HandballMarketTypeValue as any).__typeName = 'HandballMarketTypeValue';