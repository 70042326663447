<toto-dialog [dialogRef]="dialogRef" [hasImage]="true">
  <div class="dialog-image-container" header-image></div>
  <div body>
    <h3 class="mb20">
      {{'Views.User.Register.WelcomeDialog.Title' | translate}}
    </h3>

    <p [innerHTML]="'Views.User.Register.WelcomeDialog.Text' | translate"></p>
  </div>

  <div footer>
    <toto-button
      color="green"
      label="Views.User.Register.WelcomeDialog.Action.Continue"
      (click)="continue()"
    ></toto-button>
  </div>
</toto-dialog>
