<form [formGroup]="verificationGroup">
  <div class="form-title">
    <h2>
      {{'Navigator.Menu.Settings.Verification' | translate}}
    </h2>
    <h5>
      {{'Navigator.Menu.Settings.Settings' | translate}}
    </h5>
  </div>

  <div class="form-body">
    <p>
      {{'Views.User.Settings.Verification.Text.Introduction' | translate}}
    </p>

    <div class="method-title">
      <h3>
        {{'Views.User.Settings.Verification.Title.ChooseMethod' | translate}}
      </h3>
    </div>

    <div>
      <toto-radio
        [options]="verificationMethodOptions"
        for="verificationMethod"
        value="value"
        labelField="label"
      >
      </toto-radio>
    </div>

    <div class="mt40" [ngSwitch]="verificationMethod">
      <form
        class="verification-method"
        [formGroup]="verificationGroup"
        *ngSwitchCase="VerificationMethod.SmartId"
        (ngSubmit)="verifyViaSmartId()"
      >
        <form-field
          label="Views.User.Settings.Verification.Label.IdCode"
          [labelTop]="true"
        >
          <toto-input for="smartIdUserIdCode"></toto-input>
          <validation-errors for="smartIdUserIdCode"></validation-errors>
        </form-field>
        <div class="form-footer">
          <toto-button
            [loading]="actionLoading.verifySmartId"
            color="green"
            label="Action.VerifyAccount"
            type="submit"
          >
          </toto-button>
          <!-- <div *ngIf="actionLoading.verifySmartId" class="verification-code ml15">
            {{'Views.User.Settings.Verification.Label.VerificationCode' |
            translate}}: {{ smartId?.verificationCode }}
          </div> -->
        </div>
      </form>

      <form
        class="verification-method"
        [formGroup]="verificationGroup"
        *ngSwitchCase="VerificationMethod.MobileId"
        (ngSubmit)="verifyViaMobileId()"
      >
        <form-field
          label="Views.User.Settings.Verification.Label.IdCode"
          [labelTop]="true"
        >
          <toto-input for="mobileIdUserIdCode"></toto-input>
          <validation-errors for="mobileIdUserIdCode"></validation-errors>
        </form-field>

        <form-field label="Views.User.Settings.Verification.Label.PhoneNumber" [labelTop]="true">
          <div class="flex flex-row align-center full-width">
            <toto-select
              for="prefix"
              [options]="countryPrefixOptions"
              labelField="label"
              compareField="value"
              [clearable]="false"
              [translate]="translate"
              style="flex: 30%"
            ></toto-select>
            <span class="ml10 mr10">&nbsp;</span>
            <toto-input for="mobileIdPhoneNumber" size="1">
            </toto-input>                
          </div>
          <validation-errors for="phone" aliasTo="mobileIdPhoneNumber"></validation-errors>
        </form-field>
        <!-- <form-field
          label="Views.User.Settings.Verification.Label.PhoneNumber"
          [labelTop]="true"
        >
          +372&nbsp;<toto-input for="mobileIdPhoneNumber"></toto-input>
          <validation-errors for="mobileIdPhoneNumber"></validation-errors>
        </form-field> -->

        <div class="form-footer">
          <toto-button
            [loading]="actionLoading.verifyMobileId"
            color="green"
            label="Action.VerifyAccount"
            type="submit"
          >
          </toto-button>
          <!-- <div
            *ngIf="verifyMobileId"
            class="verification-code ml15"
          >
            {{'Views.User.Settings.Verification.Label.VerificationCode' |
            translate}}: {{ mobileId?.verificationCode }}
          </div> -->
        </div>
      </form>

      <div *ngSwitchCase="VerificationMethod.IdCard">
        <toto-button
          (click)="verifyViaIdCard()"
          [loading]="actionLoading.verify"
          color="theme-1"
          label="Action.Continue"
          type="submit"
        >
        </toto-button>
      </div>

      <div *ngSwitchCase="VerificationMethod.DocumentPhoto">
        <form
          enctype="multipart/form-data"
          class="verification-method upload-container"
          [formGroup]="verificationGroup"
          (ngSubmit)="uploadDocumentPhotos()"
        >
                <svg-icon key="documentPhoto" fontSize="100px"></svg-icon>
                <h2>{{ 'Views.User.Settings.Verification.Label.UploadPhotosOfYourDocument' | translate }}</h2>
                <p>{{ 'Views.User.Settings.Verification.Text.UploadPhotosDescription' | translate }}</p>

                <ul>
                  <li *ngFor="let f of files" (click)="downloadImage(f.id)" style="cursor:pointer">{{ f.originalFileName }}</li>
                </ul>

                <form-field
                  label=""
                  [labelTop]="true"
                >
                  <toto-input inputClass="documentFile" multiple="multiple" type="file" (change)="filesSelected($event)" accept="image/*" />
                </form-field>                

          <div class="form-footer">
            <toto-button
              [disabled]="!this.hasPhotos()"
              [loading]="actionLoading.verify"
              color="theme-1"
              label="Action.Continue"
              type="submit"
            >
            </toto-button>
          </div>
        </form>
      </div>

      <div *ngSwitchCase="VerificationMethod.Bank">
        <p>Eestlane valib riigiks Eesti, soomlane Soome</p>
                        
            <toto-radio
              [options]="zimplerCountryOptions"
              for="country"
              value="value"
              labelField="label"
              class="full-width mb15"
            >
            </toto-radio>
        <toto-button
          (click)="verifyViaZimpler()"
          [loading]="actionLoading.verify"
          color="theme-1"
          label="Action.Continue"
          type="submit"
        >
        </toto-button>
      </div>

      <div *ngSwitchCase="VerificationMethod.Deposit"></div>
    </div>
  </div>
</form>