import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, LocaleService } from '../services';
import { AppState } from '../app.state';
import { DEFAULT_LANGUAGE } from '../app.settings';
import { LocalStorage } from '../helpers';
import { LOCAL_STORAGE_KEY } from '../config/local-storage-key';

@Injectable({ providedIn: 'root' })
export class AppModuleGuard implements CanActivate {

  constructor(private _state: AppState, private _localeService: LocaleService) { }

  public canPassGuard(module) {
    return module && this._state.activeAppModule === module && this._localeService.currentLanguageModule === module;
  }


  canActivate(route: ActivatedRouteSnapshot) {
    const { AppModule } = route.children[0]?.data || {};

    let module = AppModule ?? this._localeService.currentLanguageModule ?? LocalStorage.getValue(LOCAL_STORAGE_KEY.LAST_VISITED_MODULE);


    if (this.canPassGuard(module)) { 
      return true;
    }

    const language = this._state.language || DEFAULT_LANGUAGE;
    this._state.setAppModule(module);

    return new Promise<boolean>((resolve, reject) => { 
      return this._localeService.setLanguage(language, module, () => {
        // this._state.setAppModule(AppModule);
      }).then(() => { 
        resolve(true);
      }).catch(err => {
        resolve(false);
        console.error(err);
      });

    });

  }
}
