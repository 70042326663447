import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Subscription, filter } from "rxjs";
import { BreadcrumbService } from "xng-breadcrumb";

@Component({
  selector: "toto-breadcrumbs",
  templateUrl: "./toto-breadcrumb.component.html",
  styleUrls: ['./toto-breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TotoBreadcrumbComponent {

  private _subscription: Subscription;

  constructor(private _cd: ChangeDetectorRef, private _router: Router) {}

  ngAfterViewInit() {
    this._cd.detach();

    this._subscription = this._router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(event => {
        this._cd.detectChanges();
      }
    );
  }

  ngOnDestroy() { 
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

}
