export enum DartsMarketTypeValue
{
    TotalMatch180sOverUnder = 288,
    CorrectScore = 301,
    SetHandicap = 302,
    Most180s = 6533,
    MatchWinner3Way = 6669,
    DrawNoBet = 6670,
    FirstLegWinner = 7014,
    FirstSetWinner = 7495,
    TotalSetsOverUnder = 7499,
    TotalLegsOverUnder = 7569,
    NextLegWinner = 7597,
    CorrectScore4Legs = 7658,
    Handicap = 9200,
    HighestCheckout = 9241,
    TwoChancesToWin = 9501,
    MatchWinner2Way = 9670,
    Most180sHandicap = 11740,
    CompetitorBTotal180sOverUnder = 11863,
    CompetitorATotal180sOverUnder = 11864,
    Next180 = 12922,
    NextLegHighestCheckout = 12923,
    NextLegWinningDouble = 12924,
    NextLegFirstDart = 12925,
    NextSetWinner = 12926,
    NextLegWinningDoubleColour = 12932,
    LegHandicap = 12933,
    Set1NextLegWinner = 14530,
    Set2NextLegWinner = 14531,
    Set3NextLegWinner = 14532,
    Set4NextLegWinner = 14533,
    Set5NextLegWinner = 14534,
    Set6NextLegWinner = 14535,
    Set7NextLegWinner = 14536,
    Set8NextLegWinner = 14537,
    Set9NextLegWinner = 14538,
    Set1NextLeg180 = 14594,
    Set2NextLeg180 = 14595,
    Set3NextLeg180 = 14596,
    Set4NextLeg180 = 14597,
    Set5NextLeg180 = 14598,
    Set6NextLeg180 = 14599,
    Set7NextLeg180 = 14600,
    Set8NextLeg180 = 14601,
    Set9NextLeg180 = 14602,
    Set10NextLeg180 = 14603,
    Set11NextLeg180 = 14604,
    Set12NextLeg180 = 14605,
    Set13NextLeg180 = 14606,
}

(DartsMarketTypeValue as any).__typeName = 'DartsMarketTypeValue';