<div class="footer-container maxWidth--{{contentMaxWidth}}">
  <div class="row align-center">
    <div class="col-lg-4 col-md-6 col-sm-12">
      <img class="mr45" id="toto" src="/assets/images/brand/logo-m.svg" />
    </div>
    <div class="col-lg-4 col-md-3 col-sm-9">
      <div class="flex flex-column mt18">
        <span>Totalisaator AS</span>
        <span>
          Merimetsa tee 8, 10614 Tallinn
        </span>
      </div>
    </div>

    <div class="col-lg-4 col-md-3 col-sm-3">
      <div class="flex flex-row justify-end mt18">
        <a
          class="socials"
          href="https://www.facebook.com/toto.ee"
          target="blank"
          id="contact-facebook"
        >
          <svg-icon class="mr10" key="facebook" fontSize="40px"></svg-icon>
        </a>
        <a class="socials" href="mailto:info@toto.ee" id="contact-email">
          <svg-icon key="email" fontSize="40px"></svg-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="row space-between mt20">
    <div class="col-lg-4 col-md-6 col-sm-12">
      <span class="list-column-title">{{'Navigator.ModuleLinks.HorseRacing' | translate}}</span>
      <ul>
        <li *ngFor="let link of horseRacingLinks">
          <a class="link" [href]="link.routerLink">
            {{ link.label | translate }}
          </a>
        </li>
      </ul>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
      <span class="list-column-title">{{'Navigator.ModuleLinks.SportsBetting' | translate}}</span>
      <ul>
        <li *ngFor="let link of sportsbettingLinks">
          <a class="link" [href]="link.routerLink">
            {{ link.label | translate }}
          </a>
        </li>

        <li>
          <a class="link" [href]="affiliateLink" target="_blank">
            {{ 'Components.Footer.AffiliateLink' | translate }}
          </a>
        </li>

      </ul>
    </div>
    <!-- <div class="col-lg-3 col-md-6 col-sm-12">
      <span class="list-column-title">Informatsioon</span>
      <ul>
        <li>Kontakt</li>
        <li>Toto baar</li>
        <li>Lingid</li>
      </ul>
    </div> -->
    <div class="col-lg-4 col-md-6 col-sm-12">
      <span class="list-column-title">
        {{'Components.Footer.Rules.Rules' | translate}}
      </span>
      <ul>
        <li >
          <a class="link" [href]="rulesLink">
            {{'Components.Footer.Rules.Rules' | translate}}
          </a>
        </li>
        <li>
          <a class="link" [href]="privacyPolicyLink">
            {{'Components.Footer.Rules.PrivacyPolicy' | translate}}
          </a>
        </li>
        <li>
          <a class="link" [href]="cookiePolicyLink">
          {{'Components.Footer.Rules.CookiePolicy' | translate}}
          </a>
        </li>
      </ul>
      <span class="list-column-title mt40">
        {{'Components.Footer.Settings.Settings' | translate}}
      </span>
      <ul>
        <li><language-select></language-select></li>
        <!-- <li>Koefitsentide kuvamine</li> -->
      </ul>
    </div>
  </div>
  <div class="row mt20">
    <div class="col-lg-4 col-md-12 col-sm-12 mt18">
      <div class="flex flex-wrap" style="gap: 20px 40px">
        <img id="plus18" src="/assets/images/misc/18plus.png" />
        <img id="emta" src="/assets/images/misc/emta.png" />
      </div>
    </div>
    <div class="col-lg-8 col-md-12 col-sm-12 mt18">
      <div class="flex flex-wrap" [innerHTML]="'Components.Footer.Text.License' | translate"></div>
      <div class="flex flex-wrap" [innerHTML]="'Components.Footer.Text.Warning' | translate"></div>
    </div>
  </div>
  <!-- <div class="row mt20" style="text-align:center; display: block;">
    <img src="/assets/images/banklinks/trustly-footer.png" alt="Trustly" style="max-width: 400px;">
  </div> -->
</div>



<!-- <div class="footer-container">
    <div class="footer-section side-section">
        <div class="logos">
            <a routerLink="/">
                <img src="/assets/images/brand/totologo3.png">
            </a>
        </div>
        <span>Totalisaator AS Paldiski mnt. 50, Tallinn 10614</span>
    </div>
    <div class="footer-section middle-section">
        <div class="footer-row mb10">
            <div class="mta-logo-wrapper mr30">
                <img class="mta-logo" src="/assets/images/toto-mta.png">
            </div>
            <div class="plus18">
                18+
            </div>
        </div>
        <div class="footer-row mb10">
            <a href="https://www.toto.ee">Toto.ee</a>
            <span>&nbsp;tegutseb Eesti Maksu- ja Tolliameti poolt väljastatud litsentside&nbsp;</span>
            <a href="https://mtr.ttja.ee/taotluse_tulemus/220496">HKT000007</a>
            <span>,&nbsp;</span>
            <a href="https://mtr.ttja.ee/taotluse_tulemus/220367#__ldandmed">HKL000018</a>
            <span>&nbsp;ja&nbsp;</span>
            <a href="https://mtr.ttja.ee/taotluse_tulemus/558101">HKL000360</a>
            <span>&nbsp;alusel.</span>
        </div>
        <div class="footer-row">
            {{'footer._.warning' | translate}}
        </div>
    </div>
    <div class="footer-section side-section">
        <div id="contact">
            <a href="https://www.facebook.com/toto.ee" target="blank" id="contact-facebook" class="fa fa-facebook-f">
            </a>
            <a href="mailto:info@toto.ee" id="contact-email" class="fa fa-envelope">
            </a>
        </div>
    </div>
</div> -->
