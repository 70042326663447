import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BannersService } from 'src/app/services/banners.service';

@Injectable()
export class BannersResolve implements Resolve<any> {

  constructor(private _bannersService: BannersService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this._bannersService.getHighlights();
  }
}