import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IRegisterAccountReq,
  IAccountNewsletterReq,
  IAccountPasswordChangeReq,
  IAccountAddressReq,
  ISaveAccountContactInfoReq
} from '../models';
import { AppState } from '../app.state';
import { BankLinkProvider } from '../enum/BankLinkProvider';
import { SafeBettingPeriod } from '../enum';

@Injectable({ providedIn: 'root' })
export class AccountService {

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public httpODataOptions = {
    headers: this.httpOptions.headers,
    params: { $top: '20', $count: 'true' }
  };

  constructor(private _http: HttpClient, private _appState: AppState) {}

  public registerAccount(data: IRegisterAccountReq): Observable<any> {
    return this._http.post<any>('api/account/register', data) as Observable<
      any
    >;
  }

  public registerViaSmartId(sessionId: string): Observable<any> {
    return this._http.get<any>(`api/account/register/smartId/${sessionId}`) as Observable<any>;
  }

  public initRegisterViaSmartId(req: IInitRegisterViaSmartIdReq) {
    return this._http.post<any>('api/account/register/smartId', req) as Observable<any>;
  }

  public registerViaMobileId(sessionId: string): Observable<any> {
    return this._http.get<any>(`api/account/register/mobileId/${sessionId}`) as Observable<any>;
  }

  public initRegisterViaMobileId(req: IInitRegisterViaMobileIdReq) {
    return this._http.post<any>('api/account/register/mobileId', req) as Observable<any>;
  }

  public sendResetPasswordLink(data: any): Observable<any> {
    return this._http.post<any>(
      'api/account/sendResetPasswordLink',
      data
    ) as Observable<any>;
  }

  public resetPassword(data: any): Observable<any> {
    return this._http.post<any>(
      'api/account/resetPassword',
      data
    ) as Observable<any>;
  }

  public getAccountInfo(): Observable<any> {
    return this._http.get<any>(`api/account/info`) as Observable<any>;
  }

  public getAvailablePayInCampaigns(): Observable<any> {
    return this._http.get<any>('api/account/availablePayInCampaigns');
  }

  // NEOPAY

  public depositNeopay(req: IZimplerDepositReq): Observable<any> {
    return this._http.post('api/neopay/deposit', req);
  }

  // TRUSTLY

  public depositTrustly(amount: number): Observable<any> {
    return this._http.post('api/trustly/deposit', {
      amount: amount
    });
  }

  public trustlyTransactionId(id: any): Observable<any> {
    return this._http.post(`api/trustly/deposit/transactions/${id}`, {
      id: id
    });
  }

  public selectAccountTrustly(amount?: number): Observable<any> {
    const req = { amount: amount };
    return this._http.post('api/trustly/selectaccount', req);
  }

  public selectAccountTransactionTrustly(transactionId: any): Observable<any> {
    const req = {};
    return this._http.post(
      `api/trustly/selectaccount/transactions/${transactionId}`,
      req
    );
  }

  public withdraw(amount: number): Observable<any> {
    return this._http.post('api/account/withdraw', {
      amount: amount
    });
  }

  public withdrawTrustly(amount: number): Observable<any> {
    return this._http.post('api/trustly/withdraw', {
      amount: amount
    });
  }

  // ZIMPLER

  public depositZimpler(req: IZimplerDepositReq): Observable<any> {
    return this._http.post('api/zimpler/deposit', req);
  }

  //

  public getTransactionById(id: any): Observable<any> {
    return this._http.get(`api/account/transactions/${id}`);
  }

  public getTransactionStatus(transactionId: any): Observable<any> {
    return this._http.get<any>(
      `api/account/transactionStatus/${transactionId}`
    ) as Observable<any>;
  }

  public deposit(
    amount: number,
    provider: BankLinkProvider,
    returnUrl: string
  ): Observable<any> {
    if (provider === BankLinkProvider.Trustly) {
      return this._http.post('api/trustly/deposit', {
        amount: amount
      });
    }

    if (provider === BankLinkProvider.Zimpler) {
      return this._http.post('api/zimpler/deposit', {
        amount: amount
      });
    }

    return this._http.post<any>('api/account/deposit', {
      amount,
      provider,
      returnUrl
    });
  }

  public getUserBalance(transactionId?: any): Observable<any> {
    return this._http.get(`api/account/balance?transactionId=${transactionId}`);
  }

  public getUserWithdrawalDetails(): Observable<any> {
    return this._http.get(`api/account/withdrawalDetails`);
  }

  public cashOut(amount: number): Observable<any> {
    return this._http.post<any>('api/account/cashout', {
      amount
    });
  }

  public getLivestreamUrl(): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'text/plain; charset=utf-8');

    return this._http.get(`api/site/livestreamurl`, {
      headers: headers,
      responseType: 'text'
    }) as Observable<any>;
  }

  // TICKETS AND TRANSACTION LOG

  public getUserHorseRacingTickets(data: IUserLogReq): Observable<any> {
    return this._http.post<any>(
      `api/Account/tickets`,
      data,
      this.httpODataOptions
    ) as Observable<any>;
  }

  public getUserActiveSportsTickets(params): Observable<any> {
    const options = {
      headers: this.httpOptions.headers,
      params: { $top: '20', $count: 'true' }
    };

    Object.assign(options.params, params);

    return this._http.get<any>(`api/sport/tickets/active`, options) as Observable<any>;
  }

  public getUserSportsTickets(data: any = {}, params?): Observable<any> {
    const options = {
      headers: this.httpOptions.headers,
      params: { $top: '20', $count: 'true' }
    };

    Object.assign(options.params, params);

    return this._http.post<any>(
      `api/sport/tickets`,
      data,
      options
    ) as Observable<any>;
  }

  public getTicketDetail(id: string): Observable<any> {
    return this._http.get(`api/Account/tickets/${id}`) as Observable<any>;
  }

  public sendTicket(id: string): Observable<any> {
    return this._http.post(`api/Account/tickets/${id}/send`, {}) as Observable<
      any
    >;
  }

  public loadTicket(id: string): Observable<any> {
    return this._http.post(`api/Account/tickets/${id}/load`, {}) as Observable<
      any
    >;
  }

  public cancelTicket(id: string): Observable<any> {
    return this._http.delete(`api/Account/tickets/${id}`) as Observable<any>;
  }

  public getUserTransactions(data: IUserLogReq): Observable<any> {
    const params = { count: true, top: '20' };

    return this._http.post<any>(
      `api/Account/transactions`,
      data,
      this.httpODataOptions
    ) as Observable<any>;
  }

  public getUserTransactionStatusLog(transactionId): Observable<any> {

    return this._http.get<any>(
      `api/Account/transactions/${transactionId}/statusLog`,
      this.httpOptions
    ) as Observable<any>;
  }

  public deleteTicket(id: string): Observable<any> {
    return this._http.delete<any>(`api/Account/tickets/${id}`) as Observable<
      any
    >;
  }

  // USER SETTINGS

  // NEW

  public getUserPreferences(): Observable<any> {
    return this._http.get<any>(`api/account/preferences`) as Observable<any>;
  }

  public saveUserPreferences(req: ISaveUserPreferencesReq): Observable<any> {
    return this._http.post<any>(`api/account/preferences`, req) as Observable<
      any
    >;
  }

  // END NEW

  public getContactInfo(): Observable<any> {
    return this._http.get<any>(
      'api/account/contacts',
      this.httpOptions
    ) as Observable<any>;
  }

  public saveContactInfo(data: ISaveAccountContactInfoReq): Observable<any> {
    return this._http.post<any>('api/account/contacts', data) as Observable<
      ISaveAccountContactInfoReq
    >;
  }

  public saveNewsletterInfo(
    data: IAccountNewsletterReq
  ): Observable<IAccountNewsletterReq> {
    return this._http.post<any>('api/account/newsletters', data) as Observable<
      IAccountNewsletterReq
    >;
  }

  public saveManualBetsInfo(data: any): Observable<any> {
    return this._http.post<any>('api/account/manualbets', data) as Observable<
      any
    >;
  }

  public saveAccountPasswordChange(
    data: IAccountPasswordChangeReq
  ): Observable<IAccountPasswordChangeReq> {
    return this._http.post<any>('api/account/password', data) as Observable<
      IAccountPasswordChangeReq
    >;
  }

  public saveAddress(data: IAccountAddressReq): Observable<IAccountAddressReq> {
    return this._http.post<any>('api/account/address', data) as Observable<
      IAccountAddressReq
    >;
  }

  // SAFE BETTING
  public getSafeBettingSettings(): Observable<any> {
    return this._http.get<any>(`api/account/safeBetting`) as Observable<any>;
  }

  public setBettingLimit(data: ISetLimitReq): Observable<any> {
    return this._http.post<any>(
      `api/account/safeBetting/bettingLimit`,
      data
    ) as Observable<any>;
  }

  public setDepositLimit(data: ISetLimitReq): Observable<any> {
    return this._http.post<any>(
      `api/account/safeBetting/depositLimit`,
      data
    ) as Observable<any>;
  }

  public setNetLossLimit(data: ISetLimitReq): Observable<any> {
    return this._http.post<any>(
      `api/account/safeBetting/netLossLimit`,
      data
    ) as Observable<any>;
  }

  public getSelfRestraint(): Observable<any> {
    return this._http.get<any>(`api/account/safeBetting/restrictBetting`) as Observable<any>;
  }

  public restrictBetting(req: IRestrictBettingReq): Observable<any> {
    return this._http.post<any>(
      `api/account/safeBetting/restrictBetting`,
      req
    ) as Observable<any>;
  }

  public closeAccount(req: ICloseAccountReq): Observable<any> {
    return this._http.post<any>(
      `api/account/close`,
      req
    ) as Observable<any>;
  }

  public getBonusSummary(): Observable<any> {
    return this._http.get<any>(`api/sport/campaigns/summary`) as Observable<any>;
  }

  public activateCampaign(id: number): Observable<any> {
    return this._http.post<any>(`api/sport/campaigns/${id}/activate`, { }) as Observable<any>;
  }

  public getClickId(refCode: string): Observable<any> {
    return this._http.get<string>(`api/account/getclickid/${refCode}`, { }) as Observable<any>;
  }

  public uploadDocumentPhoto(x: any): Observable<any> {
    return this._http.post<any>('api/account/verification-image', x);
  }

  public listUserFiles(): Observable<any> {
   return this._http.get<any>('api/account/verification-images');
  }

  public getVerificationImage(id: any) {
    var url = window.__totoAppSettings.apiUrl + 'account/verification-image/' + id;
    document.location.href = url;
  }
}


export interface IInitRegisterViaSmartIdReq {
  sessionId?: string;
  idCode: string;
  email: string;
  userName: string;
  acceptTerms: boolean;
  hasCoupon: boolean;
  coupon: string;
  clickId: string;
  externalClickId: string;
}

export interface IInitRegisterViaMobileIdReq {
  sessionId?: string;
  idCode: string;
  prefix: string;
  phone: string;
  email: string;
  acceptTerms: boolean;
  hasCoupon: boolean;
  coupon: string;
  userName: string;
  clickId: string;
  externalClickId: string;
}

export interface IZimplerDepositReq {
  amount: number;
  bic: string;
}

export interface INeopayDepositReq {
  amount: number;
  bic: string;
}

export interface ISetLimitReq {
  amount24Hours: number;
  amount7Days: number;
  amount30Days: number;
}

export interface IRestrictBettingReq {
  months: number;
  password: string;
}

export interface ICloseAccountReq {
  months: number;
  reason: string;
  password: string;
}

export interface ILimitReq {
  amount: number;
  period: SafeBettingPeriod;
}

export interface IUserLogReq {
  from: any;
  to: any;
  searchKey: string;
}

export interface ISaveUserPreferencesReq {
  language: string;
  showMessages: boolean;
  maxIdleTime: number;
  newsletterEnabled: boolean;
  newsletterLanguage: string;
}
