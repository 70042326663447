import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { MessageDispatcher } from 'src/app/helpers';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'login-block',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginBlockComponent {
  public isLoginLoading = false;

  public loginForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl()
  });

  constructor(
    private _appState: AppState,
    private _authService: AuthenticationService,
    private _msg: MessageDispatcher
    ) {
    // if (this.isLoggedIn) {
    //   this._router.navigate(['/']);
    // }
  }

  public get isLoggedIn(): boolean {
    return this._appState.isLoggedIn;
  }

  public get isMobile(): boolean {
    return this._appState.isViewMobile;
  }

  public async logOut() {
    await this._authService.logout();
  }


  public logIn() {
    this.isLoginLoading = true;
    var f = this.loginForm.value;
    this._authService.authPassword(f.username, f.password).result(this.loginForm, x => {
      this.isLoginLoading = false;
      this._authService.showLoginModal = false;
    }, e => {
      
      this._msg.error({
        message: e.validationSummary
      });
      
      this.isLoginLoading = false;
    });
  }
 
}
