export enum RugbyUnionMarketTypeValue
{
    MatchWinner = 15,
    Handicap = 89,
    FirstTryscorer = 92,
    FirstTeamToScoreATry = 178,
    HalfTimeHandicap = 195,
    TotalTries = 257,
    TotalPoints = 310,
    FirstScoringPlay = 318,
    HalfTimeFullTime = 320,
    FirstTryTime = 333,
    AnytimeTryscorer = 358,
    SecondHalfHandicap = 6453,
    TotalMatchPointsOverUnder = 6459,
    LastTryscorer = 6675,
    FirstTeamToScore = 6836,
    HighestScoringHalfTeam = 6986,
    LastTeamToScore = 7324,
    FirstTeamToScore2ndHalf = 7376,
    HighestScoringHalf = 7553,
    HalfTimeResult = 7554,
    FirstHalfTotalPointsOverUnder = 7556,
    SecondHalfTotalPointsOverUnder = 7557,
    WinBothHalvesYesNo = 7828,
    DoubleResult = 8273,
    TotalMatchTriesOverUnder = 8288,
    ToWinToNil = 8310,
    WillThereBeADropGoal = 8319,
    TotalPointsOddEven = 8345,
    SecondHalfTotalPointsOddEven = 8346,
    HalfTimeTotalPointsOddEven = 8347,
    FirstTeamToScoreWinsMatchYesNo = 8365,
    DrawNoBet = 8779,
    FirstHalfResultDrawNoBet = 8780,
    SecondHalfHandicapNoDraw = 8781,
    FirstHalfHandicap = 8782,
    MatchHandicap = 8783,
    FirstTryscorer2ndHalf = 9561,
    HalfTimeFullTimeExcDraw = 9626,
    ToWinBothHalves = 9896,
    HomeTeamTotalPoints = 10182,
    AwayTeamTotalPoints = 10183,
    HomeTeamTotalMatchPointsOverUnder = 10927,
    AwayTeamTotalMatchPointsOverUnder = 10928,
    AwayTeamTotalTriesOverUnder = 10990,
    HomeTeamTotalTriesOverUnder = 10991,
    SecondHalfResult = 11051,
    RaceTo10Points = 11100,
    LastTeamToScoreATry = 11166,
    FirstTryConvertedYesNo = 11167,
    AwayTeamTotalMatchPointsOddEven = 11168,
    HomeTeamTotalMatchPointsOddEven = 11169,
    HatTrickPlayers = 11172,
    AwayTeamTotalTries = 11180,
    HomeTeamTotalTries = 11181,
    AwayTeamFirstTryscorer = 11182,
    HomeTeamFirstTryscorer = 11183,
    FirstTryConvertedFirstHalfYesNo = 11184,
    FirstTryConvertedSecondHalfYesNo = 11185,
    LastTryTime = 11186,
    PlayerToScore2Tries = 11187,
    TeamToScoreFirstTry2ndHalf = 11205,
    ToScoreFirstLeadAtHalftimeAndWin = 11219,
    RaceTo20Points = 11347,
    RaceTo30Points = 11348,
    FirstTeamToScoreWinsMatch = 11354,
    AwayTeamTotal1stHalfPointsOddEven = 11411,
    HomeTeamTotal1stHalfPointsOddEven = 11412,
    AwayTeamTotal2ndHalfPointsOddEven = 11447,
    HomeTeamTotal2ndHalfPointsOddEven = 11448,
    AwayTeamTotal1stHalfPointsOverUnder = 11451,
    HomeTeamTotal1stHalfPointsOverUnder = 11452,
    SecondHalfResultDrawNoBet = 11456,
    AwayTeamTotal2ndHalfPointsOverUnder = 12375,
    HomeTeamTotal2ndHalfPointsOverUnder = 12376,
    TeamToScoreNextTry = 12596,
    RaceTo15Points = 12597,
    RaceTo25Points = 12598,
    RaceTo35Points = 12599,
    RaceTo40Points = 12600,
    TotalMatchPointsOddEven = 12601,
    NextScoringPlay3Way = 12956,
}

(RugbyUnionMarketTypeValue as any).__typeName = 'RugbyUnionMarketTypeValue';