export enum TableTennisMarketTypeValue
{
    MatchWinner = 6750,
    TotalPointsOverUnder = 7929,
    Game1Winner = 12744,
    Game2Winner = 12745,
    Game3Winner = 12746,
    Game4Winner = 12747,
    Game5Winner = 12748,
    Game1TotalPointsOverUnder = 12749,
    Game2TotalPointsOverUnder = 12750,
    Game3TotalPointsOverUnder = 12751,
    Game4TotalPointsOverUnder = 12752,
    Game5TotalPointsOverUnder = 12753,
    TotalPointsOddOrEven = 12754,
    Game1TotalPointsOddOrEven = 12755,
    Game2TotalPointsOddOrEven = 12756,
    Game3TotalPointsOddOrEven = 12757,
    Game4TotalPointsOddOrEven = 12758,
    Game5TotalPointsOddOrEven = 12761,
    CompetitorATotalPointsOverUnder = 12772,
    CompetitorBTotalPointsOverUnder = 12773,
    CompetitorATotalPointsOddOrEven = 12774,
    CompetitorBTotalPointsOddOrEven = 12775,
    Game1NumberedPointWinner = 12776,
    Game2NumberedPointWinner = 12777,
    Game3NumberedPointWinner = 12778,
    Game4NumberedPointWinner = 12779,
    Game5NumberedPointWinner = 12780,
    Game1RaceTo3Points = 12781,
    Game2RaceTo3Points = 12782,
    Game3RaceTo3Points = 12783,
    Game4RaceTo3Points = 12784,
    Game5RaceTo3Points = 12785,
    Game1RaceTo5Points = 12786,
    Game2RaceTo5Points = 12787,
    Game3RaceTo5Points = 12788,
    Game4RaceTo5Points = 12789,
    Game5RaceTo5Points = 12790,
    MatchGameHandicap = 12796,
    MatchPointHandicap = 12797,
    Game1Handicap = 12798,
    Game2Handicap = 12799,
    Game3Handicap = 12800,
    Game4Handicap = 12801,
    Game5Handicap = 12802,
    Game6Winner = 13531,
    Game7Winner = 13532,
    Game6TotalPointsOverUnder = 13533,
    Game7TotalPointsOverUnder = 13534,
    Game6TotalPointsOddOrEven = 13535,
    Game7TotalPointsOddOrEven = 13536,
    Game6NumberedPointWinner = 13541,
    Game7NumberedPointWinner = 13542,
    Game6RaceTo3Points = 13543,
    Game7RaceTo3Points = 13544,
    Game6RaceTo5Points = 13545,
    Game7RaceTo5Points = 13546,
    Game6Handicap = 13549,
    Game7Handicap = 13550,
    Game2ExtraPoints = 13955,
    Game3ExtraPoints = 13956,
    Game4ExtraPoints = 13957,
    Game5ExtraPoints = 13958,
    Game6ExtraPoints = 13959,
    Game7ExtraPoints = 13960,
    Game1LeadAfter2Points = 13968,
    Game1LeadAfter4Points = 13969,
    Game1LeadAfter6Points = 13970,
    Game1LeadAfter8Points = 13971,
    Game1LeadAfter10Points = 13972,
    Game2LeadAfter2Points = 13973,
    Game2LeadAfter4Points = 13974,
    Game2LeadAfter6Points = 13975,
    Game2LeadAfter8Points = 13976,
    Game2LeadAfter10Points = 13977,
    Game3LeadAfter2Points = 13978,
    Game3LeadAfter4Points = 13979,
    Game3LeadAfter6Points = 13980,
    Game3LeadAfter8Points = 13981,
    Game3LeadAfter10Points = 13982,
    Game4LeadAfter2Points = 13983,
    Game4LeadAfter4Points = 13984,
    Game4LeadAfter6Points = 13985,
    Game4LeadAfter8Points = 13986,
    Game4LeadAfter10Points = 13987,
    Game5LeadAfter2Points = 13988,
    Game5LeadAfter4Points = 13989,
    Game5LeadAfter6Points = 13990,
    Game5LeadAfter8Points = 13991,
    Game5LeadAfter10Points = 13992,
    Game6LeadAfter2Points = 13993,
    Game6LeadAfter4Points = 13994,
    Game6LeadAfter6Points = 13995,
    Game6LeadAfter8Points = 13996,
    Game6LeadAfter10Points = 13997,
    Game7LeadAfter2Points = 13998,
    Game7LeadAfter4Points = 13999,
    Game7LeadAfter6Points = 14000,
    Game7LeadAfter8Points = 14001,
    Game7LeadAfter10Points = 14002,
    Game1ExtraPoints = 14003,
    Game1CorrectScore = 14004,
    Game2CorrectScore = 14005,
    Game3CorrectScore = 14006,
    Game4CorrectScore = 14007,
    Game5CorrectScore = 14008,
    Game6CorrectScore = 14009,
    Game7CorrectScore = 14010,
    CorrectMatchScore = 14102,
    Game1RaceTo7Points = 14119,
    Game2RaceTo7Points = 14120,
    Game3RaceTo7Points = 14121,
    Game4RaceTo7Points = 14122,
    Game5RaceTo7Points = 14123,
    Game6RaceTo7Points = 14124,
    Game7RaceTo7Points = 14125,
    TotalGamesOverUnder = 14126,
    TotalGamesOddOrEven = 14127,
}

(TableTennisMarketTypeValue as any).__typeName = 'TableTennisMarketTypeValue';
