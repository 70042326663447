import { Injectable } from '@angular/core';
import { Guid } from '../helpers';
import * as Ably from 'ably';
@Injectable()
export class AblyService {
  constructor() {}

  public clientOptions: Ably.Types.ClientOptions = {
    key: window.__totoAppSettings.ablyKey,
    clientId: Guid.newGuid().toString()
  }

  getClient() {
    return new Ably.Realtime(this.clientOptions);
  }

  getChannel(channel: string, realtime: Ably.Realtime = this.getClient()) {
    return realtime.channels.get(channel);
  }

  getUserChannel(channel: string, realtime: Ably.Realtime = this.getClient()) {
    return realtime.channels.get(`${channel}:${this.clientOptions.clientId}`);
  }


}
