import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/app.state";
import { AuthenticationOption } from "src/app/components/_elements/authentication-options/authentication-options.component";
import { MessageDispatcher } from "src/app/helpers";
import { ActionHelper } from "src/app/helpers/action";
import { IRegisterAccountReq } from "src/app/models";
import { AuthenticationService, AccountService } from "src/app/services";

@Component({
  selector: "register-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./register.dialog.html",
  styleUrls: ['./register.dialog.scss']
})
export class RegisterDialogComponent {

  public AuthenticationOption = AuthenticationOption;

  @ViewChild('captchaRef2') captchaRef2: ElementRef;
  private _reCaptchaId: number;

  public isNotRobot = false;

  public controls = new FormGroup({
    selectedView: new FormControl(AuthenticationOption.Password)
  })

  public formGroup = new UntypedFormGroup({
    email: new UntypedFormControl(),
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
    coupon: new UntypedFormControl(),
    termsAndConditions: new UntypedFormControl(false),
    recaptcha: new UntypedFormControl()
  });

  public passwordForm = new UntypedFormGroup({
    email: new UntypedFormControl(),
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
    coupon: new UntypedFormControl(),
    termsAndConditions: new UntypedFormControl(false),
    recaptcha: new UntypedFormControl()
  });

  public smartIdForm = new UntypedFormGroup({
    idCode: new UntypedFormControl(),
    email: new UntypedFormControl(),
    username: new UntypedFormControl(),
    coupon: new UntypedFormControl(),
    termsAndConditions: new UntypedFormControl(false),
    recaptcha: new UntypedFormControl()
  });
    
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    public dialogRef: MatDialogRef<RegisterDialogComponent>,
    private _authService: AuthenticationService, 
    private _router: Router,
    private _route: ActivatedRoute,
    private _accountService: AccountService,
    private _appState: AppState,
    private _action: ActionHelper,
    private _msg: MessageDispatcher
  ) {
    const { data} = this._data || {};

  }

  ngAfterViewInit() {
    const grecaptcha = (window as any).grecaptcha;
    if (grecaptcha) {
      this._reCaptchaId = grecaptcha.render(this.captchaRef2.nativeElement, {
        'sitekey': '6LfO4GccAAAAANKTwgJ8WJUBMmVxbUWmGFttPXjs',
        'callback': (resonse) => this.reCapchaSuccess(resonse),
        'expired-callback': () => this.reCapchaExpired()
      });
    }
  }

  public get selectedView() {
    return this.controls.get('selectedView').value;
  }

  reCapchaSuccess(data: any) {
    if (data) {
      // console.log(data);
      this.isNotRobot = true;
    }
  }

  reCapchaExpired() {
    this.isNotRobot = false;
  }

  public register() {

    if (!this.isNotRobot) {
      this.formGroup.get("recaptcha").setErrors([ { value: 'AppMessages.Register.ValueRequired' }]);
      this._msg.error({message: 'AppMessages.Register.RegistrationFailed'});
      return;
    }

    const form = this.formGroup.value;
    const req: IRegisterAccountReq = {
      email: form.email,
      username: form.username,
      password: form.password,
      coupon: form.coupon,
      acceptTerms: form.termsAndConditions || false,
      clickId: '',
      externalClickId: ''
    }
    
    this._accountService.registerAccount(req)
      .result(this.formGroup, token => {
        
        this._msg.ok({
          message: 'AppMessages.Register.RegisterSuccess'
        });

        this._authService.setToken(token);
        this._router.navigate(["/user/settings"]);

      }, err => {
        this._msg.error({
          message: 'AppMessages.Register.RegistrationFailed'
        });
      });
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }
}

enum LoginView {
  Login = 0,
  ResetPassword = 1,
  ResetSent = 2
}
