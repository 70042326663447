import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services';
import { HttpBadRequestResponse, HttpNotFoundResponse, HttpUnauthorizedResponse } from 'src/app/helpers/BadRequest';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _authService: AuthenticationService) { }

  private _navigateWithReturnUrl(url: string) {
    const location = document.location.pathname;
    const returnUrl = location == url ? undefined : location;
    this._router.navigate([url], { queryParams: { returnUrl: returnUrl } });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError(err => {

        if (err.status === 401) {
          this._authService.logout();
          this._authService.openLoginDialog();
          const res = new HttpUnauthorizedResponse();
          return throwError(res);
        } else if (err.status === 400) {
          const res = new HttpBadRequestResponse();
          Object.assign(res, err.error);
          return throwError(res);
        } else if (err.status === 404) {
          const res = new HttpNotFoundResponse();
          this._router.navigateByUrl('/404');
          return throwError(res);
        }

        const error = err.message || err.statusText;
        return throwError(error);
        
      }
)

      );
  }
}
