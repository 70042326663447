import { TranslateService } from '@ngx-translate/core';
import { BankLinkProvider } from './BankLinkProvider';
import { BetType } from './BetType';
import { BetTypeGroup } from './BetTypeGroup';
import { DayOfWeek } from './DayOfWeek';
import { Gender } from './Gender';
import { Information } from './Information';
import { PmuBetType } from './PmuBetType';
import { Stable } from './Stable';
import { ToteSystem } from './ToteSystem';
import { DatePeriod } from './DatePeriod';
import { TicketStatus } from './TicketStatus';
import { TransactionStatus } from './TransactionStatus';
import { TransactionType } from './TransactionType';
import { HttpMethod } from './HttpMethod';
import { Bool } from './Bool';
import { Status } from './Status';
import { FilterType } from './FilterType';
import { SafeBettingPeriod } from './SafeBettingPeriod';
import { PmuRaceCategory } from './PmuRaceCategory';
import { ExternalRaceState } from './ExternalRaceState';
import { BetState } from './BetState';
import { OcspStatus } from './OcspStatus';
import { SportTicketType } from '../modules/sports/enum/SportTicketType';
import { MarketGroup } from '../modules/sports/enum/MarketGroup';
import { SportType } from '../modules/sports/enum/SportType';
import { KnownPageSection } from './KnownPageSection';
import { BasketballMarketTypeValue } from '../modules/sports/enum/MarketType/BasketballMarketTypeValue';
import { BoxingMarketTypeValue } from '../modules/sports/enum/MarketType/BoxingMarketTypeValue';
import { FootballMarketTypeValue } from '../modules/sports/enum/MarketType/FootballMarketTypeValue';
import { GolfMarketTypeValue } from '../modules/sports/enum/MarketType/GolfMarketTypeValue';
import { HandballMarketTypeValue } from '../modules/sports/enum/MarketType/HandballMarketTypeValue';
import { IceHockeyMarketTypeValue } from '../modules/sports/enum/MarketType/IceHockeyMarketTypeValue';
import { TableTennisMarketTypeValue } from '../modules/sports/enum/MarketType/TableTennisMarketTypeValue';
import { TennisMarketTypeValue } from '../modules/sports/enum/MarketType/TennisMarketTypeValue';
import { VolleyballMarketTypeValue } from '../modules/sports/enum/MarketType/VolleyballMarketTypeValue';
import { CyclingMarketTypeValue } from '../modules/sports/enum/MarketType/CyclingMarketTypeValue';
import { BaseballMarketTypeValue } from '../modules/sports/enum/MarketType/BaseballMarketTypeValue';
import { CricketMarketTypeValue } from '../modules/sports/enum/MarketType/CricketMarketTypeValue';
import { MotorSportMarketTypeValue } from '../modules/sports/enum/MarketType/MotorSportMarketTypeValue';
import { HockeyMarketTypeValue } from '../modules/sports/enum/MarketType/HockeyMarketTypeValue';
import { DartsMarketTypeValue } from '../modules/sports/enum/MarketType/DartsMarketTypeValue';
import { AustralianRulesMarketTypeValue } from '../modules/sports/enum/MarketType/AustralianRulesMarketTypeValue';
import { AmericanFootballMarketTypeValue } from '../modules/sports/enum/MarketType/AmericanFootballMarketTypeValue';
import { SnookerMarketTypeValue } from '../modules/sports/enum/MarketType/SnookerMarketTypeValue';
import { BowlsMarketTypeValue } from '../modules/sports/enum/MarketType/BowlsMarketTypeValue';
import { RugbyLeagueMarketTypeValue } from '../modules/sports/enum/MarketType/RugbyLeagueMarketTypeValue';
import { RugbyUnionMarketTypeValue } from '../modules/sports/enum/MarketType/RugbyUnionMarketTypeValue';
import { MartialArtsMarketTypeValue } from '../modules/sports/enum/MarketType/MartialArtsMarketTypeValue';
import { FutsalMarketTypeValue } from '../modules/sports/enum/MarketType/FutsalMarketTypeValue';
import { GaelicFootballMarketTypeValue } from '../modules/sports/enum/MarketType/GaelicFootballMarketTypeValue';
import { HurlingMarketTypeValue } from '../modules/sports/enum/MarketType/HurlingMarketTypeValue';
import { BeachVolleyballMarketTypeValue } from '../modules/sports/enum/MarketType/BeachVolleyballMarketTypeValue';
import { ESportsMarketTypeValue } from '../modules/sports/enum/MarketType/ESportsMarketTypeValue';
import { BadmintonMarketTypeValue } from '../modules/sports/enum/MarketType/BadmintonMarketTypeValue';
import { PickleballMarketTypeValue } from '../modules/sports/enum/MarketType/PickleballMarketTypeValue';
import { SquashMarketTypeValue } from '../modules/sports/enum/MarketType/SquashMarketTypeValue';
import { WaterPoloMarketTypeValue } from '../modules/sports/enum/MarketType/WaterPoloMarketTypeValue';
import { Basketball3x3MarketTypeValue } from '../modules/sports/enum/MarketType/Basketball3x3MarketTypeValue';
import { Region } from './Region';
import { FootballMatchPhase } from '../modules/sports/enum/MatchPhase/FootballMatchPhase';
import { IceHockeyMatchPhase } from '../modules/sports/enum/MatchPhase/IceHockeyMatchPhase';
import { BasketballMatchPhase } from '../modules/sports/enum/MatchPhase/BasketballMatchPhase';
import { MatchResultOutcome } from '../modules/sports/enum/MatchResultOutcome';
import { MarketStatus } from '../modules/sports/enum/MarketStatus';
import { SelectionStatus } from '../modules/sports/enum/SelectionStatus';
import { TotoSportsBetSlipResult } from '../modules/sports/enum/TotoSportsBetSlipResult';
import { PredictionGameType } from '../modules/sports/enum/PredictionGameType';
import { BonusValueType } from './BonusValueType';
import { UserCampaignStatus } from './UserCampaignStatus';
import { TicketRejectReason } from './TicketRejectReason';

const registry: {[index: string]: any }  = {
    [(<any>PmuBetType).__typeName]: PmuBetType,
    [(<any>BetType).__typeName]: BetType,
    [(<any>PmuRaceCategory).__typeName]: PmuRaceCategory,
    [(<any>DatePeriod).__typeName]: DatePeriod,
    [(<any>TicketStatus).__typeName]: TicketStatus,
    [(<any>TransactionType).__typeName]: TransactionType,
    [(<any>TransactionStatus).__typeName]: TransactionStatus,
    [(<any>ToteSystem).__typeName]: ToteSystem,
    [(<any>OcspStatus).__typeName]: OcspStatus,
    [(<any>SportTicketType).__typeName]: SportTicketType,
    [(<any>MarketGroup).__typeName]: MarketGroup,
    [(<any>SportType).__typeName]: SportType,
    [(<any>Region).__typeName]: Region,
    [(<any>KnownPageSection).__typeName]: KnownPageSection,
    [(<any>MatchResultOutcome).__typeName]: MatchResultOutcome,
    [(<any>MarketStatus).__typeName]: MarketStatus,
    [(<any>SelectionStatus).__typeName]: SelectionStatus,
    [(<any>TotoSportsBetSlipResult).__typeName]: TotoSportsBetSlipResult,
    [(<any>TicketRejectReason).__typeName]: TicketRejectReason,
    [(<any>PredictionGameType).__typeName]: PredictionGameType,
    [(<any>FootballMarketTypeValue).__typeName]: FootballMarketTypeValue,
    [(<any>BasketballMarketTypeValue).__typeName]: BasketballMarketTypeValue,
    [(<any>BoxingMarketTypeValue).__typeName]: BoxingMarketTypeValue,
    [(<any>GolfMarketTypeValue).__typeName]: GolfMarketTypeValue,
    [(<any>HandballMarketTypeValue).__typeName]: HandballMarketTypeValue,
    [(<any>IceHockeyMarketTypeValue).__typeName]: IceHockeyMarketTypeValue,
    [(<any>TableTennisMarketTypeValue).__typeName]: TableTennisMarketTypeValue,
    [(<any>TennisMarketTypeValue).__typeName]: TennisMarketTypeValue,
    [(<any>VolleyballMarketTypeValue).__typeName]: VolleyballMarketTypeValue,
    [(<any>CyclingMarketTypeValue).__typeName]: CyclingMarketTypeValue,
    [(<any>BaseballMarketTypeValue).__typeName]: BaseballMarketTypeValue,
    [(<any>CricketMarketTypeValue).__typeName]: CricketMarketTypeValue,
    [(<any>MotorSportMarketTypeValue).__typeName]: MotorSportMarketTypeValue,
    [(<any>HockeyMarketTypeValue).__typeName]: HockeyMarketTypeValue,
    [(<any>DartsMarketTypeValue).__typeName]: DartsMarketTypeValue,
    [(<any>AustralianRulesMarketTypeValue).__typeName]: AustralianRulesMarketTypeValue,
    [(<any>AmericanFootballMarketTypeValue).__typeName]: AmericanFootballMarketTypeValue,
    [(<any>SnookerMarketTypeValue).__typeName]: SnookerMarketTypeValue,
    [(<any>BowlsMarketTypeValue).__typeName]: BowlsMarketTypeValue,
    [(<any>RugbyLeagueMarketTypeValue).__typeName]: RugbyLeagueMarketTypeValue,
    [(<any>RugbyUnionMarketTypeValue).__typeName]: RugbyUnionMarketTypeValue,
    [(<any>MartialArtsMarketTypeValue).__typeName]: MartialArtsMarketTypeValue,
    [(<any>FutsalMarketTypeValue).__typeName]: FutsalMarketTypeValue,
    [(<any>GaelicFootballMarketTypeValue).__typeName]: GaelicFootballMarketTypeValue,
    [(<any>HurlingMarketTypeValue).__typeName]: HurlingMarketTypeValue,
    [(<any>BeachVolleyballMarketTypeValue).__typeName]: BeachVolleyballMarketTypeValue,
    [(<any>ESportsMarketTypeValue).__typeName]: ESportsMarketTypeValue,
    [(<any>BadmintonMarketTypeValue).__typeName]: BadmintonMarketTypeValue,
    [(<any>PickleballMarketTypeValue).__typeName]: PickleballMarketTypeValue,
    [(<any>SquashMarketTypeValue).__typeName]: SquashMarketTypeValue,
    [(<any>WaterPoloMarketTypeValue).__typeName]: WaterPoloMarketTypeValue,
    [(<any>Basketball3x3MarketTypeValue).__typeName]: Basketball3x3MarketTypeValue,
    [(<any>FootballMatchPhase).__typeName]: FootballMatchPhase,
    [(<any>IceHockeyMatchPhase).__typeName]: IceHockeyMatchPhase,
    [(<any>BasketballMatchPhase).__typeName]: BasketballMatchPhase,
    [(<any>BonusValueType).__typeName]: BonusValueType,
    [(<any>UserCampaignStatus).__typeName]: UserCampaignStatus,
  };

function asArray<T extends {[index: number]: string }>(values: T) {
    return Object.keys(values)
      .filter(value => isNaN(Number(value)) === false)
      .map(key => asObject(values, Number(key)));
}

function asObject<T extends {[index: number]: string }>(values: T, key: number) {
  return { id: key, name: getKey(values, key), label: values[key] };
}

function asBoolArray<T extends {[index: string]: any }>(values: T): { id: string, name: string }[] {
  return Object.keys(values)
    .filter(value => typeof values[value] === 'boolean')
    .map(value => (
      { id: values[value], name: getKey(values, values[value]) }
    ));
}


function translate<T extends { [index: string]: any }>(values: T, key: any, __?: TranslateService, params?: any) {

  if (key === null) {
    return '';
  }

  if (typeof values === 'string') {
    values = registry[values];
  }

  if (isNaN(key)) {
    key = values[key];
  }

  return __ ? __.instant(getKey(values, key), params) : values[key];
}

function getKey<T extends { [index: string]: any }>(values: T, key: any) {

  if (values && values.__altTranslationKeys && values.__altTranslationKeys[key]) {
    return values.__altTranslationKeys[key];
  }

  return `Enums.${(<any>values).__typeName}.${values[key]}`;
}

function label<T extends { [index: string]: any }>(values: T, key: any) {
  return values[key];
}

export {
    asArray, translate, getKey, asObject, label, asBoolArray, 
    FilterType, 
    Bool, 
    Status,
    PmuBetType,
    BetType,
    BetTypeGroup,
    DayOfWeek,
    BankLinkProvider as AuthenticationProvider,
    Gender,
    Information,
    Stable,
    ToteSystem,
    DatePeriod,
    TicketStatus,
    TransactionStatus,
    TransactionType,
    HttpMethod,
    SafeBettingPeriod,
    PmuRaceCategory,
    ExternalRaceState,
    BetState,
    OcspStatus,
    BonusValueType,
    UserCampaignStatus
};
