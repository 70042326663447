export enum PickleballMarketTypeValue
{
    MatchWinner = 15333,
    Game1Winner = 15595,
    Game2Winner = 15596,
    Game3Winner = 15597,
    Game4Winner = 15598,
    Game5Winner = 15599,
    TotalPointsOverUnder = 15600,
    Game1TotalPointsOverUnder = 15601,
    Game2TotalPointsOverUnder = 15602,
    Game3TotalPointsOverUnder = 15603,
    Game4TotalPointsOverUnder = 15604,
    Game5TotalPointsOverUnder = 15605,
    TotalPointsOddOrEven = 15606,
    Game1TotalPointsOddOrEven = 15607,
    Game2TotalPointsOddOrEven = 15608,
    Game3TotalPointsOddOrEven = 15609,
    Game4TotalPointsOddOrEven = 15610,
    Game5TotalPointsOddOrEven = 15611,
    CompetitorATotalPointsOddOrEven = 15612,
    CompetitorBTotalPointsOddOrEven = 15613,
    Game1NumberedPointWinner = 15614,
    Game2NumberedPointWinner = 15615,
    Game3NumberedPointWinner = 15616,
    Game4NumberedPointWinner = 15617,
    Game5NumberedPointWinner = 15618,
    Game1RaceTo3Points = 15619,
    Game2RaceTo3Points = 15620,
    Game3RaceTo3Points = 15621,
    Game4RaceTo3Points = 15622,
    Game5RaceTo3Points = 15623,
    Game1RaceTo5Points = 15624,
    Game2RaceTo5Points = 15625,
    Game3RaceTo5Points = 15626,
    Game4RaceTo5Points = 15627,
    Game5RaceTo5Points = 15628,
    Game1RaceTo7Points = 15629,
    Game2RaceTo7Points = 15630,
    Game3RaceTo7Points = 15631,
    Game4RaceTo7Points = 15632,
    Game5RaceTo7Points = 15633,
    MatchGameHandicap = 15634,
    MatchPointHandicap = 15635,
    Game1Handicap = 15636,
    Game2Handicap = 15637,
    Game3Handicap = 15638,
    Game4Handicap = 15639,
    Game5Handicap = 15640,
    Game1ExtraPoints = 15641,
    Game2ExtraPoints = 15642,
    Game3ExtraPoints = 15643,
    Game4ExtraPoints = 15644,
    Game5ExtraPoints = 15645,
    Game1LeadAfter2Points = 15646,
    Game1LeadAfter4Points = 15647,
    Game1LeadAfter6Points = 15648,
    Game1LeadAfter8Points = 15649,
    Game1LeadAfter10Points = 15650,
    Game2LeadAfter2Points = 15651,
    Game2LeadAfter4Points = 15652,
    Game2LeadAfter6Points = 15653,
    Game2LeadAfter8Points = 15654,
    Game2LeadAfter10Points = 15655,
    Game3LeadAfter2Points = 15656,
    Game3LeadAfter4Points = 15657,
    Game3LeadAfter6Points = 15658,
    Game3LeadAfter8Points = 15659,
    Game3LeadAfter10Points = 15660,
    Game4LeadAfter2Points = 15661,
    Game4LeadAfter4Points = 15662,
    Game4LeadAfter6Points = 15663,
    Game4LeadAfter8Points = 15664,
    Game4LeadAfter10Points = 15665,
    Game5LeadAfter2Points = 15666,
    Game5LeadAfter4Points = 15667,
    Game5LeadAfter6Points = 15668,
    Game5LeadAfter8Points = 15669,
    Game5LeadAfter10Points = 15670,
    Game1CorrectScore = 15671,
    Game2CorrectScore = 15672,
    Game3CorrectScore = 15673,
    Game4CorrectScore = 15674,
    Game5CorrectScore = 15675,
    CorrectScore = 15676,
    TotalGamesOverUnder = 15677,
    CompetitorATotalPointsOverUnder = 15678,
    CompetitorBTotalPointsOverUnder = 15679,
    TotalGamesOddOrEven = 15680,
}

(PickleballMarketTypeValue as any).__typeName = 'PickleballMarketTypeValue';