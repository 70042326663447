import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ApplicationRef, ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import {
  AuthenticationService,
  TitleService
} from './services';
import { AppState, TotoAppModule } from './app.state';
import { THEME_COLORS } from './config/constants';
import { trigger, transition, style, animate } from '@angular/animations';
import { LocalStorage, ResponsiveHelper } from './helpers';
import { toNumber } from './util/number-util';
import { LOCAL_STORAGE_KEY } from './config/local-storage-key';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private _minute = 60 * 1000;

  public isLoading = true;

  private _pageLoadedSubscription: Subscription;
  private _navigationEndSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _translateService: TranslateService,
    private router: Router,
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private _state: AppState,
    private _authService: AuthenticationService,
    private _responsive: ResponsiveHelper,
    private _ar: ApplicationRef,
    private _cd: ChangeDetectorRef
  ) {

    this._state.url = location.pathname;
    
    this._pageLoadedSubscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
      .subscribe(event => {
        _state.url = event.url;
        this._state.isViewLoaded = false;
        this.setThemeColors();
      });

    this._navigationEndSubscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(event => {
        this._state.isViewLoaded = true;
        this._state.isPageLoaded = true;
        this._authService.showLoginModal = false;
        if (isPlatformBrowser(this.platformId)) {
          window.scroll(0, 0);
        }

        const scrollTopContainer = document.getElementById('content-main');
        if (scrollTopContainer) {
          scrollTopContainer.scrollTop = 0;
        }
        this._state.isNavigatorOpen = false;
        this._state.isUserMenuOpen = false;
        this._state.isNotificationsMenuOpen = false;
      });
      
  }

  public setThemeColors(appModule = this._state.activeAppModule) {
    const module = appModule;
    let styles = [];

    let favIcon = 'hb-favicon.ico';

    switch (module) {
      case TotoAppModule.HorseRacing:
        styles = [
          {
            name: 'app-background-color',
            value: THEME_COLORS[TotoAppModule.HorseRacing].background
          },
          {
            name: 'theme-color-1',
            value: THEME_COLORS[TotoAppModule.HorseRacing].color1
          },
          {
            name: 'theme-color-2',
            value: THEME_COLORS[TotoAppModule.HorseRacing].color2
          }
        ];
        break;
      case TotoAppModule.Sports:
        styles = [
          {
            name: 'app-background-color',
            value: THEME_COLORS[TotoAppModule.Sports].background
          },
          {
            name: 'theme-color-1',
            value: THEME_COLORS[TotoAppModule.Sports].color1
          },
          {
            name: 'theme-color-2',
            value: THEME_COLORS[TotoAppModule.Sports].color2
          }
        ];
        favIcon = 'sport-favicon.ico';
        break;
    }

    styles.forEach(data => {
      document.documentElement.style.setProperty(`--${data.name}`, data.value);
    });


    // Set favicon
    this._document.getElementById('favIcon').setAttribute('href', `/${favIcon}`);
  }

  public get isPageLoaded() {
    return this._state.isPageLoaded;
  }

  public setTheme(appModule = this._state.activeAppModule) {
    this.setThemeColors(appModule);
  }

  ngOnInit() {
    this.setTheme();

    this._state.appModule$.subscribe((appModule: TotoAppModule) => {
      LocalStorage.set(LOCAL_STORAGE_KEY.LAST_VISITED_MODULE, appModule);
      this.setTheme(appModule);
    });

    this._state.updateApplicationSubject$
      .pipe(debounceTime(50))
      .subscribe(val => {
        this._responsive.getLayout();
        this._ar.tick();
      });

    this._translateService.addLangs(['en', 'et']);
    this._translateService.setDefaultLang('en');

    this._responsive.getLayout();
    window.onresize = () => {
      this._responsive.getLayout();
    };

    this._refreshToken();
    this._detectUserActivity(['mousedown', 'keydown'], 15000);
  }

  private _refreshToken() {
    let updateInterval: NodeJS.Timeout;
    let logoutTimeout: NodeJS.Timeout;

    this._state.userIsActiveOnPage$.subscribe((val) => {

      if (val) {
        clearTimeout(logoutTimeout);
        
        if (this._state.isLoggedIn) {

          this._authService.updateToken(() => {});
          updateInterval = setInterval(() => {
              this._authService.updateToken(() => {});
          }, 5 * this._minute);
        }
      }
      else {
        clearInterval(updateInterval);

        if (this._state.isLoggedIn) {
          let diff = this._authService.getTokenExpiry().getTime() - new Date().getTime();
          logoutTimeout = setTimeout(async () => {
            await this._authService.logout();
          }, diff);
        }
      }
    }); 
  }

  private _detectUserActivity(eventNames: string[], debounceTime: number) {
    let timeout = setTimeout(() => {
      this._state.userIsActiveOnPage$.next(false);
    }, debounceTime);

    for (var eventName of eventNames) {
      document.addEventListener(eventName, () => {
        if (!this._state.userIsActiveOnPage$.value) {
          this._state.userIsActiveOnPage$.next(true);
        }

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this._state.userIsActiveOnPage$.next(false);
        }, debounceTime);
      });
    }
  }

  // ngAfterViewInit() {
  //   setTimeout(_ => {
  //     this._state.isPageLoaded = true;
  //   });
  // }

  ngOnDestroy() {

    if (this._pageLoadedSubscription) {
      this._pageLoadedSubscription.unsubscribe();
    }

    if (this._navigationEndSubscription) {
      this._navigationEndSubscription.unsubscribe();
    }
  }
}
