export const LocalStorage = {

  set(name: string, value: any): boolean {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem(name, JSON.stringify(value));
        return true;
    }
    return false;
  },

  get(name: string) {
    if (typeof window !== 'undefined' && window.localStorage) {
        return window.localStorage.getItem(name);
    } 
    return null;
  },

  getValue(name: string) {
    if (name && typeof window !== 'undefined' && window.localStorage) {
      return JSON.parse(window.localStorage.getItem(name));
    };
    return null;
  }

}
