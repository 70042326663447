import { Component } from "@angular/core";
import { AppState } from "src/app/app.state";
import { IIdentity } from "src/app/models/IIdentity";
import { AuthenticationService } from "src/app/services";

@Component({
    selector: 'user-sidebar',
    templateUrl: './user-sidebar.component.html',
    styleUrls: ['./user-sidebar.component.scss']
  })
  export class UserSidebarComponent {
  
    constructor(private _authService: AuthenticationService, private _appState: AppState) {}
  
    public get balance() {
      return this._appState.userBalance;
    }
  
    public get user(): IIdentity {
      return this._appState.user;
    }
  
    public get firstName(): string {
      return this.user?.given_name;
    }
  
    public get lastName(): string {
      return this.user?.name;
    }
  }