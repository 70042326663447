import { Pipe, PipeTransform } from '@angular/core';
import { SportType } from '../modules/sports/enum/SportType';
import { MARKET_TYPE_NAME_MAP } from '../modules/sports/enum/MarketType';
import { TranslateEnumPipe } from 'src/app/pipes';
@Pipe({
  name: 'translateSportMarketType'
})
export class TranslateSportMarketTypePipe implements PipeTransform {


  constructor(private _translateEnumPipe: TranslateEnumPipe) {}

  transform(value: number, sportType: SportType, params?: any): string {

    const enumName = MARKET_TYPE_NAME_MAP[sportType];

    return this._translateEnumPipe.transform(value, enumName, params);
  }
}
