import { Component } from '@angular/core';
import { UserBlockComponent } from '../user.component';
import { MAIN_MENU_LINKS } from 'src/app/config/nav-links';
import { TotoAppModule } from 'src/app/app.state';


@Component({
  selector: 'user-block-mobile',
  templateUrl: './user-mobile.component.html',
  styleUrls: ['./user-mobile.component.scss']
})
export class UserBlockMobileComponent extends UserBlockComponent {

}
