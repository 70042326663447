import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NavigatorComponent } from '../navigator.component';
import { TotoAppModule } from 'src/app/app.state';
import { MAIN_MENU_LINKS } from 'src/app/config/nav-links';


@Component({
  selector: 'navigator-mobile',
  templateUrl: './navigator-mobile.component.html',
  styleUrls: ['./navigator-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigatorMobileComponent extends NavigatorComponent {

  public get menuItems() {
    return this.moduleMenuLinks.filter(x => x.isMobile);
  }

  public get livebetLink() {
    return MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/livebet'
  }
  

}
