<app-content [ngSwitch]="isTablet">
  <!-- DESKTOP VIEW -->
  <ng-container *ngSwitchCase="false">
    <div class="support-container">
      <div class="support-header-row">
        <div class="col-sidebar">
          <div class="flex f-1">
            <h2>{{pageTitle | translate}}</h2>
          </div>
        </div>
        <div class="col-content">
          <div class="flex f-1">
            <ng-template *ngTemplateOutlet="actionBoxesTemplate"></ng-template>
          </div>
        </div>
      </div>
      <div class="support-content-row mt20">
        <div class="col-sidebar">
          <ng-template *ngTemplateOutlet="linksListTemplate"></ng-template>
        </div>
        <div class="col-content" [ngSwitch]="selectedSection?.isGroupOnly">
          <ng-container *ngSwitchDefault>
            <ng-template *ngTemplateOutlet="childrenTemplate"></ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="true">
            <ng-container
              *ngTemplateOutlet="pageContentTemplate; context: { page: selectedSection }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- MOBILE VIEW -->
  <ng-container *ngSwitchDefault>
    <div class="responsive-support-container">
      <div class="support-header-row">
        <div class="col-title" (click)="onTitleSectionClick()">
          <h2 [ngSwitch]="selectedSection ? true : false">
            <ng-container *ngSwitchCase="false">
              {{pageTitle | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="true">
              <svg-icon key="arrowLeft" class="mr8"></svg-icon>
              {{selectedSection.title}}
            </ng-container>
          </h2>
        </div>
        <!-- <div class="col-categories">
          <button
            class="toggle-categories-btn"
            [class.open]="mobileCategoriesOpen"
            (click)="toggleMobileCategories()"
          >
            <span class="categories-btn-text mr10">
              {{(mobileCategoriesOpen ? 'Action.CloseCategories' : 'Action.OpenCategories') | translate}}
            </span>
            <svg-icon class="toggle-arrow" key="arrowDownSmall" fontSize="9px"></svg-icon>
          </button>
        </div> -->
      </div>

      <!-- <div class="support-categories">
  
      </div>
    -->
      <div
        class="support-content mt20"
        [ngSwitch]="selectedSection ? true : false"
      >


      <!-- <expander [trigger]="mobileCategoriesOpen"> -->
        <ng-container *ngIf="mobileCategoriesOpen">
          <ng-template *ngTemplateOutlet="linksListTemplate"></ng-template>
        </ng-container>
      <!-- </expander> -->
          <!-- <ng-container *ngSwitchCase="false">
        
          </ng-container> -->

          <ng-container
            [ngSwitch]="selectedSection?.isGroupOnly"
            *ngIf="selectedSection"
          >
            <ng-container *ngSwitchDefault>
              <ng-template *ngTemplateOutlet="childrenTemplate"></ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="true">
              <ng-container
                *ngTemplateOutlet="pageContentTemplate; context: { page: selectedSection }"
              ></ng-container>
            </ng-container>
          </ng-container>
      </div>

      <div class="support-actions">
        <ng-template *ngTemplateOutlet="actionBoxesTemplate"></ng-template>
      </div>
    </div>
  </ng-container>
</app-content>

<ng-template #childrenTemplate>
  <collapsable-section
    class="page-section"
    type="grey"
    *ngFor="let childPage of selectedSection?.children"
    [manualToggle]="true"
    [isOpen]="openPageId === childPage.id"
    (onToggle)="onPageClick(childPage)"
    id="page{{childPage.id}}"
  >
    <ng-container header>
      {{childPage?.title}}
    </ng-container>
    <ng-container
      *ngTemplateOutlet="pageContentTemplate; context: { page: childPage }"
    ></ng-container>
  </collapsable-section>
</ng-template>

<ng-template #linksListTemplate>
  <ul class="support-links-list">
    <li
      *ngFor="let link of data"
      [class.active]="selectedSection?.id == link.id"
      (click)="onSectionClick(link)"
    >
      {{link.title}}
    </li>
  </ul>
</ng-template>

<ng-template #actionBoxesTemplate>
  <div class="action-box" (click)="toggleChat()">
    <svg-icon key="liveChat" fontSize="40px" class="mr20"></svg-icon>
    <div class="flex flex-column">
      <h4>
        {{'Views.Support.Chat.StartChat' | translate}}
      </h4>
      <span class="alt-text">
        {{'Views.Support.Chat.TextRow1' | translate}}
        <br />
        {{'Views.Support.Chat.TextRow2' | translate}}
      </span>
    </div>
  </div>
  <a class="action-box" href="mailto:info@toto.ee">
    <svg-icon key="email2" fontSize="40px" class="mr20"></svg-icon>
    <div class="flex flex-column">
      <h4>
        {{'Views.Support.Email.SendEmail' | translate}}
      </h4>
      <span class="alt-text">
        {{'Views.Support.Email.TextRow1' | translate}}
        <br />
        {{'Views.Support.Email.TextRow2' | translate}}
      </span>
    </div>
  </a>
</ng-template>

<ng-template #pageContentTemplate let-page="page">
  <div class="page-content" id="page{{page.id}}">
    <quill-view class="full-width" [content]="page.content"></quill-view>
  </div>
</ng-template>
