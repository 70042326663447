import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

//
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MomentModule } from 'ngx-moment';
import { SvgIconsModule } from '@ngneat/svg-icon';

// DIRECTIVES
import * as directives from './directives';

// PIPES
import * as pipes from './pipes';

// i18n
import { TranslateModule } from '@ngx-translate/core';

// COMPONENTS
import * as components from './components';
import { LanguagesBlockComponent } from './components';

// EXTENSIONS
import 'src/app/ext/observable-result';

// HELPERS
import {
  JwtInterceptor,
  ErrorInterceptor,
  ApiInterceptor,
  JsonDateInterceptor
} from './helpers';

import { CommonModule, CurrencyPipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';

import localeEt from '@angular/common/locales/et';
import localeEn from '@angular/common/locales/en';
import { LocaleService } from './services';
import { ActionHelper } from './helpers/action';
import { IsAuthenticatedDirective } from './directives';
import {
  DataTableDropdownFilterDirective,
  DataTableFilterDirective,
  DataTableTextFilterDirective
} from './directives/data-table-filter.directive';
import {
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  DatepickerComponent,
  FooterComponent,
  FormFieldComponent,
  HamburgerToggleComponent,
  InputComponent,
  LoaderComponent,
  MessageBoxComponent,
  NoContentComponent,
  PageLoaderComponent,
  RadioComponent,
  RangepickerComponent,
  SelectComponent,
  SelectFullscreenComponent,
  TitleDateComponent
} from './components';

import { DialogComponent } from './components/_layout/dialog/dialog.component';
import { ConfirmationDialog } from './components/dialogs/confirm/confirm.dialog';
import { ConfirmDialogComponent } from './components/_layout/confirm-dialog/confirm.dialog';
import { DynamicContentComponent } from './components/misc/dynamic-content/dynamic-content.component';

import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app.routing';
import { RouterModule } from '@angular/router';
import { ExpanderComponent } from './components/_layout/expander/expander.component';
import { CollapsableSectionComponent } from './components/_layout/collapsable-section/collapsable-section.component';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { SportsListComponent } from './modules/sports/components/sports-list/sports-list.component';

import { BreadcrumbModule } from 'xng-breadcrumb';
import { BreadcrumbService } from 'xng-breadcrumb';
import { TotoBreadcrumbComponent } from './components/_elements/breadcrumb/toto-breadcrumb.component';
import { CardMessageComponent } from './components/_elements/card-message/card-message.component';
import { NavigationSpinnerComponent } from './components/_layout/loaders/navigation-spinner/navigation-spinner.component';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { SupportLayout } from './views/support/support.layout';
import { PagesService } from './services/pages.service';
import {
  SupportPageResolve,
  SupportPagesResolve
} from './resolvers/pages.resolve';
import { QuillModule } from 'ngx-quill';
import { SlideToggleComponent } from './components/form/slide-toggle/slide-toggle.component';
import { AppContentLayout } from './views/content.layout';
import { VideoBoxComponent } from './modules/horse/view/video-instructions/video-box/video-box.component';
import { VideoDialogComponent } from './modules/horse/view/video-instructions/dialogs/video/video.dialog';
import { DayPickerComponent } from './components/form/day-picker/day-picker.component';
import { NavSearchBarComponent } from './components/_layout/navigator/components/search/search.component';
import { DataTableComponent } from './components/_layout/data-table/data-table.component';
import { DateDataTableFilterComponent } from './components/_layout/data-table/filters/date-filter/date-data-table-filter.component';
import { DropdownDataTableFilterComponent } from './components/_layout/data-table/filters/dropdown-filter/dropdown-data-table-filter.component';
import { TextDataTableFilterComponent } from './components/_layout/data-table/filters/text-filter/text-data-table-filter.component';
import { DataTableFooterComponent } from './components/_layout/data-table/footer/data-table-footer.component';
import { CountUpModule } from 'ngx-countup';
import { NgxMaskModule } from 'ngx-mask';
import { SwiperModule } from 'swiper/angular';
import { BadgeComponent } from './components/_elements/badge/badge.component';

registerLocaleData(localeEt, 'et-EE');
registerLocaleData(localeEn, 'en-US');

@NgModule({
  declarations: [
    // COMPONENTS
    AppContentLayout,
    VideoBoxComponent,
    VideoDialogComponent,

    ButtonComponent,
    InputComponent,
    RadioComponent,
    SlideToggleComponent,
    SelectComponent,
    SelectFullscreenComponent,
    HamburgerToggleComponent,
    CardMessageComponent,
    NavigationSpinnerComponent,
    PageLoaderComponent,
    LoaderComponent,

    FormFieldComponent,
    CheckboxComponent,
    DayPickerComponent,
    BadgeComponent,

    CardComponent,
    MessageBoxComponent,
    DatepickerComponent,
    RangepickerComponent,
    TitleDateComponent,
    DynamicContentComponent,
    ExpanderComponent,
    CollapsableSectionComponent,
    FooterComponent,
    SportsListComponent,
    TotoBreadcrumbComponent,
    NavSearchBarComponent,

    NoContentComponent,
    LanguagesBlockComponent,

    // Dialogs
    DialogComponent,
    ConfirmationDialog,
    ConfirmDialogComponent,

    // PIPES
    pipes.TranslateEnumPipe,
    pipes.PriceValuePipe,
    pipes.SafePipe,
    pipes.TotoDatePipe,
    pipes.SortByOrderPipe,
    pipes.TranslateSportMarketTypePipe,
    pipes.SportScorePipe,
    pipes.FilterOddsPipe,
    pipes.NumericValuePipe,
    pipes.PercentValuePipe,

    // DIRECTIVES
    IsAuthenticatedDirective,
    DataTableFilterDirective,
    StopPropagationDirective,

    // DATA TABLE

    DataTableComponent,
    DataTableFooterComponent,
    TextDataTableFilterComponent,
    DropdownDataTableFilterComponent,
    DateDataTableFilterComponent,
    DataTableTextFilterDirective,
    DataTableDropdownFilterDirective,

    //
    SupportLayout,
  ],
  imports: [
    // // ANGULAR MODULES
    CommonModule,
    QuillModule.forRoot(),
    TranslateModule.forChild(),
    HttpClientModule,
    RouterModule,

    FormsModule,
    ReactiveFormsModule,
    CountUpModule,
    NgxMaskModule.forRoot(),
    SwiperModule,

    // Angular Material
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatFormFieldModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTabsModule,
    MatBadgeModule,
    MatSelectModule,
    MatStepperModule,
    MatInputModule,
    MatDatepickerModule,
    MatSnackBarModule,
    // Other
    ScrollingModule,
    NgSelectModule,
    DragDropModule,
    BreadcrumbModule,
    NgxTippyModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59
      }
    }),
    SvgIconsModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    QuillModule,
    HttpClientModule,
    RouterModule,

    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    CountUpModule,
    SwiperModule,

    // Angular Material
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatFormFieldModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTabsModule,
    MatBadgeModule,
    MatSelectModule,
    MatStepperModule,
    MatInputModule,
    MatDatepickerModule,
    MatSnackBarModule,
    //

    ScrollingModule,
    NgSelectModule,
    DragDropModule,
    MomentModule,

    // COMPONENTS
    AppContentLayout,
    VideoBoxComponent,
    VideoDialogComponent,
    LanguagesBlockComponent,
    BadgeComponent,

    ButtonComponent,
    InputComponent,
    SlideToggleComponent,
    RadioComponent,
    SelectComponent,
    SelectFullscreenComponent,
    HamburgerToggleComponent,
    FooterComponent,
    SportsListComponent,
    TotoBreadcrumbComponent,
    CardMessageComponent,
    NavigationSpinnerComponent,
    PageLoaderComponent,
    LoaderComponent,

    FormFieldComponent,
    CheckboxComponent,
    DayPickerComponent,

    CardComponent,
    MessageBoxComponent,
    DatepickerComponent,
    RangepickerComponent,
    NavSearchBarComponent,
    NoContentComponent,

    TitleDateComponent,
    DynamicContentComponent,
    ExpanderComponent,
    CollapsableSectionComponent,

    // Dialogs
    DialogComponent,
    ConfirmationDialog,
    ConfirmDialogComponent,

    // PIPES
    pipes.TranslateEnumPipe,
    pipes.PriceValuePipe,
    pipes.SafePipe,
    pipes.TotoDatePipe,
    pipes.SortByOrderPipe,
    pipes.TranslateSportMarketTypePipe,
    pipes.SportScorePipe,
    pipes.FilterOddsPipe,
    pipes.NumericValuePipe,
    pipes.PercentValuePipe,

    // DIRECTIVES
    IsAuthenticatedDirective,
    DataTableFilterDirective,

    //
    DataTableComponent,
    DataTableFooterComponent,
    TextDataTableFilterComponent,
    DropdownDataTableFilterComponent,
    DateDataTableFilterComponent,
    DataTableTextFilterDirective,
    DataTableDropdownFilterDirective,

    //
    SupportLayout
  ],
  providers: [
    ActionHelper,
    CurrencyPipe,
    PagesService,
    SupportPagesResolve,
    SupportPageResolve,
    pipes.TranslateEnumPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        BreadcrumbService,
        LocaleService,
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: true }
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        }
      ]
    };
  }
}
