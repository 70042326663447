import { Component, Input } from "@angular/core";
import { AppState } from "src/app/app.state";
import { ResponsiveHelper } from "src/app/helpers";

@Component({
  selector: 'transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss'],
  
})
export class TransactionDetailComponent {

  @Input() detail: any;


}