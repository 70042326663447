export enum TransactionType {
    PayIn = 1,
    CashOut = 2,
    Bonus = 3,
    Winning = 4,
    Bet = 5,
    AdminCorrection = 6,
    HarryBoyCorrection = 7,
    BatchBet = 8,
    BonusBet = 9,
    Charity = 12,
    MatrixBonus = 16,
    BonusFromPartner = 17,
    Discount = 19,
    BonusWin = 20,
    ConvertBonusToCredit = 21,
    BonusTakeout = 22,
}

(TransactionType as any).__typeName = 'TransactionType';

const _ = 'Enums.TransactionType.';
(TransactionType as any).__altTranslationKeys = {
    [TransactionType.PayIn]: _ + 'PayIn',
    [TransactionType.CashOut]: _ + 'CashOut',
    [TransactionType.Bonus]: _ + 'Bonus',
    [TransactionType.Winning]: _ + 'Winning',
    [TransactionType.Bet]: _ + 'Bet',
    [TransactionType.AdminCorrection]: _ + 'AdminCorrection',
    [TransactionType.HarryBoyCorrection]: _ + 'HarryBoyCorrection',
    [TransactionType.BatchBet]: _ + 'BatchBet',
    [TransactionType.BonusBet]: _ + 'BonusBet',
    [TransactionType.Charity]: _ + 'Charity',
    [TransactionType.MatrixBonus]: _ + 'MatrixBonus',
    [TransactionType.BonusFromPartner]: _ + 'BonusFromPartner',
    [TransactionType.Discount]: _ + 'Discount',
};
