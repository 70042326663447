<div class="login-wrapper" [ngSwitch]="isMobile">
    <ng-container *ngSwitchCase="true">
        <form class="flex flex-column mb20" [formGroup]="loginForm" onsubmit="login()" style="padding:20px 0;">
            <input class="toto-input" type="text" formControlName="username" placeholder="{{'Users.Login.Username' | translate}}">
            <input class="toto-input mt10" type="password" formControlName="password" placeholder="{{'Users.Login.Password' | translate}}">
            <a routerLink="forgot-password" class="mt20">
                {{'Users.Login.Forgot password?' | translate}}
            </a>
            <toto-button
                class="full-width mt20"
                height="50px"
                [loading]="isLoginLoading"
                (click)="logIn()" 
                type="submit" 
                label="Users.Login.Login">
            </toto-button>
            <toto-button
                class="full-width mt20"
                height="50px"
                routerLink="register"
                label="Users.Register.Register">
            </toto-button>
            <!-- <a routerLink="register" class="mt20">
                {{'Users.Register.Register' | translate}}
            </a> -->
        </form>
    </ng-container>
    <ng-container *ngSwitchCase="false">
        <form class="flex flex-column mt15 mb20" [formGroup]="loginForm" onsubmit="login()">
            <input class="toto-input" type="text" formControlName="username" placeholder="{{'Users.Login.Username' | translate}}">
            <input class="toto-input mt10" type="password" formControlName="password" placeholder="{{'Users.Login.Password' | translate}}">
            <toto-button 
                [loading]="isLoginLoading"
                (click)="logIn()" 
                type="submit" 
                label="Users.Login.Login" 
                class="full-width mt10"></toto-button>
            <a routerLink="forgot-password" class="mt10 mb10">
                {{'Users.Login.Forgot password?' | translate}}
            </a>
        </form>
        <toto-button class="full-width mb20"
            label="Users.Register.Register"
            width="100%"
            height="50px"
            buttonStyle="font-size: 16px !important; font-weight: bold !important;"
            routerLink="register">
        </toto-button>
    </ng-container>
</div>