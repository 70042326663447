<div class="title-date-wrapper" [formGroup]="formGroup">
  <span class="title-date">
    <div class="title-day">
        {{currentDayOfMonth}}
    </div>
    <div class="title-month">
        {{currentMonth}}
        <br>
        {{currentDay}}
    </div>
  </span>
</div>
