import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ResponsiveHelper } from '../helpers';
import { AuthenticationService } from '../services';
import { TitleService } from '../services/title.service';
import { AppState, TotoAppModule } from '../app.state';

@Component({
  selector: 'app-content',
  templateUrl: './content.layout.html',
  styleUrls: ['./content.layout.scss'],
  host: {
    'id': 'content-main'
  }
})
export class AppContentLayout {

  @HostBinding('class.centered') 
  // Center content
  @Input() public centered = true;

  @Input() public contentMaxWidth = 'default';
  @Input() public contentPadding = 'default';

  public isLoading = true;

  constructor(public responsive: ResponsiveHelper, private _titleService: TitleService, private _authService: AuthenticationService, private _state: AppState) {}

  public get isMobile(): boolean {
    return this._state.isViewMobile;
  }
  
  public ngAfterViewInit() {
    setTimeout(_ => {
      this._state.isPageLoaded = true;
    })
  }
  
}
