import { environment } from 'src/environments/environment';

export const SITE_KEYS = {
  reCAPTCHA: '6LeQSKEaAAAAAMvmDcp77tfp29SsThEU8pxtKiUT',
  secret: '6LeQSKEaAAAAAJs-MJgLfGqLqqGcOnbVsA_D9ydf'
}

export const DEV = true;

export const APP_VERSION = '0.9.9';

export const LANGUAGE_OPTIONS = [
  { name: 'EST', label: 'Common.Languages.Estonian', value: 'et', locale: 'et-EE', icon: 'flagEt' },
  { name: 'ENG', label: 'Common.Languages.English', value: 'en', locale: 'en-US', icon: 'flagEn' },
];

export const DEFAULT_LANGUAGE = LANGUAGE_OPTIONS[0];
export const ICONS_PATH = './assets/img/icons/';
export const I18N_PATH = `${environment.apiUrl}site/`;

export const DIALOG_WIDTHS = {
  AUTO: 'auto',
  M: '650px',
  L: '900px',
};

export const DIALOG_HEIGHTS = {
  AUTO: 'auto',
  L: '90%',
};
