export const MONTH_DURATION_OPTIONS = [
    {
      label: 'Common.Label.OneMonth',
      value: 1
    },
    {
      label: 'Common.Label.TwoMonths',
      value: 2
    },
    {
      label: 'Common.Label.SixMonths',
      value: 6
    }
]