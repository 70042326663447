import { Injectable, ViewChild, Inject, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { PopMessageComponent } from 'src/app/components/_layout/messages/pop-message/pop-message.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Injectable()
export class MessageDispatcher {
  public defaultDuration = 5000; //ms

  constructor(
    private _snackBar: MatSnackBar,
    private _snackBarRef: MatSnackBarRef<PopMessageComponent>,
    private _translateService: TranslateService,
    private zone: NgZone
  ) { }

  public translate(key: string) {
    return this._translateService.instant(key);
  }

  public openMessage(options: IMessageOptions, messageType = MessageType.notification) {

    return this.zone.run(_ => {

      const message = options.message || null;
      const messages = options.messages || null;
      const template = options.template || null;
      const duration = options.duration || this.defaultDuration;
      const verticalPosition = options.verticalPosition || 'bottom';
      const horizontalPosition = options.horizontalPosition || 'start';

      if (message || messages || template) {
        return this._snackBar.openFromComponent(PopMessageComponent, {
          duration: options.indefinite ? undefined : duration,
          data: {
            message: message,
            args: options.args,
            messages: messages,
            template: template,
            buttonText: options.buttonText,
            buttonIcon: options.buttonIcon,
            buttonAction: options.buttonAction,
          },
          verticalPosition: verticalPosition,
          horizontalPosition: horizontalPosition,
          panelClass: messageType
        });
      }
    })

  }

  public ok(options: IMessageOptions) {
    return this.openMessage(options, MessageType.success);
  }

  public error(options: IMessageOptions) {
    return this.openMessage(options, MessageType.error);
  }

  public notify(options: IMessageOptions) {
    return this.openMessage(options, MessageType.notification);
  }
}

export interface IMessageOptions {
  message?: string;
  args?: any[];
  messages?: string[];
  template?: string;
  duration?: number;
  indefinite?: boolean;
  buttonIcon?: string;
  buttonText?: string;
  buttonAction?: () => void;
  verticalPosition?: MatSnackBarVerticalPosition;
  horizontalPosition?: MatSnackBarHorizontalPosition;
}

enum MessageType {
  success = 'success-message',
  error = 'error-message',
  notification = 'notification-message'
}
