export enum MotorSportMarketTypeValue
{
    RaceWinner = 84,
    PointsFinish = 85,
    PolePosition = 86,
    FastestLap = 87,
    Top3Podium = 110,
    TeamWinner = 114,
    DriverHeadToHead1 = 6985,
    FirstLapLeader = 7253,
    Group1Winner = 7510,
    SuperbikeRaceWinner = 9603,
    SpeedwayMatchResult = 9669,
    Top5 = 10945,
    Top10Finish = 13513,
    CarNumberRaceWinnerOddEven = 15158,
    CarNumberRaceWinnerOverUnder = 15159,
    TopFordTeam = 15160,
    TopChevroletTeam = 15161,
    TopToyotaTeam = 15162,
    TopFordDriver = 15164,
    TopChevroletDriver = 15165,
    TopToyotaDriver = 15166,
    Group2Winner = 15167,
    Group3Winner = 15168,
    DriverHeadToHead2 = 15169,
    DriverHeadToHead3 = 15170,
    DriverHeadToHead4 = 15171,
    DriverHeadToHead5 = 15172,
}

(MotorSportMarketTypeValue as any).__typeName = 'MotorSportMarketTypeValue';