export enum BaseballMarketTypeValue
{
    GameTotalRunsOddEven = 75,
    RunLine = 97,
    MoneyLine = 117,
    RaceTo3Runs = 130,
    DoubleResult = 175,
    TotalRunsOverUnder = 335,
    FirstTeamToScore = 6737,
    FirstInningsResult = 6761,
    First5InningsMoneyLine = 6780,
    TotalRunsAfter5InningsOverUnder = 6795,
    RunLineAfter5Innings = 6798,
    LastTeamToScore = 6812,
    MatchResult = 7754,
    AwayTeamTotalRunsOddEven = 8789,
    HomeTeamTotalRunsOddEven = 8790,
    MoneyLineListedPitcher = 10195,
    First5InningsMoneyLineListedPitchers = 10297,
    First5InningsRunLineListedPitchers = 10298,
    First5InningsTotalRunsOverUnderListedPitchers = 10299,
    FirstInningsResultListedPitchers = 10300,
    DoubleResultListedPitchers = 10301,
    OddOrEvenTotalListedPitchers = 10302,
    RunLineListedPitchers = 10303,
    TotalRunsOverUnderListedPitchers = 10304,
    FirstTeamToScoreListedPitchers = 10305,
    LastTeamToScoreListedPitchers = 10306,
    RaceTo2Runs = 11491,
    RaceTo4Runs = 11492,
    RaceTo5Runs = 11493,
    RaceTo6Runs = 11494,
    RaceTo7Runs = 11495,
    HomeTeamTotalRunsOverUnder = 11496,
    AwayTeamTotalRunsOverUnder = 11498,
    RunLineAfter7Innings = 11504,
    TotalRunsAfter7InningsOverUnder = 11505,
    MoneyLineAfter3Innings = 11880,
    TotalRunsAfter3InningsOverUnder = 12091,
    TeamToWinTheNextInnings = 12495,
    MoneyLineAfter7Innings = 12523,
    RaceTo8Runs = 12524,
    RunLineAfter3Innings = 12525,
    NextInningsHitYesNo = 14426,
    MoneyLineAfter5Innings = 15157,
}

(BaseballMarketTypeValue as any).__typeName = 'BaseballMarketTypeValue';