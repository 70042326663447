import { Component, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { MessageDispatcher } from 'src/app/helpers';
import { ViewBase } from 'src/app/views/view.base';
import { AccountService, TitleService } from 'src/app/services';
import { PriceValuePipe } from 'src/app/pipes';
import { AppState } from 'src/app/app.state';
import { ActionHelper } from 'src/app/helpers/action';
import { TransactionStatus, TransactionType } from 'src/app/enum';

@Component({
  selector: 'payments-overview-view',
  templateUrl: './overview.view.html',
  styleUrls: ['./overview.view.scss', '../../shared.scss'],
  providers: [PriceValuePipe]
})
export class UserPaymentsOverviewViewComponent extends ViewBase {
  public titleFragments = [
    { key: 'Views.User.Payments.Overview.Title.MyPayments' },
    { key: 'Views.User.Payments.Overview.Title.Overview' }
  ];

  public transactionStatus: any;
  public banklink: any;
  @ViewChild('banklinkForm') banklinkForm: ElementRef;

  public selectedProvider: BankLinkProvider;

  public formGroup = new FormGroup({
    amount: new FormControl()
  });

  // public startingBalance = null;
  // public balance = this.startingBalance;
  public lastAddedBalance = null;

  public showBalanceChange = false;

  public balanceAddedTimeout;

  public paymentStatus: string;

  public balanceData: any;

  public hasUnSettledBonuses = false;
  public transactionRequiresAttention = false;
  public transactionIsPending = false;
  public transaction: any;

  public paymentMethodTitle = 'Views.User.Payments.Overview.Title.WithdrawalMethod';
  public paymentRequiresAttentionTitle = 'Views.User.Payments.Overview.Title.WithdrawalPaymentRequiresAttention';
  public paymentRequiresAttentionText = 'Views.User.Payments.Overview.Title.WithdrawalPaymentRequiresAttentionText';

  public backButtonRoute = '/user/payments/withdrawal';

  public get paidIn() {
    return this._route.snapshot.data?.balanceData?.paidIn ?? 0;
  }

  constructor(
    private _location: Location,
    private _state: AppState,
    _titleService: TitleService,
    private _accountService: AccountService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _priceValuePipe: PriceValuePipe,
    private _msg: MessageDispatcher,
    private _action: ActionHelper,
  ) {
    super(_titleService);
    
    const { paymentId, paymentStatus } = this._route.snapshot.params;

    const { transactionData, balanceData } = this._route.snapshot.data || {};
    const { transaction } = transactionData || {};

    this.transaction = transaction;
    this.hasUnSettledBonuses = balanceData?.hasUnSettledBonuses;

    if (transaction) {

      if (transaction.status == TransactionStatus.Complete || transaction.status == TransactionStatus.RequiresAttention) {
        this._state.pushDataLayer({
          'id': transaction.id,
          'event': 'deposit',
          'isFirstTimeDeposit': transaction.isFirstTimeDeposit,
          'amount': transaction.amount,
          'currency': 'EUR'
        });
      }

      this._handleTransaction(transaction);
    }
  }

  private _handleTransaction(transaction) {

    if (transaction.status == TransactionStatus.Complete) {
      this._action.ok({
        message: `AppMessages.Payment.PaymentSuccess`
      });
      this._location.go('/user/payments', undefined, { transactionId: transaction.id });
    } 
    else if (transaction.status == TransactionStatus.RequiresAttention) {
      this.transactionRequiresAttention = true;      
    }
    else if (transaction.status == TransactionStatus.Pending) {
      this.transactionIsPending = true;
    }
    else if(transaction.status == TransactionStatus.Cancelled || transaction.status == TransactionStatus.Rejected) {
      this._action.error({
        message: `AppMessages.Payment.PaymentFailed`
      });
    }

    if(transaction.fromBankAccount?.indexOf(':') >= 0) {
      var accountParts = transaction.fromBankAccount.split(':');
      var provider = accountParts[0];
      var account = accountParts[1];

      transaction.fromBankAccount = account;
      transaction.withdrawalMethod = provider;
      transaction.latestDepositAccount = account;
    }

    if (transaction.fromBankAccountDescription) {
      transaction.withdrawalMethod = transaction.fromBankAccountDescription + ' (' + transaction.withdrawalMethod + ')';
    } else if(transaction.latestDepositAccount) {
      
      if(transaction.latestDepositAccount.startsWith('EE')) {
        var bankCode = transaction.latestDepositAccount.substring(4, 6);
        var bank = '';
        if(bankCode == '42') bank = 'Coop Pank';
        if(bankCode == '16') bank = 'Eesti pank';
        if(bankCode == '10') bank = 'SEB';
        if(bankCode == '22') bank = 'Swedbank';
        if(bankCode == '96') bank = 'Luminor';
        if(bankCode == '17') bank = 'Luminor';
        if(bankCode == '12') bank = 'Citadele';
        if(bankCode == '83') bank = 'Svenska Handelsbanken';
        if(bankCode == '00') bank = 'TBB';
        if(bankCode == '51') bank = 'OP Corporate Bank';
        if(bankCode == '77') bank = 'LHV';
        if(bankCode == '75') bank = 'Bigbank';
        //if(bankCode == '10') bank = 'SEB';

        if(bank)  {
          transaction.withdrawalMethod = bank + ' (' + transaction.withdrawalMethod + ')';
        }
      }
    }

    if(transaction.type == TransactionType.PayIn && transaction.status == TransactionStatus.RequiresAttention) {
      this.paymentMethodTitle = 'Views.User.Payments.Overview.Title.DepositMethod';
      this.paymentRequiresAttentionTitle = 'Views.User.Payments.Overview.Title.DepositPaymentRequiresAttention';
      this.paymentRequiresAttentionText = 'Views.User.Payments.Overview.Title.DepositPaymentRequiresAttentionText';
      this.backButtonRoute = '/user/payments/deposit'
    }  else if (transaction.status == TransactionStatus.Pending) {
      this.transactionIsPending = true;
      this.transactionRequiresAttention = true;
      this.paymentRequiresAttentionTitle = 'Views.User.Payments.Overview.Title.PaymentIsPending';
      this.paymentRequiresAttentionText = 'Views.User.Payments.Overview.Title.PaymentIsPendingText';
    }

  }

  public get balance() {
    return this._state.userBalanceSubject$.value;
  }

  public get userBalance() {
    return this.balance?.balance;
  }

  public get userTotalBalance() {
    return this.balance?.total;
  }

  public get canContinue() {
    return (
      this.selectedProvider !== undefined &&
      this.formGroup.get('amount').value >= 1
    );
  }

  public formatPrice(price: number) {
    return this._priceValuePipe.transform(price);
  }

  public onProviderSelect(provider: BankLinkProvider) {
    this.selectedProvider = provider;
  }

  public deposit(provider: BankLinkProvider = this.selectedProvider) {
    const depositAmount = this.formGroup.value.amount;

    const returnUrl =
      window.location.origin + this._router.createUrlTree(['/user/payments']);

    this._accountService.deposit(depositAmount, provider, returnUrl).result(
      this.formGroup,
      res => {
        this.banklink = res;

        setTimeout(() => {
          this.banklinkForm.nativeElement.submit();
        }, 1);
      },
      err => {
        if (!depositAmount) return;
        this._msg.error({
          message: 'account.error.deposit_failed'
        });
      }
    );
  }
}
