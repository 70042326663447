import { Pipe, PipeTransform } from '@angular/core';
import { toNumber } from '../util/number-util';
import { formatNumber } from '@angular/common';

@Pipe({
    name: 'numericValue',
    pure: true
})
export class NumericValuePipe implements PipeTransform {
    transform(value: any, digits: number = 3, locale = 'et-EE') {
        const digitsInfo = `1.${digits}-${digits}`;
        const number = toNumber(value);
        return formatNumber(number, digitsInfo, locale);
    }
}
