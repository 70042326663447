<toto-dialog [dialogRef]="dialogRef">
  <div header-title class="flex flex-column">
    <h3>{{name | translate}}</h3>
    <div class="bet-type bet-type-{{betTypeEnum[betType]}} mt10">
      <span class="bet-mask-icon mb6 mr6"></span>
      <span class="bet-text">
        {{ betType | translateEnum: betTypeEnum }}
      </span>
    </div>

  </div>

  <div body>
    <p 
      class="mb30" 
      *ngIf="text" 
      [innerHTML]="text | translate">
    </p>

    <!-- width="700"
    height="400" -->
    <div class="responsive-iframe-container">
      <iframe [src]="embedUrl | safe:'resourceUrl'" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>
  </div>
</toto-dialog>