import { Component, Input, Output, Optional, EventEmitter, Host, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, ControlContainer } from '@angular/forms';
import { AppState } from 'src/app/app.state';

@Component({
  selector: "toto-datepicker",
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {

  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public readonly = false;
  @Input() public width = '100%';
  @Input() public min: any;
  @Input() public max: any;
  @Input() public offset = null;
  @Input() public showInput = true;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Output() change = new EventEmitter();
  @Output() clear = new EventEmitter();

  @Input() public get touchUi() {
    return this._appState.isViewMobile;
  };

  constructor(private _appState: AppState, @Optional() @Host() private _controlContainer: ControlContainer) {}

  public ngOnInit(): void {
    if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
      this.formGroup = <UntypedFormGroup>this._controlContainer.control;
    }
  }
  
  public onChange(event): void {
    this.change.emit(event);
  }

  public get hasValue() {
    return this.formGroup.get(this.for)?.value;
  }

  public clearDate() {
    event.stopImmediatePropagation();
    this.formGroup.get(this.for)?.patchValue(null);
    this.onChange(null);
  }

}
