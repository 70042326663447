<form [formGroup]="preferencesGroup" (ngSubmit)="savePreferences()">
  <div class="form-title">
    <h2>{{'Navigator.Menu.Settings.Preferences' | translate}}</h2>
    <h5>
      {{'Navigator.Menu.Settings.Settings' | translate}}
    </h5>
  </div>

  <div class="form-body">
    <form-field label="Views.User.Settings.Preferences.Label.Language" [labelTop]="true">
      <toto-select for="language" [options]="languageOptions" labelField="label" value="value" (change)="onLanguageChange($event)" [translate]="true" fullscreenLabel="Views.User.Settings.Preferences.Label.Language"></toto-select>
      <validation-errors for="language"></validation-errors>
    </form-field>

    <!-- <form-field
      label="Views.User.Settings.Preferences.Label.Timezone"
      [labelTop]="true"
    >
      <toto-select
        for="userTimezone"
        [options]="timezoneOptions"
        labelField="label"
      ></toto-select>
      <validation-errors for="userTimezone"></validation-errors>
    </form-field>

    <form-field
      label="Views.User.Settings.Preferences.Label.PostalCode"
      [labelTop]="true"
    >
      <toto-input for="postalCode"></toto-input>
      <validation-errors for="postalCode"></validation-errors>
    </form-field> -->

    <!-- <form-field label="Views.User.Settings.Preferences.Label.MaxIdleTime" [labelTop]="true">
      <toto-input class="full-width" for="maxIdleTime"></toto-input>
      <validation-errors for="maxidleTime"></validation-errors>
    </form-field> -->

    <div class="form-title">
      <h3>
        {{'Views.User.Settings.Preferences.Title.MarketingSettings' |
        translate}}
      </h3>
    </div>

    <div class="toggle-container mb20">
      <span class="toggle-label">{{'Views.User.Settings.Preferences.Label.ShowNotifications' |
        translate}}</span>
      <toto-slide-toggle for="showMessages"> </toto-slide-toggle>
    </div>

    <div class="toggle-container mb20">
      <span class="toggle-label">{{'Views.User.Settings.Preferences.Label.ReceiveNewsletter' |
        translate}}</span>
      <toto-slide-toggle for="newsletterEnabled"> </toto-slide-toggle>
    </div>

    <div class="toggle-container mb20">
      <span class="toggle-label">{{'Views.User.Settings.Preferences.Label.NewsletterLanguage' |
        translate}}</span>
      <toto-radio [options]="languageOptions" for="newsletterLanguage" value="value" labelField="label">
      </toto-radio>
    </div>

    <div class="toggle-container mb20" *ngIf="isHorseRacing">
      <span class="toggle-label">{{'Views.User.Settings.Preferences.Label.ManualCombinationPrice' |
        translate}}</span>
      <div class="flex flex-row-wrap" [formGroup]="preferencesGroup.controls.betTypesWithManualCombinationPrice">
        <toto-checkbox class="mb5 red-border" *ngFor="let option of betTypeGroupOptions" [for]="getSystemBetTypeKey(option.id)"
          [label]="option.name">
        </toto-checkbox>
      </div>
    </div>
    
  </div>

  <div class="form-footer">
    <toto-button color="theme-1" type="submit" label="Action.Save" [loading]="actionLoading.save"></toto-button>
  </div>
</form>