export function toNumber(value: any): number {
    if (value === null || value === undefined) {
        return value;
    }

    if (typeof value === "number") {
        return value;
    }

    if (typeof value !== "string") {
        // console.log("Cannot parse value: " + value)
        return value;
    }

    return parseFloat(value.trim().replace(/,/g, "."));
}

export function isNumber(value: any): boolean {
    return typeof value === "number" && !isNaN(value);
}

export function round(val, digits) {
    if (!val) {
      return val;
    }

    const mul = Math.pow(10, digits);
    return Math.round(this.toNumber(val) * mul) / mul;
  }