import { SportType } from "src/app/modules/sports/enum/SportType";
import { BasketballMarketTypeValue } from "./BasketballMarketTypeValue";
import { BoxingMarketTypeValue } from "./BoxingMarketTypeValue";
import { FootballMarketTypeValue } from "./FootballMarketTypeValue";
import { GolfMarketTypeValue } from "./GolfMarketTypeValue";
import { HandballMarketTypeValue } from "./HandballMarketTypeValue";
import { IceHockeyMarketTypeValue } from "./IceHockeyMarketTypeValue";
import { TableTennisMarketTypeValue } from "./TableTennisMarketTypeValue";
import { TennisMarketTypeValue } from "./TennisMarketTypeValue";
import { VolleyballMarketTypeValue } from "./VolleyballMarketTypeValue";
import { CyclingMarketTypeValue } from "./CyclingMarketTypeValue";
import { BaseballMarketTypeValue } from "./BaseballMarketTypeValue";
import { CricketMarketTypeValue } from "./CricketMarketTypeValue";
import { MotorSportMarketTypeValue } from "./MotorSportMarketTypeValue";
import { HockeyMarketTypeValue } from "./HockeyMarketTypeValue";
import { DartsMarketTypeValue } from "./DartsMarketTypeValue";
import { AustralianRulesMarketTypeValue } from "./AustralianRulesMarketTypeValue";
import { AmericanFootballMarketTypeValue } from "./AmericanFootballMarketTypeValue";
import { SnookerMarketTypeValue } from "./SnookerMarketTypeValue";
import { BowlsMarketTypeValue } from "./BowlsMarketTypeValue";
import { RugbyLeagueMarketTypeValue } from "./RugbyLeagueMarketTypeValue";
import { RugbyUnionMarketTypeValue } from "./RugbyUnionMarketTypeValue";
import { MartialArtsMarketTypeValue } from "./MartialArtsMarketTypeValue";
import { FutsalMarketTypeValue } from "./FutsalMarketTypeValue";
import { GaelicFootballMarketTypeValue } from "./GaelicFootballMarketTypeValue";
import { HurlingMarketTypeValue } from "./HurlingMarketTypeValue";
import { BeachVolleyballMarketTypeValue } from "./BeachVolleyballMarketTypeValue";
import { ESportsMarketTypeValue } from "./ESportsMarketTypeValue";
import { BadmintonMarketTypeValue } from "./BadmintonMarketTypeValue";
import { PickleballMarketTypeValue } from "./PickleballMarketTypeValue";
import { SquashMarketTypeValue } from "./SquashMarketTypeValue";
import { WaterPoloMarketTypeValue } from "./WaterPoloMarketTypeValue";
import { Basketball3x3MarketTypeValue } from "./Basketball3x3MarketTypeValue";

export const MARKET_TYPE_MAP = {
    [SportType.Football]: FootballMarketTypeValue,
    [SportType.Basketball]: BasketballMarketTypeValue,
    [SportType.Boxing]: BoxingMarketTypeValue,
    [SportType.Golf]: GolfMarketTypeValue,
    [SportType.Handball]: HandballMarketTypeValue,
    [SportType.IceHockey]: IceHockeyMarketTypeValue,
    [SportType.Tennis]: TennisMarketTypeValue,
    [SportType.TableTennis]: TableTennisMarketTypeValue,
    [SportType.Volleyball]: VolleyballMarketTypeValue,
    [SportType.Cycling]: CyclingMarketTypeValue,
    [SportType.Baseball]: BaseballMarketTypeValue,
    [SportType.Cricket]: CricketMarketTypeValue,
    [SportType.MotorSport]: MotorSportMarketTypeValue,
    [SportType.Hockey]: HockeyMarketTypeValue,
    [SportType.Darts]: DartsMarketTypeValue,
    [SportType.AustralianRules]: AustralianRulesMarketTypeValue,
    [SportType.AmericanFootball]: AmericanFootballMarketTypeValue,
    [SportType.Snooker]: SnookerMarketTypeValue,
    [SportType.Bowls]: BowlsMarketTypeValue,
    [SportType.RugbyLeague]: RugbyLeagueMarketTypeValue,
    [SportType.RugbyUnion]: RugbyUnionMarketTypeValue,
    [SportType.MartialArts]: MartialArtsMarketTypeValue,
    [SportType.Futsal]: FutsalMarketTypeValue,
    [SportType.GaelicFootball]: GaelicFootballMarketTypeValue,
    [SportType.Hurling]: HurlingMarketTypeValue,
    [SportType.BeachVolleyball]: BeachVolleyballMarketTypeValue,
    [SportType.eSports]: ESportsMarketTypeValue,
    [SportType.Badminton]: BadmintonMarketTypeValue,
    [SportType.Pickleball]: PickleballMarketTypeValue,
    [SportType.Squash]: SquashMarketTypeValue,
    [SportType.WaterPolo]: WaterPoloMarketTypeValue,
    [SportType.Basketball3x3]: Basketball3x3MarketTypeValue
  };

 export const MARKET_TYPE_NAME_MAP = {
    [SportType.Football]: 'FootballMarketTypeValue',
    [SportType.Basketball]: 'BasketballMarketTypeValue',
    [SportType.Boxing]: 'BoxingMarketTypeValue',
    [SportType.Golf]: 'GolfMarketTypeValue',
    [SportType.Handball]: 'HandballMarketTypeValue',
    [SportType.IceHockey]: 'IceHockeyMarketTypeValue',
    [SportType.Tennis]: 'TennisMarketTypeValue',
    [SportType.TableTennis]: 'TableTennisMarketTypeValue',
    [SportType.Volleyball]: 'VolleyballMarketTypeValue',
    [SportType.Cycling]: 'CyclingMarketTypeValue',
    [SportType.Baseball]: 'BaseballMarketTypeValue',
    [SportType.Cricket]: 'CricketMarketTypeValue',
    [SportType.MotorSport]: 'MotorSportMarketTypeValue',
    [SportType.Hockey]: 'HockeyMarketTypeValue',
    [SportType.Darts]: 'DartsMarketTypeValue',
    [SportType.AustralianRules]: 'AustralianRulesMarketTypeValue',
    [SportType.AmericanFootball]: 'AmericanFootballMarketTypeValue',
    [SportType.Snooker]: 'SnookerMarketTypeValue',
    [SportType.Bowls]: 'BowlsMarketTypeValue',
    [SportType.RugbyLeague]: 'RugbyLeagueMarketTypeValue',
    [SportType.RugbyUnion]: 'RugbyUnionMarketTypeValue',
    [SportType.MartialArts]: 'MartialArtsMarketTypeValue',
    [SportType.Futsal]: 'FutsalMarketTypeValue',
    [SportType.GaelicFootball]: 'GaelicFootballMarketTypeValue',
    [SportType.Hurling]: 'HurlingMarketTypeValue',
    [SportType.BeachVolleyball]: 'BeachVolleyballMarketTypeValue',
    [SportType.eSports]: 'ESportsMarketTypeValue',
    [SportType.Badminton]: 'BadmintonMarketTypeValue',
    [SportType.Pickleball]: 'PickleballMarketTypeValue',
    [SportType.Squash]: 'SquashMarketTypeValue',
    [SportType.WaterPolo]: 'WaterPoloMarketTypeValue',
    [SportType.Basketball3x3]: 'Basketball3x3MarketTypeValue'
  };