<ul class="user-menu">
  <ul class="user-menu">
    <!-- TICKETS -->

    <ng-container [ngSwitch]="activeAppModule">
      <li *ngSwitchCase="TotoAppModule.HorseRacing">
        <a
          class="menu-link"
          routerLink="/{{ moduleRouterLinkRoot }}/tickets"
          routerLinkActive="link-active"
          (click)="onMenuItemClick()"
        >
          <div class="menu-link-start">
            <svg-icon
              key="menuBetting1"
              fontSize="32px"
              class="mr15"
            ></svg-icon>
            <span>{{ "Navigator.Menu.MyBets.MyBets" | translate }}</span>
            <pending-tickets></pending-tickets>
          </div>
          <div class="menu-link-end"></div>
        </a>
      </li>

      <li
        *ngSwitchCase="TotoAppModule.Sports"
        [class.toggled]="showChildren.tickets"
      >
        <a
          class="menu-link"
          routerLinkActive="link-active"
          (click)="toggleChildren('tickets')"
        >
          <div class="menu-link-start">
            <svg-icon
              key="menuBetting1"
              fontSize="32px"
              class="mr15"
            ></svg-icon>
            <span>{{ "Navigator.Menu.MyBets.MyBets" | translate }}</span>
          </div>
          <div class="menu-link-end">
            <svg-icon
              class="sub-menu-toggle-arrow"
              key="menuAccordionArrow"
              fontSize="18px"
            ></svg-icon>
          </div>
        </a>
        <ul class="sub-menu">
          <li
            routerLink="/{{ moduleRouterLinkRoot }}/tickets/active"
            routerLinkActive="link-active"
          >
            <span class="mr5">{{
              "Navigator.Menu.MyBets.ActiveBets" | translate
            }}</span>
            <pending-tickets></pending-tickets>
          </li>
          <!-- <li routerLink="/user/saved-tickets" routerLinkActive="link-active">
            <span>{{'Navigator.Menu.MyBets.SavedBets' | translate}}</span>
          </li> -->
          <li
            routerLink="/{{ moduleRouterLinkRoot }}/tickets/history"
            routerLinkActive="link-active"
          >
            <span>{{ "Navigator.Menu.MyBets.BetHistory" | translate }}</span>
          </li>
        </ul>
      </li>
    </ng-container>

    <li [class.toggled]="showChildren.payments">
      <a
        class="menu-link"
        routerLinkActive="link-active"
        (click)="toggleChildren('payments')"
      >
        <div class="menu-link-start">
          <svg-icon key="menuPayments" fontSize="32px" class="mr15"></svg-icon>
          <span>{{ "Navigator.Menu.MyPayments.MyPayments" | translate }}</span>
        </div>
        <div class="menu-link-end">
          <svg-icon
            class="sub-menu-toggle-arrow"
            key="menuAccordionArrow"
            fontSize="18px"
          ></svg-icon>
        </div>
      </a>
      <ul class="sub-menu">
        <li
          routerLink="/user/payments"
          routerLinkActive="link-active"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span>{{ "Navigator.Menu.MyPayments.Overview" | translate }}</span>
        </li>
        <li routerLink="/user/payments/deposit" routerLinkActive="link-active">
          <span>{{ "Navigator.Menu.MyPayments.Deposit" | translate }}</span>
        </li>
        <li
          routerLink="/user/payments/withdrawal"
          routerLinkActive="link-active"
        >
          <span>{{ "Navigator.Menu.MyPayments.Withdrawal" | translate }}</span>
        </li>
      </ul>
    </li>

    <li [class.toggled]="showChildren.bonuses">
      <a
        class="menu-link"
        routerLinkActive="link-active"
        (click)="toggleChildren('bonuses')"
      >
        <div class="menu-link-start">
          <svg-icon key="menuBonuses" fontSize="32px" class="mr15"></svg-icon>
          <span>{{ "Navigator.Menu.MyBonuses.MyBonuses" | translate }}</span>
        </div>
        <div class="menu-link-end">
          <svg-icon
            class="sub-menu-toggle-arrow"
            key="menuAccordionArrow"
            fontSize="18px"
          ></svg-icon>
        </div>
      </a>
      <ul class="sub-menu">
        <li routerLink="/user/bonus" routerLinkActive="link-active">
          <span>{{ "Navigator.Menu.MyBonuses.Overview" | translate }}</span>
        </li>
        <!-- <li routerLink="/user/campaigns" routerLinkActive="link-active">
          <span>{{ "Navigator.Menu.MyBonuses.Campaigns" | translate }}</span>
        </li> -->
      </ul>
    </li>

    <li>
      <a
        class="menu-link"
        routerLink="/user/transactions"
        routerLinkActive="link-active"
        (click)="onMenuItemClick()"
      >
        <div class="menu-link-start">
          <svg-icon key="menuLog" fontSize="32px" class="mr15"></svg-icon>
          <span>{{
            "Navigator.Menu.TransactionLog.TransactionLog" | translate
          }}</span>
        </div>
        <div class="menu-link-end"></div>
      </a>
    </li>

    <li [class.toggled]="showChildren.responsible">
      <a class="menu-link" (click)="toggleChildren('responsible')">
        <div class="menu-link-start">
          <svg-icon
            key="menuResponsibleBetting"
            fontSize="32px"
            class="mr15"
          ></svg-icon>
          <span>{{
            "Navigator.Menu.ResponsibleGame.ResponsibleGame" | translate
          }}</span>
        </div>
        <div class="menu-link-end">
          <svg-icon
            class="sub-menu-toggle-arrow"
            key="menuAccordionArrow"
            fontSize="18px"
          ></svg-icon>
        </div>
      </a>
      <ul class="sub-menu">
        <!-- <li routerLink="/user/responsible" routerLinkActive="link-active">
        <span>{{'Navigator.Menu.ResponsibleGame.ResponsibleGame' | translate}}</span>
      </li> -->
        <li routerLink="/user/limits" routerLinkActive="link-active">
          <span>{{ "Navigator.Menu.ResponsibleGame.Limits" | translate }}</span>
        </li>
        <li routerLink="/user/restrictions" routerLinkActive="link-active">
          <span>{{
            "Navigator.Menu.ResponsibleGame.SelfRestraint" | translate
          }}</span>
        </li>
        <li routerLink="/user/close-account" routerLinkActive="link-active">
          <span>{{
            "Navigator.Menu.ResponsibleGame.AccountClosure" | translate
          }}</span>
        </li>
        <!-- <li routerLink="/user/statistics" routerLinkActive="link-active">
        <span>{{'Navigator.Menu.ResponsibleGame.GamingStatistics' | translate}}</span>
      </li> -->
      </ul>
    </li>

    <li [class.toggled]="showChildren.settings">
      <a class="menu-link" (click)="toggleChildren('settings')">
        <div class="menu-link-start">
          <svg-icon key="menuSettings" fontSize="32px" class="mr15"></svg-icon>
          <span>{{ "Navigator.Menu.Settings.Settings" | translate }}</span>
        </div>
        <div class="menu-link-end">
          <svg-icon
            class="sub-menu-toggle-arrow"
            key="menuAccordionArrow"
            fontSize="18px"
          ></svg-icon>
        </div>
      </a>
      <ul class="sub-menu">
        <li
          routerLink="/user/settings/contact-details"
          routerLinkActive="link-active"
        >
          <span>{{
            "Navigator.Menu.Settings.ContactDetails" | translate
          }}</span>
        </li>
        <!-- <li routerLink="/user/settings/address" routerLinkActive="link-active">
          <span>{{'Navigator.Menu.Settings.Address' | translate}}</span>
        </li> -->
        <li
          routerLink="/user/settings/preferences"
          routerLinkActive="link-active"
        >
          <span>{{ "Navigator.Menu.Settings.Preferences" | translate }}</span>
        </li>
        <li routerLink="/user/settings/password" routerLinkActive="link-active">
          <span>{{ "Navigator.Menu.Settings.Password" | translate }}</span>
        </li>
        <li
          routerLink="/user/settings/verification"
          routerLinkActive="link-active"
          *ngIf="!state.isUserVerified"
        >
          <span>{{ "Navigator.Menu.Settings.Verification" | translate }}</span>
        </li>
        <!-- 
      <li routerLink="/user/settings/course" routerLinkActive="link-active">
        -----------------
      </li>

      <li routerLink="/user/settings/details-old" routerLinkActive="link-active">
        <span>Andmed</span>
      </li>
      <li routerLink="/user/settings/notifications-old" routerLinkActive="link-active">
        <span>Teavitused</span>
      </li>
      <li routerLink="/user/settings/betting-options-old" routerLinkActive="link-active">
        <span>Panustamise seaded</span>
      </li>
      <li routerLink="/user/settings/password-old" routerLinkActive="link-active">
        <span>Parool</span>
      </li>
      <li routerLink="/user/settings/verification-old" routerLinkActive="link-active"> 
        <span>Konto kinnitamine</span>
      </li> -->
      </ul>
    </li>

    <li *ngIf="showDevTools" [class.toggled]="showChildren.devTools">
      <a class="menu-link" (click)="toggleChildren('devTools')">
        <div class="menu-link-start">
          <svg-icon key="menuSettings" fontSize="32px" class="mr15"></svg-icon>
          <span>Debug</span>
        </div>
        <div class="menu-link-end">
          <svg-icon
            class="sub-menu-toggle-arrow"
            key="menuAccordionArrow"
            fontSize="18px"
          ></svg-icon>
        </div>
      </a>
      <ul class="sub-menu">
        <li (click)="toggleHiddenMarkets()">
          <span>Hidden markets: {{ showHiddenMarkets }}</span>
        </li>
        <li (click)="toggleLogRealtimeUpdates()">
          <span>Log realtime: {{ logRealtimeUpdates }}</span>
        </li>
      </ul>
    </li>

    <li *ngIf="showAdmin">
      <a class="menu-link" href="{{ ADMIN_LINK }}" target="_blank">
        <div class="menu-link-start">
          <svg-icon key="menuSettings" fontSize="32px" class="mr15"></svg-icon>
          <span>
            {{ "Navigator.Menu.AdminPage" | translate }}
          </span>
        </div>
        <div class="menu-link-end"></div>
      </a>
    </li>

    <li *ngIf="showLogout">
      <a class="menu-link" (click)="logOut()">
        <div class="menu-link-start">
          <svg-icon key="menuLogout" fontSize="32px" class="mr15"></svg-icon>
          <span>
            {{ "Navigator.Menu.LogOut" | translate }}
          </span>
        </div>
        <div class="menu-link-end"></div>
      </a>
    </li>
  </ul>
</ul>
