<ng-container [ngSwitch]="transactionRequiresAttention">

  <ng-container *ngSwitchCase="true">
    <div class="alert">
      
      <svg-icon key="moneyOnHold" fontSize="154px"></svg-icon>
      <div class="title">
        {{ paymentRequiresAttentionTitle | translate }}
      </div>
      <div class="text">
        {{ paymentRequiresAttentionText | translate }}
        {{ 'Views.User.Payments.Overview.Title.TranslationLogUpdatesAreIn' | translate }}
        <a [routerLink]="['/user/transactions']">{{ 'Views.User.Payments.Overview.Title.InTransactionLogs' | translate }}</a>
      </div>
      <div>
        <form-field label="{{ paymentMethodTitle | translate }}">
          {{ transaction.withdrawalMethod }}
        </form-field>
        <form-field label="{{ 'Views.User.Payments.Overview.Title.AccountNo' | translate }}">
          {{ transaction.fromBankAccount }}
        </form-field>
        <form-field label="{{ 'Views.User.Payments.Overview.Title.Amount' | translate }}">
          {{ transaction.amount | priceValue }}
        </form-field>
      </div>

      <div class="form-footer">
        <toto-button
          routerLink="{{ this.backButtonRoute }}"
          icon="arrowLeftSmall"
          iconSize="12px"
          label="Action.Back"
        ></toto-button>
        <toto-button
          routerLink="/user/transactions"
          color="theme-1"
          label="Views.User.Payments.Overview.Title.GotoYourTransactions"
        ></toto-button>
      </div>
    </div>


  </ng-container>

  <ng-container *ngSwitchDefault>
    <form [formGroup]="formGroup">
      <div class="form-title">
        <h2>{{'Views.User.Payments.Overview.Title.Overview' | translate}}</h2>
        <h5>{{'Views.User.Payments.Overview.Title.MyPayments' | translate}}</h5>
      </div>
    
      <div class="form-body">      
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="inner-block">
              <h5>
                {{'Views.User.Payments.Overview.Title.AllFunds' | translate}}
                <!-- <button (click)="addBalance(100)">+</button> -->
              </h5>
              <h5
                class="balance-change-amount"
                [class.negative-balance]="lastAddedBalance < 0"
                *ngIf="lastAddedBalance"
                [class.show]="showBalanceChange"
              >
                {{lastAddedBalance < 0 ? '' : '+'}} {{lastAddedBalance |
                priceValue}}
              </h5>
              <div class="flex align-center">
                <!-- <user-balance></user-balance> -->
                <h1
                  class="balance-large"
                  [countUp]="userTotalBalance"
                  [reanimateOnClick]="false"
                  [options]="{ startVal: userTotalBalance - paidIn, suffix: ' €', separator: '', decimalPlaces: 2 }"
                >
                  <!-- [options]="{ formattingFn: formatPrice }" -->
                </h1>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="inner-block">
              <h5>
                {{'Views.User.Payments.Overview.Title.PayoutAmount' | translate}}
              </h5>
              <h5
                class="balance-change-amount"
                [class.negative-balance]="lastAddedBalance < 0"
                *ngIf="lastAddedBalance"
                [class.show]="showBalanceChange"
              >
                {{lastAddedBalance < 0 ? '' : '+'}} {{lastAddedBalance |
                priceValue}} 
              </h5>
              <div class="flex align-center">
                <!-- <user-balance></user-balance> -->
                <h1
                  *ngIf="!hasUnSettledBonuses"
                  class="balance-large"
                  [ngClass]="{'locked': hasUnSettledBonuses}"
                  [countUp]="userBalance"
                  [reanimateOnClick]="false"
                  [options]="{ startVal: userBalance - paidIn, suffix: ' €', separator: '', decimalPlaces: 2 }"
                >
                  <!-- [options]="{ formattingFn: formatPrice }" -->
                </h1>
                <h1
                  *ngIf="hasUnSettledBonuses"
                  class="balance-large">
                    0.00 €
                </h1>
              </div>
    
            <div class="locked-notice" *ngIf="hasUnSettledBonuses">
              <div>
                <svg-icon key="lock" fontSize="14px"></svg-icon>
              </div>
              <div class="lock-text">
                {{ 'Views.User.Payments.Withdrawal.Title.UnSettledBonusText' | translate}}
                <a [routerLink]="['/user/bonus']">{{ 'Views.User.Payments.Overview.Title.ViewBonuses' | translate }}</a>
              </div>
            </div>
            </div>
    
          </div>
        </div>
    
        <!-- <div class="table-card">
          <table>
            <thead>
              <tr>
                <th>
                  {{'Views.User.Payments.Overview.Title.Product' | translate}}
                </th>
                <th>
                  {{'Views.User.Payments.Overview.Title.Payable' | translate}}
                </th>
                <th>
                  {{'Views.User.Payments.Overview.Title.Together' | translate}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{'Navigator.ModuleLinks.HorseRacing' | translate}}</td>
                <td>
                  {{balance | priceValue}}
                </td>
                <td>
                  {{balance | priceValue}}
                </td>
              </tr>
              <tr>
                <td>{{'Navigator.ModuleLinks.SportsBetting' | translate}}</td>
                <td>
                  {{balance | priceValue}}
                </td>
                <td>
                  {{balance | priceValue}}
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    
      <div class="form-footer">
        <toto-button
          routerLink="/user/payments/withdrawal"
          label="Navigator.Menu.MyPayments.Withdrawal"
          *ngIf="!hasUnSettledBonuses"
        ></toto-button>
        <toto-button
          routerLink="/user/payments/deposit"
          color="theme-1"
          label="Navigator.Menu.MyPayments.Deposit"
        ></toto-button>
      </div>
    </form>
  </ng-container>

</ng-container>
