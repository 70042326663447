import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "user-not-authenticated-message",
  templateUrl: "./user-not-authenticated.component.html",
  styleUrls: ['./user-not-authenticated.component.scss']
})
export class UserNotAuthenticatedMessageComponent {

  @Input() public title = null;
  @Input() public text = null;
  
}
