<form [formGroup]="contactDetailsGroup" (ngSubmit)="saveContactInfo()">
  <div class="form-title">
    <h2>
      {{'Navigator.Menu.Settings.ContactDetails' | translate}}
    </h2>
    <h5>
      {{'Navigator.Menu.Settings.Settings' | translate}}
    </h5>
  </div>

  <div class="form-body">

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.ContactDetails.Label.FirstName" [labelTop]="true">
          <span class="user-data">{{contactDetailsGroup.value.firstName || '-'}}</span>
        </form-field>
        <form-field label="Views.User.Settings.ContactDetails.Label.LastName" [labelTop]="true">
          <span class="user-data">{{contactDetailsGroup.value.lastName || '-'}}</span>
        </form-field>
        <form-field label="Views.User.Settings.ContactDetails.Label.UserName" [labelTop]="true">
          <span class="user-data">{{contactDetailsGroup.value.userName || '-'}}</span>
        </form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.ContactDetails.Label.Birthdate" [labelTop]="true">
          <span class="user-data">{{contactDetailsGroup.value.birthDate | totoDate:'LL'}}</span>
        </form-field>
        <form-field label="Views.User.Settings.ContactDetails.Label.IdCode" [labelTop]="true">
          <span class="user-data">{{contactDetailsGroup.value.idCode || '-'}}</span>
        </form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.ContactDetails.Label.Email" [labelTop]="true">
          <toto-input for="email"></toto-input>
          <validation-errors for="email"></validation-errors>
        </form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.ContactDetails.Label.PhoneNumber" [labelTop]="true">
          <toto-input for="phone" mask="+00000000000000" [validation]="false"></toto-input>
          <validation-errors for="phone"></validation-errors>
        </form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.Address.Label.Country" [labelTop]="true">
          <toto-select for="countryId" [options]="countryOptions" labelField="text" value="id"
            [searchable]="true" fullscreenLabel="Views.User.Settings.Address.Label.Country"></toto-select>
          <validation-errors for="countryId"></validation-errors>
        </form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.Address.Label.PostalCode" [labelTop]="true">
          <toto-input for="postalCode"></toto-input>
          <validation-errors for="postalCode"></validation-errors>
        </form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.Address.Label.AddressFirstRow" [labelTop]="true">
          <toto-input for="addressLine1"></toto-input>
          <validation-errors for="addressLine1"></validation-errors>
        </form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <form-field label="Views.User.Settings.Address.Label.AddressSecondRow"
          additionalLabel="Views.User.Settings.Address.Label.IfNecessary" [labelTop]="true">
          <toto-input for="addressLine2"></toto-input>
          <validation-errors for="addressLine2"></validation-errors>
        </form-field>
      </div>
    </div>

  </div>

  <div class="form-footer">
    <toto-button type="submit" label="Action.Save" color="theme-1" [loading]="actionLoading.save"> </toto-button>
  </div>
</form>