export enum BeachVolleyballMarketTypeValue
{
    MatchWinner = 10549,
    MatchSetHandicap = 10550,
    Set1Winner = 10551,
    CorrectMatchScore = 10553,
    TotalPointsOverUnder = 11345,
    Set2Winner = 12565,
    Set3Winner = 12566,
    Set1TotalPointsOverUnder = 12567,
    Set2TotalPointsOverUnder = 12568,
    Set3TotalPointsOverUnder = 12569,
    Set1TotalPointsOddOrEven = 12576,
    Set2TotalPointsOddOrEven = 12577,
    Set3TotalPointsOddOrEven = 12578,
    AwayTeamTotalPointsOverUnder = 12579,
    HomeTeamTotalPointsOverUnder = 12580,
    TotalSetsOverUnder = 12581,
    Set1Handicap = 12582,
    Set2Handicap = 12583,
    Set3Handicap = 12584,
    Set1RaceTo5Points = 12585,
    Set1RaceTo10Points = 12586,
    Set1RaceTo15Points = 12587,
    Set2RaceTo5Points = 12588,
    Set2RaceTo10Points = 12589,
    Set2RaceTo15Points = 12590,
    Set3RaceTo5Points = 12591,
    Set3RaceTo10Points = 12592,
    Set1NumberedPointWinner = 12593,
    Set2NumberedPointWinner = 12594,
    Set3NumberedPointWinner = 12595,
    TotalPointsOddEven = 12888,
    MatchPointsHandicap = 13718,
    Set1CorrectScore = 13785,
    Set2CorrectScore = 13786,
    Set3CorrectScore = 13787,
    HomeTeamTotalPointsOddOrEven = 13929,
    AwayTeamTotalPointsOddOrEven = 13930,
    Set1ExtraPoints = 13931,
    Set2ExtraPoints = 13932,
    Set3ExtraPoints = 13933,
    Set1LeadAfter2Points = 13937,
    Set1LeadAfter4Points = 13938,
    Set1LeadAfter6Points = 13939,
    Set1LeadAfter8Points = 13940,
    Set1LeadAfter10Points = 13941,
    Set1LeadAfter20Points = 13942,
    Set2LeadAfter2Points = 13943,
    Set2LeadAfter4Points = 13944,
    Set2LeadAfter6Points = 13945,
    Set2LeadAfter8Points = 13946,
    Set2LeadAfter10Points = 13947,
    Set2LeadAfter20Points = 13948,
    Set3LeadAfter2Points = 13949,
    Set3LeadAfter4Points = 13950,
    Set3LeadAfter6Points = 13951,
    Set3LeadAfter8Points = 13952,
    Set3LeadAfter10Points = 13953,
}

(BeachVolleyballMarketTypeValue as any).__typeName = 'BeachVolleyballMarketTypeValue';