import { IsActiveMatchOptions } from '@angular/router';
import { AppModule } from '../app.module';
import { TotoAppModule } from '../app.state';
import { NavigatorLanguagesBlockComponent } from '../components/_layout/navigator/components/language-picker/nav-languages.component';
import { LivestreamComponent } from '../components/_layout/navigator/components/livestream/livestream.component';
import { UpcomingBlockComponent } from '../components/_layout/navigator/components/upcoming/upcoming.component';
import { SportsListComponent } from '../modules/sports/components/sports-list/sports-list.component';
import { sportNameFromId } from '../modules/sports/utilities/translate.utility';
import { SportType } from '../modules/sports/enum/SportType';

const defaultIsActiveMatchOptions: IsActiveMatchOptions = {
  matrixParams: 'subset',
  queryParams: 'ignored',
  fragment: 'exact',
  paths: 'subset'
};

const defaultHorseRacingIsActiveMatchOptions: IsActiveMatchOptions = {
  matrixParams: 'subset',
  queryParams: 'ignored',
  fragment: 'exact',
  paths: 'exact'
};

export const MAIN_MENU_LINKS = {
  [TotoAppModule.HorseRacing]: {
    label: 'Navigator.ModuleLinks.HorseRacing',
    routerLink: 'horseracing'
  },
  [TotoAppModule.Sports]: {
    label: 'Navigator.ModuleLinks.SportsBetting',
    routerLink: 'sports'
  }
};

export const MODULE_MENU_LINKS: IModuleMenuLinks = {
  [TotoAppModule.HorseRacing]: [
    {
      label: 'Navigator.Links.HorseBetting',
      routerLink: MAIN_MENU_LINKS[TotoAppModule.HorseRacing].routerLink,
      icon: 'menuHorsebetting',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultHorseRacingIsActiveMatchOptions
    },
    {
      label: 'Navigator.Links.Results',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.HorseRacing].routerLink + '/results',
      icon: 'menuResults2',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultHorseRacingIsActiveMatchOptions
    },
    {
      label: 'Navigator.Links.Instructions',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.HorseRacing].routerLink + '/instructions',
      icon: 'menuInstructions',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultHorseRacingIsActiveMatchOptions
    },
    {
      label: 'Navigator.Links.VideoInstructions',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.HorseRacing].routerLink +
        '/video-instructions',
      icon: 'menuVideoInstructions',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultHorseRacingIsActiveMatchOptions
    },
    {
      label: 'Navigator.Links.Support',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.HorseRacing].routerLink + '/support',
      icon: 'menuFaq',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultHorseRacingIsActiveMatchOptions
    },
    // {
    //   label: 'Navigator.Links.Campaigns',
    //   routerLink:
    //     MAIN_MENU_LINKS[TotoAppModule.HorseRacing].routerLink + '/campaigns',
    //   icon: 'menuCampaigns',
    //   component: null,
    //   isMobile: true,
    //   isDesktop: true,
    //   isDisabled: true,
    //   isActiveMatchOptions: defaultHorseRacingIsActiveMatchOptions
    // },
    {
      label: null,
      routerLink: null,
      icon: null,
      component: LivestreamComponent,
      isMobile: true,
      isDesktop: true,
      isAuthenticated: true
    },
    {
      label: null,
      routerLink: null,
      icon: null,
      component: UpcomingBlockComponent,
      isMobile: true,
      isDesktop: true
    },
    {
      label: null,
      routerLink: null,
      icon: null,
      component: NavigatorLanguagesBlockComponent,
      isMobile: true,
      isDesktop: false
    }
  ],
  [TotoAppModule.Sports]: [
    {
      label: 'Navigator.Links.SportsBetting',
      routerLink: MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/prematch',
      icon: 'menuBetting2',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultIsActiveMatchOptions
    },
    {
      label: 'Navigator.Links.Livebet',
      routerLink: MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/livebet',
      icon: 'menuLiveBet',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultIsActiveMatchOptions
    },
    // {
    //   label: 'Navigator.Links.BetBuilder',
    //   routerLink: MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/builder',
    //   icon: 'menuBetBuilder',
    //   component: null,
    //   isMobile: true,
    //   isDesktop: true,
    //   isDisabled: true,
    //   isActiveMatchOptions: defaultIsActiveMatchOptions
    // },
    // {
    //   label: 'Tulemused',
    //   routerLink: MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/results',
    //   icon: 'menuResults2',
    //   component: null,
    //   isMobile: true,
    //   isDesktop: true,
    //   isDisabled: true,
    //   isActiveMatchOptions: defaultIsActiveMatchOptions
    // },
    {
      label: 'Navigator.Links.Instructions',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/instructions',
      icon: 'menuInstructions',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultIsActiveMatchOptions
    },
    {
      label: 'Navigator.Links.Support',
      routerLink: MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/support',
      icon: 'menuSupport',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultIsActiveMatchOptions
    },
    // {
    //   label: 'Navigator.Links.Campaigns',
    //   routerLink:
    //     MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/campaigns',
    //   icon: 'menuCampaigns',
    //   component: null,
    //   isMobile: true,
    //   isDesktop: true,
    //   isDisabled: true,
    //   isActiveMatchOptions: defaultIsActiveMatchOptions
    // },
    {
      label: 'Navigator.Links.Predictions',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/euro2024',
      icon: 'euro2024Active',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultIsActiveMatchOptions,
      isAuthenticated: false
    },
    {
      label: 'Navigator.Links.MyBets',
      routerLink:
        MAIN_MENU_LINKS[TotoAppModule.Sports].routerLink + '/tickets/active',
      icon: 'menuBetting1',
      component: null,
      isMobile: true,
      isDesktop: true,
      isActiveMatchOptions: defaultIsActiveMatchOptions,
      isAuthenticated: true,
      badgeRef: 'userActiveTickets'
    },
    {
      label: null,
      routerLink: null,
      icon: null,
      component: NavigatorLanguagesBlockComponent,
      isMobile: true,
      isDesktop: false
    },
    {
      label: null,
      routerLink: null,
      icon: null,
      component: SportsListComponent,
      isMobile: true,
      isDesktop: false
    }
  ]
};

export const USER_MENU_LINKS = [
  {
    label: 'Navigator.Menu.MyBets',
    routerLink: '/user/tickets',
    icon: 'menu-bets-icon'
  },
  {
    label: 'Navigator.Menu.MyPayments',
    routerLink: '/user/payments',
    icon: 'menu-payments-icon'
  },
  {
    label: 'Navigator.Menu.TransactionLog',
    routerLink: '/user/transactions',
    icon: 'menu-log-icon'
  },
  {
    label: 'Navigator.Menu.Settings',
    routerLink: '/user/settings',
    icon: 'menu-settings-icon'
  }
];

export const EXTERNAL_LINKS = {
  TOTO_ADMIN: 'https://admin.toto.ee'
};

interface IModuleMenuLinks {
  [TotoAppModule.HorseRacing]: IModuleMenuLink[];
  [TotoAppModule.Sports]: IModuleMenuLink[];
}

interface IModuleMenuLink {
  label?: string;
  routerLink?: string;
  icon?: string;
  component?: any;
  isMobile: boolean;
  isDesktop: boolean;
  isDisabled?: boolean;
  isActiveMatchOptions?: IsActiveMatchOptions;
  isAuthenticated?: boolean;
  badgeRef?: string;
}
