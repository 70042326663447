<div class="pop-message-container">
  <toto-button
    class="close-button"
    size="sm"
    icon="closeWhite"
    color="transparent"
    (click)="dismiss()"
  ></toto-button>

  <span class="message-indicator"></span>

  <div class="message">
    <div class="message-data">
      {{ data.message | translate: data.args }}

      <div *ngIf="data.template" [innerHTML]="data.template"></div>

      <div *ngIf="data.messages">
        <span *ngFor="let message of data.messages">
          {{ message | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
