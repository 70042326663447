export enum BoxingMarketTypeValue
{
    TotalRoundsOverUnder = 39,
    WillTheFightGoTheDistance = 70,
    FightOutright = 201,
    MethodOfVictory = 8306,
    FightResultDrawNoBet = 8857,
    KnockOutInNextRound = 12958,
}

(BoxingMarketTypeValue as any).__typeName = 'BoxingMarketTypeValue';