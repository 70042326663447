import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  UntypedFormGroup,
  ControlContainer,
  FormControl,
  AbstractControl
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SelectComponent } from '../select.component';
import { ResponsiveHelper } from 'src/app/helpers';
import { HttpClient } from '@angular/common/http';
import { AppState } from 'src/app/app.state';
import { SelectBase } from '../select.base';

@Component({
  selector: 'select-fullscreen',
  templateUrl: './select-fullscreen.component.html',
  styleUrls: ['./select-fullscreen.component.scss']
})
export class SelectFullscreenComponent extends SelectBase {

  @Output() change: EventEmitter<any> = new EventEmitter();

  // @Input() public formGroup: UntypedFormGroup;
  // @Input() public id: string;
  // @Input() public for: string;
  // @Input() public label: string;
  // @Input() public labelField: string = 'name';
  // @Input() public compareField: string = 'id';
  // @Input() public value: string;
  // @Input() public translate = false;
  // @Input() public isInline = false;

  // @Input() public options = [];

  public componentRef: any;


  public get isOpen(): boolean {
    return this.isOpenSubject?.value || false;
  }

  constructor(    
    _ar: ApplicationRef,
    private _view: ViewContainerRef,
    private _el: ElementRef,
    public appState: AppState,
    @Inject(DOCUMENT) private document: Document,
    _translateService: TranslateService,
    @Optional() _controlContainer: ControlContainer,
    _http: HttpClient,
  ) {
    super(_translateService);
  }

  public get ref() {
    return this.formGroup.controls[this.for];
  }

  public ngOnInit(): void {
    this.isOpenSubject.subscribe(isOpen => {
      if (isOpen) {
        this.document.body.classList.add('noscroll');
      } else {
        this.document.body.classList.remove('noscroll');
      }
    });
  }

  public get currentValue() {
    if (this.ref?.value) {
      return this.getItemValue(this.ref.value);
    }
    return null;
  }

  public handleOptionClick(option, event): void {
    if (!option) return;

    if (option.function && typeof option.function === 'function') {
      option.function();
    }

    if (this.ref && option) {
      const sanitizedOption = Object.assign({}, option);

      if (option.function) {
        delete sanitizedOption.function;
      }

      if (option.shouldClose) {
        delete sanitizedOption.shouldClose;
      }

      if (this.value && option[this.value]) {
        this.ref.setValue(sanitizedOption[this.value]);
      } else {
        this.ref.setValue(sanitizedOption);
      }

      this.change.emit(sanitizedOption);
    }

    if (option.shouldClose === false) {
      return;
    }

    this.isOpenSubject.next(false);
    this.destroy();
  }

  public isOptionSelected(option) {
    if (!option || !this.ref?.value) return false;
    return this.compareWith(option, this.ref.value);
  }

  public destroy() {}

  public close() {
    this.destroy();
  }

  // public toggleOpen(toSet = !this.isOpen) {
  //   this.isOpenSubject.next(toSet);
  // }
}
