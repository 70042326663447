import {
  Directive,
  EventEmitter,
  Input,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[dynamic-content]'
})
export class DynamicContentComponent implements OnInit {
  @Input() component: any;
  @Input() data: any;
  @Input() template: string;
  @Input() type: any;

  @Input() public change = new EventEmitter<any>();

  private _changeSubscription: Subscription;

  constructor(private viewRef: ViewContainerRef) {}

  private _loadComponent() {
    // this.viewRef.clear();
    const component: any = this.viewRef.createComponent(this.component);
    if (this.data) {
      component.instance.data = this.data;
    }
    if (this.template) {
      component.instance.template = this.template;
    }
    if (this.type) {
      component.instance.type = this.type;
    }
    if (this.type) {
      component.instance.type = this.type;
    }

    if (component.instance.change) {
      this._changeSubscription = component.instance.change.subscribe(val =>
        this.change.emit(val)
      );
    }
  }

  ngOnInit() {
    if (this.component) {
      this._loadComponent();
    }
  }

  ngOnDestroy() {
    if (this._changeSubscription) {
      this._changeSubscription.unsubscribe();
    }
  }
}
