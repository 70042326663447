<div class="balance-container">
    <span class="balance-label mt10">{{'Navigator.Menu.Balance' | translate}}</span>
    <span class="balance mt10">
        <span class="balance-currency">€</span>
        <span class="ml15">
            {{balance}}
        </span>
    </span>
    <toto-button class="mt10" color="green" label="{{'Navigator.Deposit.PayIn' | translate}}" routerLink="/user/payments"></toto-button>
</div>
<user-menu></user-menu>