<form [formGroup]="formGroup" (ngSubmit)="search()">
    <div class="search-action-container" (click)="isOpen ? search() : toggleOpen()">
        <div class="search-action">
            <svg-icon class="search-action search-icon" key="search" fontSize="16px"></svg-icon>
        </div>
        <span class="search-action-label" *ngIf="!compact">
            {{'Navigator.Search.Search' | translate}}
        </span>
    </div>
    <input #termInput formControlName="term" [placeholder]="'Navigator.Search.Placeholder' | translate">
    <div class="search-clear-container">
        <div class="search-action loader" *ngIf="isLoading">
            <loader [absolute]="false"></loader>
        </div>
        <div class="search-action clear" (click)="clear($event)" [class.hidden]="!hasTermValue">
            <svg-icon class="search-icon" key="trash" fontSize="16px"></svg-icon>
        </div>
        <div class="search-action" (click)="toggleOpen(false)">
            <svg-icon class="search-icon" key="close" fontSize="12px"></svg-icon>
        </div>
    </div>
</form>