<div class="providers-wrapper">

  <!-- <swiper #swiperRef class="full-width" direction="horizontal" slidesPerView="auto" [spaceBetween]="20" [navigation]="true" [centeredSlides]="true"
    [watchOverflow]="true" (slideChange)="onSlideChange($event)" [mousewheel]="{ enabled: true, sensitivity: 5}" [touchReleaseOnEdges]="true"> -->
    <!-- <ng-template *ngFor="let provider of shownProviders; let i = index" swiperSlide> -->
    <ng-container *ngFor="let provider of shownProviders; let i = index">
      <div class="provider bank-link" [class.selected]="provider.provider === selectedProvider"
        (click)="selectProvider(provider.provider)">
        <div class="provider-image">
          <img class="{{ provider.title }}" [src]="provider.image" [alt]="provider.title" />
        </div>
        <div class="provider-description">
          {{ provider.title | translate}}
        </div>
        <div class="provider-amount">
          {{ provider.minAmount | priceValue:0 }} - {{ provider.maxAmount | priceValue:0 }}
        </div>
        <input checked="checked" id="SelectedProvider" name="SelectedProvider" type="radio"
          [value]="provider.provider" />
      </div>
    </ng-container>
  <!-- </swiper> -->


</div>