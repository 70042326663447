import { Component, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormControl,
  FormGroup
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { AuthenticationOption } from 'src/app/components/_elements/authentication-options/authentication-options.component';
import { ActionHelper } from 'src/app/helpers/action';
import { AuthenticationService, AccountService } from 'src/app/services';
import { switchMap } from 'rxjs/operators';
import { IIdentity } from 'src/app/models/IIdentity';

@Component({
  selector: 'verification-reminder-dialog',
  host: { class: 'dialog' },
  templateUrl: './verification-reminder.dialog.html',
  styleUrls: ['./verification-reminder.dialog.scss']
})
export class VerificationReminderDialogComponent {

  public actionLoading = {} as any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data,
    private _router: Router,
    public dialogRef: MatDialogRef<VerificationReminderDialogComponent>,
  ) {
    const { data } = this._data || {};
  }

  public continue() {
    this._router.navigate(['/user/settings/verification']);
    this.closeDialog();
  }

  public closeDialog(res?): void {
    this.dialogRef.close(res);
  }

}

