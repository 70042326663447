import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEvents, IPmuRaceResults } from '../models';
import { Observable } from 'rxjs';
import { ToteSystem } from '../enum';


@Injectable({ providedIn: 'root' })
export class ResultsService {

  constructor(private _http: HttpClient) {};

  public getResultsByDate(date: string): Observable<IEvents> {
    if (!date) return;
    return this._http.get<any>(`api/Results/${date}`) as Observable<IEvents>;
  }

  public getRaceResults(system: string, date: string, eventId: string, raceNr: number): Observable<IPmuRaceResults> {
    if (!system || !date || !eventId || !raceNr) return;
    return this._http.get<any>(`api/Results/${system}/${date}/${eventId}/${raceNr}`) as Observable<IPmuRaceResults>;
  }

}
