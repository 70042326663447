<card-message type="error" color="dark-grey">
  <div class="flex" style="flex-wrap: wrap; ">
    <div class="flex flex-column f-1 mr10" style="min-width: max-content">
      <h4 class="mb5">
        {{title | translate}}
      </h4>
      <p class="alt-text">
        {{text | translate}}
      </p>
    </div>
    <div class="flex f-1">
      <toto-button
        style="min-width: 100%;"
        label="Action.ToVerifyAccount"
        icon="arrowRightSmall"
        iconPosition="right"
        color="green"
        routerLink="/user/settings/verification">
      </toto-button>
    </div>
  </div>
</card-message>