export enum ESportsMarketTypeValue
{
    MatchUpHandicap = 13030,
    MatchResult3Way = 13031,
    Map1Winner = 13032,
    Map1TeamToWinTheNextRound = 13033,
    Map1TeamToSlayTheNextRoshan = 13035,
    Map1TeamToDestroyTheNextBarracks = 13036,
    Map1TotalRoshansSlainOverUnder = 13037,
    Map1TotalRoshansSlain = 13038,
    Map1TeamToSlayTheNextDragon = 13069,
    Map1TeamToSlayTheNextBaron = 13070,
    Map1TeamToDestroyTheNextInhibitor = 13071,
    Map1TotalInhibitorsDestroyedOverUnder = 13072,
    Map1TotalDragonsSlainOverUnder = 13073,
    Map1TotalDragonsSlain = 13074,
    Map1TotalBaronsSlainOverUnder2 = 13077,
    Map1TotalBaronsSlain = 13079,
    Map1TotalInhibitorsDestroyed = 13081,
    Map1BothTeamsToSlayADragon = 13082,
    Map1BothTeamsToDestroyAnInhibitor = 13083,
    Map1BothTeamsToSlayABaron = 13084,
    Map1TeamToDrawFirstBlood = 13089,
    Map1TotalKillsScoredOverUnder = 13090,
    Map1TeamToDestroyTheNextTower = 13091,
    Map1TotalTowersDestroyedOverUnder = 13092,
    Map1TotalTowersDestroyed = 13096,
    Map1TeamToScoreTheNextKill2 = 13103,
    Map1TeamToScoreTheMostKills = 13104,
    Map1TeamToScoreTheMostKillsHandicap = 13105,
    MatchResult2Way = 13124,
    Map2Winner = 13126,
    Map3Winner = 13127,
    Map4Winner = 13128,
    Map5Winner = 13130,
    Map2TeamToWinTheNextRound = 13131,
    Map3TeamToWinTheNextRound = 13132,
    Map4TeamToWinTheNextRound = 13133,
    Map5TeamToWinTheNextRound = 13134,
    Map2TeamToSlayTheNextRoshan = 13135,
    Map3TeamToSlayTheNextRoshan = 13136,
    Map4TeamToSlayTheNextRoshan = 13137,
    Map5TeamToSlayTheNextRoshan = 13138,
    Map2TotalRoshansSlainOverUnder = 13139,
    Map3TotalRoshansSlainOverUnder = 13140,
    Map4TotalRoshansSlainOverUnder = 13141,
    Map5TotalRoshansSlainOverUnder = 13142,
    Map2TotalRoshansSlain = 13143,
    Map3TotalRoshansSlain = 13144,
    Map4TotalRoshansSlain = 13145,
    Map5TotalRoshansSlain = 13146,
    Map2TeamToSlayTheNextDragon = 13167,
    Map3TeamToSlayTheNextDragon = 13168,
    Map4TeamToSlayTheNextDragon = 13169,
    Map5TeamToSlayTheNextDragon = 13170,
    Map2TeamToSlayTheNextBaron = 13171,
    Map3TeamToSlayTheNextBaron = 13172,
    Map4TeamToSlayTheNextBaron = 13173,
    Map5TeamToSlayTheNextBaron = 13174,
    Map2TeamToDestroyTheNextInhibitor = 13175,
    Map3TeamToDestroyTheNextInhibitor = 13176,
    Map4TeamToDestroyTheNextInhibitor = 13177,
    Map5TeamToDestroyTheNextInhibitor = 13178,
    Map2TotalInhibitorsDestroyedOverUnder = 13179,
    Map3TotalInhibitorsDestroyedOverUnder = 13180,
    Map4TotalInhibitorsDestroyedOverUnder = 13181,
    Map5TotalInhibitorsDestroyedOverUnder = 13182,
    Map2TotalDragonsSlainOverUnder = 13183,
    Map3TotalDragonsSlainOverUnder = 13184,
    Map4TotalDragonsSlainOverUnder = 13185,
    Map5TotalDragonsSlainOverUnder = 13186,
    Map2TotalDragonsSlain = 13187,
    Map3TotalDragonsSlain = 13188,
    Map4TotalDragonsSlain = 13189,
    Map5TotalDragonsSlain = 13190,
    Map2TotalBaronsSlain = 13191,
    Map3TotalBaronsSlain = 13192,
    Map4TotalBaronsSlain = 13193,
    Map5TotalBaronsSlain = 13194,
    Map2TotalInhibitorsDestroyed = 13195,
    Map3TotalInhibitorsDestroyed = 13196,
    Map4TotalInhibitorsDestroyed = 13197,
    Map5TotalInhibitorsDestroyed = 13198,
    Map2BothTeamsToSlayADragon = 13199,
    Map3BothTeamsToSlayADragon = 13200,
    Map4BothTeamsToSlayADragon = 13201,
    Map5BothTeamsToSlayADragon = 13202,
    Map2BothTeamsToDestroyAnInhibitor = 13203,
    Map3BothTeamsToDestroyAnInhibitor = 13204,
    Map4BothTeamsToDestroyAnInhibitor = 13205,
    Map5BothTeamsToDestroyAnInhibitor = 13206,
    Map2BothTeamsToSlayABaron = 13207,
    Map3BothTeamsToSlayABaron = 13208,
    Map4BothTeamsToSlayABaron = 13209,
    Map5BothTeamsToSlayABaron = 13210,
    Map2TeamToDrawFirstBlood = 13211,
    Map3TeamToDrawFirstBlood = 13212,
    Map4TeamToDrawFirstBlood = 13213,
    Map5TeamToDrawFirstBlood = 13214,
    Map2TotalKillsScoredOverUnder = 13215,
    Map3TotalKillsScoredOverUnder = 13216,
    Map4TotalKillsScoredOverUnder = 13217,
    Map5TotalKillsScoredOverUnder = 13218,
    Map2TeamToDestroyTheNextTower = 13219,
    Map3TeamToDestroyTheNextTower = 13220,
    Map4TeamToDestroyTheNextTower = 13221,
    Map5TeamToDestroyTheNextTower = 13222,
    Map2TotalTowersDestroyedOverUnder = 13223,
    Map3TotalTowersDestroyedOverUnder = 13224,
    Map4TotalTowersDestroyedOverUnder = 13225,
    Map5TotalTowersDestroyedOverUnder = 13226,
    Map2TotalTowersDestroyed = 13227,
    Map3TotalTowersDestroyed = 13228,
    Map4TotalTowersDestroyed = 13229,
    Map5TotalTowersDestroyed = 13230,
    Map2TeamToScoreTheNextKill = 13231,
    Map3TeamToScoreTheNextKill = 13232,
    Map4TeamToScoreTheNextKill = 13233,
    Map5TeamToScoreTheNextKill = 13234,
    Map2TeamToScoreTheMostKills2 = 13235,
    Map3TeamToScoreTheMostKills2 = 13236,
    Map4TeamToScoreTheMostKills2 = 13237,
    Map5TeamToScoreTheMostKills2 = 13238,
    Map1Winner3Way = 13244,
    Map2Winner3Way = 13245,
    Map3Winner3Way = 13246,
    Map4Winner3Way = 13247,
    Map5Winner3Way = 13248,
    Map1RoundsHandicap = 13249,
    Map2RoundsHandicap = 13250,
    Map3RoundsHandicap = 13251,
    Map4RoundsHandicap = 13252,
    Map5RoundsHandicap = 13253,
    Map2TeamToDestroyTheNextBarracks = 13258,
    Map3TeamToDestroyTheNextBarracks = 13259,
    Map4TeamToDestroyTheNextBarracks = 13260,
    Map5TeamToDestroyTheNextBarracks = 13261,
    Map2TeamToScoreTheMostKillsHandicap = 13337,
    Map3TeamToScoreTheMostKillsHandicap = 13338,
    Map4TeamToScoreTheMostKillsHandicap = 13339,
    Map5TeamToScoreTheMostKillsHandicap = 13340,
    Map2TotalBaronsSlainOverUnder2 = 13341,
    Map3TotalBaronsSlainOverUnder2 = 13342,
    Map4TotalBaronsSlainOverUnder2 = 13343,
    Map5TotalBaronsSlainOverUnder2 = 13344,
    TeamToScoreAQuadraKill = 13352,
    TeamToScoreAPentaKill = 13353,
    TeamToScoreARampage = 13354,
    TeamToScoreAnUltraKill = 13355,
    Map1TotalNumberOfRounds = 13565,
    Map2TotalNumberOfRounds = 13566,
    Map3TotalNumberOfRounds = 13567,
    Map4TotalNumberOfRounds = 13568,
    Map5TotalNumberOfRounds = 13569,
    Map1TotalKillsOddEven = 13570,
    Map2TotalKillsOddEven = 13571,
    Map3TotalKillsOddEven = 13572,
    Map4TotalKillsOddEven = 13573,
    Map5TotalKillsOddEven = 13574,
    Map1FirstPistolRoundWinner = 13575,
    Map1SecondPistolRoundWinner = 13576,
    Map2FirstPistolRoundWinner = 13577,
    Map2SecondPistolRoundWinner = 13578,
    Map3FirstPistolRoundWinner = 13579,
    Map3SecondPistolRoundWinner = 13580,
    Map4FirstPistolRoundWinner = 13581,
    Map4SecondPistolRoundWinner = 13582,
    Map5FirstPistolRoundWinner = 13583,
    Map5SecondPistolRoundWinner = 13584,
    BestOf2CorrectScore = 13585,
    BestOf3CorrectScore = 13586,
    BestOf5CorrectScore = 13587,
    TotalMapsPlayedOverUnder = 14346,
    Map1GameTimeOverUnder = 14467,
    Map2GameTimeOverUnder = 14468,
    Map3GameTimeOverUnder = 14469,
    Map4GameTimeOverUnder = 14470,
    Map5GameTimeOverUnder = 14471,
    Map1Overtime = 14472,
    Map2Overtime = 14473,
    Map3Overtime = 14474,
    Map4Overtime = 14475,
    Map5Overtime = 14476,
    Map1RaceTo5Kills = 14487,
    Map2RaceTo5Kills = 14488,
    Map3RaceTo5Kills = 14489,
    Map4RaceTo5Kills = 14490,
    Map5RaceTo5Kills = 14491,
    Map1RaceTo10Kills = 14492,
    Map2RaceTo10Kills = 14493,
    Map3RaceTo10Kills = 14494,
    Map4RaceTo10Kills = 14495,
    Map5RaceTo10Kills = 14496,
    Map5RaceTo10Kills2 = 14497,
    Map2RaceTo15Kills = 14498,
    Map3RaceTo15Kills = 14499,
    Map4RaceTo15Kills = 14500,
    Map5RaceTo15Kills = 14501,
    Map1RaceTo20Kills = 14502,
    Map2RaceTo20Kills = 14503,
    Map3RaceTo20Kills = 14504,
    Map4RaceTo20Kills = 14505,
    Map5RaceTo20Kills = 14506,
    Map1RaceTo3Rounds = 14507,
    Map2RaceTo3Rounds = 14508,
    Map3RaceTo3Rounds = 14509,
    Map4RaceTo3Rounds = 14510,
    Map5RaceTo3Rounds = 14511,
    Map1RaceTo6Rounds = 14512,
    Map2RaceTo6Rounds = 14513,
    Map3RaceTo6Rounds = 14514,
    Map4RaceTo6Rounds = 14515,
    Map5RaceTo6Rounds = 14516,
    Map1RaceTo9Rounds = 14517,
    Map2RaceTo9Rounds = 14518,
    Map3RaceTo9Rounds = 14519,
    Map4RaceTo9Rounds = 14520,
    Map5RaceTo9Rounds = 14521,
    Map1RaceTo12Rounds = 14522,
    Map2RaceTo12Rounds = 14523,
    Map3RaceTo12Rounds = 14524,
    Map4RaceTo12Rounds = 14525,
    Map5RaceTo12Rounds = 14526,
    DrawNoBet = 14697,
    NumberedGameWinner = 15100,
    NumberedGameMatchResult = 15101,
    NumberedGameTotalGoalsOverUnder = 15102,
    NumberedGameTotalPointsOverUnder = 15103,
    NumberedGameAsianHandicap = 15104,
    NumberedGameNextTeamToScore = 15105,
    NumberedGameDrawNoBet = 15106,
    NumberedGameBothTeamsToScore = 15107,
    NumberedGameDoubleChance = 15108,
    NumberedGameHandicap3Way = 15109,
    NumberedGameHomeTeamTotalGoalsOverUnder = 15127,
    NumberedGameAwayTeamTotalGoalsOverUnder = 15128,
    NumberedGameNumberedHalfResult = 15131,
    NumberedGameNumberedHalfTotalGoalsOverUnder = 15132,
    NumberedGameNumberedHalfHandicap2Way = 15133,
    NumberedGameNumberedHalfMoneyLine = 15134,
    NumberedGameNumberedHalfTotalPointsOverUnder = 15135,
    NumberedGameNumberedHalfHandicap3Way = 15137,
    NumberedGameHomeTeamTotalPointsOverUnder = 15139,
    NumberedGameAwayTeamTotalPointsOverUnder = 15140,
}

(ESportsMarketTypeValue as any).__typeName = 'ESportsMarketTypeValue';