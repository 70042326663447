<div class="radio-wrapper" *ngIf="!isLoginView; else loginView">
  <span class="radio-label" *ngIf="label">
    {{label | translate}}
  </span>
  <div class="radio-content">
    <ng-container *ngFor="let item of options">
      <label class="radio-container" [ngSwitch]="type">
        <input type="radio" (click)="select(item)" [value]="item[labelField]" name="radio-{{for}}" [checked]="isChecked(item)" />
        <span class="checkmark" *ngSwitchCase="TotoRadioType.Checkbox"></span>
        <span class="radio-box" *ngSwitchCase="TotoRadioType.Default"></span>
        {{item[labelField] | translate}}
      </label>
    </ng-container>
  </div>
  <div class="trailing-label" *ngIf="trailingLabel">
    {{trailingLabel | translate}}
  </div>
</div>

<ng-template #loginView>
  <div class="radio-wrapper login">
    <span class="radio-label" *ngIf="label">
      {{label | translate}}
    </span>
    <div class="radio-content">
      <ng-container *ngFor="let item of options">
        <label class="radio-container" [ngSwitch]="type">
          <input type="radio" (click)="select(item)" [value]="item[labelField]" name="radio-{{for}}" [checked]="isChecked(item)" />
          <span class="checkmark" *ngSwitchCase="TotoRadioType.Checkbox"></span>
          <span class="radio-box" *ngSwitchCase="TotoRadioType.Default"></span>
          {{item[labelField] | translate}}
        </label>
      </ng-container>
    </div>
    <div class="trailing-label" *ngIf="trailingLabel">
      {{trailingLabel | translate}}
    </div>
  </div>
</ng-template>