export enum TransactionStatus {
    NotSet = 0,
    Pending = 1,
    Complete = 2,
    Rejected = 3,
    Cancelled = 4,
    RequiresAttention = 5,
    Returned = 6
}

(TransactionStatus as any).__typeName = 'TransactionStatus';

const _ = 'Enums.TransactionStatus.';
(TransactionStatus as any).__altTranslationKeys = {
    [TransactionStatus.NotSet]: _ + 'NotSet',
    [TransactionStatus.Pending]: _ + 'Pending',
    [TransactionStatus.Complete]: _ + 'Complete',
    [TransactionStatus.Rejected]: _ + 'Rejected',
    [TransactionStatus.Cancelled]: _ + 'Cancelled',
    [TransactionStatus.RequiresAttention]: _ + 'RequiresAttention',
    [TransactionStatus.Returned]: _ + 'Returned',
};
