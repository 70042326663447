export enum FootballMatchPhase {
  PreMatch = 0,
  FirstHalf = 1,
  HalfTime = 2,
  SecondHalf = 3,
  FullTimeNormalTime = 4,
  ExtraTimeFirstHalf = 5,
  ExtraTimeHalfTime = 6,
  ExtraTimeSecondHalf = 7,
  FullTimeExtraTime = 8,
  Penalties = 9,
  PostMatch = 10,
  MatchAbandoned = 11
}

(FootballMatchPhase as any).__typeName = 'FootballMatchPhase';