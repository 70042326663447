import { Component, Input, OnInit, Optional } from '@angular/core';
import { UntypedFormGroup, ControlContainer, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.scss']
})
export class ValidationErrorsComponent implements OnInit {

  private _formControl: AbstractControl;
  private _errors: [];

  @Input() public for: string;
  @Input() public aliasTo: string;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public errorsFormGroup: UntypedFormGroup;
  @Input() public showErrorText = true;

  @Input() public set errors(val) {
    this._errors = val;
  }


  public get errors() {

    if (this._formControl) {
      return this._formControl.errors;
    }

    const fg = this.errorsFormGroup || this.formGroup;
    return fg.errors ? fg.errors[this.for] : this._errors;
  }

  public constructor(
    @Optional()  private _controlContainer: ControlContainer) {
  }

  public ngOnInit() {

    if (!this.formGroup) {
      if (this._controlContainer && this._controlContainer.control instanceof UntypedFormGroup) {
        this.formGroup = <UntypedFormGroup>this._controlContainer.control;
      }
    }

    if (this.aliasTo) {
      this._formControl = this.formGroup.get(this.aliasTo);
      if (this._formControl) {

        const fg = this.errorsFormGroup || this.formGroup;

        fg.statusChanges.subscribe((x: any) => {
          if (x === 'INVALID') {
            const oldErrors = this._formControl.errors;
            const newErrors = fg.errors ? (fg.errors[this.for] || null) : null;
            if (oldErrors !== newErrors) {
              this._formControl.setErrors(newErrors);
            }
          }
        });
      }
    } else if (this.for) {
      this._formControl = this.formGroup.get(this.for);
    }
  }
}
