
<div class="collapsable-section-container type--{{type}}" [class.sticky]="sticky">
    <div class="collapsable-section-header-container" (click)="toggleSection()" [class.open]="isOpen">
        <div class="collapsable-section-header">
            <ng-content select="[header]"></ng-content>
        </div>
        <div class="collapsable-section-toggle">
            <svg-icon class="arrow" key="accordionArrow" fontSize="18px"></svg-icon>
        </div>
    </div>
    <div class="collapsable-section-content">
        <expander [trigger]="isOpen" [speed]="speed">
            <ng-content></ng-content>
        </expander>
    </div>
</div>
