import { Component, Input, Optional, Output } from '@angular/core';
import { ControlContainer, FormGroup} from '@angular/forms';
import moment from 'moment';

@Component({
  selector: "day-picker",
  templateUrl: './day-picker.component.html',
  styleUrls: ['./day-picker.component.scss']
})
export class DayPickerComponent {

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public readonly = false;

  @Input() public initValue = false;

  public dayOptions = [];

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    this._setDayOptions();

    if (!this.formGroup && this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;

      if (this.initValue) {
        this.formGroup.get(this.for).setValue(this.dayOptions[0].value, { emitEvent: false });
      }
    }
  }

  private _setDayOptions() {
    let today = moment();

    let days = [today];

    for (let i = 1; i < 6; i++) {
      days.push(today.clone().add(i, 'days'));
    }

    this.dayOptions = days.map(x => {
      return {
        value: x.format('YYYY-MM-DD'),
        name: x.format('dddd DD.MM')
      };
    });

  }


}
