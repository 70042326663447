export enum BankLinkProvider {
    Swedbank = 1,
    SEB = 2,
    Sampo = 4,
    CoopPank = 6,
    LHV = 7,
    Luminor = 9,
    Trustly = 10,
    Zimpler = 11,
    ZimplerSwedbank = 12,
    ZimplerSEB = 13,
    ZimplerLHV = 14,
    ZimplerLuminor = 15,
    Neopay = 16,
    NeopayRevolut = 17,
    NeopayN26 = 18,
    NeopayWise = 19,
    NeopayPaysera = 20,
    // FINNISH BANKS ADDED
    ZimplerOPBank = 21,
    ZimplerNordea = 22,
    ZimplerDanskeBank = 23,
    ZimplerAktia = 24,
    ZimplerAlandsBanken = 25,
    ZimplerPOPPankki = 26,
    ZimplerSPankki = 27,
    ZimplerHandelsbanken = 28,
    ZimplerSäästöpankki = 29,
    ZimplerOMASäästöpankki = 30,
    BankTransfer = 9999
}
