import { ApplicationRef, ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { AppState } from '../app.state';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveHelper {

  constructor(private _zone: NgZone, private _state: AppState) {}

  public getLayout(): void {

    this._zone.run(() => {

      const win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName('body')[0],
        w = win.innerWidth || docElem.clientWidth || body.clientWidth,
        breakpoints = {
          sm: 576,
          md: 992,
          lg: 1279,
          xl: 1800
        };


      this._state.isViewMobile = w <= breakpoints.sm;
      this._state.isViewTablet = w <= breakpoints.lg;
      this._state.updateApplicationSubject$.next();
    });
  }
}
