export enum TicketRejectReason
{
    Unknown = 0,
    PriceChange = 1,
    SelectionClosed = 2,
    SelectionSuspended = 3,
    LiabilityLimitBreached = 4,
    UserLimitBreached = 5,
    LimitNotSet = 6,
    MaxStakeBreached = 7
}

(TicketRejectReason as any).__typeName = 'TicketRejectReason';