import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableComponent } from 'src/app/components/_layout/data-table/data-table.component';
import { BonusValueType, UserCampaignStatus } from 'src/app/enum';
import { MessageDispatcher } from 'src/app/helpers';
import { AccountService, TitleService } from 'src/app/services';
import { ViewBase } from 'src/app/views/view.base';
@Component({
  selector: 'user-bonus-overview-view',
  templateUrl: './bonus-overview.view.html',
  styleUrls: ['./bonus-overview.view.scss']
})
export class UserBonusOverviewViewComponent extends ViewBase {

  public titleFragments = [
    { key: 'Views.User.Bonus.Overview.Title.MyBonuses' },
    { key: 'Views.User.Bonus.Overview.Title.Overview' }
  ]

  public loading = {} as any;

  public bonusTotal  = 0;
  public campaigns = [];


  public BonusValueType = BonusValueType;
  public UserCampaignStatus = UserCampaignStatus;

  public bonusGroup: FormGroup;

  constructor(
    _titleService: TitleService,
    private _route: ActivatedRoute,
    private _accountService: AccountService,
    private _msg: MessageDispatcher
  ) {
    super(_titleService);

    const { data } = this._route.snapshot.data || {};

    this.bonusTotal = data.sum;
    this.campaigns = data.campaigns;

    this.bonusGroup = new FormGroup({
      bonusCode: new FormControl(''),
      bonusType: new FormControl('')
    })
  }

  // public maxVal(val: number, maxVal: number) {
  //   return val > maxVal ? maxVal : val;
  // }

  // public activate(campaign: any) {
  //   this._accountService.activateCampaign(campaign.id)
  //     .result(
  //       null, 
  //       res => {
  //         this._msg.ok({ message: 'AppMessages.Account.CampaignActivated' });
  //         campaign.expiresAt = res.expiresAt;
  //         campaign.status = res.status;
  //         campaign.isActivated = true;
  //       }, 
  //       err => {
  //       },
  //       'activate',
  //       this.loading
  //     );
  // }

  public filteredBonuses: any[];

  showAllBonuses(): void {
    
  }
  
  showAvailableBonuses(): void {
    
  }
  
  showActiveBonuses(): void {
    
  }
}
