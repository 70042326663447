import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IBanner, IHighlight } from '../models';
import { AppState } from '../app.state';

@Injectable({ providedIn: 'root' })
export class BannersService {

  constructor(private _http: HttpClient, private _appState: AppState) {}

  public getBanners() {
    return this._http.get<IBanner[]>(`api/banners/${this._appState.language.value}`);
  }

  public getHighlights() {
    return this._http.get<IHighlight[]>(`api/banners/highlights/${this._appState.language.value}`);
  }
}
