export enum CricketMarketTypeValue
{
    TopMatchBowler = 10,
    MostMatchSixes = 179,
    TopMatchBatsman = 270,
    FirstInningsLead = 308,
    HighestOpeningPartnership = 315,
    TeamWithMostRunOuts = 6658,
    MatchWinner3Way = 7240,
    FourDayMatch = 8760,
    MatchWinner2Way = 8884,
    Twenty20Match = 8885,
    TopHomeTeamBatsman = 8952,
    TopAwayTeamBatsman = 8953,
    TopAwayTeamBowler = 8954,
    TopHomeTeamBowler = 8955,
    FiveDayMatchDrawNoBet = 9532,
    FourDayMatchDrawNoBet = 9533,
    Highest1st6Overs = 9650,
    Highest1st15Overs = 9694,
    TotalMatchSixesOverUnder = 9788,
    AwayTeamTotalRunsOddEven = 9883,
    AwayTeamTotalRunsOverUnder = 11385,
    HomeTeamTotalRunsOverUnder = 11386,
    HomeTeamTotalRunsOddEven = 12321,
    HomeTeamTotalRuns2ndInnsOverUnder = 12861,
    AwayTeamTotalRuns2ndInnsOverUnder = 12862,
    AwayTeamTotalRuns2ndInnsOddEven = 12865,
    HomeTeamTotalRuns2ndInnsOddEven = 12866,
    AwayTeam1st6Overs = 12867,
    HomeTeam1st6Overs = 12868,
    HomeTeam1st15Overs = 12869,
    AwayTeam1st15Overs = 12870,
    HomeTeamNextWicketMethod = 12954,
    AwayTeamNextWicketMethod = 12955,
    AwayTeamTotalRuns1stInnsOverUnder = 13529,
    HomeTeamTotalRuns1stInnsOverUnder = 13530,
    NextHomeTeamOverRunsOddOrEven = 14419,
    NextAwayTeamOverRunsOddOrEven = 14420,
    NextOverHomeTeamWicketYesNo = 14421,
    NextOverAwayTeamWicketYesNo = 14422,
    HomeTeam6InNextOverYesNo = 14555,
    AwayTeam6InNextOverYesNo = 14556,
    HomeTeamOver0_5RunsInNextOverYesNo = 14557,
    HomeTeamOver1_5RunsInNextOverYesNo = 14558,
    HomeTeamOver2_5RunsInNextOverYesNo = 14559,
    HomeTeamOver3_5RunsInNextOverYesNo = 14560,
    HomeTeamOver4_5RunsInNextOverYesNo = 14561,
    HomeTeamOver5_5RunsInNextOverYesNo = 14562,
    HomeTeamOver6_5RunsInNextOverYesNo = 14563,
    HomeTeamOver7_5RunsInNextOverYesNo = 14564,
    HomeTeamOver8_5RunsInNextOverYesNo = 14565,
    HomeTeamOver9_5RunsInNextOverYesNo = 14566,
    HomeTeamOver10_5RunsInNextOverYesNo = 14567,
    HomeTeamOver11_5RunsInNextOverYesNo = 14568,
    HomeTeamOver12_5RunsInNextOverYesNo = 14569,
    HomeTeamOver13_5RunsInNextOverYesNo = 14570,
    HomeTeamOver14_5RunsInNextOverYesNo = 14571,
    HomeTeamOver15_5RunsInNextOverYesNo = 14572,
    HomeTeamOver16_5RunsInNextOverYesNo = 14573,
    AwayTeamOver0_5RunsInNextOverYesNo = 14574,
    AwayTeamOver1_5RunsInNextOverYesNo = 14575,
    AwayTeamOver2_5RunsInNextOverYesNo = 14576,
    AwayTeamOver3_5RunsInNextOverYesNo = 14577,
    AwayTeamOver4_5RunsInNextOverYesNo = 14578,
    AwayTeamOver5_5RunsInNextOverYesNo = 14579,
    AwayTeamOver6_5RunsInNextOverYesNo = 14580,
    AwayTeamOver7_5RunsInNextOverYesNo = 14581,
    AwayTeamOver8_5RunsInNextOverYesNo = 14582,
    AwayTeamOver9_5RunsInNextOverYesNo = 14583,
    AwayTeamOver1_05RunsInNextOverYesNo = 14584,
    AwayTeamOver1_15RunsInNextOverYesNo = 14585,
    AwayTeamOver1_25RunsInNextOverYesNo = 14586,
    AwayTeamOver1_35RunsInNextOverYesNo = 14587,
    AwayTeamOver1_45RunsInNextOverYesNo = 14588,
    AwayTeamOver1_55RunsInNextOverYesNo = 14589,
    AwayTeamOver1_65RunsInNextOverYesNo = 14590,
    NextBallHomeTeamBoundaryYesNo = 14630,
    NextBallAwayTeamBoundaryYesNo = 14631,
    NextOverHomeTeamTotalRunsOverUnder = 14632,
    NextOverAwayTeamTotalRunsOverUnder = 14633,
    HomeTeamNextManOut = 14634,
    AwayTeamNextManOut = 14635,
    HomeTeamBatsmanToScore100 = 14637,
    HomeTeamBatsmanToScore50 = 14638,
    AwayTeamBatsmanToScore100 = 14639,
    AwayTeamBatsmanToScore50 = 14640,
    NextOverAwayTeamTotalRunsOverUnder1stInns = 14649,
    NextOverHomeTeamTotalRunsOverUnder1stInns = 14650,
    NextOverAwayTeamTotalRunsOverUnder2ndInns = 14651,
    NextOverHomeTeamTotalRunsOverUnder2ndInns = 14652,
    NextBallHomeTeamBoundaryYesNo1stInns = 14657,
    NextBallAwayTeamBoundaryYesNo1stInns = 14658,
    NextBallAwayTeamBoundaryYesNo2ndInns = 14659,
    NextBallHomeTeamBoundaryYesNo2ndInns = 14660,
    NextBallHomeTeam6YesNo1stInns = 14661,
    NextBallAwayTeam6YesNo1stInns = 14662,
    NextBallHomeTeam6YesNo2ndInns = 14663,
    NextBallAwayTeam6YesNo2ndInns = 14664,
    NextBallHomeTeamWicketYesNo1stInns = 14665,
    NextBallAwayTeamWicketYesNo1stInns = 14666,
    NextBallHomeTeamWicketYesNo2ndInns = 14667,
    NextBallAwayTeamWicketYesNo2ndInns = 14668,
    NextBallHomeTeamRunsOddEven1stInns = 14669,
    NextBallAwayTeamRunsOddEven1stInns = 14670,
    NextBallHomeTeamRunsOddEven2ndInns = 14671,
    NextBallAwayTeamRunsOddEven2ndInns = 14672,
    AwayTeamHit6InNextOverYesNo = 14699,
    AwayTeamMethodOfNextWicket = 14700,
    AwayTeamRunsTotalOddOrEven = 14701,
    HomeTeamHit6InNextOverYesNo = 14702,
    HomeTeamMethodOfNextWicket = 14703,
    HomeTeamRunsTotalOddOrEven = 14704,
    AwayTeamBoundaryInNextOverYesNo = 14705,
    AwayTeamNextOverTotalRunsOddEven = 14706,
    AwayTeamWicketInNextOverYesNo = 14707,
    HomeTeamBoundaryInNextOverYesNo = 14708,
    HomeTeamNextOverTotalRunsOddEven = 14709,
    HomeTeamWicketInNextOverYesNo = 14710,
    AwayTeamTotalRunsFirst6OversOverUnder = 14711,
    AwayTeamTotalRunsFirst15OversOverUnder = 14712,
    HomeTeamTotalRunsFirst6OversOverUnder = 14713,
    HomeTeamTotalRunsFirst15OversOverUnder = 14714,
    HomeTeamFallOfNextWicketTotalRunsOverUnder1stInns = 14715,
    AwayTeamFallOfNextWicketOverUnderTotalRuns1stInns = 14716,
    HomeTeamFallOfNextWicketOverUnderTotalRuns2ndInns = 14717,
    AwayTeamFallOfNextWicketOverUnderTotalRuns2ndInns = 14718,
    HomeTeamBatsmanToScore1001stInns = 14755,
    HomeTeamBatsmanToScore501stInns = 14757,
    HomeTeamBatsmanToScore1002ndInns = 14759,
    HomeTeamBatsmanToScore502ndInns = 14761,
    HomeTeamNextManOut1stInns = 14763,
    HomeTeamNextManOut2ndInns = 14765,
}

(CricketMarketTypeValue as any).__typeName = 'CricketMarketTypeValue';