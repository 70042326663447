<button 
      class="toto-button toto-button--{{color}}"  
      [style]="buttonStyle" 
      [disabled]="disabled || loading" 
      (click)="onButtonClick($event)" 
      [type]="type">

      <ng-content select="content"></ng-content>
      <svg-icon 
            *ngIf="icon && iconPosition === 'left'"
            [fontSize]="iconSize"
            [key]="icon" 
            [class.button-icon-left]="label">
      </svg-icon>

      <span class="button-label" [class.invisible]="loading">
            {{label | translate}}
            <ng-content></ng-content>
      </span>

      <svg-icon 
            *ngIf="icon && iconPosition === 'right'"
            [fontSize]="iconSize"
            [key]="icon" 
            [class.button-icon-right]="label">
      </svg-icon>

      <loader *ngIf="loading"></loader>

</button>