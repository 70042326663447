<toto-dialog [dialogRef]="dialogRef">
  <h3 header-title>
    {{'Views.User.ResponsibleGaming.CloseAccount.SuccessDialog.Title' | translate}}
  </h3>
  <div body>
    <p [innerHTML]="'Views.User.ResponsibleGaming.CloseAccount.SuccessDialog.Text' | translate"
    ></p>
  </div>
  <div footer>
      <toto-button
        color="green"
        label="Action.Continue"
        (click)="continue()"
      ></toto-button>
  </div>
</toto-dialog>
