import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { TitleService } from 'src/app/services';
import { Router } from '@angular/router';
import { SportType } from '../../enum/SportType';
import { sportNameFromId } from '../../utilities/translate.utility';
import { LocalStorage, ResponsiveHelper } from 'src/app/helpers';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-key';
import { SportsLayoutState } from '../../state/layout.state';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { AppState } from 'src/app/app.state';

@Component({
  selector: 'sports-list',
  templateUrl: './sports-list.component.html',
  styleUrls: ['./sports-list.component.scss']
})
export class SportsListComponent {
  
  public SportsSidebarView = SportsSidebarView;
  public SportType = SportType;

  constructor(
    public responsive: ResponsiveHelper, 
    private _appState: AppState,
    public state: SportsLayoutState, 
    private _router: Router, 
    private _cd: ChangeDetectorRef) {}

  public get sportRegions() {
    return this.state.currentViewData?.sport?.regions ?? [];
  }

  public get sportTopCompetitions() {
    return this.state.currentViewData?.sport?.topCompetitions ?? [];
  }

  public get menuItems() {
    return this.state.menu;
  }

  @HostBinding('class.collapsed')
  public get isSidebarCollapsed() {
    return this.state.isSportsSidebarCollapsed;
  }

  public onSportClick(sport) {
    this._router.navigate(['sports/prematch', sportNameFromId(sport.id)], { onSameUrlNavigation: 'reload' });
  }

  public toSportsView() {
    this._router.navigate(['sports/prematch'], { onSameUrlNavigation: 'reload' });
  }

  public getSportTypeRegionId(sportType: SportType, regionId: number) {
    return `${sportType}_${regionId}`;
  }

  public onRegionClick(regionId) {
    this.state.toggleSportRegionExpanded(regionId)
    this._cd.detectChanges();
  }

  public onCompetitionClick(regionId, competition) {
    const sportId = this.state.currentSportId;
    const competitionId = competition.id;
    this._router.navigate(['sports/prematch', sportNameFromId(sportId), regionId, competitionId]);
  }

  public isSportActive(sportId): boolean {
    return this.state.currentSportId == sportId;
  }

  public isCompetitionActive(competitionId): boolean {
    return this.state.currentCompetitionId == competitionId;
  }

  public isRegionActive(regionId): boolean {
    return this.state.currentRegionId == regionId;
  }

  private _cdSub: Subscription;

  // ngOnInit() {

  //   this._cdSub = combineLatest(this.state.viewDataSubject$, this._appState.siteLocaleSubject$).subscribe(val => {
  //     this._cd.detectChanges();
  //   })

  // }

  // ngAfterViewInit() {
  //   this._cd.detach();
  //   this._cd.detectChanges();
  // }

  ngOnDestroy() {
    if (this._cdSub) {
      this._cdSub.unsubscribe();
    }
  }

}

export enum SportsSidebarView {
  SportsList = 1,
  CurrentSport = 2
}
