import { Component, Input } from "@angular/core";
import { AppState, IUserAccountInfoUser } from "src/app/app.state";
import { IIdentity } from "src/app/models/IIdentity";
import { AuthenticationService } from "src/app/services";

@Component({
    selector: 'user-name',
    templateUrl: './user-name.component.html',
    styleUrls: ['./user-name.component.scss']
  })
  export class UserNameComponent {

    @Input() public showLastName = false;
  
    constructor(private _authService: AuthenticationService, private _state: AppState) {}
  
    public get user(): IUserAccountInfoUser {
      return this._state.userAccountInfo;
    }

    public get username() {
      return this.user?.username;
    }

  }
  