import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AppState } from 'src/app/app.state';

@Directive()
export abstract class SelectBase {
  
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() public options: any[] = [];
  @Input() public url: string;
  @Input() public acData: () => any | any;
  @Input() public acLabelFormat: (item: any) => string;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public for: string;
  @Input() public placeholder: string;
  @Input() public searchable = false;
  @Input() public clearable = false;
  @Input() public multiple = false;
  @Input() public maxSelectedItems: number;
  @Input() public compareField: string;
  @Input() public additionalField: string;
  @Input() public value: string;
  @Input() public disabled = false;
  @Input() public hideSelected;
  @Input() public appendTo: string = 'app-root';
  @Input() public translate = false;
  @Input() public sortByLabel = false;
  @Input() public sortByFn: (a: any, b: any) => number;
  @Input() public disableOption: (i: any) => boolean;
  @Input() public addTag:
    | boolean
    | ((term: string) => any | Promise<any>) = false;
  @Input() public addTagText: string;
  @Input() public groupBy: string;
  @Input() public minTermLength: number = 2;
  @Input() public label: string;
  @Input() public fullscreenLabel: string;
  @Input() public labelFn: (i: any) => string;
  @Input() public color = 'default';

  @Input() public additionalValueField = 'odds';
  @Input() public additionalValueClass = 'odds';
  @Input() public labelField: string = 'name';
  @Input() public labelValueClass: string;

  @HostBinding('style.width') @Input() public width = '100%';
  @HostBinding('class.readonly') @Input() public readonly = false;
  @HostBinding('class.inline') @Input() public isInline = false;

  // @HostListener('mousedown', ['$event']) public onClick($event) {
  //   this.onOpen();
  // }

  @Input() public isOpenSubject = new BehaviorSubject(false);

  @Input() public compareWith = (a: any, b: any) => {
    const field = this.value || this.compareField;
    if (field) {
      return a[field] === b[field] || a[field] === b;
    }
    return JSON.stringify(a) === JSON.stringify(b);
  };

  constructor(
    public _translateService: TranslateService
  ) {}

  public getItemValue(item: any) {
    // if (this.disableOption) {
    //   item.disabled = this.disableOption(item);
    // }

    let value = item[this.labelField];

    if (this.labelFn) {
      value = this.labelFn(item);
    }

    if (this.translate) {
      return this._translateService.instant(value);
    }

    return value;
  }

  public getAdditionalValue(item: any): any {
    return item[this.additionalValueField];
  }
}
