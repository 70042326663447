import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { BANK_BIC_MAP, BANK_LINK_PROVIDER_AMOUNTS, BANK_LINK_PROVIDER_OPTIONS, BankLinkProviderType } from 'src/app/components';
import { LOCAL_STORAGE_KEY } from 'src/app/config/local-storage-key';
import { BankLinkProvider } from 'src/app/enum/BankLinkProvider';
import { LocalStorage, MessageDispatcher } from 'src/app/helpers';
import { TotoDialog } from 'src/app/helpers/dialogs';
import { AccountService, TitleService } from 'src/app/services';
import { ViewBase } from 'src/app/views/view.base';

@Component({
  selector: 'payments-deposit-view',
  templateUrl: './deposit.view.html',
  styleUrls: ['./deposit.view.scss', '../../shared.scss']
})
export class UserPaymentsDepositViewComponent extends ViewBase {
  public titleFragments = [
    { key: 'Views.User.Payments.Deposit.Title.MyPayments' },
    { key: 'Views.User.Payments.Deposit.Title.Deposit' }
  ];

  public DepositProvidersViewTab = DepositProvidersViewTab;

  public actionLoading = {} as any;

  public BankLinkProvider = BankLinkProvider;

  public transactionStatus: any;
  public banklink: any;
  @ViewChild('banklinkForm') banklinkForm: ElementRef;


  public formGroup = new FormGroup({
    amount: new FormControl()
  });


  public providerOptions = [];
  public selectedProvider: BankLinkProvider;

  public availableCampaigns = [];
  public showExperimentalPayments: boolean = false;

  constructor(
    public state: AppState,
    _titleService: TitleService,
    private _accountService: AccountService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _msg: MessageDispatcher
  ) {
    super(_titleService);
    this.transactionStatus = this._route.snapshot.children[0]?.data.initialData;

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";    

    eventer(messageEvent,function(e) {
      var key = e.message ? "message" : "data";
      var data = e[key];

      if(data.toString().startsWith('url:')) {
        window.location.href = data.substring(4);
      }

    },false);

    const { data } = this._route.snapshot.data || {};
    this.availableCampaigns = data.campaigns;
    this.showExperimentalPayments = window.__TOTO_DEV_SETTINGS.SHOW_EXPERIMENTAL_PAYMENTS;

    if(window.__TOTO_DEV_SETTINGS.SHOW_EXPERIMENTAL_PAYMENTS) {
      this.providerOptions = [
        BankLinkProvider.ZimplerSwedbank,
        BankLinkProvider.ZimplerLHV,
        BankLinkProvider.ZimplerLuminor,
        BankLinkProvider.ZimplerSEB,
        BankLinkProvider.BankTransfer,
        BankLinkProvider.Trustly,
        BankLinkProvider.Neopay,
        BankLinkProvider.NeopayRevolut,
        BankLinkProvider.NeopayN26,
        BankLinkProvider.NeopayWise,
        BankLinkProvider.NeopayPaysera,
        BankLinkProvider.ZimplerOPBank,
        BankLinkProvider.ZimplerNordea,
        BankLinkProvider.ZimplerDanskeBank,
        BankLinkProvider.ZimplerAktia,
        BankLinkProvider.ZimplerAlandsBanken,
        BankLinkProvider.ZimplerPOPPankki,
        BankLinkProvider.ZimplerSPankki,
        BankLinkProvider.ZimplerHandelsbanken,
        BankLinkProvider.ZimplerSäästöpankki,
        BankLinkProvider.ZimplerOMASäästöpankki
      ];
    } else {
      this.providerOptions = [
        BankLinkProvider.ZimplerSwedbank,
        BankLinkProvider.ZimplerLHV,
        BankLinkProvider.ZimplerLuminor,
        BankLinkProvider.ZimplerSEB,
        BankLinkProvider.BankTransfer,
        BankLinkProvider.Trustly,
        BankLinkProvider.NeopayRevolut,
        BankLinkProvider.NeopayN26,
        BankLinkProvider.NeopayWise,
        BankLinkProvider.NeopayPaysera,
        BankLinkProvider.ZimplerOPBank,
        BankLinkProvider.ZimplerNordea,
        BankLinkProvider.ZimplerDanskeBank,
        BankLinkProvider.ZimplerAktia,
        BankLinkProvider.ZimplerAlandsBanken,
        BankLinkProvider.ZimplerPOPPankki,
        BankLinkProvider.ZimplerSPankki,
        BankLinkProvider.ZimplerHandelsbanken,
        BankLinkProvider.ZimplerSäästöpankki,
        BankLinkProvider.ZimplerOMASäästöpankki
      ];
    }

    if (this.transactionStatus) {
      if (this.transactionStatus.status == 2) {
        this._msg.ok({
          message: 'AppMessages.Deposit.DepositSuccess'
        });
      } else if (this.transactionStatus.status == 4) {
        this._msg.error({
          message: 'AppMessages.Deposit.UserCancelledPayment'
        });
      } else if (this.transactionStatus.status == 3) {
        this._msg.error({
          message: 'AppMessages.Deposit.BankRejectedDeposit'
        });
      } else if (this.transactionStatus.status == 5) {
        this._msg.error({
          message: 'AppMessages.Deposit.PaymentRequiresAttention'
        });
      }

      // this._router.navigate(['/deposit'], { replaceUrl: true });
    }
  }

  public get canContinue() {
    const amount = this.formGroup.get('amount').value;
    const minAmount = BANK_LINK_PROVIDER_AMOUNTS[this.selectedProvider].min;
    const maxAmount = BANK_LINK_PROVIDER_AMOUNTS[this.selectedProvider].max;

    return (
      this.selectedProvider !== undefined &&
      amount >= minAmount && amount <= maxAmount
    );
  }

  public onProviderSelect(provider: BankLinkProvider) {
    this.selectedProvider = provider;
  }

  public depositNeopay(depositAmount, provider, returnUrl) {
    let req = {
      amount: depositAmount,
      bic: BANK_BIC_MAP[provider]
    };

    this._accountService.depositNeopay(req).result(
      this.formGroup,
      res => {
        const neopayLink = res.url;
        if (neopayLink) {
          window.open(neopayLink, '_self');
        }
      },
      err => {
        this._msg.error({
          message: err.validationSummary
        });
      },
      'deposit',
      this.actionLoading
    );
  }

  public depositTrustly(depositAmount, returnUrl) {
    this._accountService.depositTrustly(depositAmount).result(
      this.formGroup,
      res => {

        const trustlyLink = res.url;
        if (trustlyLink) {
          // Redirect on smaller screens
          if (this.state.isViewTablet || this.state.isViewMobile) {
            window.open(trustlyLink, '_self');
          } else {
            var iframe = document.getElementById('trustlyFrame');
            iframe.addEventListener("load", function() {            
                var trustlyOverlay = document.getElementById('trustlyOverlay');
                trustlyOverlay.style.display = '';
            });
            iframe.setAttribute('src', trustlyLink);
          }
     
        }
      },
      err => {
        this._msg.error({
          message: err.validationSummary
        });
      },
      'deposit',
      this.actionLoading
    );
  }

  public depositZimpler(depositAmount, provider: BankLinkProvider, returnUrl) {

    let req = {
      amount: depositAmount,
      bic: BANK_BIC_MAP[provider],
      iframe: !(this.state.isViewTablet || this.state.isViewMobile)
    };

    if(!this.showExperimentalPayments) {
      req.iframe = false;
    }

    this._accountService.depositZimpler(req).result(
      this.formGroup,
      res => {
        const zimplerLink = res.url;
          // if (zimplerLink) {
          //   window.open(zimplerLink, '_self');
          // }
        if(!this.showExperimentalPayments) {
          const zimplerLink = res.url;
          if (zimplerLink) {
            window.open(zimplerLink, '_self');
          }
        }
        else {
          const zimplerLink = res.url;
          if (zimplerLink) {
            // Redirect on smaller screens
            if (this.state.isViewTablet || this.state.isViewMobile) {
              window.open(zimplerLink, '_self');
            } else {
              var iframe = document.getElementById('trustlyFrame');
              iframe.addEventListener("load", function() {            
                  var trustlyOverlay = document.getElementById('trustlyOverlay');
                  trustlyOverlay.style.display = '';
              });
              iframe.setAttribute('src', zimplerLink);
            }     
          }
        }
      },
      err => {
        this._msg.error({
          message: err.validationSummary
        });
      },
      'deposit',
      this.actionLoading
    );
  }

  public depositBankLink(depositAmount, provider, returnUrl) {
    this._accountService.deposit(depositAmount, provider, returnUrl).result(
      this.formGroup,
      res => {
        this.banklink = res;

        setTimeout(() => {
          this.banklinkForm.nativeElement.submit();
        }, 1);
      },
      err => {
        this._msg.error({
          message: 'account.error.deposit_failed'
        });
      }
    );
  }

  public deposit(provider: BankLinkProvider = this.selectedProvider) {
    if (!this.canContinue) {
      return;
    }


    const depositAmount = this.formGroup.value.amount;
    if (!depositAmount) return;

    const returnUrl = window.location.origin + this._router.createUrlTree(['/user/payments']);

    LocalStorage.set(LOCAL_STORAGE_KEY.LAST_USED_DEPOSIT_PAYMENT_METHOD, provider);

    switch (provider) { 
      case BankLinkProvider.Neopay:
      case BankLinkProvider.NeopayRevolut:
      case BankLinkProvider.NeopayN26:
      case BankLinkProvider.NeopayWise:
      case BankLinkProvider.NeopayPaysera:
        return this.depositNeopay(depositAmount, provider, returnUrl);
      case BankLinkProvider.Trustly:
        return this.depositTrustly(depositAmount, returnUrl);
      case BankLinkProvider.ZimplerSwedbank:
      case BankLinkProvider.ZimplerLHV:
      case BankLinkProvider.ZimplerLuminor:
      case BankLinkProvider.ZimplerSEB:
      case BankLinkProvider.ZimplerOPBank:
      case BankLinkProvider.ZimplerNordea:
      case BankLinkProvider.ZimplerDanskeBank:
      case BankLinkProvider.ZimplerAktia:
      case BankLinkProvider.ZimplerAlandsBanken:
      case BankLinkProvider.ZimplerPOPPankki:
      case BankLinkProvider.ZimplerSPankki:
      case BankLinkProvider.ZimplerHandelsbanken:
      case BankLinkProvider.ZimplerSäästöpankki:
      case BankLinkProvider.ZimplerOMASäästöpankki:
        return this.depositZimpler(depositAmount, provider, returnUrl);
      default:
        return this.depositBankLink(depositAmount, provider, returnUrl);
    }
  }


  ngOnInit() {
    const lastSelectedProvider = LocalStorage.getValue(LOCAL_STORAGE_KEY.LAST_USED_DEPOSIT_PAYMENT_METHOD);
    this.selectedProvider = this.providerOptions.find(p => p === lastSelectedProvider) || this.providerOptions[0];
  }

  // TESTING

  // public bankSelection = BANK_LINK_PROVIDER_OPTIONS;
  // public estonianProviderOptionTest = [];


  // public get estonianBanks() {
  //   return this.bankSelection.filter((bank) => bank.providerType === BankLinkProviderType.Estonia);
  // } 

  // public get estBanks() {
  //   for (const provider of this.bankSelection) {
  //     if (provider.providerType === BankLinkProviderType.Estonia) {
  //       this.estonianProviderOptionTest.push(provider);
  //     }
  //     return this.estonianProviderOptionTest;
  //   }
    
  // }

  

  // public get estonianProvidersListTest() {
  //   for (const provider of this.bankSelection) {
  //     if(provider.providerType === providerTy) {
  //       this.estonianProviderOptionTest.push(provider);
  //     }
  //     return this.estonianProviderOptionTest;
  //   }
  // }
  
  public get estonianProvidersList() {
    const estonianProviders = BANK_LINK_PROVIDER_OPTIONS.filter(provider => {
      return provider.providerType === BankLinkProviderType.Estonia;
    });

    return estonianProviders.filter(x => this.providerOptions.includes(x.provider)).map(x => x.provider);
  }

  public get internationalProvidersList() {
    const internationalProviders = BANK_LINK_PROVIDER_OPTIONS.filter(provider => {
      return provider.providerType === BankLinkProviderType.International;
    });
    return internationalProviders.filter(x => this.providerOptions.includes(x.provider)).map(x => x.provider);
  }


  // SWITCHING BETWEEN ESTONIAN AND INTERNATIONAL DEPOSITS
  public isChecked = false;

  public viewTab = DepositProvidersViewTab.Estonian;

  onSelectEstonianDeposit() {
    this.viewTab = DepositProvidersViewTab.Estonian;
  }

  onSelectInternationalDeposit() {
    this.viewTab = DepositProvidersViewTab.International;
  }
}


export enum DepositProvidersViewTab {
  Estonian = 1,
  International = 2,
}