<ng-container [formGroup]="formGroup">
  <div class="select-fullscreen-wrapper__header">
    <span>{{label | translate}}</span>
    <hamburger-toggle [isOpen]="true" (click)="close()">
    </hamburger-toggle>
  </div>
  <div class="select-fullscreen-wrapper__content">
    <ng-container *ngIf="options?.length > 0">
      <div class="select-fullscreen-option" *ngFor="let option of options" (click)="handleOptionClick(option)"
        [class.option-selected]="isOptionSelected(option)">
        <div class="select-fullscreen-option__text">
          {{getItemValue(option) | translate}}
        </div>
        <div>
          <span class="select-fullscreen-option__icon tick-active-icon"></span>
        </div>
      </div>
    </ng-container>
    <ng-content></ng-content>
  </div>
</ng-container>