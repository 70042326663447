import {
  Component,
  OnInit,
  Optional,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostBinding,
  OnChanges,
  SimpleChanges,
  HostListener,
  ElementRef,
  ApplicationRef,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, ControlContainer } from '@angular/forms';
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { AppState } from 'src/app/app.state';
import { SelectFullscreenComponent } from './select-fullscreen/select-fullscreen.component';
import { SelectBase } from './select.base';

@Component({
  selector: 'toto-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent extends SelectBase implements OnInit {
  @ViewChild('select', { static: false }) public select: NgSelectComponent;



  public notFoundTranslated: string;
  public typeToSearchTranslated: string;

  public selected: any;
  public isOpenSubject = new BehaviorSubject(false);
  public fullscreenSelectOpenSubject = new BehaviorSubject(false);
  public hasValue: boolean;

  public acRes: Observable<any>;
  public acSubject = new Subject();

  private _scrollParent: HTMLElement;

  constructor(
    private applicationRef: ApplicationRef,
    private _el: ElementRef,
    private _appState: AppState,
    // private _config: NgSelectConfig,
    private _http: HttpClient,
    public _translateService: TranslateService,
    @Optional() private _controlContainer: ControlContainer
  ) {
    super(_translateService);
    this._onParentScroll = this._onParentScroll.bind(this);
  }

  private _openFullscreenSelect() {
    // Get the root view container ref of the application by injecting it into the root component
    const rootViewContainerRef = this.applicationRef.components[0].injector.get(
      ViewContainerRef
    );
    // Insert the modal component into the root view container
    const componentRef = rootViewContainerRef.createComponent(
      SelectFullscreenComponent
    );

    componentRef.instance.formGroup = this.formGroup;
    componentRef.instance.for = this.for;
    componentRef.instance.isOpenSubject = this.isOpenSubject;
    componentRef.instance.options = this.options;
    componentRef.instance.labelField = this.labelField;
    componentRef.instance.compareField = this.compareField;
    componentRef.instance.value = this.value;
    componentRef.instance.translate = this.translate;
    componentRef.instance.label = this.label ?? this.fullscreenLabel;
    componentRef.instance.labelFn = this.labelFn;
    componentRef.instance.color = this.color;
    componentRef.instance.additionalValueField = this.additionalValueField;
    componentRef.instance.additionalValueClass = this.additionalValueClass;
    componentRef.instance.labelValueClass = this.labelValueClass;
    componentRef.instance.width = this.width;
    componentRef.instance.readonly = this.readonly;
    componentRef.instance.isInline = this.isInline;
    componentRef.instance.change = this.change;
    componentRef.instance.destroy = () => componentRef.destroy();
  }

  public get isOpen(): boolean {
    return this.isOpenSubject.value;
  }

  public get isMobile(): boolean {
    return this._appState.isViewMobile;
  }

  public get isTablet(): boolean {
    return this._appState.isViewTablet;
  }

  public get isFullscreenOpen() {
    return this.fullscreenSelectOpenSubject.value;
  }

  private _translateLabels() {
    this._translateService
      .get('Components.Select.TypeToSearchText', { number: this.minTermLength })
      .subscribe((translated: string) => {
        this.typeToSearchTranslated = translated;
      });
    this._translateService
      .get('Components.Select.NotFoundText')
      .subscribe((translated: string) => {
        this.notFoundTranslated = translated;
      });
  }

  public onChange(value: any): void {
    if (!this.url && !this.multiple) {
      this.select.blur();
    }

    const refValue = this.formGroup.get(this.for).value;
    if (refValue?.function && typeof refValue.function === 'function') {
      refValue.function();
    }

    this.change.emit(value);
  }

  public open() {
    this.select.open();
  }

  public close() {
    this.select.close();
  }

  public toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  public getScrollParent(element, includeHidden) {
    var style = getComputedStyle(element);
    var excludeStaticParent = style.position === 'absolute';
    var overflowRegex = includeHidden
      ? /(auto|scroll|hidden)/
      : /(auto|scroll)/;

    if (style.position === 'fixed') return document.body;
    for (var parent = element; (parent = parent.parentElement); ) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === 'static') {
        continue;
      }
      if (
        overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
      )
        return parent;
    }

    return document.body;
  }

  private _onParentScroll() {
    this.select.close();
  }

  public onOpen() {
    if (this.isTablet || this.isMobile) {
      this._openFullscreenSelect();
      this.fullscreenSelectOpenSubject.next(true);
      this.select?.close();
    } else {
      this.select.open();
      this.isOpenSubject.next(true);
      this._scrollParent = this.getScrollParent(this._el.nativeElement, false);
      this._scrollParent.addEventListener('scroll', this._onParentScroll);
    }
  }

  public onClose() {
    this.isOpenSubject.next(false);
    this.fullscreenSelectOpenSubject.next(false);

    if (this.url) {
      this.options = [];
    }

    if (this._scrollParent) {
      this._scrollParent.removeEventListener('scroll', this._onParentScroll);
    }
  }

  public ngOnInit() {
    this._translateLabels();
    this._translateService.onLangChange.subscribe(() => {
      this._translateLabels();
    });

    if (
      this._controlContainer &&
      this._controlContainer.control instanceof UntypedFormGroup
    ) {
      this.formGroup = <UntypedFormGroup>this._controlContainer.control;

      if (this.for) {
        const ref = this.formGroup.get(this.for);

        this.selected = ref.value;
        this._updateHasValue(this.selected);

        ref.valueChanges.subscribe(x => {
          this._updateHasValue(x);
        });
      }
    }

    const _this = this;
    this.acRes = this.acSubject.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        if (_this.acData) {
          const data =
            typeof _this.acData === 'function' ? _this.acData() : _this.acData;
          return _this._http.post<any[]>(_this.url + term, data);
        } else {
          if (term === null) {
            return null;
          }

          return _this._http.get<any[]>(_this.url + term);
        }
      })
    );

    this.acRes.subscribe(o => {
      if (o === null) {
        return;
      }

      o.subscribe((res: any[]) => {
        if (_this.acLabelFormat) {
          _this.options = res.map(x =>
            Object.assign(x, { [_this.labelField]: _this.acLabelFormat(x) })
          );
        } else {
          _this.options = res;
        }
      });
    });
  }

  public search = (term: string, item: any) => {
    if (this.url) {
      return true;
    }

    if (this.additionalField) {
      return (
        this.getItemValue(item)
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1 ||
        this.getAdditionalValue(item)
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1
      );
    }

    return (
      this.getItemValue(item)
        .toLowerCase()
        .indexOf(term.toLowerCase()) > -1
    );
  };

  public find(search: { term: string; items: any[] }) {
    if (!this.url) {
      return;
    }

    if (search.term?.length < this.minTermLength) {
      this.options = [];
      return;
    }

    this.acSubject.next(search.term);
  }

  private _updateHasValue(x: any) {
    var isArray = o => o instanceof Array;
    this.hasValue =
      x !== undefined &&
      x !== null &&
      ((isArray(x) && x.length > 0) || (!isArray(x) && x !== ''));
  }

  // ngAfterViewInit() {
  //   this._openFullscreenSelect();

  // }
}
