<ng-container [formGroup]="formGroup" [ngSwitch]="readonly">
  <span class="input-label" *ngIf="label">
    <label for="{{for}}">{{label | translate}}</label>
  </span>

  <ng-container *ngSwitchCase="false" [ngSwitch]="type">

    <input *ngSwitchCase="'price'"
      class="toto-input color--{{color}} {{inputClass}}"
      name="{{for}}"
      id="{{id}}"
      (blur)="onBlur.emit($event)"
      [type]="type"
      [formControlName]="for"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [maxlength]="maxlength" 
      [attr.disabled]="disabled ? '' : null"
      [autocomplete]="autocomplete"
      mask="separator.{{pricePrecision}}" 
      prefix="{{currencyPrefix}} " 
      decimalMarker="." 
      [maxlength]="maxLength"
      #input />

    <input *ngSwitchCase="'numeric'"
      class="toto-input color--{{color}} {{inputClass}}"
      name="{{for}}"
      id="{{id}}"
      (blur)="onBlur.emit($event)"
      [type]="type"
      [formControlName]="for"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [maxlength]="maxlength" 
      [attr.disabled]="disabled ? '' : null"
      [autocomplete]="autocomplete"
      mask="separator.{{digits}}"
      decimalMarker="."
      [maxlength]="maxLength"
      #input />

    <input *ngSwitchDefault
      class="toto-input color--{{color}} {{inputClass}}"
      name="{{for}}"
      id="{{id}}"
      (blur)="onBlur.emit($event)"
      [type]="type"
      [formControlName]="for"
      [placeholder]="placeholder ? (placeholder | translate) : ''"
      [maxlength]="maxlength"
      [attr.disabled]="disabled ? '' : null"
      [autocomplete]="autocomplete"
      [validation]="validation"
      [maxlength]="maxLength"
      [mask]="mask"
      [multiple]="multiple" />

  </ng-container>

  <span *ngSwitchCase="true" class="toto-input input-readonly full-width" [ngClass]="inputClass">
    {{inputValue}}
  </span>
  
</ng-container>

<ng-content></ng-content>