import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpMethod } from '../enum';
import { ITableParams } from '../components/_layout/data-table/table-params';
import { PAGE_SIZE } from '../config/pagination';

@Injectable({ providedIn: 'root' })
export class DataTableService<TItem, TData> {
  public constructor(private _http: HttpClient) {}

  public get(dataUrl: string, data: TData, tableParams: ITableParams, method: HttpMethod): Observable<ODataResponse<TItem>> {
    if (!dataUrl) {
      return new Observable<ODataResponse<TItem>>(x => x.complete());
    }

    switch (method) {
      case HttpMethod.Get:
        return this._http.get('api/' + dataUrl, {
          params: this.getHttpParams(data, tableParams)
        }) as Observable<ODataResponse<TItem>>;

      case HttpMethod.Post:
        return this._http.post('api/' + dataUrl, data || {}, {
          params: this.getHttpParams(null, tableParams)
        }) as Observable<ODataResponse<TItem>>;
    }
  }

  protected getHttpParams(data: TData, tableParams: ITableParams) {
    let result = new HttpParams();

    console.log(data);
    if (data) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          result = result.set(key, data[key] ? data[key].toString() : '');
        }
      }
    }

    const skip = tableParams.pageIndex && tableParams.pageSize ? (tableParams.pageIndex * tableParams.pageSize).toString() : null;
    const top = tableParams.pageSize ? tableParams.pageSize.toString() : PAGE_SIZE.DEFAULT.toString();
    const count = tableParams.count ? tableParams.count.toString() : 'true';

    result = this.set(result, '$filter', tableParams.filter);
    result = this.set(result, '$orderBy', tableParams.sort);
    result = this.set(result, '$skip', skip);
    result = this.set(result, '$top', top);
    result = this.set(result, '$count', count);

    return result;
  }

  private set(params: HttpParams, key: string, value: string): HttpParams {
    return value ? params.set(key, value) : params;
  }
}

export class ODataResponse<TItem> {
  public items: TItem[];
  public count: number;
  public searchKey: string;
  public page: number;
  public pageSize: number;
  public sort: string;
  public summary?: any;
}
