import { Component } from '@angular/core';
import { AppState } from 'src/app/app.state';
import { AuthenticationService } from 'src/app/services';

@Component({
  selector: 'not-found',
  host: { 'class': 'layout' },
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']

})
export class NotFoundViewComponent {

  constructor(private _state: AppState, private _authService: AuthenticationService) {}

  public get activeAppModuleRoot() {
    return this._authService.activeAppModuleRoot;
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      this._state.isPageLoaded = true;
    })
  }

}
