export enum AustralianRulesMarketTypeValue
{
    DrawNoBet = 18,
    MatchHandicapIncludingOvertime = 123,
    TotalMatchPointsOverUnder = 6476,
    FirstQuarterResult = 6485,
    HighestScoringQuarter = 6735,
    FirstTeamToScore = 6924,
    WinningMargin = 7268,
    FirstHalfHandicap = 7325,
    HalfTimeResultWithDraw = 7326,
    FirstQuarterHandicap = 7327,
    HalfTimeFullTime = 7379,
    OddOrEvenTotal = 7934,
    HighestScoringHalf = 7935,
    MatchWinner3Way = 8730,
    FirstQuarterFirstGoalScorer = 9023,
    HalfTimeResult = 9060,
    FirstHalfTotalsOverUnder = 9919,
    WinningMarginNoDraw = 10840,
    FirstHalfHomeTeamTotalsOverUnder = 11223,
    FirstHalfAwayTeamTotalsOverUnder = 11224,
    FirstQuarterHomeTeamTotalsOverUnder = 11228,
    FirstQuarterAwayTeamTotalsOverUnder = 11229,
    SecondHalfHandicap = 11230,
    SecondHalfTotalsOverUnder = 11233,
    FourthQuarterHandicap = 11247,
    ThirdQuarterHandicap = 11248,
    FirstQuarterTotalsOverUnder = 11252,
    SecondQuarterHandicap = 11279,
    SecondQuarterTotalsOverUnder = 11288,
    ThirdQuarterTotalsOverUnder = 11289,
    FourthQuarterTotalsOverUnder = 11290,
    SecondQuarterAwayTeamTotalsOverUnder = 11300,
    ThirdQuarterAwayTeamTotalsOverUnder = 11301,
    FourthQuarterAwayTeamTotalsOverUnder = 11302,
    SecondHalfAwayTeamTotalsOverUnder = 11303,
    SecondHalfHomeTeamTotalsOverUnder = 11313,
    SecondQuarterHomeTeamTotalsOverUnder = 11314,
    ThirdQuarterHomeTeamTotalsOverUnder = 11315,
    FourthQuarterHomeTeamTotalsOverUnder = 11316,
    SecondQuarterFirstGoalScorer = 11360,
    ThirdQuarterFirstGoalScorer = 11361,
    FourthQuarterFirstGoalScorer = 11362,
    FirstQuarterLastGoalScorer = 11364,
    SecondQuarterLastGoalScorer = 11365,
    ThirdQuarterLastGoalScorer = 11366,
    SecondQuarterResult = 11368,
    FirstHalfTotalsOddEven = 11464,
    FirstQuarterTotalsOddEven = 11465,
    ToHave30DisposalsOrMore = 11849,
    SecondQuarterTotalsOddEven = 11885,
    ThirdQuarterTotalsOddEven = 11886,
    FourthQuarterTotalsOddEven = 11887,
    ToHave25DisposalsOrMore = 11918,
    SecondHalfTotalsOddEven = 11959,
    TopMatchGoalscorer = 12059,
    FourthQuarterResult = 12310,
    ThirdQuarterResult = 12312,
    NextTeamToScore = 12859,
    FourthQuarterLastGoalScorer = 14389,
    MatchWinner2Way = 14418,
}

(AustralianRulesMarketTypeValue as any).__typeName = 'AustralianRulesMarketTypeValue';