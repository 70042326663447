<form [formGroup]="formGroup">
  <div class="form-title">
    <h2>{{'Views.User.Payments.Withdrawal.Title.Withdrawal' | translate}}</h2>
    <h5>{{'Views.User.Payments.Withdrawal.Title.MyPayments' | translate}}</h5>
  </div>

  <div class="form-body" [ngSwitch]="state.isUserVerified">

    <ng-container *ngSwitchCase="true">

      <ng-container [ngSwitch]="withdrawalInfo?.hasUnSettledBonuses">
      
        <ng-container *ngSwitchCase="true">
        
          <div class="alert">
            <svg-icon key="moneyOnHold" fontSize="154px"></svg-icon>
            <div class="title">
              {{ 'Views.User.Payments.Withdrawal.Title.YouHaveUnSettledBonuses' | translate}}
            </div>
            <div class="text">
              {{ 'Views.User.Payments.Withdrawal.Title.UnSettledBonusText' | translate}}
            </div>
            <br>
            <div class="text">
              {{ 'Views.User.Payments.Withdrawal.Title.ReadMore' | translate}}
                <a [routerLink]="['/sports/instructions']" [queryParams]="{ sectionId: 163 }">{{ 'Views.User.Bonus.Overview.Bonuses.TermsAndConditions' | translate }} <svg-icon key="arrowRightSmall" fontSize="9px"></svg-icon></a>
            </div>
          </div>

        </ng-container>

        <ng-container *ngSwitchDefault>

          <section>
            <h4>
              {{'Views.User.Payments.Withdrawal.Title.ChooseAmount' | translate}}
            </h4>
    
            <div class="withdrawal-info-field" *ngIf="withdrawalInfo?.withdrawalMethod">
              <div class="withdrawal-info-label">
                {{'Views.User.Payments.Withdrawal.Label.WithdrawalMethod' | translate}}
              </div>
              <div class="withdrawal-info-amount">
                {{withdrawalInfo?.withdrawalMethod}}
              </div>
            </div>
    
            <div class="withdrawal-info-field" *ngIf="withdrawalInfo?.latestDepositAccount">
              <div class="withdrawal-info-label">
                {{'Views.User.Payments.Withdrawal.Label.WithdrawalAccount' | translate}}
              </div>
              <div class="withdrawal-info-amount">
                {{withdrawalInfo?.latestDepositAccount || '-'}}
              </div>
            </div>              
    
            <div class="withdrawal-info-field">
              <div class="withdrawal-info-label">
                {{'Views.User.Payments.Withdrawal.Label.WithdrawalBalance' | translate}}
              </div>
              <div class="withdrawal-info-amount">
                {{withdrawalInfo?.withdrawalBalance | priceValue}}
              </div>
            </div>
    
            <!-- <form-field label="Väljamakstav summa">
              {{withdrawalInfo?.withdrawalBalance | priceValue}}
    
            </form-field> -->
    
            <payment-amount for="amount"></payment-amount>
          </section>

          <div class="form-footer">
            <toto-button
              size="xl"
              label="Action.Continue"
              color="theme-1"
              (click)="withdraw()"
              [loading]="actionLoading.withdraw"
              [disabled]="!canContinue">
            </toto-button>
            <div class="min-amount-disclaimer" *ngIf="showMinAmountDisclaimer">
              {{'Views.User.Payments.Withdrawal.UserNotEnoughResources' | translate}}
            </div>
          </div>

        </ng-container>
      
      </ng-container>

    </ng-container>

    <ng-container *ngSwitchCase="false">
      <user-not-authenticated-message title="Views.User.Payments.Withdrawal.AccountNotVerified.Title" text="Views.User.Payments.Withdrawal.AccountNotVerified.Text"></user-not-authenticated-message>
    </ng-container>

  </div>
</form>
