import { Directive, Input } from '@angular/core';
import { ITitleFragment, TitleService } from 'src/app/services';

@Directive()
export class ViewBase {
  
  @Input()
  protected titleKey: string;

  @Input()
  protected titleFragments: ITitleFragment[];

  constructor(
    public _titleService: TitleService,
  ) {}

  public setTitleFragments(fragments: ITitleFragment[] = this.titleFragments) { 
    this._titleService.setTitleFragments(fragments);
  }
 
  public ngOnInit() {

    if (this.titleKey) {
      setTimeout(_ => {
        this._titleService.setTitle(this.titleKey);
      })
    }

    if (this.titleFragments) {
      setTimeout(_ => {
        this.setTitleFragments(this.titleFragments);
      })
    }
    // this._titleService.setTitle(this.titleKey, this.titleKeyParams);
  }

}

