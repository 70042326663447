import { Component, OnDestroy, HostBinding, Input  } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "./loader.component.html",
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @HostBinding('class.show') @Input() public isLoading = true;
  @HostBinding('class.absolute') @Input() public absolute = true;

  constructor() {}

}
