export enum PmuBetType {
    GAG = 1,
    PLA = 3,
    _2S4 = 8,
    TRC = 10,
    COG = 13,
    COP = 14,
    QAP = 17,
    MTI = 29,
    CLT = 30,
    COO = 32,
    TRO = 33,
    GAGI = 34,
    QIP = 36,
    TOO = 45,
    COI = 53,
    TOI = 54,
    PLAI = 114,
    ESG = 117,
    ESP = 118,
    ECG = 126,
    ECO = 128,
    ETR = 133,
    ETO = 138,
    QIT = 153,
    MMI = 162,
    ECP = 180
}

(PmuBetType as any).__typeName = 'PmuBetType';

const _ = 'Enums.PmuBetType.';
(PmuBetType as any).__altTranslationKeys = {
    [PmuBetType.GAG]: _ + 'GAG',
    [PmuBetType.PLA]: _ + 'PLA',
    [PmuBetType._2S4]: _ + '_2S4',
    [PmuBetType.TRC]: _ + 'TRC',
    [PmuBetType.COG]: _ + 'COG',
    [PmuBetType.COP]: _ + 'COP',
    [PmuBetType.QAP]: _ + 'QAP',
    [PmuBetType.MTI]: _ + 'MTI',
    [PmuBetType.CLT]: _ + 'CLT',
    [PmuBetType.COO]: _ + 'COO',
    [PmuBetType.TRO]: _ + 'TRO',
    [PmuBetType.GAGI]: _ + 'GAGI',
    [PmuBetType.QIP]: _ + 'QIP',
    [PmuBetType.TOO]: _ + 'TOO',
    [PmuBetType.COI]: _ + 'COI',
    [PmuBetType.TOI]: _ + 'TOI',
    [PmuBetType.PLAI]: _ + 'PLAI',
    [PmuBetType.ESG]: _ + 'ESG',
    [PmuBetType.ESP]: _ + 'ESP',
    [PmuBetType.ECG]: _ + 'ECG',
    [PmuBetType.ECO]: _ + 'ECO',
    [PmuBetType.ETR]: _ + 'ETR',
    [PmuBetType.ETO]: _ + 'ETO',
    [PmuBetType.QIT]: _ + 'QIT',
    [PmuBetType.MMI]: _ + 'MMI',
    [PmuBetType.ECP]: _ + 'ECP',
};
