<div class="no-content-wrapper size--{{ size }}">

  <svg-icon
    class="no-content-icon"
    [key]="icon"
    [fontSize]="iconSize"
  ></svg-icon>
  
  <span class="no-content-text">
    {{ text | translate }}
  </span>

  <span class="no-content-description-text" *ngIf="description">
    {{ description | translate }}
  </span>

  <div class="btn-wrapper mt40" *ngIf="showBackButton && hasReferrer">
    <toto-button
      label="Action.Back"
      icon="arrowLeftSmall"
      iconSize="12px"
      (click)="onBackClick()"
    ></toto-button>
  </div>
</div>
