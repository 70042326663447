<form>

  <div class="form-title">
    <h2>{{'Transaction success' | translate}}</h2>
    <h5>{{'' | translate}}</h5>
  </div>

  <div class="form-body">
    {{transaction | json}}
  </div>

  <div class="form-footer">
    
  </div>
</form>