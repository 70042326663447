<div class="layout-wrapper">
  
  <div class="message-container">
    <span class="error-404">404</span>
    <span class="not-found-text mt5">
      {{ 'Views.PageNotFound.Text1' | translate }}
    </span>
    <span class="not-found-text-alt mt20">
      {{'Views.PageNotFound.Text1' | translate}} <a class="link" [routerLink]="'/' + activeAppModuleRoot">{{'Views.PageNotFound.ToLandingPage' | translate}}</a> 
    </span>

  </div>

</div>