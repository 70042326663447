export enum BasketballMarketTypeValue
{
    FirstHalfResult = 5,
    MoneyLine = 115,
    PointSpread = 147,
    Handicap = 193,
    TotalPointsOverUnder = 286,
    MatchResultExcludingOvertime = 306,
    FirstHalfMoneyLine = 331,
    FirstQuarterMoneyLine = 6598,
    FirstHalfPointSpread = 6602,
    FirstQuarterPointSpread = 6603,
    FirstHalfOddOrEvenTotalPoints = 6606,
    FirstHalfTotalPointsOverUnder = 6698,
    FirstQuarterTotalPointsOverUnder = 6736,
    FirstTeamToScore = 6739,
    SecondQuarterPointSpread = 6848,
    ThirdQuarterPointSpread = 6849,
    FourthQuarterPointSpread = 6850,
    SecondQuarterTotalPoints = 6851,
    ThirdQuarterTotalPointsOverUnder = 6852,
    FourthQuarterTotalPointsOverUnder = 6853,
    HighestScoringQuarter = 6854,
    FirstHalfHandicap = 6859,
    FirstQuarterHandicap = 6949,
    FirstQuarterResult = 6950,
    ThreeWayThirdQuarterHandicap = 6955,
    ThreeWayFourthQuarterHandicap = 6956,
    HalfTimeFullTime = 6957,
    SecondQuarterMoneyLine = 6982,
    ThirdQuarterMoneyLine = 6983,
    FourthQuarterMoneyLine = 6984,
    OddOrEvenTotalPoints = 7351,
    HighestScoringHalf = 7393,
    FirstQuarterOddOrEvenTotalPoints = 7411,
    SecondHalfPointSpread = 7416,
    SecondHalfTotalPointsOverUnder = 7417,
    DoubleChance = 7479,
    HomeTeamTotalPoints = 8421,
    AwayTeamTotalPointsOverUnderIncludingOvertime = 8422,
    LastTeamToScoreIncludingOvertime = 8471,
    SecondHalfRaceTo10PointsIncludingOT = 8555,
    SecondHalfOddOrEvenTotalPoints = 8577,
    SecondHalfRaceTo20PointsIncludingOT = 8702,
    SecondHalfMoneyLine = 8743,
    ThreeWaySecondQuarterHandicap = 9179,
    WillThereBeOvertime = 9470,
    SecondQuarterOddOrEvenTotalPoints = 9700,
    ThirdQuarterOddOrEvenTotalPoints = 9701,
    FourthQuarterOddOrEvenTotalPoints = 9702,
    SecondQuarterResult = 9766,
    ThirdQuarterResult = 9767,
    FourthQuarterResultExcludingOvertime = 9768,
    SecondHalfResultExcludingOvertime = 9769,
    FirstHalfRaceTo20Points = 9932,
    FirstQuarterDoubleChance = 10134,
    FirstHalfDoubleChance = 10135,
    DoubleResult = 10203,
    MoneyLineRegularTime = 10471,
    PointSpreadExcludingOT = 10472,
    OddOrEvenTotalPointsExcludingOT = 10474,
    SecondQuarterRaceTo10Points = 10505,
    SecondHalfRaceTo15PointsIncludingOT = 10506,
    FourthQuarterRaceTo10Points = 10507,
    FirstQuarterRaceTo10Points = 10545,
    FirstQuarterRaceTo15Points = 10963,
    FirstHalfRaceTo5Points = 11262,
    FirstHalfRaceTo10Points = 11263,
    FirstQuarterRaceTo5Points = 11264,
    FirstQuarterRaceTo20Points = 11379,
    SecondQuarterRaceTo5Points = 11748,
    SecondQuarterRaceTo15Points = 11749,
    SecondQuarterRaceTo20Points = 11750,
    ThirdQuarterRaceTo5Points = 11751,
    ThirdQuarterRaceTo10Points = 11752,
    ThirdQuarterRaceTo15Points = 11753,
    ThirdQuarterRaceTo20Points = 11754,
    FourthQuarterRaceTo5Points = 11755,
    FourthQuarterRaceTo15Points = 11756,
    FourthQuarterRaceTo20Points = 11757,
    ThreeWaySecondHalfHandicap = 11811,
    SecondQuarterDoubleChance = 11812,
    ThirdQuarterDoubleChance = 11813,
    SecondHalfDoubleChance = 11814,
    FourthQuarterDoubleChance = 12100,
    FirstQuarterRaceTo5Points2Way = 13796,
    FirstQuarterRaceTo15Points2Way = 13797,
    FirstQuarterRaceTo10Points2Way = 13798,
    FirstQuarterRaceTo20Points2Way = 13799,
    SecondQuarterRaceTo5Points2Way = 13803,
    SecondQuarterRaceTo15Points2Way = 13804,
    SecondQuarterRaceTo10Points2Way = 13805,
    SecondQuarterRaceTo20Points2Way = 13806,
    FirstHalfRaceTo15Points2Way = 13821,
    ThirdQuarterRaceTo10Points2Way = 13826,
    ThirdQuarterRaceTo15Points2Way = 13827,
    ThirdQuarterRaceTo20Points2Way = 13828,
    FourthQuarterRaceTo10Points2Way = 13833,
    FourthQuarterRaceTo15Points2Way = 13834,
    FourthQuarterRaceTo20Points2Way = 13835,
    ThirdQuarterRaceTo5Points2Way = 13839,
    FourthQuarterRaceTo5Points2Way = 13840,
    HomeTeamTotalPointsOverUnderExcludingOvertime = 14301,
    AwayTeamTotalPointsOverUnderExcludingOvertime = 14302,
    AamsMatchbet1StQuarter = 14347,
    AamsMatchbet2NdQuarter = 14348,
    AamsMatchbet3Quarter = 14349,
    AamsMatchbet4ThQuarterExcludingOvertime = 14350,
    AamsMatchbetExcludingOvertime = 14352,
    AamsMatchbetIncludingOvertime = 14353,
    TotalPointsOverUnderExcludingOvertime = 14411,
    SecondHalfRaceTo5PointsIncludingOt = 15054,
    SecondHalfTotalPointsOverUnderIncludingOt = 15056,
    SecondHalfOddEvenTotalPointsIncludingOt = 15057,
    SecondHalfPointsSpreadIncludingOt = 15058,
    SecondHalfRaceTo5PointsExcludingOt = 15059,
    SecondHalfRaceTo10PointsExcludingOt = 15060,
    SecondHalfRaceTo15PointsExcludingOt = 15061,
    SecondHalfRaceTo20PointsExcludingOt = 15062,
    FirstQuarterHomeTeamToScore15PointsYesNo = 15261,
    FirstQuarterHomeTeamToScore20PointsYesNo = 15262,
    FirstQuarterHomeTeamToScore25PointsYesNo = 15263,
    FirstQuarterHomeTeamToScore30PointsYesNo = 15264,
    FirstQuarterHomeTeamToScore35PointsYesNo = 15265,
    FirstQuarterAwayTeamToScore15PointsYesNo = 15266,
    FirstQuarterAwayTeamToScore20PointsYesNo = 15267,
    FirstQuarterAwayTeamToScore25PointsYesNo = 15268,
    FirstQuarterAwayTeamToScore30PointsYesNo = 15269,
    FirstQuarterAwayTeamToScore35PointsYesNo = 15270,
    SecondQuarterHomeTeamToScore15PointsYesNo = 15271,
    SecondQuarterHomeTeamToScore20PointsYesNo = 15272,
    SecondQuarterHomeTeamToScore25PointsYesNo = 15273,
    SecondQuarterHomeTeamToScore30PointsYesNo = 15274,
    SecondQuarterHomeTeamToScore35PointsYesNo = 15275,
    SecondQuarterAwayTeamToScore15PointsYesNo = 15276,
    SecondQuarterAwayTeamToScore20PointsYesNo = 15277,
    SecondQuarterAwayTeamToScore25PointsYesNo = 15278,
    SecondQuarterAwayTeamToScore30PointsYesNo = 15279,
    SecondQuarterAwayTeamToScore35PointsYesNo = 15280,
    ThirdQuarterHomeTeamToScore15PointsYesNo = 15281,
    ThirdQuarterHomeTeamToScore20PointsYesNo = 15282,
    ThirdQuarterHomeTeamToScore25PointsYesNo = 15283,
    ThirdQuarterHomeTeamToScore30PointsYesNo = 15284,
    ThirdQuarterHomeTeamToScore35PointsYesNo = 15285,
    ThirdQuarterAwayTeamToScore15PointsYesNo = 15286,
    ThirdQuarterAwayTeamToScore20PointsYesNo = 15287,
    ThirdQuarterAwayTeamToScore25PointsYesNo = 15288,
    ThirdQuarterAwayTeamToScore30PointsYesNo = 15289,
    ThirdQuarterAwayTeamToScore35PointsYesNo = 15290,
    FourthQuarterHomeTeamToScore15PointsYesNo = 15291,
    FourthQuarterHomeTeamToScore20PointsYesNo = 15292,
    FourthQuarterHomeTeamToScore25PointsYesNo = 15293,
    FourthQuarterHomeTeamToScore30PointsYesNo = 15294,
    FourthQuarterHomeTeamToScore35PointsYesNo = 15295,
    FourthQuarterAwayTeamToScore15PointsYesNo = 15296,
    FourthQuarterAwayTeamToScore20PointsYesNo = 15297,
    FourthQuarterAwayTeamToScore25PointsYesNo = 15298,
    FourthQuarterAwayTeamToScore30PointsYesNo = 15299,
    FourthQuarterAwayTeamToScore35PointsYesNo = 15300,
}

(BasketballMarketTypeValue as any).__typeName = 'BasketballMarketTypeValue';
