import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sortByOrder'
})
export class SortByOrderPipe implements PipeTransform {

  transform(value: any[], key: string = 'sortOrder'): any[] {
    return value.sort((n1,n2) => 
    {
      return n1[key] - n2[key]; 
    });
  }
}
