import { Injectable, Injector, InjectionToken, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../components/_layout/confirm-dialog/confirm.dialog';
import { DIALOG_WIDTHS, DIALOG_HEIGHTS } from '../app.settings';

@Injectable({ providedIn: 'root' })
export class TotoDialog {

  public get openDialogs() {
    return this.dialog.openDialogs;
  }

  public constructor(
    private dialog: MatDialog,
    private readonly _translateService: TranslateService) { }

  public translate(key: string) {
    return this._translateService.instant(key);
  }

  public open(ref, options: IDialogOptions, afterClosed = _ => { }) {

    let alllowMultiple = options.allowMultipleDialogs || false;

    if (!alllowMultiple && this.dialog.openDialogs.length > 0) { 
      return
    };

    let data = options.data || {};
    let width = options.width || DIALOG_WIDTHS.M;
    let minWidth = options.minWidth || options.width;
    let height = options.height || DIALOG_HEIGHTS.AUTO;
    let minHeight = options.minHeight || DIALOG_HEIGHTS.AUTO;
    let restoreFocus = options.restoreFocus || false;
    data.__resolve = options.resolve;

    this.dialog
      .open(ref, {
        width: width,
        minWidth: minWidth,
        minHeight: minHeight,
        height: height,
        data: data,
        restoreFocus: restoreFocus
      })
      .afterClosed()
      .subscribe(result => {
        afterClosed(result);
      });
  }

  public confirm(options: IConfirmOptions, onConfirm: (cd?: MatDialogRef<ConfirmDialogComponent, any>) => any) {

    const title = options.title,
      message = options.message || '',
      template = options.template || null,
      templateRef = options.templateRef,
      image = options.image || null,
      width = options.width || '600px',
      height = options.height || 'auto',
      yes = options.yes || 'Action.Confirm',
      no = options.no || 'Action.Cancel',
      restoreFocus = options.restoreFocus || false,
      swapButtons = options.swapButtons || false;

    const ref = this.dialog
      .open(ConfirmDialogComponent, {
        width: width,
        height: height,
        data: {
          title: title,
          message: message,
          template: template,
          image: image,
          yes: yes,
          no: no,
          showNo: options.showNo === undefined ? true : options.showNo,
          showYes: options.showYes === undefined ? true : options.showYes,
          swapButtons: swapButtons,
          templateRef: templateRef
        },
        restoreFocus: restoreFocus
      });

    ref
      .afterClosed()
      .subscribe(result => {
        if (result) {
          onConfirm(ref);
        }
      });
  }
}

export interface IDialogOptions {
  data?: any;
  width?: string;
  minWidth?: string;
  height?: string;
  minHeight?: string;
  restoreFocus?: boolean;
  resolve?: Observable<any>;
  allowMultipleDialogs?: boolean;
}
export interface IConfirmOptions {
  restoreFocus?: boolean;
  no?: string;
  showNo?: boolean;
  yes?: string;
  showYes?: boolean;
  height?: string;
  width?: string;
  title?: string;
  message?: string;
  template?: string;
  templateRef?: TemplateRef<any>;
  image?: string;
  swapButtons?: boolean;
}
