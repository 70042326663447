import { Component, OnDestroy, HostBinding, Input  } from "@angular/core";

@Component({
  selector: "hamburger-toggle",
  templateUrl: "./hamburger-toggle.component.html",
  styleUrls: ['./hamburger-toggle.component.scss']
})
export class HamburgerToggleComponent {

  @HostBinding('class.toggled') @Input() public isOpen = false;

  constructor() {}

}
