import { Component, OnInit, Inject, Input, HostListener } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KeyCode } from "src/app/enum/key-code";
import { IConfirmOptions } from "src/app/helpers";


@Component({
  selector: "confirmation-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./confirm.dialog.html"
})
export class ConfirmationDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmOptions) {
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(e) {
    if (e.keyCode === KeyCode.ENTER) {
      if (document.activeElement.tagName == 'MAT-DIALOG-CONTAINER') {
        this.onConfirm();
      }
    }
  }

  public get swapButtons() {
    return this.data?.swapButtons ? true : false;
  }

  public get templateRef() {
    return this.data?.templateRef;
  }

  public onDecline() {
    this.dialogRef.close(false);
  }

  public onConfirm() {
    this.dialogRef.close(true);
  }

}
