import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Guid, MessageDispatcher } from 'src/app/helpers';
import { AccountService, AuthenticationService, TitleService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { finalize } from 'rxjs/operators';
import { ViewBase } from 'src/app/views/view.base';

@Component({
  selector: 'user-address-view',
  templateUrl: './user-address.view.html',
  styleUrls: ['./user-address.view.scss', '../shared.scss']
})
export class UserAddressViewComponent extends ViewBase {

  public titleFragments = [
    { key: 'Navigator.Menu.Settings.Settings' },
    { key: 'Views.User.Settings.Address.Title.Address' }
  ]

  public addressGroup: FormGroup;

  public cityOptions = [
    { text: "Tallinn", value: "Tallinn" },
    { text: "Tartu", value: "Tartu" }
  ];
  public countryOptions = [
    { text: "Common.Countries.Estonia", value: "Eesti" },
    { text: "Common.Countries.Finland", value: "Soome" }
  ];
   

  public addressSaveLoading = false;

  constructor(
    _titleService: TitleService,
    private _authService: AuthenticationService,
    private _accountService: AccountService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _msg: MessageDispatcher
  ) {
    super(_titleService);

    console.log(this._route.snapshot.data);
    // const { accountInfo, contact } = this._route.snapshot.data.settingsData || {};


    this.addressGroup = new FormGroup({
      userAddress1: new FormControl(''), 
      userAddress2: new FormControl(''),
      userPostalCode: new FormControl(''),
      userCity: new FormControl(''),
      userCountry: new FormControl('')
    });
  }
  
  public saveAddress() {
    this.addressSaveLoading = true; 
    const addressData = this.addressGroup.value;
  }
}
