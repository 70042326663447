import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { BankLinkProvider } from "src/app/enum/BankLinkProvider";
import { BANK_LINK_PROVIDER_OPTIONS, BankLinkProviderType } from "../..";
import { SwiperComponent } from "swiper/angular";
import { title } from "process";

@Component({
  selector: 'bank-login-options',
  templateUrl: './bank-login-options.component.html',
  styleUrls: ['./bank-login-options.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class BankLoginOptionsComponent {

  @Input() isLoginView: boolean = false;

  public Providers = BankLinkProvider;

  @Input() public providerOptions = [
    BankLinkProvider.Trustly,
    BankLinkProvider.Zimpler
  ];

  @Input() public selectedProvider: BankLinkProvider;

  @Output() selectedProviderChange = new EventEmitter();

  @Output() onProviderSelect = new EventEmitter();

  public onSlideChange([e]) {
    const provider = this.shownProviders[e.activeIndex]?.provider;
    this.selectProvider(provider);
  }

  public get selectedProviderIndex() {
    return this.shownProviders.findIndex(
      x => x.provider === this.selectedProvider
    );
  }

  public selectProvider(provider: BankLinkProvider) {
    this.selectedProvider = provider;
    
    this.selectedProviderChange.emit(this.selectedProvider);
    this.onProviderSelect.emit(this.selectedProvider);
  }

  public showProvider(provider: BankLinkProvider) {
    return this.providerOptions.includes(provider);
  }

  public get shownProviders() {
    return BANK_OPTIONS.filter(x =>
      this.showProvider(x.provider)
    );
  }
}

export const BANK_OPTIONS = [
    {
        provider: BankLinkProvider.Zimpler,
        title: 'Zimpler',
        image: '/assets/images/banklinks/zimpler.svg',
        proivderType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerSwedbank,
        title: 'Swedbank',
        image: '/assets/images/banklinks/swedbank_grey.svg',
        providerType: BankLinkProviderType.Estonia
    },
    {
        provider: BankLinkProvider.ZimplerLHV,
        title: 'LHV',
        image: '/assets/images/banklinks/lhv_grey.svg',
        providerType: BankLinkProviderType.Estonia
    },
    {
        provider: BankLinkProvider.ZimplerSEB,
        image: '/assets/images/banklinks/seb_grey.svg',
        title: 'SEB',
        providerType: BankLinkProviderType.Estonia
    },
    {
        provider: BankLinkProvider.ZimplerLuminor,
        image: '/assets/images/banklinks/luminor_grey.svg',
        title: 'Luminor',
        providerType: BankLinkProviderType.Estonia
    },
    {
        provider: BankLinkProvider.Trustly,
        image: '/assets/images/banklinks/trustly.svg',
        title: 'Trustly',
        providerType: BankLinkProviderType.International
    },
    // FINNISH BANKS
    {
        provider: BankLinkProvider.ZimplerOPBank,
        image: '/assets/images/banklinks/op_grey.svg',
        title: 'OP Bank',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerNordea,
        image: '/assets/images/banklinks/nordea_grey.svg',
        title: 'Nordea',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerDanskeBank,
        image: '/assets/images/banklinks/danske_grey.svg',
        title: 'Danske Bank',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerAktia,
        image: '/assets/images/banklinks/aktia_grey.svg',
        title: 'Aktia',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerAlandsBanken,
        image: '/assets/images/banklinks/alandsbanken_grey.svg',
        title: 'Alandsbanken',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerPOPPankki,
        image: '/assets/images/banklinks/poppankki_grey.svg',
        title: 'POP Pankki',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerSPankki,
        image: '/assets/images/banklinks/s-pankki_grey.svg',
        title: 'S-Pankki',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerHandelsbanken,
        image: '/assets/images/banklinks/handelsbanken_grey.svg',
        title: 'Handelsbanken',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerSäästöpankki,
        image: '/assets/images/banklinks/saastopankki_grey.svg',
        title: 'Säästöpankki',
        providerType: BankLinkProviderType.International
    },
    {
        provider: BankLinkProvider.ZimplerOMASäästöpankki,
        image: '/assets/images/banklinks/omasaastopankki_grey.svg',
        title: 'OMA säästöpankki',
        providerType: BankLinkProviderType.International
    }
]