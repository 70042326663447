import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { DataTableComponent } from 'src/app/components/_layout/data-table/data-table.component';
import { DatePeriod, asArray, TransactionType, TransactionStatus, HttpMethod } from 'src/app/enum';
import { TextValueFilterType } from 'src/app/enum/FilterType';
import { LocalStorage, DateHandler } from 'src/app/helpers';
import { AccountService } from 'src/app/services';
import { DataTableService } from 'src/app/services/data-table.service';
@Component({
  selector: 'transaction-log-view',
  templateUrl: './transaction-log.view.html',
  styleUrls: ['./transaction-log.view.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserTransactionLogViewComponent extends DataTableComponent<any, any> {

  
  public TransactionStatus = TransactionStatus;
  public DatePeriod = DatePeriod;
  public datePeriod: DatePeriod = DatePeriod.Today;

  public periodOptions = asArray(DatePeriod);
  public typeOptions = asArray(TransactionType);
  public statusOptions = asArray(TransactionStatus);

  public TextFilterValueType = TextValueFilterType;

  public displayedColumns: string[] = ['type', 'date', 'amount', 'status'];
  public dataUrl = 'Account/transactions';
  method = HttpMethod.Post;
  initialData: any;

  private _fgSnapshot: any;
  private _dateSubscription: Subscription;

  public selectedFilter: any = null;
  public showDateControls = false;

  public formGroup = new FormGroup({
    dateFrom: new FormControl(),
    dateTo: new FormControl(),
    period: new FormControl()
  });

  private _ticketDateSubscription: Subscription;

  public FILTER_LOCAL_STORAGE_KEY = "showTransactionFilters";
  public showFilters = false; //LocalStorage.getValue(this.FILTER_LOCAL_STORAGE_KEY) || false;

  constructor(
    private _appState: AppState,
    private _dateHandler: DateHandler, 
    private _route: ActivatedRoute,
    private _accountService: AccountService,
    dataService: DataTableService<any, any>,
    cd: ChangeDetectorRef
    ) {
      super(dataService, cd);

      const { initialData } = this._route.snapshot.data;

      this.initialData = initialData;

  }

  public get isViewMobile() {
    return this._appState.isViewMobile;
  }

  public setCustomFilter(filter: string): void {
    this.customFilter = filter;
    this.load();
  }

  public showAmountStatuses = [TransactionStatus.Complete];
  public showAmountTypes = [TransactionType.PayIn, TransactionType.CashOut]
  
  public isAmountRealized(row) {
    const { status, type } = row || {};

    if (this.showAmountStatuses.includes(status)) {
      return true;
    }

    if (this.showAmountTypes.includes(type)) {
      return true;
    }

    return false;
 
  }

  public setDatePeriod(period: DatePeriod = this.datePeriod): void {
    this.datePeriod = period;
    this.showDateControls = false;
    
    if (period === DatePeriod.All) {
      this.formGroup.reset();
    } else {
      const x = this._dateHandler.getPeriod(period);
      this.formGroup.patchValue({
        dateFrom: x.begin,
        dateTo: x.end
      })
    }
  }

  public getTransactionDetail(row) {
    console.log(row);
    const { id } = row;

    if (row.expandedDetail) {
      row.expandedDetail = null;
      return;
    }

    this._accountService.getUserTransactionStatusLog(id).result(null, res => {
      row.expandedDetail = res;
    }, e => {
      console.error(e);
    })
  }

  public toggleDateControls(toSet = !this.showDateControls) {
    this.datePeriod = DatePeriod.Custom;
    this.showDateControls = true;
  }

  public get isMobile() {
    return this._appState.isViewMobile
  }

  protected getSortParam(sort: MatSort) {
    if (!sort || !sort.active || sort.direction === "") {
      return '';
    }

    let colId = sort.active;

    return colId + ' ' + sort.direction;
  }

  public toggleFilters(toSet = !this.showFilters) {
    if (toSet) {
      this.formGroup.patchValue(this._fgSnapshot);
    } else {
      this._fgSnapshot = this.formGroup.value;
      this.clearFilters();
      this.formGroup.reset();
    }
    this.showFilters = toSet;
    LocalStorage.set(this.FILTER_LOCAL_STORAGE_KEY, toSet);
  }

  ngOnInit() {
    super.ngOnInit();

    this._fgSnapshot = this.formGroup.value;

    this._dateSubscription = this.formGroup.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(x => {

      const { dateFrom, dateTo } = this.formGroup.value;

      this.params = {
        from: dateFrom,
        to: dateTo
      };

      this.load();
    })
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    if (this._dateSubscription) {
      this._dateSubscription.unsubscribe();
    }
}
}
