export enum RugbyLeagueMarketTypeValue
{
    MatchWinner = 8133,
    Handicap = 8136,
    FirstTryscorer = 8137,
    FirstTeamToScoreATry = 8139,
    HalfTimeHandicap = 8143,
    TotalTries = 8144,
    TotalPoints = 8145,
    FirstScoringPlay = 8146,
    HalftimeFulltime = 8147,
    FirstTryTime = 8148,
    AnytimeTryscorer = 8149,
    SecondHalfHandicap = 8150,
    TotalMatchTriesOverUnder = 8151,
    TotalMatchPointsOverUnder = 8152,
    LastTryscorer = 8161,
    FirstTeamToScore = 8165,
    HighestScoringHalfTeam = 8169,
    LastTeamToScore = 8178,
    FirstTeamToScore2ndHalf = 8181,
    HighestScoringHalf = 8189,
    HalfTimeResult = 8190,
    FirstHalfTotalPointsOverUnder = 8191,
    SecondHalfTotalPointsOverUnder = 8192,
    ToWinBothHalves = 8193,
    HatTrickPlayers = 8247,
    FirstTeamToScoreWinsMatchYesNo = 8656,
    LastTeamToScoreATry = 8686,
    DrawNoBet = 8784,
    FirstHalfResultDrawNoBet = 8785,
    FirstHalfHandicap = 8786,
    MatchHandicap = 8787,
    SecondHalfHandicapNoDraw = 8788,
    DoubleResult = 9523,
    TotalPointsOddEven = 9524,
    SecondHalfResult = 9567,
    HalftimeFulltimeExcDraw = 9625,
    RaceTo10Points = 9916,
    MatchWinner2WayIncludingOvertime = 10208,
    AwayTeamTotalMatchPointsOverUnder = 11162,
    HomeTeamTotalMatchPointsOverUnder = 11163,
    HomeTeamTotalTriesOverUnder = 11164,
    AwayTeamTotalTriesOverUnder = 11165,
    SecondHalfTotalPointsOddEven = 11174,
    FirstTryConverted = 11175,
    PlayerToScore2Tries = 11188,
    AwayTeamFirstTryscorer = 11191,
    HomeTeamFirstTryscorer = 11192,
    AwayTeamTotalPoints = 11193,
    HomeTeamTotalPoints = 11194,
    AwayTeamTotalTries = 11195,
    HomeTeamTotalTries = 11196,
    FirstTryscorer2ndHalf = 11197,
    LastTryTime = 11198,
    FirstTryConvertedFirstHalf = 11199,
    FirstTryConvertedSecondHalf = 11200,
    HalftimeTotalPointsOddEven = 11201,
    WillThereBeADropGoal = 11202,
    ToWinToNil = 11203,
    TeamToScoreFirstTry2ndHalf = 11204,
    HomeTeamTotal1stHalfPointsOverUnder = 11208,
    AwayTeamTotal1stHalfPointsOverUnder = 11209,
    ToScoreFirstLeadAtHalftimeAndWin = 11218,
    WinBothHalvesYesNo = 11349,
    FirstTeamToScoreWinsMatch = 11353,
    AwayTeamTotal2ndHalfPointsOverUnder = 11372,
    HomeTeamTotal2ndHalfPointsOverUnder = 11373,
    SecondHalfResultDrawNoBet = 11406,
    RaceTo20Points = 11422,
    TeamToScoreNextTry = 12602,
    RaceTo15Points = 12603,
    RaceTo25Points = 12604,
    RaceTo30Points = 12605,
    RaceTo40Points = 12606,
    RaceTo35Points = 12607,
    HomeTeamTotalMatchPointsOddEven = 12608,
    AwayTeamTotalMatchPointsOddEven = 12609,
    HomeTeamTotal1stHalfPointsOddEven = 12610,
    AwayTeamTotal1stHalfPointsOddEven = 12611,
    HomeTeamTotal2ndHalfPointsOddEven = 12612,
    AwayTeamTotal2ndHalfPointsOddEven = 12613,
    NextScoringPlay2Way = 12957,
}

(RugbyLeagueMarketTypeValue as any).__typeName = 'RugbyLeagueMarketTypeValue';